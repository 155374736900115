import { getViewportHeight } from '@app/utils';
import { useAuth0 } from '@auth0/auth0-react';
import { EsLoading } from '@lib/components';
import loadable from '@loadable/component';
import { Box } from '@mui/material';
import { FC, Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';

import { OwnBukkenPage } from './own_bukken/OwnBukkenPage';

import DefaultLayout from '@/Components/DefaultLayout';
import { PartnerApplicantsReceivedDialog } from '@/Components/Partner/Applicants/PartnerApplicantsReceivedDialog';
import { PartnerApplicantsSentDialog } from '@/Components/Partner/Applicants/PartnerApplicantsSentDialog';
import { usePageView, useUserLogInTracking } from '@/Hooks/Analytics';
import { RequireCurrentUser } from '@/Hooks/Esa/RequireCurrentUser';
import { RequireLoginContext } from '@/Hooks/Esa/RequireLoginContext';
import { RouteDefinitions } from '@/Pages/RouteDefinitions';
import { MoveinApplicationRedirect } from '@/Pages/bukken/chintai/MoveinApplicationRedirect';
import { PreviewReservationRedirect } from '@/Pages/bukken/chintai/PreviewReservationRedirect';
import PropertyFullKeyRedirect from '@/Pages/bukken/chintai/PropertyFullKeyRedirect';
import { GeneralNotFoundPage } from '@/Pages/error/GeneralNotFoundPage';
import { PartnerPage } from '@/Pages/partner/PartnerPage';
import { PartnerApplicantsReceivedPage } from '@/Pages/partner_applicants/PartnerApplicantsReceivedPage';
import { PartnerApplicantsSentPage } from '@/Pages/partner_applicants/PartnerApplicantsSentPage';
import { OldDetailPathRedirect } from '@/Pages/redirect/OldDetailPathRedirect';
import { RedirectPage } from '@/Pages/redirect/RedirectPage';
import { SettingPage } from '@/Pages/setting/SettingPage';

const LoginPage = loadable(() => import(/* webpackChunkName: "LoginPage" */ './Login'));

const SearchAndDetailPage = loadable(
  () => import(/* webpackChunkName: "SearchAndDetailPage" */ './bukken/chintai/SearchAndDetailPage')
);
const MapSearchAndDetailPage = loadable(
  () => import(/* webpackChunkName: "MapSearchAndDetailPage" */ './bukken/chintai/MapSearchAndDetailPage')
);

/**
 * ログインが必要なルート定義 (ログインしていない場合はログインページにリダイレクト)
 * 無効なユーザのブロック、メンテナンス時表示もここで行う
 */
const PrivateRoute: FC = () => {
  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading) {
    return (
      <Box height={getViewportHeight(100)} width="100%" overflow="hidden">
        <EsLoading />
      </Box>
    );
  }

  if (!isAuthenticated) {
    return <RedirectPage url={() => '/login'} />;
  }

  return (
    <Fragment>
      <Routes>
        <Route element={<RequireLoginContext />}>
          <Route element={<RequireCurrentUser />}>
            <Route path="/" element={<DefaultLayout />}>
              <Route path="404" element={<GeneralNotFoundPage />} />
              <Route path="bukken">
                <Route path="chintai">
                  <Route path="detail">
                    <Route path="pfk/:pfk" element={<PropertyFullKeyRedirect />} />
                    {/*エリア/沿線検索の詳細画面のルーティング変更により一時的に旧URLでのアクセスを新URLへリダイレクトしている*/}
                    <Route path=":guid" element={<OldDetailPathRedirect />} />
                  </Route>
                  <Route path="search">
                    <Route path="detail/:guid" element={<SearchAndDetailPage />} />
                    <Route path="" element={<SearchAndDetailPage />} />
                    <Route path="*" element={<RedirectPage url={() => RouteDefinitions[404].path} />} />
                  </Route>
                  <Route path="mapSearch">
                    <Route path="detail/:guid" element={<MapSearchAndDetailPage />} />
                    <Route path="" element={<MapSearchAndDetailPage />} />
                    <Route path="*" element={<RedirectPage url={() => RouteDefinitions[404].path} />} />
                  </Route>
                  <Route path="" element={<RedirectPage url={() => RouteDefinitions.chintaiBukkenSearch.path} />} />
                  <Route path="*" element={<RedirectPage url={() => RouteDefinitions[404].path} />} />
                </Route>
                <Route path="" element={<RedirectPage url={() => RouteDefinitions.chintaiBukkenSearch.path} />} />
                <Route path="*" element={<RedirectPage url={() => RouteDefinitions[404].path} />} />
              </Route>
              <Route path="own_bukken">
                <Route path="detail/:guid" element={<OwnBukkenPage />} />
                <Route path="" element={<OwnBukkenPage />} />
                <Route path="*" element={<RedirectPage url={() => RouteDefinitions[404].path} />} />
              </Route>
              <Route path="partner" element={<PartnerPage />} />
              <Route path="partner_applicants">
                <Route path="sent" element={<PartnerApplicantsSentPage />} />
                <Route path="sent/:partnerApplicantUid" element={<PartnerApplicantsSentDialog />} />
                <Route path="received" element={<PartnerApplicantsReceivedPage />} />
                <Route path="received/:partnerApplicantUid" element={<PartnerApplicantsReceivedDialog />} />
                <Route path="" element={<RedirectPage url={() => RouteDefinitions.partnerApplicantsSent.path} />} />
                <Route path="*" element={<RedirectPage url={() => RouteDefinitions[404].path} />} />
              </Route>
              <Route path="" element={<RedirectPage url={() => RouteDefinitions.chintaiBukkenSearch.path} />} />
              <Route path="*" element={<RedirectPage url={() => RouteDefinitions[404].path} />} />
              <Route path="setting" element={<SettingPage />} />
              <Route path="setting/:organizationUid" element={<SettingPage />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </Fragment>
  );
};

const RootRoutes: FC = () => {
  usePageView();
  useUserLogInTracking();

  return (
    <Routes>
      <Route path="/notSupportedBrowser" />
      <Route path="login" element={<LoginPage />} />
      {/* NOTE: 内見/申込への遷移はトップバーなどを非表示にしたいため、こちらに配置 */}
      <Route path="bukken/chintai/preview-reservation" element={<PreviewReservationRedirect />} />
      <Route path="bukken/chintai/movein-application" element={<MoveinApplicationRedirect />} />
      <Route path="" element={<RedirectPage url={() => RouteDefinitions.chintaiBukkenSearch.path} />} />
      <Route path="*" element={<PrivateRoute />} />
    </Routes>
  );
};

export default RootRoutes;
