import { NyukyoMoshikomiMethodCode } from '@app/enums';
import { ChinshakuBoshuJokenViewTensai, ChinshakuBukken, Kukaku, SpecBukkenView, Tatemono } from '@app/models';
import { deepCompare, isNotNullOrUndefined } from '@app/utils';
import { useIsSmallDevice } from '@lib/components';
import { Box } from '@mui/material';
import { memo, FC } from 'react';

import { DownloadImageZipButton } from '@/Components/Action/DownloadImageZipButton';
import { FetchBukkenDataButton } from '@/Components/Action/FetchBukkenDataButton';
import { FlyerDownloadButtonContainer } from '@/Components/Action/FlyerDownloadButton';
import { MessageButton } from '@/Components/Action/MessageButton';
import { MoveinApplicationButton } from '@/Components/Action/MoveinApplicationButton';
import { PreviewReserveButton } from '@/Components/Action/PreviewReserveButton';
import { searchResultSxProps } from '@/Pages/bukken/chintai/SearchPartial/SearchResultStyles';
import { ViewStatusBukken } from '@/Services/ISendingViewStatusService';

type ActionButtonContainerProps = {
  isCustomerView: boolean;
  isSmallToMediumDevice: boolean;
  kukakuName: string;
  analyticsPlace: string;
  useIconButton?: boolean;
  tensaiArray: ChinshakuBoshuJokenViewTensai[];
} & (
  | {
      parentComponent: 'BukkenListItemMedium' | 'BukkenListItemSmall';
      specBukkenView: SpecBukkenView;
      motozukeGyoshaGuids: (string | null | undefined)[];
    }
  | {
      parentComponent: 'DetailHeader';
      chinshakuBukken: ChinshakuBukken;
      tatemono: Tatemono;
      kukaku: Kukaku | null;
    }
);

export const IconButtonThresholdPx = 355;

export const ActionButtonContainer: FC<ActionButtonContainerProps> = memo(props => {
  const bukkenOrBukkenView = props.parentComponent === 'DetailHeader' ? props.chinshakuBukken : props.specBukkenView;
  const motozukeGyoshaGuids =
    props.parentComponent === 'DetailHeader'
      ? [props.chinshakuBukken.chinshaku_boshu_joken?.chinshaku_boshu_joken_motozuke?.motozuke_gyosha_guid]
      : props.motozukeGyoshaGuids;
  const boshuJoken =
    props.parentComponent === 'DetailHeader'
      ? props.chinshakuBukken.chinshaku_boshu_joken
      : props.specBukkenView.chinshaku_boshu_joken_view;
  const moshikomiFlag = boshuJoken?.moshikomi_flag;
  const tensaiFlag = isNotNullOrUndefined(props.tensaiArray) && props.tensaiArray.length > 0;
  /**
   ** 表示フラグのいずれかにOFFが含まれるか
   */
  const notHyojiFlag =
    props.parentComponent === 'DetailHeader'
      ? !(
          props.chinshakuBukken.chinshaku_boshu_joken?.chinshaku_b2b_boshu_joken?.b2b_tatemono_name_hyoji_flag &&
          props.chinshakuBukken.chinshaku_boshu_joken?.chinshaku_b2b_boshu_joken?.b2b_heya_bango_hyoji_flag &&
          props.chinshakuBukken.chinshaku_boshu_joken?.chinshaku_b2b_boshu_joken?.b2b_eda_bango_hyoji_flag
        )
      : !(
          props.specBukkenView.chinshaku_boshu_joken_view?.b2b_tatemono_name_hyoji_flag &&
          props.specBukkenView.chinshaku_boshu_joken_view?.b2b_heya_bango_hyoji_flag &&
          props.specBukkenView.chinshaku_boshu_joken_view?.b2b_eda_bango_hyoji_flag
        );
  const bukkenGuid =
    props.parentComponent === 'DetailHeader'
      ? props.chinshakuBukken.chinshaku_bukken_guid
      : props.specBukkenView.chinshaku_bukken_view.chinshaku_bukken_guid;
  const viewStatusBukken: ViewStatusBukken =
    props.parentComponent === 'DetailHeader'
      ? {
          bukkenName: props.tatemono.tatemono_name ?? null,
          bukkenGuid: props.tatemono.tatemono_guid,
          kukakuName: props.kukaku?.heya_kukaku_number ?? null,
          kukakuGuid: props.kukaku?.kukaku_guid ?? null,
          kokaimotoGuid: props.chinshakuBukken.domain_guid,
        }
      : {
          bukkenName: props.specBukkenView.tatemono_name,
          bukkenGuid: props.specBukkenView.tatemono_guid,
          kukakuName: props.specBukkenView?.heya_kukaku_number ?? null,
          kukakuGuid: props.specBukkenView?.kukaku_guid ?? null,
          kokaimotoGuid: props.specBukkenView?.domain_guid ?? null,
        };
  const isSmallDevice = useIsSmallDevice();
  return (
    <Box display="flex" alignItems="center" width="100%">
      <Box
        flexGrow={1}
        sx={searchResultSxProps.buttonListContainer}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent={props.parentComponent === 'BukkenListItemMedium' ? 'flex-end' : 'flex-start'}
        flexWrap={isSmallDevice ? 'wrap' : 'nowrap'}
      >
        <MessageButton
          bukkenGuid={bukkenGuid}
          bukkenOrBukkenView={bukkenOrBukkenView}
          motozukeGyoshaGuids={motozukeGyoshaGuids}
          analyticsPlace={props.analyticsPlace}
          buttonVariant="outlined"
          buttonColor="primary"
          useIconButton={props.useIconButton === true}
          viewStatusBukken={viewStatusBukken}
        />
        {!props.isSmallToMediumDevice && (
          <FetchBukkenDataButton
            bukkenGuid={bukkenGuid}
            bukkenOrBukkenView={bukkenOrBukkenView}
            analyticsPlace={props.analyticsPlace}
            buttonVariant="outlined"
            viewStatusBukken={viewStatusBukken}
            buttonColor="primary"
            isCustomerView={props.isCustomerView}
            useIconButton={props.useIconButton === true}
            moshikomiFlag={isNotNullOrUndefined(moshikomiFlag) ? moshikomiFlag : false}
            notHyojiFlag={notHyojiFlag ?? false}
          />
        )}
        <PreviewReserveButton
          bukkenOrBukkenView={bukkenOrBukkenView}
          analyticsPlace={props.analyticsPlace}
          buttonVariant="outlined"
          buttonColor="primary"
          useIconButton={props.useIconButton === true}
          viewStatusBukken={viewStatusBukken}
        />
        <MoveinApplicationButton
          bukkenOrBukkenView={bukkenOrBukkenView}
          analyticsPlace={props.analyticsPlace}
          buttonVariant="outlined"
          buttonColor="primary"
          useIconButton={props.useIconButton === true}
          viewStatusBukken={viewStatusBukken}
          isApplied={isNotNullOrUndefined(moshikomiFlag) ? moshikomiFlag : false}
          webMoshikomiFlag={boshuJoken?.nyukyo_moshikomi_code === NyukyoMoshikomiMethodCode.web ?? false}
        />

        <FlyerDownloadButtonContainer
          bukkenGuid={bukkenGuid}
          analyticsPlace={props.analyticsPlace}
          buttonVariant="outlined"
          isCustomerView={props.isCustomerView}
          isSmallToMediumDevice={props.isSmallToMediumDevice}
          useIconButton={props.useIconButton === true}
          viewStatusBukken={viewStatusBukken}
        />
      </Box>
      <DownloadImageZipButton
        bukkenGuid={bukkenGuid}
        bukkenOrBukkenView={bukkenOrBukkenView}
        kukakuName={props.kukakuName}
        analyticsPlace={props.analyticsPlace}
        viewStatusBukken={viewStatusBukken}
        isCustomerView={props.isCustomerView}
        isSmallToMediumDevice={props.isSmallToMediumDevice}
        moshikomiFlag={isNotNullOrUndefined(moshikomiFlag) ? moshikomiFlag : false}
        tensaiFlag={tensaiFlag}
        notHyojiFlag={notHyojiFlag ?? false}
      />
    </Box>
  );
}, deepCompare);
