import { uniqStable, isNotNullOrEmptyString, isNullOrEmptyString, isNullOrUndefined } from '@app/utils';
import { useState, useEffect, useMemo, Reducer, useReducer, useCallback } from 'react';

import { SearchConditionHook } from './SearchConditionHook';

import { PlaceSearchCondition } from '@/Models/SearchConditions/ChintaiSearchConditions';

export type PlaceSearchConditionHook<C> = SearchConditionHook<PlaceSearchCondition[], C> & {
  onSelectPref: (pref: number, checked: boolean) => void;
  onSelectCities: (pref: number, cities: readonly number[], checked: boolean) => void;
  onSelectOoaza: (pref: number, city: number, ooazaList: readonly string[], checked: boolean) => void;
  actualConditionValue: readonly Readonly<PlaceSearchCondition>[];
  actualQueryValue: readonly Readonly<PlaceSearchCondition>[];
};

const calcurateActualCondition = (
  value: readonly Readonly<PlaceSearchCondition>[] | undefined
): readonly Readonly<PlaceSearchCondition>[] => {
  if (isNullOrUndefined(value)) {
    return [];
  }
  const checkedPlaceList = Array.from(value);
  // 全てのチェックが都道府県までしか指定されていない場合
  if (checkedPlaceList.every(it => it.city === 0)) {
    return checkedPlaceList;
  }
  const checkedCityList = checkedPlaceList.filter(it => it.city > 0);
  // 全てのチェックが市区郡までしか指定されていない場合
  if (checkedPlaceList.every(it => isNullOrEmptyString(it.ooaza))) {
    return checkedCityList;
  }

  return checkedCityList.filter(it => isNotNullOrEmptyString(it.ooaza));
};

type PlaceSearchConditionReducerAction =
  | {
      type: 'selectPref' | 'unselectPref';
      pref: number;
    }
  | {
      type: 'selectCities' | 'unselectCities';
      pref: number;
      cities: readonly number[];
    }
  | {
      type: 'selectOoaza' | 'unselectOoaza';
      pref: number;
      city: number;
      ooaza: readonly string[];
    }
  | {
      type: 'reset';
      newPlaces: readonly PlaceSearchCondition[];
    };
const placeSearchConditionReducer: Reducer<PlaceSearchCondition[], PlaceSearchConditionReducerAction> = (
  places,
  action
) => {
  switch (action.type) {
    case 'selectPref':
      return places.concat([{ prefecture: action.pref, city: 0, ooaza: '' }]);
    case 'unselectPref':
      return places.filter(p => p.prefecture !== action.pref);
    case 'selectCities':
      return places.concat(
        action.cities
          .filter(c => !places.some(p => p.prefecture === action.pref && p.city === c))
          .map(c => ({
            prefecture: action.pref,
            city: c,
            ooaza: '',
          }))
      );
    case 'unselectCities':
      return places.filter(p => p.prefecture !== action.pref || !action.cities.includes(p.city));
    case 'selectOoaza':
      return places.concat(
        action.ooaza
          .filter(o => !places.some(p => p.prefecture === action.pref && p.city === action.city && p.ooaza === o))
          .map(o => ({
            prefecture: action.pref,
            city: action.city,
            ooaza: o,
          }))
      );
    case 'unselectOoaza':
      return places.filter(
        p => p.prefecture !== action.pref || p.city !== action.city || !action.ooaza.includes(p.ooaza)
      );
    case 'reset':
      return Array.from(action.newPlaces);
  }
};

export const usePlaceSearchCondition = <C>(
  conditionValue: PlaceSearchCondition[],
  conditionSetter: (value: PlaceSearchCondition[], conditions: C) => void
): PlaceSearchConditionHook<C> => {
  const [value, dispatch] = useReducer(placeSearchConditionReducer, []);
  const [queryValue, setQueryValue] = useState<PlaceSearchCondition[]>([]);
  const actualConditionValue = useMemo(() => calcurateActualCondition(value), [value]);
  const actualQueryValue = useMemo(() => calcurateActualCondition(queryValue), [queryValue]);

  const clear = useCallback(() => dispatch({ type: 'reset', newPlaces: [] }), []);
  const setValue = useCallback(
    (newPlaces: readonly PlaceSearchCondition[]) => dispatch({ type: 'reset', newPlaces }),
    []
  );

  const onSelectPref = useCallback((pref: number, checked: boolean) => {
    if (checked) {
      dispatch({ type: 'selectPref', pref });
    } else {
      dispatch({ type: 'unselectPref', pref });
    }
  }, []);
  const onSelectCities = useCallback((pref: number, cities: readonly number[], checked: boolean) => {
    if (checked) {
      dispatch({ type: 'selectCities', pref, cities });
    } else {
      dispatch({ type: 'unselectCities', pref, cities });
    }
  }, []);
  const onSelectOoaza = useCallback((pref: number, city: number, ooaza: readonly string[], checked: boolean) => {
    if (checked) {
      dispatch({ type: 'selectOoaza', pref, city, ooaza });
    } else {
      dispatch({ type: 'unselectOoaza', pref, city, ooaza });
    }
  }, []);

  useEffect(() => {
    let places = Array.from(conditionValue);
    for (const selectedPlace of conditionValue) {
      places = places.concat([
        {
          prefecture: selectedPlace.prefecture,
          city: 0,
          ooaza: '',
        },
        {
          prefecture: selectedPlace.prefecture,
          city: selectedPlace.city,
          ooaza: '',
        },
      ]);
    }
    const newVal = uniqStable(places);
    dispatch({ type: 'reset', newPlaces: newVal });
    setQueryValue(newVal);
  }, [conditionValue]);

  const mapToConditions = useCallback(
    (conditions: C) => {
      conditionSetter(Array.from(actualConditionValue), conditions);
    },
    [actualConditionValue, conditionSetter]
  );

  return {
    value,
    actualConditionValue,
    setValue,
    queryValue,
    actualQueryValue,
    clear,
    mapToConditions,
    onSelectPref,
    onSelectCities,
    onSelectOoaza,
  };
};
