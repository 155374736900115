import { TatemonoShubetsuCode } from '@app/enums';
import { isNullOrUndefined } from '@app/utils';
import loadable from '@loadable/component';
import { Box } from '@mui/material';
import { FC, useMemo } from 'react';

import { ChinshakuBukkenDetailProps } from './ChinshakuBukkenDetailProps';
import { FacilityDetail } from './GeneralDescriptionTabPartial/FacilityDetail';
import { ForChukaiCompanyMessage } from './GeneralDescriptionTabPartial/ForChukaiCompanyMessage';
import { Overview } from './GeneralDescriptionTabPartial/Overview';

// photo-sphere-viewer を含む依存関係のスクリプトサイズが大きいため、
// 局所的に遅延ロードにしている
const PanoramaView = loadable(
  () => import(/* webpackChunkName: "PanoramaView" */ './GeneralDescriptionTabPartial/PanoramaView')
);

type GeneralDescriptionTabProps = ChinshakuBukkenDetailProps & {
  showMap: () => void;
};

export const GeneralDescriptionTab: FC<GeneralDescriptionTabProps> = props => {
  const isLand = useMemo(
    () => props.tatemono.tatemono_shubetsu_code === TatemonoShubetsuCode.land,
    [props.tatemono.tatemono_shubetsu_code]
  );
  const b2bBoshuJoken = props.chinshakuBukken?.chinshaku_boshu_joken?.chinshaku_b2b_boshu_joken;

  return (
    <Box p={{ xs: 0, md: 2 }}>
      {!isNullOrUndefined(props.chinshakuBukken.chinshaku_panorama_gazo) && (
        <PanoramaView chinshakuPanoramaGazoList={props.chinshakuBukken.chinshaku_panorama_gazo} />
      )}
      <ForChukaiCompanyMessage
        message={b2bBoshuJoken?.butsumoto_gyosha_message}
        isCustomerView={props.isCustomerView}
      />
      <Overview {...props} isLand={isLand} />
      {!isLand && <FacilityDetail {...props} />}
    </Box>
  );
};
