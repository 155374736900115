import { PriceUnitCode } from '@app/enums';
import { isNullOrUndefined } from '@app/utils';
import { SerializedStyles } from '@emotion/react';
import { Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import { DeleteOutline } from 'mdi-material-ui';
import { FC } from 'react';

import {
  ExtraInitialCostData,
  InitialCostCalcType,
  InitialCostDisplayData,
  InitialCostGetsujiReadOnlyType,
  createInitialCostSonotaGetsujiDisplayData,
} from './CreateInitialCostDisplayData';
import { InitialCostPriceForm } from './InitialCostPriceForm';

import { theme } from '@/Theme';
import { makePriceText } from '@/Utils/DisplayText/Money';

type InitialCostDisplayGetsujiHiyoContainerProps = {
  initialCostCalcData: InitialCostCalcType;
  getsujiReadOnlyData: InitialCostGetsujiReadOnlyType;
  initialCostDialogStyles: () => SerializedStyles;
  initialCostSonotaGetsujiWithLabelDisplayData: ExtraInitialCostData[];
  setInitialCostSonotaGetsujiWithLabelDisplayData: (value: React.SetStateAction<ExtraInitialCostData[]>) => void;
  extraInitialCostSonotaGetsujiDisplayData: ExtraInitialCostData[];
  setExtraInitialCostSonotaGetsujiDisplayData: (value: React.SetStateAction<ExtraInitialCostData[]>) => void;
  handleChangeTotalInitialCostPriceAmount: () => void;
  isSmallDevice: boolean;
};

const InitialCostDisplayGetsujiHiyoContainer: FC<InitialCostDisplayGetsujiHiyoContainerProps> = ({
  initialCostCalcData,
  initialCostDialogStyles,
  initialCostSonotaGetsujiWithLabelDisplayData,
  setInitialCostSonotaGetsujiWithLabelDisplayData,
  extraInitialCostSonotaGetsujiDisplayData,
  setExtraInitialCostSonotaGetsujiDisplayData,
  handleChangeTotalInitialCostPriceAmount,
  isSmallDevice,
  getsujiReadOnlyData,
}) => {
  const chinryoText = makePriceText(getsujiReadOnlyData.chinryo, PriceUnitCode.yen, {
    emptyIfZero: true,
    emptySubstituteText: '0円',
  });

  const kanrihiCode =
    initialCostCalcData.kanrihi_kubun_code === PriceUnitCode.actualCost ? PriceUnitCode.actualCost : PriceUnitCode.yen;

  const kyoekihiCode =
    initialCostCalcData.kyoekihi_kubun_code === PriceUnitCode.actualCost ? PriceUnitCode.actualCost : PriceUnitCode.yen;

  const kanrihiKyoekihiCode =
    kanrihiCode === PriceUnitCode.actualCost && kyoekihiCode === PriceUnitCode.actualCost
      ? PriceUnitCode.actualCost
      : PriceUnitCode.yen;

  const kanrihiKyoekihiText = makePriceText(
    getsujiReadOnlyData.kanrihi + getsujiReadOnlyData.kyoekihi,
    kanrihiKyoekihiCode,
    {
      emptyIfZero: true,
      emptySubstituteText: '0円',
    }
  );

  const zappiCode =
    initialCostCalcData.zappi_kubun_code === PriceUnitCode.actualCost ? PriceUnitCode.actualCost : PriceUnitCode.yen;
  const zappiText = makePriceText(getsujiReadOnlyData.zappi, zappiCode, {
    emptyIfZero: true,
    emptySubstituteText: '0円',
  });

  const initialCostGetsujiData = createInitialCostSonotaGetsujiDisplayData(chinryoText, kanrihiKyoekihiText, zappiText);

  const handleChangeSonotaGetsujiWithLabelPrice = (index: number, price: number): void => {
    const newInitialCostSonotaGetsujiWithLabelDisplayData = [...initialCostSonotaGetsujiWithLabelDisplayData];
    newInitialCostSonotaGetsujiWithLabelDisplayData[index].price = price;
    setInitialCostSonotaGetsujiWithLabelDisplayData(newInitialCostSonotaGetsujiWithLabelDisplayData);
  };

  const handleChangeExtraInitialCostSonotaGetsujiLabel = (index: number, label: string): void => {
    const newExtraInitialCostDisplayData = [...extraInitialCostSonotaGetsujiDisplayData];
    newExtraInitialCostDisplayData[index].label = label;
    setExtraInitialCostSonotaGetsujiDisplayData(newExtraInitialCostDisplayData);
  };
  const handleChangeExtraInitialCostSonotaGetsujiPrice = (index: number, price: number): void => {
    const newExtraInitialCostDisplayData = [...extraInitialCostSonotaGetsujiDisplayData];
    newExtraInitialCostDisplayData[index].price = price;
    setExtraInitialCostSonotaGetsujiDisplayData(newExtraInitialCostDisplayData);
  };
  const removeExtraInitialCostSonotaGetsujiInputForm = (index: number): void => {
    const newExtraInitialCostData = [...extraInitialCostSonotaGetsujiDisplayData];
    newExtraInitialCostData.splice(index, 1);
    setExtraInitialCostSonotaGetsujiDisplayData(newExtraInitialCostData);
  };

  return (
    <InitialCostDisplayGetsujiHiyoPresenter
      initialCostGetsujiData={initialCostGetsujiData}
      initialCostDialogStyles={initialCostDialogStyles}
      initialCostSonotaGetsujiWithLabelDisplayData={initialCostSonotaGetsujiWithLabelDisplayData}
      handleChangeSonotaGetsujiWithLabelPrice={handleChangeSonotaGetsujiWithLabelPrice}
      extraInitialCostSonotaGetsujiDisplayData={extraInitialCostSonotaGetsujiDisplayData}
      handleChangeExtraInitialCostSonotaGetsujiLabel={handleChangeExtraInitialCostSonotaGetsujiLabel}
      handleChangeExtraInitialCostSonotaGetsujiPrice={handleChangeExtraInitialCostSonotaGetsujiPrice}
      removeExtraInitialCostSonotaGetsujiInputForm={removeExtraInitialCostSonotaGetsujiInputForm}
      handleChangeTotalInitialCostPriceAmount={handleChangeTotalInitialCostPriceAmount}
      isSmallDevice={isSmallDevice}
    />
  );
};

export { InitialCostDisplayGetsujiHiyoContainer as InitialCostDisplayGetsujiHiyo };

type InitialCostDisplayGetsujiHiyoPresenterProps = {
  initialCostGetsujiData: InitialCostDisplayData[];
  initialCostDialogStyles: () => SerializedStyles;
  initialCostSonotaGetsujiWithLabelDisplayData: ExtraInitialCostData[];
  handleChangeSonotaGetsujiWithLabelPrice: (index: number, price: number) => void;
  extraInitialCostSonotaGetsujiDisplayData: ExtraInitialCostData[];
  handleChangeExtraInitialCostSonotaGetsujiLabel: (index: number, label: string) => void;
  handleChangeExtraInitialCostSonotaGetsujiPrice: (index: number, price: number) => void;
  removeExtraInitialCostSonotaGetsujiInputForm: (index: number) => void;
  handleChangeTotalInitialCostPriceAmount: () => void;
  isSmallDevice: boolean;
};

export const InitialCostDisplayGetsujiHiyoPresenter: FC<InitialCostDisplayGetsujiHiyoPresenterProps> = ({
  initialCostGetsujiData,
  initialCostDialogStyles,
  initialCostSonotaGetsujiWithLabelDisplayData,
  handleChangeSonotaGetsujiWithLabelPrice,
  extraInitialCostSonotaGetsujiDisplayData,
  handleChangeExtraInitialCostSonotaGetsujiLabel,
  handleChangeExtraInitialCostSonotaGetsujiPrice,
  removeExtraInitialCostSonotaGetsujiInputForm,
  handleChangeTotalInitialCostPriceAmount,
  isSmallDevice,
}) => (
  <Grid item xs={12}>
    {initialCostGetsujiData.map(data => {
      if (isNullOrUndefined(data.content)) {
        return undefined;
      }
      return (
        <Grid container key={data.label}>
          <Grid item css={[initialCostDialogStyles, { backgroundColor: theme.palette.secondary.light }]} xs={12} md={5}>
            <Typography variant="body1">
              <b>{data.label}</b>
            </Typography>
          </Grid>
          <Grid item css={[initialCostDialogStyles, { textAlign: 'end' }]} xs={12} md={7}>
            {data.content}
          </Grid>
        </Grid>
      );
    })}
    {initialCostSonotaGetsujiWithLabelDisplayData.map((data, index) => {
      return (
        <Grid container key={data.label}>
          <Grid item css={[initialCostDialogStyles, { backgroundColor: theme.palette.secondary.light }]} xs={12} md={5}>
            <Typography variant="body1">
              <b>{data.label}</b>
            </Typography>
          </Grid>
          <Grid item css={initialCostDialogStyles} xs={12} md={7}>
            <InitialCostPriceForm
              handleChangeInitialCostPrice={handleChangeSonotaGetsujiWithLabelPrice}
              handleChangeTotalInitialCostAmount={handleChangeTotalInitialCostPriceAmount}
              index={index}
              value={data.price === 0 ? '' : data.price.toString()}
              isSmallDevice={isSmallDevice}
            />
          </Grid>
        </Grid>
      );
    })}
    {extraInitialCostSonotaGetsujiDisplayData.map((data, index) => {
      if (isNullOrUndefined(data.price)) {
        return undefined;
      }
      return (
        <Grid container key={data.id}>
          <Grid item css={[initialCostDialogStyles, { backgroundColor: theme.palette.secondary.light }]} xs={12} md={5}>
            <Stack direction="row" css={{ justifyContent: 'space-between' }}>
              <TextField
                placeholder="名称"
                variant="standard"
                value={data.label}
                onChange={event => {
                  handleChangeExtraInitialCostSonotaGetsujiLabel(index, event.target.value);
                }}
                inputProps={{
                  sx: { fontWeight: 'bold' },
                }}
                css={{
                  [theme.breakpoints.down('md')]: {
                    width: '100%',
                  },
                }}
              />
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() => {
                  handleChangeExtraInitialCostSonotaGetsujiPrice(index, 0);
                  removeExtraInitialCostSonotaGetsujiInputForm(index);
                  handleChangeTotalInitialCostPriceAmount();
                }}
                css={{ padding: 0 }}
              >
                <DeleteOutline fontSize="small" />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item css={initialCostDialogStyles} xs={12} md={7}>
            <InitialCostPriceForm
              handleChangeInitialCostPrice={handleChangeExtraInitialCostSonotaGetsujiPrice}
              handleChangeTotalInitialCostAmount={handleChangeTotalInitialCostPriceAmount}
              index={index}
              value={data.price === 0 ? '' : data.price.toString()}
              isSmallDevice={isSmallDevice}
            />
          </Grid>
        </Grid>
      );
    })}
  </Grid>
);
