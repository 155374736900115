import { Box } from '@mui/material';
import { toPng } from 'html-to-image';
import { useEffect, useRef, FC, ReactNode } from 'react';

import { reportError } from '@/ErrorLogger';

const convertToPng = (current: HTMLDivElement | null, id: string) => {
  const currentHeight = current?.clientHeight ?? 0;
  const imagingDom = document.getElementById(id);
  /**
   * 画像化の対象とする要素が空の場合はreturnする
   */
  if (current === null || currentHeight <= 0 || imagingDom === null) {
    return;
  }
  const beforeComponent = document.getElementById(id)?.children[0];
  /**
   * 既に画像化されている場合はreturnする
   */
  if (beforeComponent?.tagName === 'IMG') {
    return;
  }
  toPng(current, { cacheBust: true, fontEmbedCSS: '' })
    .then(dataUrl => {
      const img = new Image();
      img.src = dataUrl;
      img.style.maxWidth = '100%';
      img.style.height = `${currentHeight}px`;
      return img;
    })
    .then(img => {
      imagingDom.innerHTML = '';
      imagingDom.appendChild(img);
    })
    .catch(err => {
      reportError(`一部項目の読み込みに失敗しました:${err}`, 'ImagedComponent');
    });
};
type ImagedComponentProps = {
  children: ReactNode;
};
export const ImagedComponent: FC<ImagedComponentProps> = ({ children }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const isShown = useRef(false);
  const id = Date.now().toString();
  useEffect(() => {
    /**
     * 開発環境(StrictMode)においてuseEffectが2回発火するのを防ぐ
     * 参考：https://ja.reactjs.org/blog/2022/03/29/react-v18.html#new-strict-mode-behaviors
     */
    if (!isShown.current) {
      isShown.current = true;
      convertToPng(ref.current, id);
    }
  }, [id]);
  return (
    <Box id={id} display="flex">
      <div ref={ref} style={{ width: 'fit-content' }}>
        {children}
      </div>
    </Box>
  );
};
