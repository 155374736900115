import { uniqStable } from '@app/utils';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useList } from 'react-use';

import { SearchConditionMapper } from './SearchConditionHook';

import { LineSearchCondition, StationSearchCondition } from '@/Models/SearchConditions/ChintaiSearchConditions';

export type LineAndStationSearchConditionHook<C> = {
  selectedPrefs: readonly number[];
  selectedLines: readonly Readonly<LineSearchCondition>[];
  selectedStations: readonly Readonly<StationSearchCondition>[];
  queryLines: readonly Readonly<LineSearchCondition>[];
  queryStations: readonly Readonly<StationSearchCondition>[];
  actualLinesCondition: readonly Readonly<LineSearchCondition>[];
  actualStationCondition: readonly Readonly<StationSearchCondition>[];
  onSelectPref: (pref: number, checked: boolean) => void;
  onSelectLines: (selectedPrefs: number[], selectedLines: readonly number[], checked: boolean) => void;
  onSelectStations: (
    selectedPrefs: number[],
    selectedLine: number,
    selectedStations: readonly number[],
    checked: boolean
  ) => void;
  clear: () => void;
  isAllStationShown: boolean;
  setIsAllStationShown: (value: boolean) => void;
} & SearchConditionMapper<C>;

export const useLineAndStationCondition = <C>(
  lineConditionValue: readonly Readonly<LineSearchCondition>[],
  stationConditionValue: readonly Readonly<StationSearchCondition>[],
  conditionSetter: (_lines: LineSearchCondition[], _stations: StationSearchCondition[], _conditions: C) => void
): LineAndStationSearchConditionHook<C> => {
  const [prefs, { set: setPrefs, push: pushPref, filter: filterPref }] = useList<number>();
  const [lines, setLines] = useState<readonly Readonly<LineSearchCondition>[]>([]);
  const [stations, setStations] = useState<readonly Readonly<StationSearchCondition>[]>([]);
  const [queryLines, setQueryLines] = useState<readonly Readonly<LineSearchCondition>[]>([]);
  const [isAllStationShown, setIsAllStationShown] = useState<boolean>(false);
  const clear = useCallback((): void => {
    setPrefs([]);
    setLines([]);
    setStations([]);
  }, [setPrefs]);

  const onSelectPref = useCallback(
    (pref: number, checked: boolean): void => {
      if (checked) {
        pushPref(pref);
      } else {
        filterPref(p => p !== pref);
      }
    },
    [filterPref, pushPref]
  );
  const onSelectLines = useCallback((selectedPrefs: number[], selectedLines: readonly number[], checked: boolean) => {
    if (checked) {
      const addedLines: readonly Readonly<LineSearchCondition>[] = selectedLines.map(selectedLine => ({
        lineCode: selectedLine,
        prefCodeList: selectedPrefs,
      }));
      setLines(prev => prev.concat(addedLines));
    } else {
      setLines(prev => prev.filter(l => !selectedLines.includes(l.lineCode)));
    }
  }, []);
  const onSelectStations = useCallback(
    (selectedPrefs: number[], selectedLine: number, selectedStations: readonly number[], checked: boolean) => {
      if (checked) {
        const addedStations: readonly Readonly<StationSearchCondition>[] = selectedStations.map(selectedStation => ({
          prefCodeList: selectedPrefs,
          lineCode: selectedLine,
          stationCode: selectedStation,
        }));
        setStations(prev => prev.concat(addedStations));
      } else {
        setStations(prev => prev.filter(it => !selectedStations.includes(it.stationCode)));
      }
    },
    []
  );

  useEffect(() => {
    setStations(stationConditionValue);
    const newLines =
      stationConditionValue.length > 0
        ? uniqStable(
            stationConditionValue.map<LineSearchCondition>(s => ({
              lineCode: s.lineCode,
              prefCodeList: s.prefCodeList,
            }))
          )
        : lineConditionValue;
    setLines(newLines);
    setQueryLines(newLines);
    setPrefs(uniqStable(newLines.flatMap(l => l.prefCodeList)));
  }, [lineConditionValue, setPrefs, stationConditionValue]);

  useEffect(() => {
    const linesOnSelectedPrefs = lines.filter(l => l.prefCodeList.some(it => prefs.includes(it)));
    if (linesOnSelectedPrefs.length !== lines.length) {
      setLines(linesOnSelectedPrefs);
    }
  }, [lines, prefs]);

  useEffect(() => {
    const stationsOnSelectedLines = stations.filter(s => lines.find(l => l.lineCode === s.lineCode));
    if (stationsOnSelectedLines.length !== stations.length) {
      setStations(stationsOnSelectedLines);
    }
  }, [lines, stations]);

  const actualLinesCondition = useMemo(() => (stations.length > 0 ? [] : lines), [lines, stations.length]);
  const actualStationCondition = stations;

  const mapToConditions = useCallback(
    (conditions: C) =>
      conditionSetter(Array.from(actualLinesCondition), Array.from(actualStationCondition), conditions),
    [actualLinesCondition, actualStationCondition, conditionSetter]
  );

  return {
    selectedPrefs: prefs,
    selectedLines: lines,
    selectedStations: stations,
    queryLines,
    queryStations: stationConditionValue,
    actualLinesCondition,
    actualStationCondition,
    onSelectPref,
    onSelectLines,
    onSelectStations,
    clear,
    isAllStationShown,
    setIsAllStationShown,
    mapToConditions,
  };
};
