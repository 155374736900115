import { isNullOrUndefined } from '@app/utils';
import { useCallback, MouseEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  getChintaiSearchPageQuery,
  parseChintaiSearchPageQuery,
} from '@/Models/SearchConditions/ChintaiBukkenSearchConditions';

export const useTransitToMotodukeList = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const makeMotodukeListQuery = useCallback(
    (motodukeName: string) => {
      const conditions = parseChintaiSearchPageQuery(location.search);
      conditions.kanriGyosha = motodukeName;
      return getChintaiSearchPageQuery(conditions);
    },
    [location.search]
  );

  const transitToMotodukeList = useCallback(
    (motodukeName: string | undefined, e: MouseEvent) => {
      if (isNullOrUndefined(motodukeName)) {
        return;
      }
      e.stopPropagation();
      const locationToMotodukeList = makeMotodukeListQuery(motodukeName);
      if (isNullOrUndefined(locationToMotodukeList)) {
        return;
      }
      navigate({
        search: locationToMotodukeList,
      });
    },
    [navigate, makeMotodukeListQuery]
  );

  return transitToMotodukeList;
};
