import { isNullOrUndefined } from '@app/utils';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import { FC, useMemo } from 'react';

import { searchResultHeaderZIndex } from '@/Consts/Values';
import { useIsSmallDevice } from '@/Hooks/Styles/IsSmallDevice';
import {
  asChintaiResultOrder,
  ChintaiResultOrder,
  chintaiResultOrderToText,
  ItemsPerPageCollection,
} from '@/Models/SearchConditions/ChintaiSearchConditionEnums/ChintaiSearchConditionEnums';

type OwnBukkenSearchResultHeaderContainerProps = {
  totalCount: number | undefined;
  currentOrder: ChintaiResultOrder;
  onChangeOrder: (order: ChintaiResultOrder) => void;
  isCustomerView: boolean;
  itemsPerPage: number | undefined;
  setItemsPerPage: (value: number) => void;
  currentPage: number;
};

const OwnBukkenSearchResultHeaderContainer: FC<OwnBukkenSearchResultHeaderContainerProps> = ({
  totalCount,
  currentOrder,
  onChangeOrder,
  isCustomerView,
  itemsPerPage,
  setItemsPerPage,
  currentPage,
}) => {
  const isSmallDevice = useIsSmallDevice();
  const itemsCountText = useMemo(() => {
    if (isNullOrUndefined(itemsPerPage) || isNullOrUndefined(totalCount)) {
      return '';
    }
    return totalCount === 0
      ? '0 件'
      : `${itemsPerPage * (currentPage - 1) + 1}-${Math.min(itemsPerPage * currentPage, totalCount)}件`;
  }, [currentPage, itemsPerPage, totalCount]);

  return (
    <OwnBukkenSearchResultHeaderPresenter
      totalCount={totalCount}
      itemsCountText={itemsCountText}
      currentOrder={currentOrder}
      onChangeOrder={onChangeOrder}
      isCustomerView={isCustomerView}
      isSmallDevice={isSmallDevice}
      itemsPerPage={itemsPerPage}
      setItemsPerPage={setItemsPerPage}
    />
  );
};

export { OwnBukkenSearchResultHeaderContainer as OwnBukkenSearchResultHeader };

type OwnBukkenSearchResultHeaderPresenterProps = {
  totalCount: number | undefined;
  itemsCountText: string;
  currentOrder: ChintaiResultOrder;
  onChangeOrder: (order: ChintaiResultOrder) => void;
  isCustomerView: boolean;
  isSmallDevice: boolean;
  itemsPerPage: number | undefined;
  setItemsPerPage: (value: number) => void;
};

export const OwnBukkenSearchResultHeaderPresenter: FC<OwnBukkenSearchResultHeaderPresenterProps> = ({
  totalCount,
  itemsCountText,
  currentOrder,
  onChangeOrder,
  isCustomerView,
  isSmallDevice,
  itemsPerPage,
  setItemsPerPage,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      position="sticky"
      top={0}
      p={1}
      mt={2}
      zIndex={searchResultHeaderZIndex}
      bgcolor={theme => theme.palette.background.default}
    >
      <Box display="flex" flexDirection="row" alignItems="center" width="100%">
        <Box flexShrink={0} minWidth="3rem">
          <Box pr={2}>
            <Typography fontSize="18px" fontWeight="bold" component="span">
              {itemsCountText}
            </Typography>
            <Typography component="span">{`/ 全${totalCount}件`}</Typography>
          </Box>
        </Box>
      </Box>
      <Box flexGrow={1} minWidth={0} width="100%" display="flex" alignItems="center" justifyContent="flex-end">
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center" p={1} maxHeight="32px">
            <Box display="flex" flexDirection="row" alignItems="center">
              {itemsPerPage && (
                <Select
                  variant="outlined"
                  value={itemsPerPage}
                  onChange={(e): void => setItemsPerPage(Number(e.target.value))}
                  sx={{ height: '32px', backgroundColor: '#fff' }}
                >
                  {Object.entries(ItemsPerPageCollection).map(([label, value]) => {
                    return (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            </Box>
          </Box>
          <Select
            variant="outlined"
            value={currentOrder}
            onChange={(e): void =>
              onChangeOrder(asChintaiResultOrder(e.target.value) ?? ChintaiResultOrder.chinryoAscending)
            }
            disableUnderline={true}
            sx={{ height: '32px', backgroundColor: '#fff', maxWidth: isSmallDevice ? '6rem' : '100%' }}
          >
            {Object.values(ChintaiResultOrder).map(o => {
              if (isCustomerView && o === ChintaiResultOrder.kokokuryoDescending) {
                return undefined;
              }
              return (
                <MenuItem key={o} value={o}>
                  {chintaiResultOrderToText(o)}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
      </Box>
    </Box>
  );
};
