import { Grid } from '@mui/material';
import { ReactNode, FC } from 'react';

import { searchConditionSxProps } from './SearchConditionStyle';

import { borderSxProps } from '@/Hooks/Styles/BorderStyle';

type ConditionFormGridProps = {
  children: ReactNode;
  isSmallDevice: boolean;
  label: string;
};

export const ConditionFormGrid: FC<ConditionFormGridProps> = ({ children, isSmallDevice, label }) => {
  return (
    <Grid container sx={{ ...searchConditionSxProps.root, ...borderSxProps.borderBottom }}>
      <Grid
        item
        xs={12}
        sm={2}
        sx={{ ...searchConditionSxProps.firstCol, ...(isSmallDevice ? borderSxProps.borderBottom : undefined) }}
      >
        {label}
      </Grid>
      <Grid item xs={12} sm={10} sx={searchConditionSxProps.secondCol}>
        {children}
      </Grid>
    </Grid>
  );
};
