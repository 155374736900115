import { isNotNullOrUndefined } from '@app/utils';
import { Box, BoxProps } from '@mui/material';
import { FC } from 'react';

import { LabelColors, labelColorSxProps } from '@/Hooks/Styles/ColorStyle';
import { textSxProps } from '@/Hooks/Styles/TextStyle';

type SquareChipProps = BoxProps & {
  color?: LabelColors;
  bold?: boolean;
};
export const SquareChip: FC<SquareChipProps> = ({ children, color, bold, ...boxProps }) => {
  const labelColor = color ?? 'grey';
  const result = Object.entries(labelColorSxProps).find(it => it[0] === labelColor);
  const chipColorSxProps = isNotNullOrUndefined(result) ? result[1] : {};
  const boldSxProps = bold ? textSxProps.bold : {};
  // 属性を指定する場合に中身が undefined だと動作不良を起こすため、やむを得ず null 結合している
  return (
    <Box
      {...boxProps}
      px="4px"
      py="2px"
      sx={{
        display: 'inline-block',
        borderRadius: `2px`,
        ...chipColorSxProps,
        ...boldSxProps,
        ...textSxProps.sm,
      }}
    >
      {children}
    </Box>
  );
};
