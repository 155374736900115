import { ChinshakuBoshuJokenTensai } from '@app/models';
import { deepCompare } from '@app/utils';
import { TabContext, TabList, TabPanel, tabPanelClasses } from '@mui/lab';
import { Box, IconButton, Link, styled, Tab, Typography } from '@mui/material';
import { Close } from 'mdi-material-ui';
import { useCallback, useMemo, useState, useRef, FC, useEffect, forwardRef, memo, Fragment, ChangeEvent } from 'react';
import { useMeasure, useMount } from 'react-use';
import { UseMeasureRef } from 'react-use/lib/useMeasure';

import { bukkenDetailSxProps, DetailTopHeaderBox, DetailContainerZIndex } from './BukkenDetailStyle';
import { BukkenHeaderContainer } from './BukkenHeader';
import { BusinessInfoTab } from './BusinessInfoTab';
import { calcDisplayData, CalculatedDisplayData } from './CalcDisplayData';
import { ChinshakuBukkenDetailProps } from './ChinshakuBukkenDetailProps';
import { GeneralDescriptionTab } from './GeneralDescriptionTab';
import { SurroundingsInfoTab } from './SurroundingsInfoTab';

import { useDependency } from '@/Hooks/DependencyHook';
import { borderSxProps } from '@/Hooks/Styles/BorderStyle';
import { useIsSmallDevice } from '@/Hooks/Styles/IsSmallDevice';
import { textSxProps } from '@/Hooks/Styles/TextStyle';
import {
  ActionButtonContainer,
  IconButtonThresholdPx,
} from '@/Pages/bukken/chintai/SearchPartial/BukkenListItem/ActionButtonContainer';
import { viewStatusAction } from '@/Services/ISendingViewStatusService';
import { makeKukakuName } from '@/Utils/DisplayText/KukakuName';

const AnalyticsPlaceName = '賃借物件詳細';

const tabs = {
  generalDescription: '1',
  surroundingsInfo: '2',
  businessInfoTab: '3',
} as const;

const PadlessTabPanel = styled(TabPanel)({
  [`&.${tabPanelClasses.root}`]: {
    padding: 0,
  },
});

const DetailContainerContainer: FC<ChinshakuBukkenDetailProps> = props => {
  const sendingViewStatusService = useDependency('sendingViewStatusService');

  const { chinshakuBukken, kukaku, tatemono } = props;
  const calculatedDisplayData = calcDisplayData(chinshakuBukken, kukaku, tatemono);
  const [currentTab, setCurrentTab] = useState<string>(tabs.generalDescription);
  const isSmallDevice = useIsSmallDevice();
  const onTabChange: (event: ChangeEvent<unknown>, value: string) => void = useCallback((_, newTab: string): void => {
    setCurrentTab(newTab);
  }, []);

  useMount(() => {
    sendingViewStatusService.send(
      {
        bukkenName: tatemono.tatemono_name ?? null,
        bukkenGuid: tatemono.tatemono_guid,
        kukakuName: kukaku?.heya_kukaku_number ?? null,
        kukakuGuid: kukaku?.kukaku_guid ?? null,
        kokaimotoGuid: chinshakuBukken.domain_guid,
      },
      viewStatusAction.detail
    );
  });

  const [actionButtonParentRef, actionButtonParentMeasure] = useMeasure<HTMLDivElement>();
  const useIconButton = useMemo(
    () => actionButtonParentMeasure.width !== 0 && actionButtonParentMeasure.width < IconButtonThresholdPx,
    [actionButtonParentMeasure.width]
  );

  const showMap = useCallback(
    (ref: HTMLDivElement | null): void => {
      setCurrentTab(tabs.surroundingsInfo);
      // TODO: 遷移時に地図タブの上部へと自動的にスクロール
      isSmallDevice && ref?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    },
    [isSmallDevice]
  );

  const kukakuName = makeKukakuName(chinshakuBukken, tatemono, kukaku?.heya_kukaku_number ?? '');

  return (
    <DetailContainerPresenter
      {...props}
      showMap={showMap}
      kukakuName={kukakuName}
      currentTab={currentTab}
      onTabChange={onTabChange}
      actionButtonParentRef={actionButtonParentRef}
      useIconButton={useIconButton}
      calculatedDisplayData={calculatedDisplayData}
      isSmallDevice={isSmallDevice}
    />
  );
};

export type DetailContainerPresenterProps = ChinshakuBukkenDetailProps & {
  showMap: (ref: HTMLDivElement | null) => void;
  kukakuName: string;
  currentTab: string;
  onTabChange: (event: ChangeEvent<unknown>, value: string) => void;
  actionButtonParentRef: UseMeasureRef<HTMLDivElement>;
  useIconButton: boolean;
  calculatedDisplayData: CalculatedDisplayData;
  isSmallDevice: boolean;
  isOwnBukkenPage: boolean | undefined;
};

export const DetailContainerPresenter: FC<DetailContainerPresenterProps> = props => {
  const {
    chinshakuBukken,
    kukaku,
    tatemono,
    showMap,
    kukakuName,
    isCustomerView,
    isMobile,
    currentTab,
    onTabChange,
    actionButtonParentRef,
    useIconButton,
    calculatedDisplayData,
    isSmallDevice,
    isOwnBukkenPage,
  } = props;

  const ref = useRef<HTMLElement>(null);
  const [tabTop, setTabTop] = useState<string>();
  const tabListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current && setTabTop(`${ref.current.getBoundingClientRect().height}px`);
  }, [tabTop]);

  return (
    <Fragment>
      <TopHeader {...props} kukakuName={kukakuName} tensaiArray={calculatedDisplayData?.tensaiArray} ref={ref} />
      <Box pb={{ xs: 2, md: 0 }}>
        <BukkenHeaderContainer
          {...props}
          showMap={() => showMap(tabListRef.current)}
          isCustomerView={isCustomerView}
          headerDisplayData={calculatedDisplayData}
        />
      </Box>
      <TabContext value={currentTab}>
        <Box
          mx={{ xs: 0, md: 2 }}
          sx={{
            borderBottom: theme => `2px solid ${theme.palette.divider}`,
            ...(isMobile ? borderSxProps.borderY : undefined),
            position: 'sticky',
            top: tabTop,
            background: '#fff',
            zIndex: DetailContainerZIndex,
          }}
          ref={tabListRef}
        >
          {/*TabListの要素数は動的に変えることができないためTabListごと切り替える必要がある*/}
          {isCustomerView ? (
            <TabList onChange={onTabChange}>
              <Tab label="物件情報" value={tabs.generalDescription} />
              <Tab label="地図・近隣情報" value={tabs.surroundingsInfo} />
            </TabList>
          ) : (
            <TabList onChange={onTabChange}>
              <Tab label="物件情報" value={tabs.generalDescription} />
              <Tab label="地図・近隣情報" value={tabs.surroundingsInfo} />
              <Tab label="不動産会社様向け情報" value={tabs.businessInfoTab} />
            </TabList>
          )}
        </Box>
        <PadlessTabPanel value={tabs.generalDescription}>
          <GeneralDescriptionTab {...props} showMap={() => showMap(tabListRef.current)} />
        </PadlessTabPanel>
        <PadlessTabPanel value={tabs.surroundingsInfo}>
          <SurroundingsInfoTab {...props} />
        </PadlessTabPanel>
        {!isCustomerView && (
          <PadlessTabPanel value={tabs.businessInfoTab}>
            <BusinessInfoTab {...props} />
          </PadlessTabPanel>
        )}
      </TabContext>
      {!isOwnBukkenPage && isMobile && (
        <Box
          {...{ ref: actionButtonParentRef }}
          display="flex"
          sx={{
            padding: 1,
            position: 'sticky',
            bottom: 0,
            backgroundColor: 'background.paper',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            zIndex: DetailContainerZIndex + 1,
          }}
        >
          <ActionButtonContainer
            analyticsPlace={AnalyticsPlaceName}
            isCustomerView={isCustomerView}
            kukakuName={kukakuName}
            useIconButton={useIconButton}
            parentComponent="DetailHeader"
            chinshakuBukken={chinshakuBukken}
            kukaku={kukaku}
            tatemono={tatemono}
            tensaiArray={calculatedDisplayData.tensaiArray}
            isSmallToMediumDevice={isSmallDevice}
          />
        </Box>
      )}
    </Fragment>
  );
};

type TopHeaderProps = ChinshakuBukkenDetailProps & {
  kukakuName: string;
  tensaiArray: ChinshakuBoshuJokenTensai[];
  isOwnBukkenPage: boolean | undefined;
};

const TopHeaderForwardRef = forwardRef<HTMLElement, TopHeaderProps>((props, ref) => {
  const headerKukakuName = useMemo(
    () => (props.kukakuName.length > 20 ? props.kukakuName.slice(0, 20).concat('…') : props.kukakuName),
    [props.kukakuName]
  );
  const isSmallDevice = useIsSmallDevice();
  return (
    <DetailTopHeaderBox ref={ref}>
      <Box sx={bukkenDetailSxProps.headerLabel}>
        <IconButton component={Link} onClick={props.onClose} size="large">
          <Close />
        </IconButton>
        <Typography component="span" sx={{ wordBreak: 'break-word', ...textSxProps.lg, ...textSxProps.bold }}>
          {props.isMobile ? headerKukakuName : props.kukakuName}
        </Typography>
      </Box>
      {!props.isOwnBukkenPage && !isSmallDevice && (
        <Box display="flex" flexDirection="row" alignItems="center" pr={1}>
          <ActionButtonContainer
            analyticsPlace={AnalyticsPlaceName}
            isCustomerView={props.isCustomerView}
            kukakuName={props.kukakuName}
            useIconButton={
              false /* PC版物件詳細のヘッダーは物件名を表示する十分なスペースが存在することが保証されているため切り替えを行わない */
            }
            parentComponent="DetailHeader"
            chinshakuBukken={props.chinshakuBukken}
            kukaku={props.kukaku}
            tatemono={props.tatemono}
            tensaiArray={props.tensaiArray}
            isSmallToMediumDevice={isSmallDevice}
          />
        </Box>
      )}
    </DetailTopHeaderBox>
  );
});

const TopHeader = memo(TopHeaderForwardRef, deepCompare);

export default DetailContainerContainer;
