import { useState, useEffect, useCallback } from 'react';

import { PartnerSearchConditionHook, SearchConditionHook } from './SearchConditionHook';

export const useStringSearchCondition = <C>(
  conditionValue: string,
  conditionSetter: (value: string, conditions: C) => void
): SearchConditionHook<string, C> => {
  const [value, setValue] = useState<string>('');
  const clear = useCallback((): void => {
    setValue('');
  }, []);

  useEffect(() => {
    setValue(conditionValue);
  }, [conditionValue]);
  const mapToConditions = useCallback(
    (conditions: C): void => {
      conditionSetter(value, conditions);
    },
    [conditionSetter, value]
  );

  return {
    value,
    setValue,
    queryValue: conditionValue,
    clear,
    mapToConditions,
  };
};

export const useBooleanSearchCondition = <C>(
  conditionValue: boolean,
  conditionSetter: (value: boolean, conditions: C) => void
): SearchConditionHook<boolean, C> => {
  const [value, setValue] = useState<boolean>(false);
  const clear = useCallback((): void => {
    setValue(false);
  }, []);

  useEffect(() => {
    setValue(conditionValue);
  }, [conditionValue]);

  const mapToConditions = useCallback(
    (conditions: C): void => {
      conditionSetter(value, conditions);
    },
    [conditionSetter, value]
  );

  return {
    value,
    setValue,
    queryValue: conditionValue,
    clear,
    mapToConditions,
  };
};

export const usePartnerStringSearchCondition = (conditionValue: string): PartnerSearchConditionHook<string> => {
  const [value, setValue] = useState<string>('');
  const clear = useCallback((): void => {
    setValue('');
  }, []);

  useEffect(() => {
    setValue(conditionValue);
  }, [conditionValue]);

  return {
    value,
    setValue,
    queryValue: conditionValue,
    clear,
  };
};
