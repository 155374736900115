import { range, deepCompare } from '@app/utils';
import { Select } from '@e-seikatsu/design-system';
import { Box, FormGroup, Grid } from '@mui/material';
import { memo, FC, Fragment } from 'react';

import { searchConditionSxProps } from './SearchConditionStyle';

import { SearchConditionHook } from '@/Hooks/Search/SearchConditionHook';
import { borderSxProps } from '@/Hooks/Styles/BorderStyle';
import { ChintaiSearchConditions } from '@/Models/SearchConditions/ChintaiSearchConditions';

type BukkenSennyuMensekiFormProps = {
  fromHook: SearchConditionHook<string, ChintaiSearchConditions>;
  toHook: SearchConditionHook<string, ChintaiSearchConditions>;
  isSmallDevice: boolean;
};

const areaRange = range(20, 70, 5).concat(range(80, 100, 10));

const BukkenSennyuMensekiForm: FC<BukkenSennyuMensekiFormProps> = memo(({ fromHook, toHook, isSmallDevice }) => {
  return (
    <Grid container sx={{ ...searchConditionSxProps.root, ...borderSxProps.borderBottom }}>
      <Grid
        item
        xs={12}
        sm={2}
        sx={{ ...searchConditionSxProps.firstCol, ...(isSmallDevice ? borderSxProps.borderBottom : undefined) }}
      >
        面積
      </Grid>
      <Grid item xs={12} sm={10} sx={{ ...searchConditionSxProps.secondCol, ...searchConditionSxProps.inputCol }}>
        <Box width="100%">
          <FormGroup row={true} sx={searchConditionSxProps.formGroupRoot}>
            {isSmallDevice ? (
              <Box display="flex" alignItems="center" width="100%" gap={1}>
                <MensekiFrom fromHook={fromHook} isSmallDevice={isSmallDevice} />
                ～
                <MensekiTo toHook={toHook} isSmallDevice={isSmallDevice} />
              </Box>
            ) : (
              <Fragment>
                <Box display="flex" alignItems="center" gap={1}>
                  <MensekiFrom fromHook={fromHook} isSmallDevice={isSmallDevice} />
                  ～
                  <MensekiTo toHook={toHook} isSmallDevice={isSmallDevice} />
                </Box>
              </Fragment>
            )}
          </FormGroup>
        </Box>
      </Grid>
    </Grid>
  );
}, deepCompare);

type MensekiFromProps = {
  fromHook: SearchConditionHook<string, ChintaiSearchConditions>;
  isSmallDevice: boolean;
};

export const MensekiFrom: FC<MensekiFromProps> = props => {
  return (
    <Select
      size="small"
      length={props.isSmallDevice ? 'full' : 'normal'}
      value={props.fromHook.value}
      onChange={(value): void => {
        props.fromHook.setValue(value ?? '');
      }}
    >
      <Select.Option value="">下限なし</Select.Option>
      {areaRange.map(it => (
        <Select.Option value={it.toString()} key={it}>
          {`${it}㎡`}
        </Select.Option>
      ))}
    </Select>
  );
};

type MensekiToProps = {
  toHook: SearchConditionHook<string, ChintaiSearchConditions>;
  isSmallDevice: boolean;
};

export const MensekiTo: FC<MensekiToProps> = props => {
  return (
    <Select
      size="small"
      length={props.isSmallDevice ? 'full' : 'normal'}
      value={props.toHook.value}
      onChange={(value): void => {
        props.toHook.setValue(value ?? '');
      }}
    >
      <Select.Option value="">上限なし</Select.Option>
      {areaRange.map(it => (
        <Select.Option value={it.toString()} key={it}>
          {`${it}㎡`}
        </Select.Option>
      ))}
    </Select>
  );
};

export default BukkenSennyuMensekiForm;
