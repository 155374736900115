import { ChinshakuBukken, SpecBukkenView } from '@app/models';
import { MuiButtonColorType } from '@lib/components';
import { Button, buttonClasses, Tooltip } from '@mui/material';
import { DatabaseImport } from 'mdi-material-ui';
import { FC, Fragment, useMemo } from 'react';

import { ActionButtonLayout } from './ActionButtonLayout';

import { useCurrentUser } from '@/Hooks/Esa/RequireCurrentUser';
import { getBukkenButtonData, useFetchBukkenData } from '@/Hooks/SearchResult/FetchBukkenDataHook';
import { useIsValidTakkenLicenseNumber } from '@/Hooks/ValidationHooks';
import { searchResultSxProps } from '@/Pages/bukken/chintai/SearchPartial/SearchResultStyles';
import { ViewStatusBukken } from '@/Services/ISendingViewStatusService';

type ContainerProps = {
  bukkenGuid: string;
  bukkenOrBukkenView: SpecBukkenView | ChinshakuBukken;
  analyticsPlace: string;
  buttonVariant: 'text' | 'outlined' | 'contained';
  viewStatusBukken: ViewStatusBukken;
  buttonColor?: MuiButtonColorType | 'accent';
  useIconButton: boolean;
  isCustomerView: boolean;
  moshikomiFlag: boolean;
  notHyojiFlag: boolean;
};

/**
 * Squareから物件を取り込む(Oneネット取込)ボタン
 * @param bukkenGuid 物件のGuid
 * @param bukkenOrBukkenView 物件データ
 * @param analyticsPlace GAに送信するデータ名
 * @param buttonVariant buttonのタイプ
 * @param viewStatusBukken 閲覧物件データ
 * @param buttonColor buttonの色
 * @param useIconButton ボタンにアイコンが表示されているか
 * @param isCustomerView 接客用表示かどうか
 * @param moshikomiFlag 入居申込済かどうか
 * @param notHyojiFlag 表示フラグのいずれかにOFFが含まれるか
 */
const FetchBukkenDataButtonContainer: FC<ContainerProps> = ({
  bukkenGuid,
  bukkenOrBukkenView,
  analyticsPlace,
  buttonVariant,
  viewStatusBukken,
  buttonColor,
  useIconButton,
  isCustomerView,
  moshikomiFlag,
  notHyojiFlag,
}) => {
  const isValidTakkenLicenseNumber = useIsValidTakkenLicenseNumber();
  const b2bKokaiCode =
    'chinshaku_bukken_view' in bukkenOrBukkenView
      ? bukkenOrBukkenView.chinshaku_boshu_joken_view.b2b_kokai_code
      : bukkenOrBukkenView.b2b_kokai_code;

  const currentUser = useCurrentUser();
  const isOwnBukken = useMemo(() => {
    if (!currentUser.ebone) {
      return false;
    }

    return currentUser.ebone.domainGuid === bukkenOrBukkenView.domain_guid;
  }, [bukkenOrBukkenView.domain_guid, currentUser.ebone]);

  const fetchBukkenButtonData = getBukkenButtonData(
    moshikomiFlag,
    notHyojiFlag,
    isValidTakkenLicenseNumber,
    isOwnBukken,
    b2bKokaiCode
  );

  const fetchBukkenData = useFetchBukkenData(bukkenGuid, analyticsPlace, viewStatusBukken);

  return (
    <FetchBukkenDataButtonPresenter
      buttonVariant={buttonVariant}
      buttonColor={buttonColor}
      useIconButton={useIconButton}
      isCustomerView={isCustomerView}
      disabled={fetchBukkenButtonData.disabled}
      tooltipText={fetchBukkenButtonData.tooltipText}
      fetchBukkenData={fetchBukkenData}
    />
  );
};

export { FetchBukkenDataButtonContainer as FetchBukkenDataButton };

export type FetchBukkenDataButtonPresenterProps = {
  buttonVariant: 'text' | 'outlined' | 'contained';
  buttonColor?: MuiButtonColorType | 'accent';
  useIconButton: boolean;
  isCustomerView: boolean;
  disabled: boolean;
  tooltipText: string;
  fetchBukkenData: () => void;
};

export const FetchBukkenDataButtonPresenter: FC<FetchBukkenDataButtonPresenterProps> = ({
  buttonVariant,
  buttonColor,
  useIconButton,
  isCustomerView,
  disabled,
  tooltipText,
  fetchBukkenData,
}) => {
  return (
    <Fragment>
      {!isCustomerView ? (
        <ActionButtonLayout>
          <Tooltip title={tooltipText} disableInteractive={!disabled}>
            <span>
              <Button
                variant={buttonVariant}
                color={buttonColor !== 'accent' ? buttonColor : 'primary'}
                startIcon={!useIconButton ? <DatabaseImport /> : ''}
                onClick={!disabled ? fetchBukkenData : undefined}
                disabled={disabled}
                sx={{
                  [`&.${buttonClasses.root}`]: useIconButton && searchResultSxProps.actionSquareButton,
                  [`&.${buttonClasses.outlined}`]: searchResultSxProps.actionButtonOutlined,
                  [`& .${buttonClasses.startIcon}`]: searchResultSxProps.actionButtonStartIcon,
                }}
              >
                取込
              </Button>
            </span>
          </Tooltip>
        </ActionButtonLayout>
      ) : null}
    </Fragment>
  );
};
