import { deepCompare } from '@app/utils';
import { Select } from '@e-seikatsu/design-system';
import { Box, Grid } from '@mui/material';
import { memo, FC } from 'react';

import { searchConditionSxProps } from './SearchConditionStyle';

import { reportError } from '@/ErrorLogger';
import { ShikikinReikinConditionHook } from '@/Hooks/Search/CustomSearchCondition';
import { borderSxProps } from '@/Hooks/Styles/BorderStyle';
import { ChintaiSearchConditions } from '@/Models/SearchConditions/ChintaiSearchConditions';

const reportPlace = '@/Pages/bukken/chintai/SearchPartial/BukkenSearchForms';

type BukkenSyokiHiyoFormProps = {
  hook: ShikikinReikinConditionHook<ChintaiSearchConditions>;
  isSmallDevice: boolean;
};

const BukkenSyokiHiyoForm: FC<BukkenSyokiHiyoFormProps> = memo(({ hook, isSmallDevice }) => {
  const onChangeShikikin = (selectedValue: string): void => {
    if (hook.value === undefined) {
      return;
    }
    switch (selectedValue) {
      case '0':
        hook.clearShikikin();
        break;
      case '1':
        hook.setValue({ ...hook.value, isExcludeShikikin: true, shikikinTo: undefined });
        break;
      case '2':
        hook.setValue({ ...hook.value, isExcludeShikikin: false, shikikinTo: 1 });
        break;
    }
  };
  const onChangeReikin = (selectedValue: string): void => {
    if (hook.value === undefined) {
      return;
    }
    switch (selectedValue) {
      case '0':
        hook.clearReikin();
        break;
      case '1':
        hook.setValue({ ...hook.value, isExcludeReikin: true, reikinTo: undefined });
        break;
      case '2':
        hook.setValue({ ...hook.value, isExcludeReikin: false, reikinTo: 1 });
        break;
    }
  };
  const hookToValue = (isExclude: boolean | undefined, valueTo: number | undefined): number => {
    if (!isExclude && valueTo === undefined) {
      return 0;
    }
    if (isExclude && valueTo === undefined) {
      return 1;
    }
    if (!isExclude && valueTo === 1) {
      return 2;
    }
    reportError(`敷金・礼金の値が不正です。（${isExclude}, ${valueTo}）`, reportPlace, true);
    return 0;
  };
  return (
    <Grid container sx={{ ...searchConditionSxProps.root, ...borderSxProps.borderBottom }}>
      <Grid
        item
        xs={12}
        sm={2}
        sx={{ ...searchConditionSxProps.firstCol, ...(isSmallDevice ? borderSxProps.borderBottom : undefined) }}
      >
        敷金/礼金
      </Grid>
      <Grid
        item
        xs={12}
        sm={10}
        gap={1}
        sx={{ ...searchConditionSxProps.secondCol, ...searchConditionSxProps.inputCol }}
      >
        <Box display="flex" alignItems="center" width="100%" gap={1}>
          <Box flexShrink="0">敷金</Box>
          <Box width="100%">
            <Select
              size="small"
              length={isSmallDevice ? 'full' : 'normal'}
              value={hookToValue(hook.value?.isExcludeShikikin, hook.value?.shikikinTo)}
              onChange={(value): void => {
                onChangeShikikin(String(value));
              }}
            >
              <Select.Option value={0}>指定しない</Select.Option>
              <Select.Option value={1}>なし</Select.Option>
              <Select.Option value={2}>1ヶ月以内</Select.Option>
            </Select>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" width="100%" gap={1}>
          <Box flexShrink="0">礼金</Box>
          <Box width="100%">
            <Select
              size="small"
              length={isSmallDevice ? 'full' : 'normal'}
              value={hookToValue(hook.value?.isExcludeReikin, hook.value?.reikinTo)}
              onChange={(value): void => {
                onChangeReikin(String(value));
              }}
            >
              <Select.Option value={0}>指定しない</Select.Option>
              <Select.Option value={1}>なし</Select.Option>
              <Select.Option value={2}>1ヶ月以内</Select.Option>
            </Select>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}, deepCompare);

export default BukkenSyokiHiyoForm;
