import { isNullOrUndefined } from '@app/utils';
import { Grid, Theme, Typography, useTheme } from '@mui/material';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { Fragment, FC, ReactNode } from 'react';

import { ImagedComponent } from '@/Components/DataDisplay/ImagedComponent';

export type DisplayData = {
  label: string;
  content: ReactNode;
  span?: boolean;
  isImage?: boolean;
};
type DetailGridProps = {
  data: DisplayData[];
};
const DetailGridContainer: FC<DetailGridProps> = ({ data }) => {
  const theme = useTheme();
  const GridBorder = `1px solid ${theme.palette.divider}`;
  const gridCellCommons: SystemStyleObject<Theme> = {
    padding: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRight: GridBorder,
    borderBottom: GridBorder,
  };
  return <DetailGridPresenter data={data} gridBorder={GridBorder} gridCellCommons={gridCellCommons} />;
};

export { DetailGridContainer as DetailGrid };

type DetailGridPresenterProps = {
  data: DisplayData[];
  gridBorder: string;
  gridCellCommons: SystemStyleObject<Theme>;
};

export const DetailGridPresenter: FC<DetailGridPresenterProps> = ({ data, gridBorder, gridCellCommons }) => {
  return (
    <Grid
      container
      sx={{
        borderLeft: gridBorder,
        borderTop: gridBorder,
      }}
    >
      {data.map(d => {
        if (isNullOrUndefined(d.content)) {
          return undefined;
        }
        return (
          <Fragment key={d.label}>
            <Grid
              item
              sx={{
                backgroundColor: 'secondary.light',
                ...gridCellCommons,
              }}
              xs={4}
              md={3}
            >
              <Typography variant="body1">
                <b>{d.label}</b>
              </Typography>
            </Grid>
            <Grid item sx={gridCellCommons} xs={8} md={d.span ? 9 : 3}>
              {d.isImage === true ? (
                <ImagedComponent>
                  <DetailContent data={d} />
                </ImagedComponent>
              ) : (
                <DetailContent data={d} />
              )}
            </Grid>
          </Fragment>
        );
      })}
    </Grid>
  );
};

type DetailContentProps = {
  data: DisplayData;
};
export const DetailContent: FC<DetailContentProps> = ({ data }) => {
  return (
    <Fragment>
      {typeof data.content === 'string' ? (
        <Typography variant="body1" component="div">
          {data.content}
        </Typography>
      ) : (
        data.content
      )}
    </Fragment>
  );
};
