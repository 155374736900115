import { Datejun, JunCodeToTextMap } from '@app/models';
import { isNullOrUndefined } from '@app/utils';
import { differenceInYears } from 'date-fns';

export const getTextFromDatejun = (datejun: number | null | undefined): string => {
  if (datejun == null) {
    return '';
  }
  const dateJunStruct = Datejun.numberToDatejun(datejun)?.destructDatejun() ?? null;

  if (dateJunStruct === null) {
    return '';
  }

  const { day, junCode, month, year } = dateJunStruct;
  const yearAndMonthText = `${year.toFixed(0)}年${month.toFixed(0)}月`;
  if (day > 0) {
    return `${yearAndMonthText}${day.toFixed(0)}日`;
  } else if (junCode > 0) {
    const junText = junCode <= 3 ? JunCodeToTextMap[junCode] : '(不正な旬コードです)';
    return `${yearAndMonthText}${junText}`;
  } else {
    return yearAndMonthText;
  }
};

export const shunkoDatejunToChikuNensu = (datejun: Datejun | null | undefined): number | null => {
  if (isNullOrUndefined(datejun)) {
    return null;
  }
  return differenceInYears(new Date(), datejun.toDate());
};
