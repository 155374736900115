import { useState } from 'react';

export const PlaceSelectorModes = ['pref', 'city', 'ooaza'] as const;
export type PlaceSelectorModes = typeof PlaceSelectorModes[number];

export const StationAndLineSelectorModes = ['pref', 'line', 'station'] as const;
export type StationAndLineSelectorModes = typeof StationAndLineSelectorModes[number];

export type RegionConditionStateHook = {
  placeCurrentMode: PlaceSelectorModes;
  setPlaceCurrentMode: (value: PlaceSelectorModes) => void;
  lineCurrentMode: StationAndLineSelectorModes;
  setLineCurrentMode: (value: StationAndLineSelectorModes) => void;
  onClear: () => void;
  onClearPlace: () => void;
  onClearStationAndLine: () => void;
  onPlaceModeNext: () => void;
  onPlaceModePrev: () => void;
  onLineModeNext: () => void;
  onLineModePrev: () => void;
};
export const useRegionConditionStateHook = (): RegionConditionStateHook => {
  const [placeCurrentMode, setPlaceCurrentMode] = useState<PlaceSelectorModes>('pref');
  const [lineCurrentMode, setLineCurrentMode] = useState<StationAndLineSelectorModes>('pref');
  const onClear = (): void => {
    onClearPlace();
    onClearStationAndLine();
  };
  const onClearPlace = (): void => {
    setPlaceCurrentMode(PlaceSelectorModes[0]);
  };
  const onClearStationAndLine = (): void => {
    setLineCurrentMode(StationAndLineSelectorModes[0]);
  };
  const onPlaceModeNext = (): void => {
    const idx = PlaceSelectorModes.indexOf(placeCurrentMode);
    const nextIdx = idx + 1 === PlaceSelectorModes.length ? idx : idx + 1;
    setPlaceCurrentMode(PlaceSelectorModes[nextIdx]);
  };
  const onPlaceModePrev = (): void => {
    const idx = PlaceSelectorModes.indexOf(placeCurrentMode);
    const prevIdx = idx === 0 ? 0 : idx - 1;
    setPlaceCurrentMode(PlaceSelectorModes[prevIdx]);
  };
  const onLineModeNext = (): void => {
    const idx = StationAndLineSelectorModes.indexOf(lineCurrentMode);
    const nextIdx = idx + 1 === StationAndLineSelectorModes.length ? idx : idx + 1;
    setLineCurrentMode(StationAndLineSelectorModes[nextIdx]);
  };
  const onLineModePrev = (): void => {
    const idx = StationAndLineSelectorModes.indexOf(lineCurrentMode);
    const prevIdx = idx === 0 ? 0 : idx - 1;
    setLineCurrentMode(StationAndLineSelectorModes[prevIdx]);
  };

  return {
    placeCurrentMode,
    setPlaceCurrentMode,
    lineCurrentMode,
    setLineCurrentMode,
    onClear,
    onClearPlace,
    onClearStationAndLine,
    onPlaceModeNext,
    onPlaceModePrev,
    onLineModeNext,
    onLineModePrev,
  };
};
