import { useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useUserActionTracker } from '@/Hooks/Analytics';
import { useDependency } from '@/Hooks/DependencyHook';
import { ServiceConfigure } from '@/Services/Configure';
import { viewStatusAction, ViewStatusBukken } from '@/Services/ISendingViewStatusService';

const NO_AD_TOOLTIP_TEXT = '広告掲載ができない物件です';
const INVALID_TAKKEN_LICENSE_NUMBER_TOOLTIP_TEXT = '宅建免許番号が不正なため物件取込できません';
const IS_OWN_BUKKEN_TOOLTIP_TEXT = '自社物件は取込できません';

export type BukkenButtonData = {
  tooltipText: string;
  disabled: boolean;
};

export const getBukkenButtonData = (
  moshikomiFlag: boolean,
  notHyojiFlag: boolean,
  isValidTakkenLicenseNumber: boolean,
  isOwnBukken: boolean,
  b2bKokaiCode: number | undefined
): BukkenButtonData => {
  const disabled = moshikomiFlag || notHyojiFlag || (!isValidTakkenLicenseNumber && b2bKokaiCode === 3) || isOwnBukken;
  const tooltipText = (() => {
    if (isOwnBukken) {
      return IS_OWN_BUKKEN_TOOLTIP_TEXT;
    } else if (moshikomiFlag || notHyojiFlag) {
      return NO_AD_TOOLTIP_TEXT;
    } else if (!isValidTakkenLicenseNumber && b2bKokaiCode === 3) {
      return INVALID_TAKKEN_LICENSE_NUMBER_TOOLTIP_TEXT;
    } else {
      return '賃貸クラウド物件広告に取込';
    }
  })();

  return { disabled, tooltipText };
};

export const useFetchBukkenData = (
  bukkenGuid: string,
  analyticsPlace: string,
  viewStatusBukken: ViewStatusBukken
): (() => void) => {
  const sendingViewStatusService = useDependency('sendingViewStatusService');
  const tracker = useUserActionTracker(analyticsPlace);
  const sourceUserId = uuidv4();
  const url =
    ServiceConfigure.ebChintaiUrl + `/torikomi/square?bukken_guid=${bukkenGuid}&source_user_id=${sourceUserId}`;
  // ユーザトラッキングのためのuuidを追加
  const viewStatusTorikomiBukken = useMemo(() => {
    return { ...viewStatusBukken, torikomiSourceUserId: sourceUserId };
  }, [sourceUserId, viewStatusBukken]);

  const fetchBukkenData = useCallback(() => {
    tracker('物件取込', bukkenGuid);
    sendingViewStatusService.send(viewStatusTorikomiBukken, viewStatusAction.torikomi);
    window.open(url, '_blank', 'noopener,noreferrer');
  }, [bukkenGuid, sendingViewStatusService, tracker, url, viewStatusTorikomiBukken]);

  return fetchBukkenData;
};
