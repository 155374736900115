import { Box } from '@mui/material';
import { FC, Fragment, useCallback, useMemo } from 'react';

import { PrefectureSelector } from './RegionConditionPartial/PrefectureSelector';
import { LineSelector, LineSelectorFooter } from './StationSelectorPartial/LineSelector';
import { PrefectureSelectorFooter } from './StationSelectorPartial/PrefectureSelector';
import { StationSelector, StationSelectorFooter } from './StationSelectorPartial/StationSelector';

import { useDependency } from '@/Hooks/DependencyHook';
import { useCurrentUser } from '@/Hooks/Esa/RequireCurrentUser';
import { RegionConditionStateHook } from '@/Hooks/Search/RegionConditionStateHook';
import { LineAndStationSearchConditionHook } from '@/Hooks/Search/StationOrLineSearchCondition';
import { ChintaiSearchConditions } from '@/Models/SearchConditions/ChintaiSearchConditions';

type StationAndLineSelector = {
  lineAndStationHook: LineAndStationSearchConditionHook<ChintaiSearchConditions>;
  onStationAndLineSelected: () => void;
  regionCondition: RegionConditionStateHook;
  isOwnBukken: boolean;
};

export const StationAndLineSelector: FC<StationAndLineSelector> = ({
  lineAndStationHook,
  onStationAndLineSelected,
  regionCondition,
  isOwnBukken,
}) => {
  const masterApiService = useDependency('masterApiService');
  const statsApiService = useDependency('statsApiService');
  const {
    selectedPrefs,
    selectedLines,
    selectedStations,
    onSelectPref: onSelectPrefInner,
    onSelectLines,
    onSelectStations,
  } = lineAndStationHook;

  const onSelectPref = useCallback(
    (pref: number, checked: boolean): void => {
      onStationAndLineSelected();
      onSelectPrefInner(pref, checked);
    },
    [onSelectPrefInner, onStationAndLineSelected]
  );

  const currentUser = useCurrentUser();
  const domainGuid = useMemo(() => {
    if (!currentUser.ebone) {
      return undefined;
    }

    return currentUser.ebone.domainGuid;
  }, [currentUser.ebone]);

  const currentSelector =
    regionCondition.lineCurrentMode === 'pref' ? (
      <PrefectureSelector
        masterApiService={masterApiService}
        statsApiService={statsApiService}
        selectedPrefs={selectedPrefs}
        onSelectPref={onSelectPref}
        isOwnBukken={isOwnBukken}
        domainGuid={domainGuid}
      />
    ) : regionCondition.lineCurrentMode === 'line' ? (
      <LineSelector
        masterApiService={masterApiService}
        statsApiService={statsApiService}
        selectedPrefs={selectedPrefs}
        selectedLines={selectedLines}
        onSelectLines={onSelectLines}
        isOwnBukken={isOwnBukken}
        domainGuid={domainGuid}
      />
    ) : regionCondition.lineCurrentMode === 'station' ? (
      <StationSelector
        masterApiService={masterApiService}
        statsApiService={statsApiService}
        selectedPrefs={selectedPrefs}
        selectedLines={selectedLines}
        selectedStations={selectedStations}
        onSelectStations={onSelectStations}
        isAllStationShown={lineAndStationHook.isAllStationShown}
        setIsAllStationShown={lineAndStationHook.setIsAllStationShown}
        isOwnBukken={isOwnBukken}
        domainGuid={domainGuid}
      />
    ) : null;

  return <Box>{currentSelector}</Box>;
};

type StationAndLineSelectorFooterProps = {
  lineAndStationHook: LineAndStationSearchConditionHook<ChintaiSearchConditions>;
  onDetermined: () => void;
  onStationAndLineSelected: () => void;
  regionCondition: RegionConditionStateHook;
  transitToRegion: () => void;
};

export const StationAndLineSelectorFooter: FC<StationAndLineSelectorFooterProps> = props => {
  const { selectedPrefs, selectedLines, selectedStations } = props.lineAndStationHook;
  return (
    <Fragment>
      {props.regionCondition.lineCurrentMode === 'pref' ? (
        <PrefectureSelectorFooter
          selectedPrefs={selectedPrefs}
          onNext={props.regionCondition.onLineModeNext}
          transitToRegion={props.transitToRegion}
        />
      ) : props.regionCondition.lineCurrentMode === 'line' ? (
        <LineSelectorFooter
          selectedLines={selectedLines}
          onNext={props.regionCondition.onLineModeNext}
          onPrev={props.regionCondition.onLineModePrev}
          onLinesDetermined={props.onDetermined}
          transitToRegion={props.transitToRegion}
        />
      ) : props.regionCondition.lineCurrentMode === 'station' ? (
        <StationSelectorFooter
          selectedStations={selectedStations}
          onPrev={props.regionCondition.onLineModePrev}
          onStationsDetermined={props.onDetermined}
          transitToRegion={props.transitToRegion}
        />
      ) : null}
    </Fragment>
  );
};
