import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Button, Tooltip, buttonClasses } from '@mui/material';
import { useCallback, useState, Fragment } from 'react';
import { useOnOffState } from '../Hooks';
import { MenuWithoutBlock } from './MenuWithoutBlock';
export const ButtonMenu = props => {
    var _a;
    const [isMenuOpen, { toggle: toggleMenuOpen, setFalse: setMenuClose }] = useOnOffState(false);
    const [ref, setRef] = useState(null);
    const onButtonClick = useCallback(e => {
        e.stopPropagation();
        toggleMenuOpen();
    }, [toggleMenuOpen]);
    return (_jsxs(Fragment, { children: [_jsx("div", Object.assign({ ref: setRef }, { children: _jsx(Tooltip, Object.assign({ title: (_a = props.tooltipTitle) !== null && _a !== void 0 ? _a : '' }, { children: _jsx("span", { children: _jsx(Button, { startIcon: props.icon, onClick: onButtonClick, variant: props.buttonVariant, color: props.buttonColor !== 'accent' ? props.buttonColor : 'primary', sx: {
                                [`&.${buttonClasses.root}`]: {
                                    height: '2rem',
                                    minWidth: '3rem',
                                    paddingLeft: 0.5,
                                    paddingRight: 0.5,
                                    backgroundColor: props.buttonColor === 'accent' ? 'accent.main' : undefined,
                                },
                                [`& .${buttonClasses.startIcon}`]: {
                                    marginRight: 0,
                                    marginLeft: 0,
                                },
                            } }) }) })) })), _jsx(MenuWithoutBlock, { isMenuOpen: isMenuOpen, setMenuClose: setMenuClose, menuActions: props.menuActions, anchorEl: ref })] }));
};
