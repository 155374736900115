import { Line, StatByLine } from '@app/models';
import { Button, Badge, Checkbox } from '@e-seikatsu/design-system';
import { ErrorMessage, Loading } from '@lib/components';
import { Box, Grid, Stack, Tooltip } from '@mui/material';
import { FC, useMemo, useContext } from 'react';
import { useAsync } from 'react-use';

import { getLineDataErrorMessageHeader } from '@/Consts/ErrorMessages';
import { borderSxProps } from '@/Hooks/Styles/BorderStyle';
import { useIsSmallDevice } from '@/Hooks/Styles/IsSmallDevice';
import { LineSearchCondition } from '@/Models/SearchConditions/ChintaiSearchConditions';
import { HeaderText } from '@/Pages/bukken/chintai/SearchPartial/BukkenSearchForms/RegionConditionPartial/HeaderText';
import { searchConditionSxProps } from '@/Pages/bukken/chintai/SearchPartial/BukkenSearchForms/SearchConditionStyle';
import { ValidationContext } from '@/Pages/bukken/chintai/SearchPartial/BukkenSearchForms/SearchConditionValidHooks';
import { IMasterApiService } from '@/Services/IMasterApiService';
import { IStatsApiService } from '@/Services/IStatsApiService';
import { applyNumberFormat } from '@/Utils/DisplayText/Number';

type LinesOnPrefecture = {
  readonly prefCode: number;
  readonly linesByCompany: readonly LinesInCompanyExtended[];
};

type LinesInCompanyExtended = {
  readonly companyName: string;
  readonly lines: readonly Readonly<LineWithStat>[];
};

type LineWithStat = Line & { count?: number };

type LineSelectorProps = {
  masterApiService: IMasterApiService;
  statsApiService: IStatsApiService;
  selectedPrefs: readonly number[];
  selectedLines: readonly LineSearchCondition[];
  onSelectLines: (selectedPrefs: number[], selectedLines: readonly number[], checked: boolean) => void;
  isOwnBukken: boolean;
  domainGuid: string | undefined;
};

export const LineSelector: FC<LineSelectorProps> = ({
  masterApiService,
  statsApiService,
  selectedPrefs,
  selectedLines,
  onSelectLines,
  isOwnBukken,
  domainGuid,
}) => {
  const { value: prefCodeToNameMap, loading: isLoadingNameMap } = useAsync(
    async () =>
      new Map(
        await Promise.all(
          selectedPrefs.map(p => masterApiService.getPrefecture(p).then<[number, string]>(p => [p.code, p.name]))
        )
      ),
    [masterApiService, selectedPrefs]
  );
  const prefAndLinesList = useAsync(async () => {
    return (await Promise.all(selectedPrefs.map(p => masterApiService.getLinesGroupedByCompany(p)))).map((v, i) => ({
      prefCode: selectedPrefs[i],
      linesByCompany: v,
    }));
  }, [selectedPrefs, masterApiService]);

  const { value: bukkenStatsByLine } = useAsync(async (): Promise<readonly StatByLine[]> => {
    // 都道府県コードで onenet/stats へ問い合わせを掛けると、
    // その都道府県内に存在する物件の数が返ってくるために路線上に物件が無いように見えてしまうため、
    // 選択県によらず全件取得する
    return await statsApiService.getStatsByLines(isOwnBukken, domainGuid);
  }, [domainGuid, isOwnBukken, statsApiService]);

  const linesByPrefWithStats = useMemo<readonly LinesOnPrefecture[] | undefined>(() => {
    if (prefAndLinesList.loading || prefAndLinesList.value === undefined) {
      return undefined;
    }

    if (bukkenStatsByLine === undefined) {
      return prefAndLinesList.value;
    }

    return prefAndLinesList.value.map<LinesOnPrefecture>(lop => ({
      prefCode: lop.prefCode,
      linesByCompany: lop.linesByCompany.map(lbc => ({
        companyName: lbc.companyName,
        lines: lbc.lines.map(line => ({
          ...line,
          count: bukkenStatsByLine.find(s => s.line_code === line.line_code)?.bukken_count ?? 0,
        })),
      })),
    }));
  }, [bukkenStatsByLine, prefAndLinesList.loading, prefAndLinesList.value]);

  const onSelectLine = (pref: number, line: Line, checked: boolean): void => {
    onSelectLines([...selectedPrefs], [line.line_code], checked);
  };

  const list =
    prefAndLinesList.loading || isLoadingNameMap ? (
      <Box p={4}>
        <Loading />
      </Box>
    ) : linesByPrefWithStats === undefined || prefCodeToNameMap === undefined ? (
      <Box p={4}>
        <ErrorMessage header={getLineDataErrorMessageHeader} small />
      </Box>
    ) : (
      <Stack gap={2}>
        {linesByPrefWithStats.map(prefAndLines => (
          <LinesOnPrefectureGroup
            key={prefAndLines.prefCode}
            prefCode={prefAndLines.prefCode}
            prefName={prefCodeToNameMap.get(prefAndLines.prefCode) ?? ''}
            linesByCompanyList={prefAndLines.linesByCompany}
            selectedLines={selectedLines}
            onSelectLine={onSelectLine}
          />
        ))}
      </Stack>
    );

  return <Box>{list}</Box>;
};

type LinesOnPrefectureGroupProps = {
  prefCode: number;
  prefName: string;
  linesByCompanyList: readonly LinesInCompanyExtended[];
  selectedLines: readonly LineSearchCondition[];
  onSelectLine: (pref: number, line: Line, checked: boolean) => void;
};

export const LinesOnPrefectureGroup: FC<LinesOnPrefectureGroupProps> = ({
  prefCode,
  prefName,
  linesByCompanyList,
  selectedLines,
  onSelectLine,
}) => {
  const isSmallDevice = useIsSmallDevice();

  return (
    <Stack gap={1}>
      <HeaderText selectionStateText={prefName} fixedText="の路線" isSmallDevice={isSmallDevice} />
      <Box>
        {linesByCompanyList.map((linesByCompany, index) => (
          <Grid container key={linesByCompany.companyName}>
            <Grid
              item
              xs={12}
              sm={2}
              sx={{
                ...searchConditionSxProps.firstCol,
                ...(index === 0 ? borderSxProps.borderY : borderSxProps.borderBottom),
              }}
            >
              {linesByCompany.companyName}
            </Grid>
            <Grid
              item
              xs={12}
              sm={10}
              sx={{
                ...searchConditionSxProps.secondCol,
                ...searchConditionSxProps.checkboxGrid({ gridItemCountPC: 3, gridItemCountMobile: 2 }),
                ...(index === 0 && !isSmallDevice ? borderSxProps.borderY : borderSxProps.borderBottom),
              }}
            >
              {linesByCompany.lines.map(line => (
                <Checkbox
                  data-testclass="checkbox"
                  checked={Boolean(selectedLines.find(sl => sl.lineCode === line.line_code))}
                  disabled={line.count === 0}
                  onChange={(e): void => onSelectLine(prefCode, line, e.target.checked)}
                  label={[line.line_name, line.count !== undefined ? ` (${applyNumberFormat(line.count)})` : ''].join(
                    ''
                  )}
                  key={line.line_code}
                  size="small"
                />
              ))}
            </Grid>
          </Grid>
        ))}
      </Box>
    </Stack>
  );
};

type LineSelectorFooterProps = {
  selectedLines: readonly LineSearchCondition[];
  onNext: () => void;
  onPrev: () => void;
  onLinesDetermined: () => void;
  transitToRegion: () => void;
};

export const LineSelectorFooter: FC<LineSelectorFooterProps> = props => {
  const isSearchConditionInvalid = useContext(ValidationContext);
  const isSmallDevice = useIsSmallDevice();

  return (
    <Box display="flex" width="100%">
      <Box flexGrow={1} textAlign="left">
        <Button
          onClick={props.onPrev}
          prependIcon="chevronLeft"
          variant="text"
          color="primary"
          size={!isSmallDevice ? 'medium' : 'small'}
        >
          都道府県
        </Button>
      </Box>
      <Box textAlign="right" display="flex" columnGap={1}>
        <Badge invisible={!isSearchConditionInvalid} color="red" value="!" position="outsider">
          <Button
            onClick={props.transitToRegion}
            variant="outlined"
            color="primary"
            size={!isSmallDevice ? 'medium' : 'small'}
          >
            詳細条件を入力
          </Button>
        </Badge>
        <Button
          onClick={props.onNext}
          disabled={props.selectedLines.length === 0}
          variant="outlined"
          color="primary"
          size={!isSmallDevice ? 'medium' : 'small'}
        >
          駅を選択
        </Button>
        <Tooltip title={isSearchConditionInvalid ? '入力項目にエラーがあります' : ''} placement="top">
          <Box minWidth="60px">
            <Button
              onClick={props.onLinesDetermined}
              disabled={props.selectedLines.length === 0 || isSearchConditionInvalid}
              variant="contained"
              color="primary"
              size={!isSmallDevice ? 'medium' : 'small'}
              fullWidth
            >
              検索
            </Button>
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};
