import { Address, StatByOoaza } from '@app/models';
import {
  asKanaLine,
  classifyToKanaLine,
  isNullOrUndefined,
  KanaLine,
  KanaLineToLabelMap,
  uniqStable,
} from '@app/utils';
import { Button, Badge, Checkbox } from '@e-seikatsu/design-system';
import { ErrorMessage, Loading } from '@lib/components';
import { Box, Grid, Stack, Tooltip } from '@mui/material';
import { ChangeEvent, FC, useCallback, useContext, useMemo } from 'react';
import { useAsync } from 'react-use';

import { getOoazaDataErrorMessageHeader } from '@/Consts/ErrorMessages';
import { useStableMemo } from '@/Hooks/StableMemo';
import { borderSxProps } from '@/Hooks/Styles/BorderStyle';
import { useIsSmallDevice } from '@/Hooks/Styles/IsSmallDevice';
import { PlaceSearchCondition } from '@/Models/SearchConditions/ChintaiSearchConditions';
import { HeaderText } from '@/Pages/bukken/chintai/SearchPartial/BukkenSearchForms/RegionConditionPartial/HeaderText';
import { searchConditionSxProps } from '@/Pages/bukken/chintai/SearchPartial/BukkenSearchForms/SearchConditionStyle';
import { ValidationContext } from '@/Pages/bukken/chintai/SearchPartial/BukkenSearchForms/SearchConditionValidHooks';
import { IMasterApiService } from '@/Services/IMasterApiService';
import { IStatsApiService } from '@/Services/IStatsApiService';
import { applyNumberFormat } from '@/Utils/DisplayText/Number';

type PrefAndCityTuple = [pref: number, city: number];
const toPrefAndCityCode = (t: PrefAndCityTuple): string =>
  t[0].toFixed(0).padStart(2, '0') + t[1].toFixed(0).padStart(3, '0');

type OoazaWithStat = {
  readonly name: string;
  readonly kanaLine: KanaLine | null;
  readonly count?: number;
};

type OoazaGroupTuple = { prefAndCity: PrefAndCityTuple; ooazaRecord: Record<KanaLine, OoazaWithStat[]> };
const initializeKanaLineRecord = (): Record<KanaLine, OoazaWithStat[]> => {
  return {
    a: [],
    ka: [],
    sa: [],
    ta: [],
    na: [],
    ha: [],
    ma: [],
    ya: [],
    ra: [],
    wa: [],
  };
};

type OoazaSelectorProps = {
  masterApiService: IMasterApiService;
  statsApiService: IStatsApiService;
  selectedPlaces: readonly PlaceSearchCondition[];
  onSelectOoaza: (pref: number, city: number, ooaza: readonly string[], checked: boolean) => void;
  isOwnBukken: boolean;
  domainGuid: string | undefined;
};

export const OoazaSelector: FC<OoazaSelectorProps> = props => {
  const selectedCities: PrefAndCityTuple[] = useStableMemo(
    () => uniqStable(props.selectedPlaces.filter(p => p.city > 0).map(p => [p.prefecture, p.city])),
    [props.selectedPlaces]
  );
  const { value: prefAndCityCodeToNameMap, loading: isLoadingNameMap } = useAsync(async () => {
    return new Map(
      (await Promise.all(selectedCities.map(pc => props.masterApiService.getCity(pc[0], pc[1])))).map(c => [
        c.address_code.slice(0, 5),
        c.prefecture_name + c.city_name,
      ])
    );
  }, [props.masterApiService, selectedCities]);
  const ooazaByPrefAndCity = useAsync(async () => {
    return (await Promise.all(selectedCities.map(pc => props.masterApiService.getOoaza(pc[0], pc[1])))).map<
      [PrefAndCityTuple, readonly Address[]]
    >((ooazaList, index) => [selectedCities[index], ooazaList]);
  }, [selectedCities, props.masterApiService]);

  const { value: bukkenStatsByOoaza } = useAsync(async (): Promise<Map<string, readonly StatByOoaza[]> | undefined> => {
    const ooazaByPrefAndCityValue = ooazaByPrefAndCity.value;
    if (ooazaByPrefAndCityValue === undefined) {
      return undefined;
    }

    return new Map(
      await Promise.all(
        ooazaByPrefAndCityValue.map<Promise<[string, readonly StatByOoaza[]]>>(it => {
          const addressCode = it[1][0].address_code;
          const prefAndCityCode = addressCode.slice(0, 5);
          const prefCode = parseInt(addressCode.slice(0, 2));
          const cityCode = parseInt(addressCode.slice(2, 5));
          if (isNaN(prefCode) || isNaN(cityCode)) {
            return Promise.resolve([prefAndCityCode, []]);
          }

          return props.statsApiService
            .getStatsByOoaza(prefCode, cityCode, props.isOwnBukken, props.domainGuid)
            .then((v): [string, StatByOoaza[]] => [prefAndCityCode, v]);
        })
      )
    );
  }, [ooazaByPrefAndCity.value, props.domainGuid, props.isOwnBukken, props.statsApiService]);

  const ooazaByCityWithStats = useMemo<OoazaGroupTuple[] | undefined>(() => {
    if (ooazaByPrefAndCity.loading || ooazaByPrefAndCity.value === undefined) {
      return undefined;
    }

    if (bukkenStatsByOoaza === undefined) {
      return ooazaByPrefAndCity.value.map<OoazaGroupTuple>(orig => {
        const ooazaRecord = initializeKanaLineRecord();
        orig[1].forEach(o => {
          const kanaLine = classifyToKanaLine(o.ooaza_kana);
          if (isNullOrUndefined(kanaLine)) {
            return;
          }
          ooazaRecord[kanaLine].push({
            name: o.ooaza_tsuushou,
            kanaLine,
          });
        });
        return {
          prefAndCity: orig[0],
          ooazaRecord: ooazaRecord,
        };
      });
    }

    return ooazaByPrefAndCity.value.map<OoazaGroupTuple>(orig => {
      const ooazaRecord = initializeKanaLineRecord();
      orig[1].forEach(o => {
        const prefAndCityCode = o.address_code.slice(0, 5);
        const ooazaCode = o.address_code.slice(5, 8);
        const kanaLine = classifyToKanaLine(o.ooaza_kana);
        if (isNullOrUndefined(kanaLine)) {
          return;
        }
        ooazaRecord[kanaLine].push({
          name: o.ooaza_tsuushou,
          kanaLine,
          count: bukkenStatsByOoaza.get(prefAndCityCode)?.find(s => s.ooaza_code === ooazaCode)?.bukken_count ?? 0,
        });
      });

      return {
        prefAndCity: orig[0],
        ooazaRecord: ooazaRecord,
      };
    });
  }, [bukkenStatsByOoaza, ooazaByPrefAndCity.loading, ooazaByPrefAndCity.value]);

  const isSmallDevice = useIsSmallDevice();

  const list =
    ooazaByPrefAndCity.loading || isLoadingNameMap ? (
      <Box p={4}>
        <Loading />
      </Box>
    ) : ooazaByCityWithStats === undefined || prefAndCityCodeToNameMap === undefined ? (
      <Box p={4}>
        <ErrorMessage header={getOoazaDataErrorMessageHeader} small />
      </Box>
    ) : (
      <Stack gap={1}>
        {ooazaByCityWithStats.map((opc, index) => {
          const [pref, city] = opc.prefAndCity;
          const ooazaRecord = opc.ooazaRecord;
          return (
            <OoazaCheckBoxGroup
              key={city}
              index={index}
              prefCode={pref}
              cityCode={city}
              prefAndCityName={prefAndCityCodeToNameMap.get(toPrefAndCityCode(opc.prefAndCity)) ?? ''}
              ooazaRecord={ooazaRecord}
              selectedOoaza={props.selectedPlaces
                .filter(sp => sp.prefecture === pref && sp.city === city && sp.ooaza !== '')
                .map(sp => sp.ooaza)}
              onSelect={props.onSelectOoaza}
            />
          );
        })}
      </Stack>
    );

  return (
    <Stack gap={1}>
      <HeaderText
        selectionStateText={selectedCities
          .map(pc => prefAndCityCodeToNameMap?.get(toPrefAndCityCode(pc)) ?? '')
          .join(', ')}
        fixedText="の町名"
        isSmallDevice={isSmallDevice}
      />
      <Box>{list}</Box>
    </Stack>
  );
};

type OoazaCheckBoxGroupProps = {
  index: number;
  prefCode: number;
  cityCode: number;
  prefAndCityName: string;
  ooazaRecord: Record<KanaLine, OoazaWithStat[]>;
  selectedOoaza: readonly string[];
  onSelect: (pref: number, city: number, ooaza: readonly string[], checked: boolean) => void;
};
const OoazaCheckBoxGroup: FC<OoazaCheckBoxGroupProps> = props => {
  const ooazaList = Object.values(props.ooazaRecord).flat();
  const allOoazaSelected = ooazaList.length === props.selectedOoaza.length;
  const onSelectAll = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      const onSelect = props.onSelect;
      onSelect(
        props.prefCode,
        props.cityCode,
        ooazaList.map(o => o.name),
        e.target.checked
      );
    },
    [ooazaList, props.cityCode, props.onSelect, props.prefCode]
  );

  return (
    <Stack>
      <Box sx={{ ...searchConditionSxProps.firstColNoWrap, ...borderSxProps.borderY }}>
        <Checkbox
          data-testclass="largeCheckbox"
          checked={allOoazaSelected}
          onChange={onSelectAll}
          label={props.prefAndCityName}
          variant="text"
          size="small"
        />
      </Box>
      <Box>
        {Object.entries(props.ooazaRecord).map(recordItem => {
          const kanaLine = asKanaLine(recordItem[0]);
          if (isNullOrUndefined(kanaLine)) {
            return undefined;
          }
          if (recordItem[1].length === 0) {
            return undefined;
          }
          return (
            <Grid container sx={searchConditionSxProps.root} key={recordItem[0]}>
              <Grid item xs={12} sm={2} sx={{ ...searchConditionSxProps.firstCol, ...borderSxProps.borderBottom }}>
                {KanaLineToLabelMap[kanaLine]}行
              </Grid>
              <Grid
                item
                xs={12}
                sm={10}
                sx={{
                  ...searchConditionSxProps.secondCol,
                  ...searchConditionSxProps.checkboxGrid({ gridItemCountPC: 5, gridItemCountMobile: 2 }),
                  ...borderSxProps.borderBottom,
                }}
              >
                {recordItem[1].map(it => (
                  <Checkbox
                    data-testclass="checkbox"
                    checked={props.selectedOoaza.includes(it.name)}
                    disabled={it.count === 0}
                    onChange={(e): void => props.onSelect(props.prefCode, props.cityCode, [it.name], e.target.checked)}
                    label={[it.name, it.count !== undefined ? ` (${applyNumberFormat(it.count)})` : ''].join('')}
                    key={it.name}
                    size="small"
                  />
                ))}
              </Grid>
            </Grid>
          );
        })}
      </Box>
    </Stack>
  );
};

type OoazaSelectorFooterProps = {
  selectedPlaces: readonly PlaceSearchCondition[];
  onPrev: () => void;
  onDetermined: () => void;
  transitToRegion: () => void;
};

export const OoazaSelectorFooter: FC<OoazaSelectorFooterProps> = props => {
  const isOoazaSelected = props.selectedPlaces.filter(sp => sp.ooaza !== '').length > 0;
  const isSearchConditionInvalid = useContext(ValidationContext);
  const isSmallDevice = useIsSmallDevice();

  return (
    <Box display="flex" width="100%">
      <Box flexGrow={1} textAlign="left">
        <Button
          onClick={props.onPrev}
          prependIcon="chevronLeft"
          variant="text"
          color="primary"
          size={!isSmallDevice ? 'medium' : 'small'}
        >
          市区郡
        </Button>
      </Box>
      <Box textAlign="right" display="flex" columnGap={1}>
        <Badge invisible={!isSearchConditionInvalid} color="red" value="!" position="outsider">
          <Button
            onClick={props.transitToRegion}
            variant="outlined"
            color="primary"
            size={!isSmallDevice ? 'medium' : 'small'}
          >
            詳細条件を入力
          </Button>
        </Badge>
        <Tooltip title={isSearchConditionInvalid ? '入力項目にエラーがあります' : ''} placement="top">
          <Box minWidth="60px">
            <Button
              onClick={props.onDetermined}
              disabled={!isOoazaSelected || isSearchConditionInvalid}
              variant="contained"
              color="primary"
              size={!isSmallDevice ? 'medium' : 'small'}
              fullWidth
            >
              検索
            </Button>
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};
