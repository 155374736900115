import { Chip } from '@mui/material';
import { FC } from 'react';

export const NewChip: FC = () => {
  return (
    <Chip
      label="NEW"
      color="primary"
      size="small"
      sx={{
        minWidth: '2rem',
        fontWeight: 'bold',
        background: theme => theme.palette.attention.main,
        fontSize: theme => theme.typography.pxToRem(12),
        borderRadius: 1,
        marginRight: 1,
        marginLeft: 1,
      }}
    />
  );
};
