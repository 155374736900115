import { FC, Fragment, useCallback, useMemo } from 'react';

import { CitySelector, CitySelectorFooter } from './PlaceSelectorPartial/CitySelector';
import { OoazaSelector, OoazaSelectorFooter } from './PlaceSelectorPartial/OoazaSelector';
import { PrefectureSelectorFooter } from './PlaceSelectorPartial/PrefectureSelector';
import { PrefectureSelector } from './RegionConditionPartial/PrefectureSelector';

import { useDependency } from '@/Hooks/DependencyHook';
import { useCurrentUser } from '@/Hooks/Esa/RequireCurrentUser';
import { PlaceSearchConditionHook } from '@/Hooks/Search/PlaceSearchCondition';
import { RegionConditionStateHook } from '@/Hooks/Search/RegionConditionStateHook';
import { ChintaiSearchConditions } from '@/Models/SearchConditions/ChintaiSearchConditions';

type PlaceSelectorProps = {
  hooks: PlaceSearchConditionHook<ChintaiSearchConditions>;
  onPlaceSelected: () => void;
  regionCondition: RegionConditionStateHook;
  isOwnBukken: boolean;
};

export const PlaceSelector: FC<PlaceSelectorProps> = ({ hooks, onPlaceSelected, regionCondition, isOwnBukken }) => {
  const masterApiService = useDependency('masterApiService');
  const statsApiService = useDependency('statsApiService');
  const { value: selectedPlaces, onSelectPref: onSelectPrefOrig, onSelectCities, onSelectOoaza } = hooks;
  const onSelectPref = useCallback(
    (pref: number, checked: boolean): void => {
      onPlaceSelected();
      onSelectPrefOrig(pref, checked);
    },
    [onPlaceSelected, onSelectPrefOrig]
  );

  const currentUser = useCurrentUser();
  const domainGuid = useMemo(() => {
    if (!currentUser.ebone) {
      return undefined;
    }

    return currentUser.ebone.domainGuid;
  }, [currentUser.ebone]);

  return regionCondition.placeCurrentMode === 'pref' ? (
    <PrefectureSelector
      masterApiService={masterApiService}
      statsApiService={statsApiService}
      selectedPrefs={selectedPlaces?.map(p => p.prefecture) ?? []}
      onSelectPref={onSelectPref}
      isOwnBukken={isOwnBukken}
      domainGuid={domainGuid}
    />
  ) : regionCondition.placeCurrentMode === 'city' ? (
    <CitySelector
      masterApiService={masterApiService}
      statsApiService={statsApiService}
      selectedPlaces={selectedPlaces ?? []}
      onSelectCity={onSelectCities}
      isOwnBukken={isOwnBukken}
      domainGuid={domainGuid}
    />
  ) : regionCondition.placeCurrentMode === 'ooaza' ? (
    <OoazaSelector
      masterApiService={masterApiService}
      statsApiService={statsApiService}
      selectedPlaces={selectedPlaces ?? []}
      onSelectOoaza={onSelectOoaza}
      isOwnBukken={isOwnBukken}
      domainGuid={domainGuid}
    />
  ) : null;
};

type PlaceSelectorFooterProps = {
  hooks: PlaceSearchConditionHook<ChintaiSearchConditions>;
  onDetermined: () => void;
  onPlaceSelected: () => void;
  regionCondition: RegionConditionStateHook;
  transitToRegion: () => void;
};

export const PlaceSelectorFooter: FC<PlaceSelectorFooterProps> = props => {
  const selectedPlaces = props.hooks.value;
  return (
    <Fragment>
      {props.regionCondition.placeCurrentMode === 'pref' ? (
        <PrefectureSelectorFooter
          selectedPrefs={selectedPlaces?.map(p => p.prefecture) ?? []}
          onNext={props.regionCondition.onPlaceModeNext}
          onDetermined={props.onDetermined}
          transitToRegion={props.transitToRegion}
        />
      ) : props.regionCondition.placeCurrentMode === 'city' ? (
        <CitySelectorFooter
          selectedPlaces={selectedPlaces ?? []}
          onNext={props.regionCondition.onPlaceModeNext}
          onPrev={props.regionCondition.onPlaceModePrev}
          onDetermined={props.onDetermined}
          transitToRegion={props.transitToRegion}
        />
      ) : props.regionCondition.placeCurrentMode === 'ooaza' ? (
        <OoazaSelectorFooter
          selectedPlaces={selectedPlaces ?? []}
          onPrev={props.regionCondition.onPlaceModePrev}
          onDetermined={props.onDetermined}
          transitToRegion={props.transitToRegion}
        />
      ) : null}
    </Fragment>
  );
};
