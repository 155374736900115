import { ChinshakuBukken, Datejun, SpecBukkenView } from '@app/models';
import { MuiButtonColorType } from '@lib/components';
import { Box, Button, buttonClasses, Tooltip } from '@mui/material';
import { CalendarClock, Phone } from 'mdi-material-ui';
import { FC, MouseEventHandler } from 'react';

import { ActionButtonLayout } from './ActionButtonLayout';

import { useUserActionTracker } from '@/Hooks/Analytics';
import { useDependency } from '@/Hooks/DependencyHook';
import { usePreviewReservation } from '@/Hooks/SumaiEntry/PreviewReservationHooks';
import { searchResultSxProps } from '@/Pages/bukken/chintai/SearchPartial/SearchResultStyles';
import { viewStatusAction, ViewStatusBukken } from '@/Services/ISendingViewStatusService';

const PHONE_INQUIRY_TOOLTIP_TEXT = 'お電話でお問い合わせください';
const INVALID_TAKKEN_LICENSE_NUMBER_TOOLTIP_TEXT = '宅建免許番号が不正なため内見予約できません';

type LocalStyleProps = {
  useIconButton: boolean;
};

const localSxProps = {
  // カスタマイズの容易性から、Button コンポーネントの再利用ではなく独自定義としている
  pseudoButton: (props: LocalStyleProps) => ({
    borderRadius: '4px',
    height: '1.7rem',
    minWidth: props.useIconButton ? '0' : '3rem',
    paddingLeft: props.useIconButton ? 1 : 0.5,
    paddingRight: props.useIconButton ? 1 : 0.5,
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    lineHeight: '1.2em',
  }),
  textSmall: {
    fontSize: '0.7rem',
  },
  accentBg: {
    backgroundColor: 'accent.main',
  },
  twoLinesButton: {
    paddingTop: '2px',
    paddingBottom: '2px',
  },
} as const;

type ContainerProps = {
  bukkenOrBukkenView: SpecBukkenView | ChinshakuBukken;
  analyticsPlace: string;
  buttonVariant: 'text' | 'outlined' | 'contained';
  viewStatusBukken: ViewStatusBukken;
  buttonColor?: MuiButtonColorType | 'accent';
  useIconButton?: boolean;
};

export const PreviewReserveButtonContainer: FC<ContainerProps> = props => {
  const sendingViewStatusService = useDependency('sendingViewStatusService');

  const tracker = useUserActionTracker(props.analyticsPlace);
  const { previewState, previewStartDatejun, canRedirect, redirect, redirectError } = usePreviewReservation(
    props.bukkenOrBukkenView,
    tracker
  );
  const sendingViewStatus: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();
    sendingViewStatusService.send(props.viewStatusBukken, viewStatusAction.naiken);
    redirect();
  };
  return (
    <PreviewReserveButtonPresenter
      sendingViewStatus={sendingViewStatus}
      previewState={previewState}
      previewStartDatejun={previewStartDatejun}
      canRedirect={canRedirect}
      redirectError={redirectError}
      {...props}
    />
  );
};

export { PreviewReserveButtonContainer as PreviewReserveButton };

export type PreviewReserveButtonPresenterProps = {
  previewState: 'possible' | 'impossible' | 'notStarted' | 'unknown';
  previewStartDatejun: Datejun | undefined;
  canRedirect: boolean;
  redirectError?: 'invalidBukkenCondition' | 'invalidTakkenLicenseNumber';
  sendingViewStatus: MouseEventHandler<HTMLButtonElement>;
  buttonVariant: 'text' | 'outlined' | 'contained';
  buttonColor?: MuiButtonColorType | 'accent';
  useIconButton?: boolean;
};

export const PreviewReserveButtonPresenter: FC<PreviewReserveButtonPresenterProps> = ({
  previewState,
  previewStartDatejun,
  canRedirect,
  redirectError,
  sendingViewStatus,
  buttonVariant,
  buttonColor,
  useIconButton,
}) => {
  const background = buttonColor === 'accent' ? localSxProps.accentBg : undefined;
  if (previewState === 'impossible') {
    return (
      <ActionButtonLayout>
        <Button
          variant={buttonVariant}
          startIcon={!useIconButton ? <CalendarClock /> : ''}
          sx={{
            [`&.${buttonClasses.root}`]: {
              background,
              ...(useIconButton && searchResultSxProps.actionSquareButton),
            },
            [`&.${buttonClasses.outlined}`]: searchResultSxProps.actionButtonOutlined,
            [`& .${buttonClasses.startIcon}`]: !useIconButton
              ? searchResultSxProps.actionButtonStartIcon
              : searchResultSxProps.actionSquareButtonStartIcon,
          }}
          disabled
        >
          内見不可
        </Button>
      </ActionButtonLayout>
    );
  }

  if (previewState === 'notStarted') {
    if (!previewStartDatejun) {
      return (
        <ActionButtonLayout>
          <Button
            variant={buttonVariant}
            startIcon={!useIconButton ? <CalendarClock /> : ''}
            sx={{
              [`&.${buttonClasses.root}`]: {
                background,
                ...(useIconButton && searchResultSxProps.actionSquareButton),
                ...localSxProps.twoLinesButton,
              },
              [`&.${buttonClasses.outlined}`]: searchResultSxProps.actionButtonOutlined,
              [`& .${buttonClasses.startIcon}`]: searchResultSxProps.actionButtonStartIcon,
            }}
            disabled
          >
            <Box
              sx={{
                ...localSxProps.pseudoButton({ useIconButton: useIconButton === true }),
                ...localSxProps.textSmall,
              }}
            >
              <div>内見開始</div>
              <div>予定</div>
            </Box>
          </Button>
        </ActionButtonLayout>
      );
    }

    const previewStartDatejunObj = Datejun.destructDatejun(previewStartDatejun);
    const previewStartDateOfMonthText =
      previewStartDatejunObj.day === 0 ? previewStartDatejunObj.jun : previewStartDatejunObj.day.toFixed(0);
    const previewStartDateText = `${previewStartDatejunObj.month}/${previewStartDateOfMonthText}`;
    return (
      <ActionButtonLayout>
        <Button
          variant={buttonVariant}
          startIcon={!useIconButton ? <CalendarClock /> : ''}
          sx={{
            [`&.${buttonClasses.root}`]: {
              background,
              ...(useIconButton && searchResultSxProps.actionSquareButton),
              ...localSxProps.twoLinesButton,
            },
            [`&.${buttonClasses.outlined}`]: searchResultSxProps.actionButtonOutlined,
            [`& .${buttonClasses.startIcon}`]: searchResultSxProps.actionSquareButtonStartIcon,
          }}
          disabled
        >
          <Box
            sx={{
              ...localSxProps.pseudoButton({ useIconButton: useIconButton === true }),
              ...localSxProps.textSmall,
            }}
          >
            <div>{previewStartDateText}～</div>
            <div>内見可</div>
          </Box>
        </Button>
      </ActionButtonLayout>
    );
  }

  if (previewState === 'unknown' || !canRedirect) {
    return (
      <ActionButtonLayout>
        <Tooltip
          title={
            redirectError === 'invalidTakkenLicenseNumber'
              ? INVALID_TAKKEN_LICENSE_NUMBER_TOOLTIP_TEXT
              : PHONE_INQUIRY_TOOLTIP_TEXT
          }
        >
          <span>
            <Button
              variant={buttonVariant}
              startIcon={!useIconButton ? <Phone fontSize="small" /> : ''}
              sx={{
                [`&.${buttonClasses.root}`]: {
                  background,
                  ...(useIconButton && searchResultSxProps.actionSquareButton),
                },
                [`&.${buttonClasses.outlined}`]: searchResultSxProps.actionButtonOutlined,
                [`& .${buttonClasses.startIcon}`]: !useIconButton
                  ? searchResultSxProps.actionButtonStartIcon
                  : searchResultSxProps.actionSquareButtonStartIcon,
              }}
              disabled
            >
              {previewState === 'unknown' ? '内見' : '内見可'}
            </Button>
          </span>
        </Tooltip>
      </ActionButtonLayout>
    );
  }

  return (
    <ActionButtonLayout>
      <Tooltip title="内見予約" disableInteractive>
        <span>
          <Button
            variant={buttonVariant}
            color={buttonColor !== 'accent' ? buttonColor : 'primary'}
            startIcon={!useIconButton ? <CalendarClock /> : ''}
            onClick={sendingViewStatus}
            sx={{
              [`&.${buttonClasses.root}`]: { background, ...(useIconButton && searchResultSxProps.actionSquareButton) },
              [`&.${buttonClasses.outlined}`]: searchResultSxProps.actionButtonOutlined,
              [`& .${buttonClasses.startIcon}`]: !useIconButton
                ? searchResultSxProps.actionButtonStartIcon
                : searchResultSxProps.actionSquareButtonStartIcon,
            }}
          >
            内見
          </Button>
        </span>
      </Tooltip>
    </ActionButtonLayout>
  );
};
