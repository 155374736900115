import { Station } from '@app/models';
import { isNullOrUndefined } from '@app/utils';
import { Button, Badge, Checkbox } from '@e-seikatsu/design-system';
import { ErrorMessage, Loading } from '@lib/components';
import { Box, Stack, Tooltip } from '@mui/material';
import { useCallback, useMemo, FC, useContext, Fragment, ChangeEvent } from 'react';
import { useAsync } from 'react-use';
import { AsyncState } from 'react-use/lib/useAsyncFn';

import { getStationDataErrorMessageHeader } from '@/Consts/ErrorMessages';
import { borderSxProps } from '@/Hooks/Styles/BorderStyle';
import { useIsSmallDevice } from '@/Hooks/Styles/IsSmallDevice';
import { LineSearchCondition, StationSearchCondition } from '@/Models/SearchConditions/ChintaiSearchConditions';
import { HeaderText } from '@/Pages/bukken/chintai/SearchPartial/BukkenSearchForms/RegionConditionPartial/HeaderText';
import { searchConditionSxProps } from '@/Pages/bukken/chintai/SearchPartial/BukkenSearchForms/SearchConditionStyle';
import { ValidationContext } from '@/Pages/bukken/chintai/SearchPartial/BukkenSearchForms/SearchConditionValidHooks';
import { IMasterApiService } from '@/Services/IMasterApiService';
import { IStatsApiService } from '@/Services/IStatsApiService';
import { applyNumberFormat } from '@/Utils/DisplayText/Number';

type LineData = {
  readonly lineCode: number;
  readonly stations: readonly Readonly<Station>[];
};
type StationsOnLine = {
  readonly line: LineSearchCondition;
  readonly stations: readonly StationWithStat[];
};

type StationWithStat = Readonly<Station> & { readonly count?: number };

type StationSelectorProps = {
  masterApiService: IMasterApiService;
  statsApiService: IStatsApiService;
  selectedPrefs: readonly number[];
  selectedLines: readonly LineSearchCondition[];
  selectedStations: readonly StationSearchCondition[];
  onSelectStations: (
    selectedPrefs: number[],
    selectedLine: number,
    selectedStations: readonly number[],
    checked: boolean
  ) => void;
  isAllStationShown: boolean;
  setIsAllStationShown: (value: boolean) => void;
  isOwnBukken: boolean;
  domainGuid: string | undefined;
};

export const StationSelector: FC<StationSelectorProps> = ({
  masterApiService,
  statsApiService,
  selectedPrefs,
  selectedLines,
  selectedStations,
  onSelectStations,
  isAllStationShown,
  setIsAllStationShown,
  isOwnBukken,
  domainGuid,
}) => {
  const isSmallDevice = useIsSmallDevice();

  const { value: lineCodeToNameMap, loading: isLoadingNameMap } = useAsync(
    async () =>
      new Map(
        await Promise.all(
          selectedLines.map(l =>
            masterApiService.getLine(l.lineCode).then<[number, string]>(l => [l.line_code, l.line_name])
          )
        )
      )
  );

  const { value: selectedPrefNames } = useAsync(
    () =>
      Promise.all(selectedPrefs.map(pc => masterApiService.getPrefecture(pc))).then<readonly string[]>(prefs =>
        prefs.map(p => p.name)
      ),
    [masterApiService, selectedPrefs]
  );

  const getLineData = useCallback(
    async (lineCode: number): Promise<LineData> => {
      return { lineCode: lineCode, stations: await masterApiService.getStations(lineCode) };
    },
    [masterApiService]
  );
  const stationsList: AsyncState<LineData[]> = useAsync(async () => {
    return await Promise.all(
      selectedLines.map(l => {
        return getLineData(l.lineCode);
      })
    );
  }, [getLineData, selectedLines]);
  const stationsOnLineList = useMemo(() => {
    if (isNullOrUndefined(stationsList.value) || isNullOrUndefined(selectedPrefNames)) {
      return null;
    }
    return stationsList.value
      .filter(it => selectedLines.some(selectedLine => selectedLine.lineCode === it.lineCode))
      .map((lineData, index) => ({
        line: selectedLines[index],
        stations: isAllStationShown
          ? lineData.stations
          : lineData.stations.filter(it => {
              return selectedPrefNames.includes(it.station_address.prefecture_name);
            }),
      }));
  }, [isAllStationShown, selectedLines, selectedPrefNames, stationsList]);

  const hasExcludedStation = useMemo(() => {
    if (isNullOrUndefined(stationsList.value) || isNullOrUndefined(selectedPrefNames)) {
      return false;
    }
    return stationsList.value.some(lineData =>
      lineData.stations.some(station => {
        return !selectedPrefNames.includes(station.station_address.prefecture_name);
      })
    );
  }, [selectedPrefNames, stationsList.value]);

  const { value: bukkenStatsByStation } = useAsync(async (): Promise<Map<number, number> | undefined> => {
    // 複数の路線が乗り入れている駅については集計を取るため、
    // Master API から取得した駅情報を用いて、表示しようとする駅に繋がっている
    // 全ての路線の情報を取得する
    if (isNullOrUndefined(stationsOnLineList)) {
      return undefined;
    }

    const lines = Array.from(
      new Set(
        stationsOnLineList
          .flatMap(sol => sol.stations)
          .flatMap(s => s.line_data)
          .map(l => l.line_code)
      ).values()
    );
    return new Map(
      await statsApiService
        .getStatsByStations(lines, isOwnBukken, domainGuid)
        .then(stats => stats.map(s => [s.station_code, s.bukken_count]))
    );
  }, [domainGuid, isOwnBukken, stationsOnLineList, statsApiService]);

  const stationsOnLineWithStats = useMemo<readonly StationsOnLine[] | undefined>(() => {
    if (stationsList.loading || isNullOrUndefined(stationsOnLineList)) {
      return undefined;
    }

    if (bukkenStatsByStation === undefined) {
      return stationsOnLineList;
    }

    return stationsOnLineList.map(sol => ({
      line: sol.line,
      stations: sol.stations.map<StationWithStat>(s => ({
        ...s,
        count: bukkenStatsByStation.get(s.station_name_data.station_code) ?? 0,
      })),
    }));
  }, [bukkenStatsByStation, stationsList.loading, stationsOnLineList]);

  const onSelectStation = (line: LineSearchCondition, stations: Station[], checked: boolean): void => {
    onSelectStations(
      [...selectedPrefs],
      line.lineCode,
      stations.map(it => it.station_name_data.station_code),
      checked
    );
  };

  const list =
    stationsList.loading || isLoadingNameMap ? (
      <Box p={4}>
        <Loading />
      </Box>
    ) : stationsOnLineWithStats === undefined || lineCodeToNameMap === undefined ? (
      <Box p={4}>
        <ErrorMessage header={getStationDataErrorMessageHeader} small />
      </Box>
    ) : (
      <Fragment>
        {stationsOnLineWithStats.map((stationsOnLine, index) => (
          <SingleLineStationSelector
            index={index}
            key={stationsOnLine.line.lineCode}
            line={stationsOnLine.line}
            lineName={lineCodeToNameMap.get(stationsOnLine.line.lineCode) ?? ''}
            stations={stationsOnLine.stations}
            selectedStations={selectedStations}
            onSelectStation={onSelectStation}
          />
        ))}
      </Fragment>
    );

  return (
    <Stack gap={1}>
      <Box display="flex" alignItems="center" flexWrap="wrap" columnGap={isSmallDevice ? 0 : 2} rowGap={1}>
        {!isNullOrUndefined(lineCodeToNameMap) && (
          <HeaderText
            selectionStateText={selectedLines.map(l => lineCodeToNameMap?.get(l.lineCode) ?? '').join(', ')}
            fixedText="の駅"
            isSmallDevice={isSmallDevice}
          />
        )}
        {hasExcludedStation && (
          <Box pl={isSmallDevice ? 2 : 0}>
            <Checkbox
              checked={isAllStationShown}
              onChange={(): void => {
                setIsAllStationShown(!isAllStationShown);
              }}
              label="全駅を表示"
              size="small"
            />
          </Box>
        )}
      </Box>
      <Box>{list}</Box>
    </Stack>
  );
};

type SingleLineStationSelectorProps = {
  index: number;
  line: LineSearchCondition;
  lineName: string;
  stations: readonly StationWithStat[];
  selectedStations: readonly StationSearchCondition[];
  onSelectStation: (line: LineSearchCondition, stations: Station[], checked: boolean) => void;
};

const SingleLineStationSelector: FC<SingleLineStationSelectorProps> = ({
  index,
  line,
  lineName,
  stations,
  selectedStations,
  onSelectStation,
}) => {
  const selectAll = (e: ChangeEvent<HTMLInputElement>): void =>
    onSelectStation(line, Array.from(stations), e.target.checked);
  const isAllSelected = stations.every(s =>
    Boolean(selectedStations.find(ss => ss.stationCode === s.station_name_data.station_code))
  );

  return (
    <Fragment>
      <Box
        sx={{
          ...searchConditionSxProps.firstColNoWrap,
          ...(index === 0 ? borderSxProps.borderY : borderSxProps.borderBottom),
        }}
      >
        <Checkbox
          checked={isAllSelected}
          onChange={selectAll}
          data-testclass="largeCheckbox"
          label={lineName}
          variant="text"
          size="small"
        />
      </Box>
      <Box
        sx={{
          ...searchConditionSxProps.secondColNoWrap,
          ...borderSxProps.borderBottom,
          ...searchConditionSxProps.checkboxGrid({ gridItemCountPC: 5, gridItemCountMobile: 2 }),
        }}
      >
        {stations.map(station => (
          <Checkbox
            data-testclass="checkbox"
            checked={Boolean(selectedStations.find(ss => ss.stationCode === station.station_name_data.station_code))}
            disabled={station.count === 0}
            onChange={(e): void => onSelectStation(line, [station], e.target.checked)}
            label={[
              station.station_name_data.station_name,
              station.count !== undefined ? ` (${applyNumberFormat(station.count)})` : '',
            ].join('')}
            key={station.station_name_data.station_code}
            size="small"
          />
        ))}
      </Box>
    </Fragment>
  );
};

type StationSelectorFooterProps = {
  selectedStations: readonly StationSearchCondition[];
  onPrev: () => void;
  onStationsDetermined: () => void;
  transitToRegion: () => void;
};

export const StationSelectorFooter: FC<StationSelectorFooterProps> = props => {
  const isSearchConditionInvalid = useContext(ValidationContext);
  const isSmallDevice = useIsSmallDevice();

  return (
    <Box display="flex" flexWrap="wrap" width="100%">
      <Box flexGrow={1} textAlign="left">
        <Button
          onClick={props.onPrev}
          prependIcon="chevronLeft"
          variant="text"
          color="primary"
          size={!isSmallDevice ? 'medium' : 'small'}
        >
          沿線
        </Button>
      </Box>
      <Box textAlign="right" display="flex" columnGap={1}>
        <Badge invisible={!isSearchConditionInvalid} color="red" value="!" position="outsider">
          <Button
            onClick={props.transitToRegion}
            variant="outlined"
            color="primary"
            size={!isSmallDevice ? 'medium' : 'small'}
          >
            詳細条件を入力
          </Button>
        </Badge>
        <Tooltip title={isSearchConditionInvalid ? '入力項目にエラーがあります' : ''} placement="top">
          <Box minWidth="60px">
            <Button
              onClick={props.onStationsDetermined}
              disabled={props.selectedStations.length === 0 || isSearchConditionInvalid}
              variant="contained"
              color="primary"
              size={!isSmallDevice ? 'medium' : 'small'}
              fullWidth
            >
              検索
            </Button>
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};
