import { isNotNullOrUndefined, isNullOrUndefined } from '@app/utils';
import { FC, useEffect, useState } from 'react';
import { useAsync } from 'react-use';

import { ImageContainer, ImageContainerProps } from './ImageContainer';
import NoImage from './NoImage';

import { useDependency } from '@/Hooks/DependencyHook';
import noimageSrc from '@/noimage.svg';

type ImageFromFileAPIProps = Omit<ImageContainerProps, 'children'> & {
  thumbnail?: boolean;
};

const ImageFromFileApi: FC<ImageFromFileAPIProps> = props => {
  const fileApiService = useDependency('fileApiService');
  const state = useAsync(async () => {
    return props.thumbnail
      ? await fileApiService.getImageThumbnailFileAsBlob(props.src)
      : await fileApiService.getImageFileAsBlob(props.src);
  }, [props.thumbnail, props.src, fileApiService]);

  const [imageURL, setImageURL] = useState<string>();
  useEffect(() => {
    if (isNullOrUndefined(state.value)) {
      return;
    }
    const imageURLLocal = URL.createObjectURL(state.value);
    setImageURL(imageURLLocal);
    return () => {
      if (isNullOrUndefined(imageURLLocal)) {
        return;
      }
      URL.revokeObjectURL(imageURLLocal);
    };
  }, [state.value]);

  const component =
    !state.loading && isNotNullOrUndefined(imageURL) ? (
      <img src={imageURL} alt={props.alt} />
    ) : noimageSrc !== null ? (
      <img src={noimageSrc} alt={props.alt} />
    ) : (
      <NoImage />
    );

  return <ImageFromFileApiPresenter props={props} component={component} />;
};

type ImageFromFileApiPresenterProps = {
  props: ImageFromFileAPIProps;
  component: JSX.Element;
};

export const ImageFromFileApiPresenter: FC<ImageFromFileApiPresenterProps> = ({ props, component }) => {
  return <ImageContainer {...props}>{component}</ImageContainer>;
};

export default ImageFromFileApi;
