import { deepCompare } from '@app/utils';
import { TextField } from '@e-seikatsu/design-system';
import { useEffect, useState, FC, memo, ChangeEventHandler, KeyboardEvent, Dispatch, SetStateAction } from 'react';

import { ConditionFormGrid } from './ConditionFormGrid';

import { useHandlerOnChangeText } from '@/Hooks/Handlers/ChangeHandlers';
import { useHandlerOnEnter } from '@/Hooks/Handlers/KeyDownHandlers';
import { SearchConditionHook } from '@/Hooks/Search/SearchConditionHook';
import { ChintaiSearchConditions } from '@/Models/SearchConditions/ChintaiSearchConditions';

type BukkenKanaNameFormContainerProps = {
  hook: SearchConditionHook<string, ChintaiSearchConditions>;
  onEnter: () => void;
  isSmallDevice: boolean;
  focusOnOpen: boolean;
};

const BukkenNameKanaFormContainer: FC<BukkenKanaNameFormContainerProps> = memo(
  ({ hook, onEnter, isSmallDevice, focusOnOpen }) => {
    const { value, setValue } = hook;
    const onChange = useHandlerOnChangeText(setValue);
    const onKeyDown = useHandlerOnEnter(onEnter);

    const [formInputRef, setFormInputRef] = useState<HTMLInputElement | null>(null);
    useEffect(() => {
      if (focusOnOpen && formInputRef) {
        formInputRef.focus();
      }
    }, [focusOnOpen, formInputRef]);

    return (
      <BukkenNameKanaFormPresenter
        isSmallDevice={isSmallDevice}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        setFormInputRef={setFormInputRef}
      />
    );
  },
  deepCompare
);

export type BukkenNameKanaFormPresenterProps = {
  isSmallDevice: boolean;
  value: string | undefined;
  onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onKeyDown: (e: KeyboardEvent<Element>) => void;
  setFormInputRef: Dispatch<SetStateAction<HTMLInputElement | null>>;
};
export const BukkenNameKanaFormPresenter: FC<BukkenNameKanaFormPresenterProps> = ({
  isSmallDevice,
  value,
  onChange,
  onKeyDown,
  setFormInputRef,
}) => {
  return (
    <ConditionFormGrid label="物件名（カナ）" isSmallDevice={isSmallDevice}>
      <TextField
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        ref={setFormInputRef}
        size="small"
        length="long"
      />
    </ConditionFormGrid>
  );
};

export default BukkenNameKanaFormContainer;
