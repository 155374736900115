import { ButtonMenu, SplitButton } from '@lib/components';
import { Box, Button, buttonClasses, CircularProgress, Tooltip } from '@mui/material';
import { Download } from 'mdi-material-ui';
import { useCallback, FC, MouseEvent, Fragment } from 'react';

import { ActionButtonLayout } from './ActionButtonLayout';

import { useUserActionTracker } from '@/Hooks/Analytics';
import { useFlyerDownload } from '@/Hooks/Flyer/FlyerDownloadHook';
import { searchResultSxProps } from '@/Pages/bukken/chintai/SearchPartial/SearchResultStyles';
import { ViewStatusBukken } from '@/Services/ISendingViewStatusService';

type FlyerDownloadButtonContainerProps = {
  bukkenGuid: string;
  analyticsPlace: string;
  buttonVariant: 'outlined' | 'contained' | 'text';
  viewStatusBukken: ViewStatusBukken;
  isCustomerView: boolean;
  isSmallToMediumDevice: boolean;
  useIconButton?: boolean;
};

export const FlyerDownloadButtonContainer: FC<FlyerDownloadButtonContainerProps> = ({
  bukkenGuid,
  analyticsPlace,
  buttonVariant,
  viewStatusBukken,
  isCustomerView,
  isSmallToMediumDevice,
  useIconButton,
}) => {
  const tracker = useUserActionTracker(analyticsPlace);
  const { motodukeDownload, kyakudukeDownload, isDownloading, canDownload } = useFlyerDownload(
    bukkenGuid,
    viewStatusBukken,
    tracker
  );

  return (
    <ActionButtonLayout>
      <FlyerDownloadButtonPresenter
        motodukeDownload={motodukeDownload}
        kyakudukeDownload={kyakudukeDownload}
        buttonVariant={buttonVariant}
        isCustomerView={isCustomerView}
        isSmallToMediumDevice={isSmallToMediumDevice}
        useIconButton={useIconButton}
        isDownloading={isDownloading}
        canDownload={canDownload}
      />
    </ActionButtonLayout>
  );
};

export type FlyerDownloadButtonPresenterProps = {
  motodukeDownload: () => Promise<void>;
  kyakudukeDownload: () => Promise<void>;
  buttonVariant: 'outlined' | 'contained' | 'text';
  isCustomerView: boolean;
  isSmallToMediumDevice: boolean;
  useIconButton?: boolean;
  isDownloading: boolean;
  canDownload: boolean;
};

export const FlyerDownloadButtonPresenter: FC<FlyerDownloadButtonPresenterProps> = ({
  motodukeDownload,
  kyakudukeDownload,
  buttonVariant,
  isCustomerView,
  isSmallToMediumDevice,
  useIconButton,
  isDownloading,
  canDownload,
}) => {
  const onClickButton = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      kyakudukeDownload();
    },
    [kyakudukeDownload]
  );

  const renderButtonMenu = () => (
    <ButtonMenu
      icon={
        isDownloading ? (
          <CircularProgress size={24} />
        ) : (
          <Box display="flex" alignItems="center">
            {!useIconButton && <Download sx={{ width: '20px', height: '20px' }} />}
            <Box fontSize="12px" mr={0.5}>
              図面
            </Box>
          </Box>
        )
      }
      menuActions={
        !isCustomerView
          ? [
              {
                text: '元付帯図面',
                onSelected: motodukeDownload,
              },
              {
                text: '貴社帯図面',
                onSelected: kyakudukeDownload,
              },
            ]
          : [
              {
                text: '貴社帯図面',
                onSelected: kyakudukeDownload,
              },
            ]
      }
      buttonVariant={buttonVariant}
      buttonColor="primary"
      tooltipTitle="図面ダウンロード"
    />
  );

  const renderSplitButton = () => (
    <SplitButton
      mainAction={{ text: '図面', onSelected: motodukeDownload }}
      menuActions={[
        {
          text: '貴社帯図面',
          onSelected: kyakudukeDownload,
        },
      ]}
      variant={buttonVariant}
      color="primary"
      startIcon={isDownloading ? <CircularProgress size={24} /> : <Download />}
      disabled={!canDownload}
      tooltipTitle="元付帯図面"
    />
  );

  const renderCustomerView = () => (
    <Tooltip title="貴社帯図面">
      <Button
        variant={buttonVariant}
        color="primary"
        startIcon={isDownloading ? <CircularProgress size={24} /> : <Download />}
        onClick={onClickButton}
        sx={{
          [`&.${buttonClasses.root}`]: searchResultSxProps.actionButton,
          [`&.${buttonClasses.outlined}`]: searchResultSxProps.actionButtonOutlined,
          [`& .${buttonClasses.startIcon}`]: searchResultSxProps.actionButtonStartIcon,
        }}
        disabled={!canDownload}
      >
        図面
      </Button>
    </Tooltip>
  );

  const render = () => {
    if (useIconButton) {
      return renderButtonMenu();
    }
    if (isCustomerView) {
      return renderCustomerView();
    }
    return isSmallToMediumDevice ? renderButtonMenu() : renderSplitButton();
  };

  return <Fragment>{render()}</Fragment>;
};
