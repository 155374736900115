import { Checkbox } from '@e-seikatsu/design-system';
import { ChangeEvent } from 'react';

type BukkenCheckboxFormProps<T extends string> = {
  value: T[] | undefined;
  setValue: (value: T[]) => void;
  label: string;
  choiceCode: T;
};

const BukkenCheckbox = <T extends string>(props: BukkenCheckboxFormProps<T>): JSX.Element => {
  const onChange = (e: ChangeEvent<HTMLInputElement>, x: T): void => {
    const oldVal = props.value ?? [];
    const newVal = e.target.checked ? [...oldVal, x] : oldVal.filter(n => n !== x);
    props.setValue(newVal);
  };

  return (
    <Checkbox
      variant="outlined"
      size="small"
      checked={props.value?.includes(props.choiceCode)}
      onChange={(e): void => onChange(e, props.choiceCode)}
      label={props.label}
    />
  );
};
export default BukkenCheckbox;
