import { deepCompare } from '@app/utils';
import { Select } from '@e-seikatsu/design-system';
import { Grid, Box, Tooltip } from '@mui/material';
import { memo, FC } from 'react';

import { searchConditionSxProps } from './SearchConditionStyle';

import { SearchConditionHook } from '@/Hooks/Search/SearchConditionHook';
import { borderSxProps } from '@/Hooks/Styles/BorderStyle';
import { ChintaiSearchConditions } from '@/Models/SearchConditions/ChintaiSearchConditions';

type BukkenEkitohoFormProps = {
  ekitohoHook: SearchConditionHook<string, ChintaiSearchConditions>;
  isEnable: boolean;
  isSmallDevice: boolean;
};

const BukkenEkitohoForm: FC<BukkenEkitohoFormProps> = memo(({ ekitohoHook, isEnable, isSmallDevice }) => {
  const ekitohoRange = ['1', '5', '7', '10', '15', '20'];
  const tooltipTitle = isEnable ? '' : '路線・駅の未選択時は駅からの徒歩時間は指定できません。';
  return (
    <Grid container sx={{ ...searchConditionSxProps.root, ...borderSxProps.borderBottom }}>
      <Grid
        item
        xs={12}
        sm={2}
        sx={{ ...searchConditionSxProps.firstCol, ...(isSmallDevice ? borderSxProps.borderBottom : null) }}
      >
        駅徒歩
      </Grid>
      <Grid item xs={12} sm={10} sx={{ ...searchConditionSxProps.secondCol, ...searchConditionSxProps.inputCol }}>
        <Tooltip title={tooltipTitle}>
          {/* MUIのTooltipは子要素にrefを必要とするため、Boxでラップしてrefを付与 */}
          <Box width={isSmallDevice ? '100%' : 'auto'}>
            <Select
              size="small"
              length={isSmallDevice ? 'full' : 'normal'}
              data-testid="bukkenEkitohoForm"
              disabled={!isEnable}
              value={ekitohoHook.value}
              onChange={(value): void => {
                ekitohoHook.setValue(String(value));
              }}
            >
              <Select.Option value="">指定しない</Select.Option>
              {ekitohoRange.map(it => (
                <Select.Option value={it} key={it}>
                  {`${it}分以内`}
                </Select.Option>
              ))}
            </Select>
          </Box>
        </Tooltip>
      </Grid>
    </Grid>
  );
}, deepCompare);

export default BukkenEkitohoForm;
