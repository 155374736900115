import { HttpError, TooLongUrlError, errorCodeType } from '@app/errors';
import { SpecBukkenView } from '@app/models';
import { isNullOrUndefined } from '@app/utils';
import { ErrorMessage, Loading, useIsSmallDevice } from '@lib/components';
import { Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import { FC, Fragment, memo } from 'react';
import { useAsync } from 'react-use';
import { AsyncState } from 'react-use/lib/useAsync';

import { EmptyOwnBukkenPage } from './EmptyOwnBukkenPage';
import { OwnBukkenList } from './OwnBukkenList';
import { OwnBukkenListColumnHeader } from './OwnBukkenListColumnHeader';
import { OwnBukkenSearchResultFooter } from './OwnBukkenSearchResultFooter';
import { OwnBukkenSearchResultHeader } from './OwnBukkenSearchResultHeader';

import { ErrorMessageObject, BukkenSearchErrorMessage } from '@/Consts/ErrorMessages';
import { makeReportErrorFunc } from '@/ErrorLogger';
import { CustomerViewStateHooks, useCustomerViewStateHooks } from '@/Hooks/customerViewHooks';
import { ChintaiResultOrder } from '@/Models/SearchConditions/ChintaiSearchConditionEnums/ChintaiSearchConditionEnums';
import { AsyncListBase } from '@/Models/SearcherBase';
import { LoadingWrapperBox, searchResultSxProps } from '@/Pages/bukken/chintai/SearchPartial/SearchResultStyles';

const reportErrorLocal = makeReportErrorFunc('OwnBukkenList');

type OwnBukkenSearchResultListContainerProps = {
  asyncList: AsyncListBase<SpecBukkenView> | null;
  currentPage: number;
  currentOrder: ChintaiResultOrder;
  onChangePage: (index: number) => void;
  onChangeOrder: (order: ChintaiResultOrder) => void;
  setItemsPerPage: (itemsPerPage: number) => void;
};

const OwnBukkenSearchResultListContainer: FC<OwnBukkenSearchResultListContainerProps> = memo(
  ({ asyncList, currentPage, onChangePage, currentOrder, onChangeOrder, setItemsPerPage }) => {
    const isSmallDevice = useIsSmallDevice();
    const customerViewStateHooks = useCustomerViewStateHooks();

    const currentView = useAsync(async () => {
      if (!asyncList) {
        return null;
      }

      const searchResult = await asyncList.getPage(currentPage - 1);
      return searchResult;
    }, [asyncList, currentPage]);

    if (currentView.loading && isNullOrUndefined(asyncList?.totalCount)) {
      return (
        <LoadingWrapperBox>
          <Loading />
        </LoadingWrapperBox>
      );
    }

    if (!isNullOrUndefined(currentView.error)) {
      const buildErrorMessage = (e: Error): ErrorMessageObject => {
        switch (true) {
          case e instanceof TooLongUrlError:
            return BukkenSearchErrorMessage.tooManyConditions;
          case e instanceof HttpError && e.errorCode === errorCodeType.tooManyResults:
            return BukkenSearchErrorMessage.tooManySearchResults;
          default:
            reportErrorLocal(e, 'OwnBukkenList');
            return BukkenSearchErrorMessage.default;
        }
      };
      const errorMessage = buildErrorMessage(currentView.error);
      return (
        <Box sx={searchResultSxProps.errorWrapper}>
          <Box py={2} px={{ xs: 2, md: 14 }} bgcolor="background.paper">
            <ErrorMessage header={errorMessage.messageHeader} supportMessage={errorMessage.messageText} small />
          </Box>
        </Box>
      );
    }

    if (isNullOrUndefined(currentView.value)) {
      return <EmptyOwnBukkenPage />;
    }

    return (
      <OwnBukkenSearchResultListPresenter
        asyncList={asyncList}
        currentView={currentView}
        currentPage={currentPage}
        currentOrder={currentOrder}
        onChangePage={onChangePage}
        onChangeOrder={onChangeOrder}
        setItemsPerPage={setItemsPerPage}
        customerViewStateHooks={customerViewStateHooks}
        isSmallDevice={isSmallDevice}
      />
    );
  }
);

export { OwnBukkenSearchResultListContainer as OwnBukkenSearchResultList };

type OwnBukkenSearchResultListPresenterProps = {
  asyncList: AsyncListBase<SpecBukkenView> | null;
  currentView: AsyncState<SpecBukkenView[] | null>;
  currentPage: number;
  currentOrder: ChintaiResultOrder;
  onChangePage: (index: number) => void;
  onChangeOrder: (order: ChintaiResultOrder) => void;
  setItemsPerPage: (itemsPerPage: number) => void;
  customerViewStateHooks: CustomerViewStateHooks;
  isSmallDevice: boolean;
};

export const OwnBukkenSearchResultListPresenter: FC<OwnBukkenSearchResultListPresenterProps> = memo(
  ({
    asyncList,
    currentView,
    currentPage,
    onChangePage,
    currentOrder,
    onChangeOrder,
    setItemsPerPage,
    customerViewStateHooks,
    isSmallDevice,
  }) => {
    return (
      <Box display="flex" flexDirection="column" bgcolor={`${grey[100]}`}>
        <OwnBukkenSearchResultHeader
          totalCount={asyncList?.totalCount}
          currentOrder={currentOrder}
          onChangeOrder={onChangeOrder}
          isCustomerView={customerViewStateHooks.isCustomerView}
          itemsPerPage={asyncList?.itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          currentPage={currentPage}
        />
        {currentView.value && currentView.value.length === 0 ? (
          <EmptyOwnBukkenPage />
        ) : (
          <Fragment>
            <Box flexGrow="1">
              {!isSmallDevice && <OwnBukkenListColumnHeader />}
              <OwnBukkenList isLoading={currentView.loading} currentView={currentView.value} />
            </Box>
            <Box position="sticky" bottom={0} bgcolor={`${grey[100]}`} height="48px">
              <OwnBukkenSearchResultFooter
                itemsPerPage={asyncList?.itemsPerPage}
                totalCount={asyncList?.totalCount}
                currentPage={currentPage}
                onChangePage={onChangePage}
              />
            </Box>
          </Fragment>
        )}
      </Box>
    );
  }
);
