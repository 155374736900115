import { SpecBukkenView } from '@app/models';
import { Loading } from '@lib/components';
import { FC, Fragment, memo } from 'react';

import { OwnBukkenItem } from './OwnBukkenItem';

import { LoadingWrapperBox } from '@/Pages/bukken/chintai/SearchPartial/SearchResultStyles';

type OwnBukkenListProps = {
  isLoading: boolean;
  currentView: SpecBukkenView[] | null | undefined;
};

export const OwnBukkenList: FC<OwnBukkenListProps> = memo(({ isLoading, currentView }) => {
  if (isLoading) {
    return (
      <LoadingWrapperBox>
        <Loading />
      </LoadingWrapperBox>
    );
  }

  return (
    <Fragment>
      {currentView?.map((view): JSX.Element => {
        return <OwnBukkenItem key={view.chinshaku_bukken_view.chinshaku_bukken_guid} specBukkenView={view} />;
      })}
    </Fragment>
  );
});
