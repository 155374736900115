import { Address } from '@app/models';
import { css } from '@emotion/react';
import { useIsExtraSmallDevice, useOnOffState } from '@lib/components';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { AccountPlus, Close } from 'mdi-material-ui';
import { FC, memo } from 'react';

import { PartnerApplicantsSendExplanationStepper } from './PartnerApplicantsSendExplanationStepper';

import { PartnerApplicantsSendDialog } from '@/Components/Partner/Applicants/PartnerApplicantsSendDialog';
import { restrictionInternalAccountTooltipText } from '@/Consts/Messages';
import { useIsValidTakkenLicenseNumber } from '@/Hooks/ValidationHooks';
import { EsaPublicDomain } from '@/Models/Esa/EsaDomain';
import { EsaPublicOrganization } from '@/Models/Esa/EsaOrganization';
import { Prefecture } from '@/Services/IMasterApiService';

type PartnerApplicantsSendExplanationDialogPresenterProps = {
  isExtraSmallDevice: boolean;
  isOpenExplanationDialog: boolean;
  closeExplanationDialog: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleDialogClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  domain: EsaPublicDomain;
  organization: EsaPublicOrganization;
  isOpenPartnerApplicantsSendDialog: boolean;
  openPartnerApplicantsSendDialog: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  closePartnerApplicantsSendDialog: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isValidTakkenLicenseNumber: boolean;
  analyticsPlace: string;
};

type PartnerApplicantsSendExplanationDialogContainerProps = {
  isOpenExplanationDialog: boolean;
  closeExplanationDialog: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  domain: EsaPublicDomain;
  organization: EsaPublicOrganization;
  analyticsPlace: string;
};

export type AreaItem = {
  pref: Prefecture | null;
  city: Address | null;
};

const dialogTitleStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '50px',
});

const dialogActionStyle = css({
  textAlign: 'right',
});
const PartnerApplicantsSendExplanationDialogContainer: FC<PartnerApplicantsSendExplanationDialogContainerProps> = memo(
  ({ isOpenExplanationDialog, closeExplanationDialog, domain, organization, analyticsPlace }) => {
    const isExtraSmallDevice = useIsExtraSmallDevice();
    const isValidTakkenLicenseNumber = useIsValidTakkenLicenseNumber();
    const [
      isOpenPartnerApplicantsSendDialog,
      { setTrue: openPartnerApplicantsSendDialog, setFalse: closePartnerApplicantsSendDialog },
    ] = useOnOffState(false);

    const onOpenPartnerApplicantsSendDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      openPartnerApplicantsSendDialog();
    };

    const onClosePartnerApplicantsSendDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      closePartnerApplicantsSendDialog();
      closeExplanationDialog(e);
    };

    const handleDialogClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
    };

    return (
      <PartnerApplicantsSendExplanationDialogPresenter
        isExtraSmallDevice={isExtraSmallDevice}
        isOpenExplanationDialog={isOpenExplanationDialog}
        closeExplanationDialog={closeExplanationDialog}
        handleDialogClick={handleDialogClick}
        domain={domain}
        organization={organization}
        isOpenPartnerApplicantsSendDialog={isOpenPartnerApplicantsSendDialog}
        openPartnerApplicantsSendDialog={onOpenPartnerApplicantsSendDialog}
        closePartnerApplicantsSendDialog={onClosePartnerApplicantsSendDialog}
        isValidTakkenLicenseNumber={isValidTakkenLicenseNumber}
        analyticsPlace={analyticsPlace}
      />
    );
  }
);

export { PartnerApplicantsSendExplanationDialogContainer as PartnerApplicantsSendExplanationDialog };

export const PartnerApplicantsSendExplanationDialogPresenter: FC<PartnerApplicantsSendExplanationDialogPresenterProps> =
  memo(
    ({
      isExtraSmallDevice,
      isOpenExplanationDialog,
      closeExplanationDialog,
      handleDialogClick,
      domain,
      organization,
      isOpenPartnerApplicantsSendDialog,
      openPartnerApplicantsSendDialog,
      closePartnerApplicantsSendDialog,
      isValidTakkenLicenseNumber,
      analyticsPlace,
    }) => {
      return (
        <>
          <Dialog
            open={isOpenExplanationDialog}
            onClose={closeExplanationDialog}
            onClick={handleDialogClick}
            maxWidth="sm"
            fullWidth
            fullScreen={isExtraSmallDevice}
          >
            <DialogTitle css={dialogTitleStyle}>
              <Box display="flex" alignItems="center">
                取引先になるとメッセージが送れます
              </Box>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <IconButton onClick={closeExplanationDialog} size="large">
                  <Close />
                </IconButton>
              </Box>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <PartnerApplicantsSendExplanationStepper activeStepNumber={0} />
              <Box mb={1}>取引先申請を送り、{organization.businessName}とメッセージをしましょう🤝</Box>
              <Box p={2} css={{ backgroundColor: 'rgb(229, 246, 253)' }}>
                <Typography fontSize="1rem" fontWeight="bold">
                  {organization.businessName}と取引先になると・・・
                </Typography>
                <Typography fontSize="1rem">
                  ・限定公開物件を閲覧できます <br />
                  ・物件紹介などを受け取りやすくなります <br />
                  ・PCとスマホからメッセージを送れます
                </Typography>
              </Box>
            </DialogContent>
            <Divider />
            <DialogActions css={dialogActionStyle}>
              <Button onClick={closeExplanationDialog}>閉じる</Button>
              <Tooltip
                title={!isValidTakkenLicenseNumber ? restrictionInternalAccountTooltipText : ''}
                disableInteractive
              >
                <span>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={openPartnerApplicantsSendDialog}
                    disabled={!isValidTakkenLicenseNumber}
                  >
                    <AccountPlus />
                    取引先申請画面へ
                  </Button>
                </span>
              </Tooltip>
            </DialogActions>
          </Dialog>
          {isOpenPartnerApplicantsSendDialog && (
            <PartnerApplicantsSendDialog
              isOpenDialog={isOpenPartnerApplicantsSendDialog}
              closeDialog={closePartnerApplicantsSendDialog}
              domain={domain}
              organization={organization}
              analyticsPlace={analyticsPlace}
              isFromPartnerApplicants={false}
            />
          )}
        </>
      );
    }
  );
