import { isNullOrUndefined } from '@app/utils';
import { useOnOffState } from '@lib/components';
import { Stack, TextField, Typography } from '@mui/material';
import { FC, useState } from 'react';

import { theme } from '@/Theme';

const inputMaxLength = 9;

export const parse = (value: string | undefined): number => {
  const formattedValue = isNullOrUndefined(value)
    ? 0
    : value
        // 小数点の正規化
        .replace(/[．.。｡]/g, '')
        // 数字の正規化
        .replace(/[０-９]/g, s => String.fromCharCode(s.charCodeAt(0) - 0xfee0))
        .replace(/[^0-9.]/g, '');
  return Number(formattedValue);
};

type InitialCostPriceFormContainerProps = {
  handleChangeInitialCostPrice: (index: number, price: number) => void;
  handleChangeTotalInitialCostAmount: () => void;
  index: number;
  value: string;
  isSmallDevice: boolean;
};

const InitialCostPriceFormContainer: FC<InitialCostPriceFormContainerProps> = ({
  handleChangeInitialCostPrice,
  handleChangeTotalInitialCostAmount,
  index,
  value,
  isSmallDevice,
}) => {
  const [focused, { setTrue: focus, setFalse: blur }] = useOnOffState(false);
  const [internalValue, setInternalValue] = useState<string>(value);

  const formattedPriceNumber = internalValue === '' ? 0 : parse(internalValue);

  const formattedPriceText = focused ? internalValue : Number(value) === 0 ? '' : Number(value).toLocaleString();

  const onBlur = () => {
    blur();
    handleChangeInitialCostPrice(index, formattedPriceNumber);
    handleChangeTotalInitialCostAmount();
    setInternalValue(formattedPriceNumber === 0 ? '' : formattedPriceNumber.toString());
  };

  return (
    <InitialCostPriceFormPresenter
      value={formattedPriceText}
      setInternalValue={setInternalValue}
      onBlur={onBlur}
      onFocus={focus}
      isSmallDevice={isSmallDevice}
    />
  );
};

export { InitialCostPriceFormContainer as InitialCostPriceForm };

type InitialCostPriceFormPresenterProps = {
  value: string | undefined;
  setInternalValue: React.Dispatch<React.SetStateAction<string>>;
  onBlur: () => void;
  onFocus: () => void;
  isSmallDevice: boolean;
};

export const InitialCostPriceFormPresenter: FC<InitialCostPriceFormPresenterProps> = ({
  value,
  setInternalValue,
  onBlur,
  onFocus,
  isSmallDevice,
}) => (
  <Stack direction="row" css={{ justifyContent: 'flex-end', alignItems: 'center' }}>
    <TextField
      variant="standard"
      value={value}
      onChange={event => setInternalValue(event.target.value)}
      onBlur={onBlur}
      onFocus={onFocus}
      inputProps={{
        maxLength: inputMaxLength,
        sx: { textAlign: 'end' },
        inputMode: isSmallDevice ? 'numeric' : undefined,
      }}
      css={{
        [theme.breakpoints.down('md')]: {
          width: '100%',
        },
      }}
    />
    <Typography css={{ paddingLeft: '4px' }}>円</Typography>
  </Stack>
);
