import { ChinshakuSonotaHiyo } from '@app/models';
import { ReactNode } from 'react';

export type InitialCostCalcType = {
  chinryo: number | undefined | null;
  kanrihi: number | undefined | null;
  kanrihi_kubun_code: number | undefined | null;
  kyoekihi: number | undefined | null;
  kyoekihi_kubun_code: number | undefined | null;
  zappi_amount: number | undefined | null;
  zappi_kubun_code: number | undefined | null;
  shikikin_amount: number | undefined | null;
  shikikin_kubun_code: number | undefined | null;
  reikin_amount: number | undefined | null;
  reikin_kubun_code: number | undefined | null;
  hoshokin_amount: number | undefined | null;
  hoshokin_kubun_code: number | undefined | null;
  sompo_ryokin_amount: number | undefined | null;
  kokokuryo_amount: number | undefined | null;
  kokokuryo_tani: number | undefined | null;
  hoshonin_daiko_code: number | undefined | null;
  hoshonin_daiko_kaisha_kubun: string | undefined | null;
  hoshonin_daiko_shosai: string | undefined | null;
  es_b2b_chintai_pdf_comment: string | undefined | null;
  comment: string | undefined | null;
  sonotaHiyo: ChinshakuSonotaHiyo[] | undefined;
};

export type InitialCostGetsujiReadOnlyType = {
  chinryo: number;
  kanrihi: number;
  kyoekihi: number;
  zappi: number;
};

export type InitialCostIchijikinReadOnlyType = {
  shikikin: number;
  reikin: number;
  hoshokin: number;
  sompo: number;
};

export type InitialCostDisplayData = {
  label: string;
  content: ReactNode;
};

export const createInitialCostSonotaGetsujiDisplayData = (
  chinryoText: string,
  kanrihiKyoekihiText: string,
  zappiText: string
): InitialCostDisplayData[] => {
  return [
    { label: '賃料', content: chinryoText },
    { label: '管理費・共益費', content: kanrihiKyoekihiText },
    { label: '雑費', content: zappiText },
  ];
};

export const createInitialCostSonotaIchijiDisplayData = (
  shikikinText: string,
  reikinText: string,
  hoshokinText: string,
  sompo: string
): InitialCostDisplayData[] => {
  return [
    { label: '敷金', content: shikikinText },
    { label: '礼金', content: reikinText },
    {
      label: '保証金',
      content: hoshokinText,
    },
    {
      label: '損害保険',
      content: sompo,
    },
  ];
};

//レスポンスとして受け取ったその他費用のデータから初期費用表示に必要なデータを作成
export const createSonotaHiyoDisplayData = (sonotaHiyo: ChinshakuSonotaHiyo): ExtraInitialCostData => {
  const sonotaHiyoPrice = sonotaHiyo.sonota_hiyo_amount ? sonotaHiyo.sonota_hiyo_amount : '';
  return { label: sonotaHiyo.sonota_hiyo_name, price: sonotaHiyoPrice };
};

// その他費用として入力フォームで追加する値の型
export type ExtraInitialCostData = {
  label: string;
  price: '' | number;
  id?: number;
};

export const calcTotalInitialCostPriceAmount = (
  chinryo: number,
  kanrihi: number,
  kyoekihi: number,
  zappi: number,
  shikikin: number,
  reikin: number,
  hoshokin: number,
  sompo: number,
  initialCostSonotaGetsujiWithLabelDisplayData: ExtraInitialCostData[],
  initialCostSonotaIchijiWithLabelDisplayData: ExtraInitialCostData[],
  extraInitialCostGetsuji: ExtraInitialCostData[],
  extraInitialCostIchiji: ExtraInitialCostData[]
): number => {
  const totalReadOnlyInitialCostPriceAmount =
    chinryo + kanrihi + kyoekihi + zappi + shikikin + reikin + hoshokin + sompo;

  const totalSonotaGetsujiWithLabelPriceAmount = initialCostSonotaGetsujiWithLabelDisplayData.reduce(
    (sum, element) => sum + Number(element.price),
    0
  );

  const totalSonotaIchijiWithLabelPriceAmount = initialCostSonotaIchijiWithLabelDisplayData.reduce(
    (sum, element) => sum + Number(element.price),
    0
  );

  const totalExtraInitialCostGetsujiPriceAmount = extraInitialCostGetsuji.reduce(
    (sum, element) => sum + Number(element.price),
    0
  );
  const totalExtraInitialCostIchijiPriceAmount = extraInitialCostIchiji.reduce(
    (sum, element) => sum + Number(element.price),
    0
  );

  const totalInitialCostPriceAmount =
    totalReadOnlyInitialCostPriceAmount +
    totalSonotaGetsujiWithLabelPriceAmount +
    totalSonotaIchijiWithLabelPriceAmount +
    totalExtraInitialCostGetsujiPriceAmount +
    totalExtraInitialCostIchijiPriceAmount;
  return totalInitialCostPriceAmount;
};
