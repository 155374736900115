import { useIsExtraSmallDevice } from '@lib/components';
import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { FC, memo } from 'react';

type PartnerApplicantsSendExplanationStepperContainerProps = {
  activeStepNumber: number;
};

const PartnerApplicantsSendExplanationStepperContainer: FC<PartnerApplicantsSendExplanationStepperContainerProps> =
  memo(({ activeStepNumber }) => {
    const isExtraSmallDevice = useIsExtraSmallDevice();
    return (
      <PartnerApplicantsSendExplanationStepperPresenter
        isExtraSmallDevice={isExtraSmallDevice}
        activeStepNumber={activeStepNumber}
      />
    );
  });

export { PartnerApplicantsSendExplanationStepperContainer as PartnerApplicantsSendExplanationStepper };

type PartnerApplicantsSendExplanationStepperPresenterProps = {
  isExtraSmallDevice: boolean;
  activeStepNumber: number;
};

const stepLabels = ['申請','承認','メッセージ'];

export const PartnerApplicantsSendExplanationStepperPresenter: FC<PartnerApplicantsSendExplanationStepperPresenterProps> =
  memo(({ isExtraSmallDevice, activeStepNumber }) => {
    return isExtraSmallDevice ? (
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStepNumber} alternativeLabel>
          {stepLabels.map(label => (
            <Step key={label}>
              <StepLabel>
                <Typography fontWeight="bold">{label}</Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    ) : (
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStepNumber} alternativeLabel>
          {stepLabels.map(label => (
            <Step key={label}>
              <StepLabel>
                <Typography fontWeight="bold">{label}</Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    );
  });
