import {
  HoshoninDaikoCode,
  HoshoninDaikoCodeType,
  KeiyakuKubunCodeToTextMap,
  NyukyoKanoCode,
  NyukyoKanoCodeType,
  areaUnitCodeToText,
  asAreaUnitCode,
  asKeiyakuKubunCode,
  hoshoninDaikoCodeToText,
  nyukyoKanoCodeToText,
  PriceUnitCode,
  PriceUnitCodeType,
} from '@app/enums';
import {
  ChinshakuBoshuJokenShosai,
  ChinshakuKotsu,
  KukakuHeyaShosai,
  Datejun,
  SpecBukkenView,
  ChinshakuBukken,
  Tatemono,
  Kukaku,
  Tochi,
} from '@app/models';
import { isNotNullOrEmptyString, isNotNullOrUndefined, isNullOrUndefined } from '@app/utils';
import { format, parseISO } from 'date-fns';

import { makePriceText } from './Money';

import { overviewDateFormat } from '@/Pages/bukken/chintai/DetailPartial/GeneralDescriptionTabPartial/OverviewData/CalcNormalOverviewData';

export function makeKotsuText(k: ChinshakuKotsu): string {
  if (k.kotsu_shubetsu === 1) {
    // 鉄道
    const ekiText = `${k.kotsu_rosen_name ?? ''} ${k.kotsu_eki_name ?? '-'}駅`;
    let accessText: string;
    if (k.kotsu_moyorieki_shudan === '徒歩') {
      accessText = `徒歩${k.kotsu_ekitoho_jikan ?? '-'}分`;
    } else if (k.kotsu_moyorieki_shudan === 'バス') {
      accessText = `バス：${k.kotsu_bus_rosen_name ?? ''} ${k.kotsu_bus_tei_name ?? '-'}`;
      if (isNotNullOrUndefined(k.kotsu_bus_josha_jikan)) {
        accessText += ` (乗車${k.kotsu_bus_josha_jikan ?? '-'}分) `;
      }
      if (isNotNullOrUndefined(k.kotsu_bus_tei_kyori)) {
        accessText += `徒歩${k.kotsu_bus_tei_kyori}m`;
      } else if (isNotNullOrUndefined(k.kotsu_bus_tei_teiho_jikan)) {
        accessText += `徒歩${k.kotsu_bus_tei_teiho_jikan}分`;
      }
    } else if (k.kotsu_moyorieki_shudan === '距離') {
      const kotsuList: string[] = [];
      if (isNotNullOrUndefined(k.kotsu_eki_kyori)) {
        kotsuList.push(`徒歩${k.kotsu_eki_kyori ?? '-'}m`);
      }
      if (isNotNullOrUndefined(k.kotsu_kuruma_shoyo_jikan) || isNotNullOrUndefined(k.kotsu_kuruma_kyori)) {
        kotsuList.push(
          `車${!isNullOrUndefined(k.kotsu_kuruma_shoyo_jikan) ? `${k.kotsu_kuruma_shoyo_jikan}分` : ''} ${
            !isNullOrUndefined(k.kotsu_kuruma_kyori) ? `(${k.kotsu_kuruma_kyori / 1000}km)` : ''
          }`
        );
      }
      accessText = kotsuList.join(' ');
    } else {
      accessText = '(不正な最寄り駅アクセス手段です)';
    }
    return `${ekiText} ${accessText}`;
  } else if (k.kotsu_shubetsu === 2) {
    // バス
    return `バス：${[
      k.kotsu_bus_rosen_name ?? '',
      k.kotsu_bus_tei_name ?? '',
      isNotNullOrUndefined(k.kotsu_bus_tei_teiho_jikan) ? `徒歩${k.kotsu_bus_tei_teiho_jikan}分` : '',
      isNotNullOrUndefined(k.kotsu_bus_tei_kyori) ? `徒歩${k.kotsu_bus_tei_kyori}m` : '',
    ]
      .filter(isNotNullOrEmptyString)
      .join(' ')}`;
  } else if (k.kotsu_shubetsu === 99) {
    // その他
    return k.sonota_kotsu ?? '';
  } else {
    return '(不正な交通情報です)';
  }
}

const MoneyUnitCodeMap: { [key: number]: string } = {
  1: 'ヶ月',
  2: '年',
  3: '円',
  4: '％',
  5: '実費',
};
export function makeMoneyAmountWithUnit(amount: number | null, unitCode: number | null): string {
  if (amount === null || unitCode === null) {
    return '-';
  }

  const unitCodeText = unitCode >= 1 && unitCode <= 5 ? MoneyUnitCodeMap[unitCode] : '(不正な単位コードです)';
  return `${amount}${unitCodeText}`;
}

const nyukyoKanoCodeMap: { [key: number]: string } = {
  1: '即入居',
  2: '相談',
  3: '期日指定',
  4: '予定',
};
export function getNyukyoKanoText(code: number | null): string {
  if (code === null) {
    return '-';
  }

  return nyukyoKanoCodeMap[code];
}

const boshuYotoCodeMap: { [key: number]: string } = {
  1: '居住用',
  2: '事業用',
  3: '投資用',
};
export function getBoshuYotoText(code: number | null): string {
  if (code === null) {
    return '-';
  }

  return boshuYotoCodeMap[code];
}

export function getKeiyakuKubunCodeMap(code: number | null | undefined): string {
  const keiyakuKubunCode = asKeiyakuKubunCode(code);
  if (isNullOrUndefined(keiyakuKubunCode)) {
    return '-';
  }

  return KeiyakuKubunCodeToTextMap[keiyakuKubunCode];
}

export function makeMadoriShosaiText(madoriList: KukakuHeyaShosai[] | null | undefined): string {
  if (!madoriList || madoriList.length === 0) {
    return '-';
  }

  return madoriList
    .map(m => {
      const areaText = String(m.heya_shosai_heya_menseki).concat(
        areaUnitCodeToText(asAreaUnitCode(m.heya_shosai_heya_menseki_unit_code))
      );
      const floorText = m.heya_shosai_heya_kaisu?.concat('階');
      return [m.heya_shosai_heya_type, areaText, floorText].filter(t => Boolean(t)).join(' ');
    })
    .join(', ');
}

export const makeTaikyoDateText = (taikyoDate: string | null | undefined): string => {
  return isNotNullOrUndefined(taikyoDate) ? format(parseISO(taikyoDate), overviewDateFormat) : '-';
};

export const makeNyukyoKanoText = (
  kubunCode: NyukyoKanoCodeType | null,
  datejun?: Datejun | null | undefined,
  slim?: boolean
): string => {
  if (isNullOrUndefined(kubunCode)) {
    return '-';
  }

  const datejunConvertFunc = (): string | undefined => {
    if (slim) {
      const datejunObj = datejun?.destructDatejun();
      if (!datejunObj) {
        return undefined;
      }
      const { month, day, jun } = datejunObj;
      return day === 0
        ? `${month.toFixed(0).padStart(2, '0')}/${jun}`
        : `${month.toFixed(0).padStart(2, '0')}/${day.toFixed(0).padStart(2, '0')}`;
    }

    return datejun?.toSlashString();
  };

  switch (kubunCode) {
    case NyukyoKanoCode.即入居:
    case NyukyoKanoCode.相談:
      return nyukyoKanoCodeToText(kubunCode);
    case NyukyoKanoCode.予定: {
      const date = datejunConvertFunc();
      return !isNullOrUndefined(date) ? `${date}予定` : '-';
    }
    case NyukyoKanoCode.期日指定: {
      return datejunConvertFunc() ?? '-';
    }
  }
};

export const makeCombinedPriceString = (
  lhsAmount: number | null | undefined,
  lhsPriceUnit: PriceUnitCodeType | null,
  rhsAmount: number | null | undefined,
  rhsPriceUnit: PriceUnitCodeType | null,
  rhsLabel: string
): string => {
  const lhsPriceText = makePriceText(lhsAmount, lhsPriceUnit, {
    emptyIfZero: true,
    emptySubstituteText: '-',
  });
  const rhsPriceText = makePriceText(rhsAmount, rhsPriceUnit, {
    emptyIfZero: true,
  });
  return rhsPriceText.length > 0 ? `${lhsPriceText} (${rhsLabel}：${rhsPriceText})` : lhsPriceText;
};

export const makeSompoString = (
  needSompo: boolean,
  sompoName: string | null | undefined,
  sompoMonths: number | null | undefined,
  sompoPrice: number | null | undefined,
  sompoComment: string | null | undefined,
  joinCommentByNewLine?: boolean
): string => {
  if (!needSompo) {
    return '';
  }

  const sompoMonthsText = !isNullOrUndefined(sompoMonths) ? `${sompoMonths.toFixed(0)} ヶ月` : '';
  const sompoPriceText = !isNullOrUndefined(sompoPrice)
    ? `${makePriceText(sompoPrice, PriceUnitCode.yen, {
        emptySubstituteText: '-',
      })}`
    : '';
  const sompoCommentText =
    !isNullOrUndefined(sompoComment) && sompoComment.length > 0
      ? `${joinCommentByNewLine ? '\n' : ' '}${sompoComment}`
      : '';
  return `有り ${sompoName ?? ''} ${sompoMonthsText} ${sompoPriceText}${sompoCommentText}`;
};

export const makeHoshoKaishaText = (
  hoshoninDaikoCode: HoshoninDaikoCodeType | null,
  hoshoKaishaName: string | null | undefined,
  hoshoKaishaShosai: string | null | undefined
): string => {
  if (isNullOrUndefined(hoshoninDaikoCode)) {
    return '';
  }

  if (hoshoninDaikoCode === HoshoninDaikoCode.notAvailable) {
    return hoshoninDaikoCodeToText(hoshoninDaikoCode);
  }

  if (hoshoKaishaName === 'その他') {
    return [hoshoninDaikoCodeToText(hoshoninDaikoCode), hoshoKaishaShosai ?? '']
      .filter(isNotNullOrEmptyString)
      .join(' ');
  }

  return [hoshoninDaikoCodeToText(hoshoninDaikoCode), hoshoKaishaName ?? '', hoshoKaishaShosai ?? '']
    .filter(isNotNullOrEmptyString)
    .join(' ');
};

const makeSensitiveNyukyoText = (text: string | null | undefined): string | undefined => {
  if (isNullOrUndefined(text)) {
    return undefined;
  }
  // 入居条件が不可に設定されている場合は表示しない
  if (text === '不可') {
    return undefined;
  }
  return text;
};

export const makeNyukyoJokenText = (
  shosai: ChinshakuBoshuJokenShosai | undefined,
  nyukyoJokenComment: string | null | undefined
): string => {
  const nyukyoJokenList: { name: string; condition?: string | null }[] = [
    {
      name: 'ペット',
      condition: shosai?.keiyaku_joken_pet,
    },
    {
      name: '事務所',
      condition: shosai?.keiyaku_joken_jimusho,
    },
    {
      name: '楽器使用',
      condition: shosai?.keiyaku_joken_gakki_shiyo,
    },
    {
      name: '法人',
      condition: shosai?.keiyaku_joken_hojin,
    },
    {
      name: '飲食店',
      condition: shosai?.keiyaku_joken_inshokuten,
    },
    {
      name: 'ルームシェア',
      condition: shosai?.keiyaku_joken_room_share,
    },
    {
      name: '性別',
      condition: shosai?.keiyaku_joken_seibetsu,
    },
    {
      name: '二人入居',
      condition: shosai?.keiyaku_joken_futari_nyukyo,
    },
    {
      name: '外国人入居',
      condition: makeSensitiveNyukyoText(shosai?.keiyaku_joken_gaikokujin_nyukyo),
    },
    {
      name: '学生専用',
      condition: shosai?.keiyaku_joken_gakusei_senyo,
    },
    {
      name: '子供',
      condition: shosai?.keiyaku_joken_kodomo,
    },
    {
      name: '高齢者',
      condition: makeSensitiveNyukyoText(shosai?.keiyaku_joken_koreisha),
    },
    {
      name: '単身',
      condition: shosai?.keiyaku_joken_tanshin,
    },
    {
      name: '友人同士',
      condition: shosai?.keiyaku_joken_yujin,
    },
    {
      name: '留学生',
      condition: makeSensitiveNyukyoText(shosai?.keiyaku_joken_ryugakusei),
    },
    {
      name: '喫煙者',
      condition: shosai?.keiyaku_joken_kitsuensha,
    },
    {
      name: '保証人',
      condition: shosai?.hoshonin,
    },
  ];

  const jokenTexts = nyukyoJokenList
    .filter(joken => isNotNullOrUndefined(joken.condition))
    .map(joken => `${joken.name}(${joken.condition})`);
  if (isNotNullOrUndefined(nyukyoJokenComment)) {
    jokenTexts.push(nyukyoJokenComment);
  }

  return jokenTexts.join(', ');
};

export const isSpecBukkenViewOneHouse = (specBukkenView: SpecBukkenView): boolean =>
  specBukkenView.chinshaku_bukken_view.spec_shubetsu_code === 2 &&
  !(specBukkenView.tatemono_shubetsu_code === 7 || specBukkenView.tatemono_shubetsu_code === 12);

export const isBukkenOneHouse = (bukken: ChinshakuBukken, tatemono: Tatemono): boolean =>
  bukken.spec_shubetsu_code === 2 && !(tatemono.tatemono_shubetsu_code === 7 || tatemono.tatemono_shubetsu_code === 12);

export const calcAreaSizeForDisplay = (
  kukaku?: Kukaku | null,
  tatemono?: Tatemono | null,
  tochi?: Tochi | null
): number => {
  if (
    !isNullOrUndefined(kukaku) &&
    (!isNullOrUndefined(kukaku.senyu_menseki_hekishin) || !isNullOrUndefined(kukaku.senyu_menseki_tokibo))
  ) {
    return Math.max(kukaku.senyu_menseki_hekishin ?? 0, kukaku.senyu_menseki_tokibo ?? 0);
  }
  if (
    !isNullOrUndefined(tatemono) &&
    (!isNullOrUndefined(tatemono.nobe_yuka_menseki_hekishin) || !isNullOrUndefined(tatemono.nobe_yuka_menseki_tokibo))
  ) {
    return Math.max(tatemono.nobe_yuka_menseki_hekishin ?? 0, tatemono.nobe_yuka_menseki_tokibo ?? 0);
  }
  if (!isNullOrUndefined(tochi)) {
    return Math.max(tochi.tochi_menseki_jissoku ?? 0, tochi.tochi_menseki_kobo ?? 0);
  }

  return 0;
};
