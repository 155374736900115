import { isNotNullOrEmptyString } from '@app/utils';
import { Box, Typography } from '@mui/material';
import { FC, Fragment } from 'react';
import Linkify from 'react-linkify';

import { SectionHeader } from '@/Pages/bukken/chintai/DetailPartial/SectionHeaders';
import { LinkifyUrlSafeMatchDecorator } from '@/Utils/DisplayText/UrlMatch';

type ForChukaiCompanyMessageProps = {
  message: string | null | undefined;
  isCustomerView: boolean;
};

export const ForChukaiCompanyMessage: FC<ForChukaiCompanyMessageProps> = props => {
  return isNotNullOrEmptyString(props.message) ? (
    <Fragment>
      {!props.isCustomerView && (
        <Fragment>
          <SectionHeader>メッセージ</SectionHeader>
          <Box px={2}>
            <Typography variant="body1">
              <Linkify matchDecorator={LinkifyUrlSafeMatchDecorator}>{props.message}</Linkify>
            </Typography>
          </Box>
        </Fragment>
      )}
    </Fragment>
  ) : null;
};
