import { useIsSmallDevice } from '@lib/components';
import { Box } from '@mui/material';
import { FC, memo, useMemo } from 'react';

import { OwnBukkenSearchResultList } from './OwnBukkenSearchResultList';

import { useCurrentUser } from '@/Hooks/Esa/RequireCurrentUser';
import { useOwnBukkenAsyncList } from '@/Hooks/Search/OwnBukkenHooks';
import { ChintaiBukkenAsyncList } from '@/Models/ChintaiBukkenSearcher';
import { ChintaiResultOrder } from '@/Models/SearchConditions/ChintaiSearchConditionEnums/ChintaiSearchConditionEnums';
import { ChintaiSearchConditions } from '@/Models/SearchConditions/ChintaiSearchConditions';
import BukkenSearchConditionCard from '@/Pages/bukken/chintai/SearchPartial/BukkenSearchConditionCard';

const OwnBukkenListPageContainer: FC = memo(() => {
  const isSmallDevice = useIsSmallDevice();
  const currentUser = useCurrentUser();
  const domainGuid = useMemo(() => {
    if (!currentUser.ebone) {
      return '';
    }

    return currentUser.ebone.domainGuid;
  }, [currentUser.ebone]);
  const { asyncList, conditions, currentPage, setPage, setItemsPerPage, setOrder, setCondition } =
    useOwnBukkenAsyncList(domainGuid);

  return (
    <OwnBukkenListPagePresenter
      isSmallDevice={isSmallDevice}
      asyncList={asyncList}
      conditions={conditions}
      currentPage={currentPage}
      setPage={setPage}
      setItemsPerPage={setItemsPerPage}
      setOrder={setOrder}
      setCondition={setCondition}
      domainGuid={domainGuid}
    />
  );
});

export { OwnBukkenListPageContainer as OwnBukkenListPage };

type OwnBukkenListPagePresenterProps = {
  isSmallDevice: boolean;
  asyncList: ChintaiBukkenAsyncList | null;
  conditions: ChintaiSearchConditions;
  currentPage: number;
  setPage: (page: number) => void;
  setItemsPerPage: (itemsPerPage: number) => void;
  setOrder: (order: ChintaiResultOrder) => void;
  setCondition: (newConditions: Readonly<ChintaiSearchConditions>) => void;
  domainGuid: string | undefined;
};

export const OwnBukkenListPagePresenter: FC<OwnBukkenListPagePresenterProps> = memo(
  ({
    isSmallDevice,
    asyncList,
    conditions,
    currentPage,
    setPage,
    setItemsPerPage,
    setOrder,
    setCondition,
    domainGuid,
  }) => {
    return (
      <Box display="flex" flexDirection="column" width="100%" minHeight="100%" px={isSmallDevice ? 0 : 2}>
        {domainGuid && (
          <BukkenSearchConditionCard
            onSearch={condition => {
              setCondition(condition);
            }}
            conditions={conditions}
            isOwnBukken={true}
          />
        )}
        <OwnBukkenSearchResultList
          asyncList={asyncList}
          currentPage={currentPage}
          currentOrder={conditions.order}
          onChangePage={setPage}
          onChangeOrder={setOrder}
          setItemsPerPage={setItemsPerPage}
        />
      </Box>
    );
  }
);
