/* 検索条件追加時のバッジを生成する処理を実装する */
import { isNullOrEmptyString } from '@app/utils';
import { ConditionBadge } from '@lib/components';

import { DetailSearchConditionHooks } from './DetailConditionForms';

import {
  BukkenDateRangeRadioLabelMap,
  BukkenDateRangeRadioState,
  GenkyoConditionsLabelMap,
  BukkenNyukyoKanoDateRangeRadioLabelMap,
  BukkenNyukyoKanoDateRangeRadioState,
  KozoConditionsLabelMap,
  MadoriConditionsLabelMap,
  NaikenConditions,
  NaikenConditionsLabelMap,
  SearchBoshuShubetsuConditionsLabelMap,
  B2bKokaiCodeLabelMap,
  B2bKokaiCodeRadioState,
} from '@/Models/SearchConditions/ChintaiSearchConditionEnums/ChintaiSearchConditionEnums';
import { KodawariConditionsTextMap } from '@/Models/SearchConditions/ChintaiSearchConditionEnums/ChintaiSearchKodawariConditionEnums';
import { formatDate } from '@/Utils/DisplayText/Date';

type BadgeBuilderDefinition<C> = {
  builder: (condition: C, requestSearch: () => void, options?: BadgeBuilderOptions) => JSX.Element[];
};

export type BadgeBuilderOptions = {
  ignoreEkitoho: boolean;
};

const conditionsSeparator = '，';

const dateRangeBadgeBuilder = (
  indexLabel: string,
  radioState: BukkenDateRangeRadioState,
  from: Date | undefined,
  to: Date | undefined,
  onDelete: () => void,
  requestSearch: () => void
): JSX.Element[] => {
  if (
    radioState === BukkenDateRangeRadioState.unselected ||
    (radioState === BukkenDateRangeRadioState.select && from === undefined && to === undefined)
  ) {
    return [];
  }

  const formattedFrom = from ? formatDate(from) : '';
  const formattedTo = to ? formatDate(to) : '';
  const labelText =
    radioState === BukkenDateRangeRadioState.select
      ? `${formattedFrom}~${formattedTo}`
      : BukkenDateRangeRadioLabelMap[radioState];

  return [
    <ConditionBadge
      key={`${indexLabel}${radioState}`}
      label={`${indexLabel}：${labelText}`}
      onDelete={[onDelete, requestSearch]}
    />,
  ];
};

const nyukyoKanoDateRangeBadgeBuilder = (
  indexLabel: string,
  radioState: BukkenNyukyoKanoDateRangeRadioState,
  hasPlan: boolean | undefined,
  from: Date | undefined,
  to: Date | undefined,
  onDelete: () => void,
  requestSearch: () => void
): JSX.Element[] => {
  if (
    radioState === BukkenNyukyoKanoDateRangeRadioState.unselected ||
    (radioState === BukkenNyukyoKanoDateRangeRadioState.select && from === undefined && to === undefined)
  ) {
    return [];
  }

  const formattedFrom = from ? formatDate(from) : '';
  const formattedTo = to ? formatDate(to) : '';
  const labelText =
    radioState === BukkenNyukyoKanoDateRangeRadioState.select
      ? `${formattedFrom}~${formattedTo}`
      : BukkenNyukyoKanoDateRangeRadioLabelMap[radioState];

  return [
    <ConditionBadge
      key={`${indexLabel}${radioState}`}
      label={
        hasPlan
          ? `${indexLabel}：${labelText} (${
              BukkenNyukyoKanoDateRangeRadioLabelMap[BukkenNyukyoKanoDateRangeRadioState.plan]
            })`
          : `${indexLabel}：${labelText}`
      }
      onDelete={[onDelete, requestSearch]}
    />,
  ];
};

const rangeBadgeBuilder = (
  indexLabel: string,
  unit = '',
  from: string,
  to: string,
  onDelete: (() => void) | (() => void)[],
  requestSearch: () => void
): JSX.Element[] => {
  if (from === '' && to === '') {
    return [];
  }
  const formattedFrom = from !== '' ? from + unit : '';
  const formattedTo = to !== '' ? to + unit : '';
  return [
    <ConditionBadge
      key={indexLabel}
      label={`${indexLabel}：${formattedFrom}~${formattedTo}`}
      onDelete={Array.isArray(onDelete) ? [...onDelete, requestSearch] : [onDelete, requestSearch]}
    />,
  ];
};

export const badgeKeys = {
  kokaiDate: 'kokaiDate',
  koshinDate: 'koshinDate',
  nyukyoKanoDate: 'nyukyoKanoDate',
  bukkenShubetsu: 'bukkenShubetsu',
  kozo: 'kozo',
  chinryo: 'chinryo',
  kanriKyoekihi: 'kanriKyoekihi',
  freeRent: 'freeRent',
  shikikin: 'shikikin',
  reikin: 'reikin',
  madori: 'madori',
  genkyo: 'genkyo',
  menseki: 'menseki',
  ekitoho: 'ekitoho',
  chikuNensu: 'chikuNensu',
  ad: 'ad',
  bukkenName: 'bukkenName',
  bukkenNameKana: 'bukkenNameKana',
  motodukeName: 'motodukeName',
  naiken: 'naiken',
  sokunyukyo: 'sokunyukyo',
  excludeMoshikomiExist: 'excludeMoshikomiExist',
  kodawari: 'kodawari',
  heyaNumber: 'heyaNumber',
  totalGazo: 'totalGazo',
  gaikanGazo: 'gaikanGazo',
  madoriGazo: 'madoriGazo',
  naikanGazo: 'naikanGazo',
  bukkenKokaiScope: 'bukkenKokaiScope',
} as const;
export type BadgeKeys = typeof badgeKeys[keyof typeof badgeKeys];
export const badgeKeyLabelMap: Record<BadgeKeys, string> = {
  kokaiDate: '情報公開日',
  koshinDate: '最終更新日',
  nyukyoKanoDate: '入居可能日',
  bukkenShubetsu: '物件種別',
  kozo: '建物構造',
  chinryo: '賃料',
  kanriKyoekihi: '管理費・共益費',
  freeRent: 'フリーレント有り',
  shikikin: '敷金',
  reikin: '礼金',
  madori: '間取り',
  genkyo: '現況',
  menseki: '面積',
  ekitoho: '駅徒歩',
  chikuNensu: '築年数',
  ad: 'AD',
  bukkenName: '物件名',
  bukkenNameKana: '物件名（カナ）',
  motodukeName: '元付会社名',
  naiken: '内見',
  sokunyukyo: '即入居可能',
  excludeMoshikomiExist: '申込ありを除外',
  kodawari: 'こだわり条件',
  heyaNumber: '部屋番号',
  totalGazo: '画像',
  gaikanGazo: '外観あり',
  madoriGazo: '間取り・地図あり',
  naikanGazo: '内観あり',
  bukkenKokaiScope: '公開範囲',
};

const BadgeBuilder: Record<BadgeKeys, BadgeBuilderDefinition<DetailSearchConditionHooks>> = {
  kokaiDate: {
    builder: (c: DetailSearchConditionHooks, requestSearch: () => void): JSX.Element[] => {
      if (c.kokaiCondition.queryValue === undefined) {
        return [];
      }
      return dateRangeBadgeBuilder(
        badgeKeyLabelMap.kokaiDate,
        c.kokaiCondition.queryValue.kokaiRadioState,
        c.kokaiCondition.queryValue.kokaiDateFrom,
        c.kokaiCondition.queryValue.kokaiDateTo,
        c.kokaiCondition.clear,
        requestSearch
      );
    },
  },
  koshinDate: {
    builder: (c: DetailSearchConditionHooks, requestSearch: () => void): JSX.Element[] => {
      if (c.koshinDateCondition.queryValue === undefined) {
        return [];
      }
      return dateRangeBadgeBuilder(
        badgeKeyLabelMap.koshinDate,
        c.koshinDateCondition.queryValue.koshinRadioState,
        c.koshinDateCondition.queryValue.koshinDateFrom,
        c.koshinDateCondition.queryValue.koshinDateTo,
        c.koshinDateCondition.clear,
        requestSearch
      );
    },
  },
  nyukyoKanoDate: {
    builder: (c: DetailSearchConditionHooks, requestSearch: () => void): JSX.Element[] => {
      if (c.nyukyoKanoDateCondition.queryValue === undefined) {
        return [];
      }
      return nyukyoKanoDateRangeBadgeBuilder(
        badgeKeyLabelMap.nyukyoKanoDate,
        c.nyukyoKanoDateCondition.queryValue.nyukyoKanoRadioState.state,
        c.nyukyoKanoDateCondition.queryValue.nyukyoKanoRadioState.hasPlan,
        c.nyukyoKanoDateCondition.queryValue.nyukyoKanoDateFrom,
        c.nyukyoKanoDateCondition.queryValue.nyukyoKanoDateTo,
        c.nyukyoKanoDateCondition.clear,
        requestSearch
      );
    },
  },
  bukkenShubetsu: {
    builder: (c: DetailSearchConditionHooks, requestSearch: () => void): JSX.Element[] => {
      if (c.boshuShubetsuCondition.queryValue === undefined) {
        return [];
      }
      const label = c.boshuShubetsuCondition.queryValue
        .map(it => SearchBoshuShubetsuConditionsLabelMap[it])
        .join(conditionsSeparator);
      if (label.length === 0) {
        return [];
      }
      return [
        <ConditionBadge
          key={badgeKeyLabelMap.bukkenShubetsu}
          label={`${badgeKeyLabelMap.bukkenShubetsu}：${label}`}
          onDelete={[c.boshuShubetsuCondition.clear, requestSearch]}
        />,
      ];
    },
  },
  kozo: {
    builder: (c: DetailSearchConditionHooks, requestSearch: () => void): JSX.Element[] => {
      if (c.kozoCondition.queryValue === undefined) {
        return [];
      }
      const label = c.kozoCondition.queryValue.map(it => KozoConditionsLabelMap[it]).join(conditionsSeparator);
      if (label.length === 0) {
        return [];
      }
      return [
        <ConditionBadge
          key={badgeKeyLabelMap.kozo}
          label={`${badgeKeyLabelMap.kozo}：${label}`}
          onDelete={[c.kozoCondition.clear, requestSearch]}
        />,
      ];
    },
  },
  chinryo: {
    builder: (c: DetailSearchConditionHooks, requestSearch: () => void): JSX.Element[] => {
      if (c.chinryoCondition.queryValue === undefined) {
        return [];
      }
      return rangeBadgeBuilder(
        badgeKeyLabelMap.chinryo,
        '円',
        c.chinryoCondition.queryValue.chinryoFrom,
        c.chinryoCondition.queryValue.chinryoTo,
        c.chinryoCondition.clearChinryo,
        requestSearch
      );
    },
  },
  kanriKyoekihi: {
    builder: (c: DetailSearchConditionHooks, requestSearch: () => void): JSX.Element[] => {
      if (c.chinryoCondition.queryValue === undefined) {
        return [];
      }

      return c.chinryoCondition.queryValue.isIncludeManagementFee
        ? [
            <ConditionBadge
              key={badgeKeyLabelMap.kanriKyoekihi}
              label={badgeKeyLabelMap.kanriKyoekihi}
              onDelete={[c.chinryoCondition.clearManagementFee, requestSearch]}
            />,
          ]
        : [];
    },
  },
  freeRent: {
    builder: (c: DetailSearchConditionHooks, requestSearch: () => void): JSX.Element[] => {
      if (c.chinryoCondition.queryValue === undefined) {
        return [];
      }

      return c.chinryoCondition.queryValue.isFreeRentAvailable
        ? [
            <ConditionBadge
              key={badgeKeyLabelMap.freeRent}
              label={badgeKeyLabelMap.freeRent}
              onDelete={[c.chinryoCondition.clearIsFreeRentAvailable, requestSearch]}
            />,
          ]
        : [];
    },
  },
  shikikin: {
    builder: (c: DetailSearchConditionHooks, requestSearch: () => void): JSX.Element[] => {
      if (c.shikikinReikinCondition.queryValue === undefined) {
        return [];
      }
      if (c.shikikinReikinCondition.queryValue.isExcludeShikikin) {
        return [
          <ConditionBadge
            key={badgeKeyLabelMap.shikikin}
            label="敷金なし"
            onDelete={[c.shikikinReikinCondition.clearShikikin, requestSearch]}
          />,
        ];
      }
      if (c.shikikinReikinCondition.queryValue.shikikinTo !== undefined) {
        return [
          <ConditionBadge
            key={badgeKeyLabelMap.shikikin}
            label={`${badgeKeyLabelMap.shikikin}：${c.shikikinReikinCondition.queryValue.shikikinTo}ヶ月以内`}
            onDelete={[c.shikikinReikinCondition.clearShikikin, requestSearch]}
          />,
        ];
      }
      return [];
    },
  },
  reikin: {
    builder: (c: DetailSearchConditionHooks, requestSearch: () => void): JSX.Element[] => {
      if (c.shikikinReikinCondition.queryValue === undefined) {
        return [];
      }
      if (c.shikikinReikinCondition.queryValue.isExcludeReikin) {
        return [
          <ConditionBadge
            key={badgeKeyLabelMap.reikin}
            label="礼金なし"
            onDelete={[c.shikikinReikinCondition.clearReikin, requestSearch]}
          />,
        ];
      }
      if (c.shikikinReikinCondition.queryValue.reikinTo !== undefined) {
        return [
          <ConditionBadge
            key={badgeKeyLabelMap.reikin}
            label={`${badgeKeyLabelMap.reikin}：${c.shikikinReikinCondition.queryValue.reikinTo}ヶ月以内`}
            onDelete={[c.shikikinReikinCondition.clearReikin, requestSearch]}
          />,
        ];
      }
      return [];
    },
  },
  madori: {
    builder: (c: DetailSearchConditionHooks, requestSearch: () => void): JSX.Element[] => {
      if (c.madoriCondition.queryValue === undefined) {
        return [];
      }
      const label = c.madoriCondition.queryValue.map(it => MadoriConditionsLabelMap[it]).join(conditionsSeparator);
      if (label.length === 0) {
        return [];
      }
      return [
        <ConditionBadge
          key={badgeKeyLabelMap.madori}
          label={`${badgeKeyLabelMap.madori}：${label}`}
          onDelete={[c.madoriCondition.clear, requestSearch]}
        />,
      ];
    },
  },
  genkyo: {
    builder: (c: DetailSearchConditionHooks, requestSearch: () => void): JSX.Element[] => {
      if (c.genkyoCondition.queryValue === undefined) {
        return [];
      }
      const label = c.genkyoCondition.queryValue.map(it => GenkyoConditionsLabelMap[it]).join(conditionsSeparator);
      if (label.length === 0) {
        return [];
      }
      return [
        <ConditionBadge
          key={badgeKeyLabelMap.genkyo}
          label={`${badgeKeyLabelMap.genkyo}：${label}`}
          onDelete={[c.genkyoCondition.clear, requestSearch]}
        />,
      ];
    },
  },
  menseki: {
    builder: (c: DetailSearchConditionHooks, requestSearch: () => void): JSX.Element[] => {
      if (c.mensekiFromCondition.queryValue === undefined || c.mensekiToCondition.queryValue === undefined) {
        return [];
      }
      return rangeBadgeBuilder(
        badgeKeyLabelMap.menseki,
        '㎡',
        c.mensekiFromCondition.queryValue,
        c.mensekiToCondition.queryValue,
        [c.mensekiFromCondition.clear, c.mensekiToCondition.clear],
        requestSearch
      );
    },
  },
  ekitoho: {
    builder: (
      c: DetailSearchConditionHooks,
      requestSearch: () => void,
      options?: BadgeBuilderOptions
    ): JSX.Element[] => {
      if (isNullOrEmptyString(c.kotsuEkitohoCondition.queryValue) || options?.ignoreEkitoho === true) {
        return [];
      }
      return [
        <ConditionBadge
          key={badgeKeyLabelMap.ekitoho}
          label={`${badgeKeyLabelMap.ekitoho}：${c.kotsuEkitohoCondition.queryValue}分以内`}
          onDelete={[c.kotsuEkitohoCondition.clear, requestSearch]}
        />,
      ];
    },
  },
  chikuNensu: {
    builder: (c: DetailSearchConditionHooks, requestSearch: () => void): JSX.Element[] => {
      if (c.isShinchikuCondition.queryValue === undefined) {
        return [];
      }
      if (c.isShinchikuCondition.queryValue) {
        return [
          <ConditionBadge
            key={badgeKeyLabelMap.chikuNensu}
            label="新築"
            onDelete={[c.isShinchikuCondition.clear, requestSearch]}
          />,
        ];
      }
      if (isNullOrEmptyString(c.chikuNensuCondition.queryValue)) {
        return [];
      }
      return [
        <ConditionBadge
          key={badgeKeyLabelMap.chikuNensu}
          label={`${badgeKeyLabelMap.chikuNensu}：${c.chikuNensuCondition.queryValue}年以内`}
          onDelete={[c.chikuNensuCondition.clear, requestSearch]}
        />,
      ];
    },
  },
  ad: {
    builder: (c: DetailSearchConditionHooks, requestSearch: () => void): JSX.Element[] => {
      if (
        c.kokokuyroKagetsuFromCondition.queryValue === undefined ||
        c.kokokuyroKagetsuFromCondition.queryValue === ''
      ) {
        return [];
      }
      if (c.kokokuyroKagetsuFromCondition.queryValue === '0.01') {
        return [
          <ConditionBadge
            key={badgeKeyLabelMap.ad}
            label="ADあり"
            onDelete={[c.kokokuyroKagetsuFromCondition.clear, requestSearch]}
          />,
        ];
      }
      const formattedFrom = String(Number(c.kokokuyroKagetsuFromCondition.queryValue) * 100);
      return [
        <ConditionBadge
          key={badgeKeyLabelMap.ad}
          label={`${badgeKeyLabelMap.ad}：${formattedFrom}%以上`}
          onDelete={[c.kokokuyroKagetsuFromCondition.clear, requestSearch]}
        />,
      ];
    },
  },
  bukkenName: {
    builder: (c: DetailSearchConditionHooks, requestSearch: () => void): JSX.Element[] => {
      if (isNullOrEmptyString(c.bukkenNameCondition.queryValue)) {
        return [];
      }
      return [
        <ConditionBadge
          key={badgeKeyLabelMap.bukkenName}
          label={`${badgeKeyLabelMap.bukkenName}：${c.bukkenNameCondition.queryValue}`}
          onDelete={[c.bukkenNameCondition.clear, requestSearch]}
        />,
      ];
    },
  },
  bukkenNameKana: {
    builder: (c: DetailSearchConditionHooks, requestSearch: () => void): JSX.Element[] => {
      if (isNullOrEmptyString(c.bukkenNameKanaCondition.queryValue)) {
        return [];
      }
      return [
        <ConditionBadge
          key={badgeKeyLabelMap.bukkenNameKana}
          label={`${badgeKeyLabelMap.bukkenNameKana}：${c.bukkenNameKanaCondition.queryValue}`}
          onDelete={[c.bukkenNameKanaCondition.clear, requestSearch]}
        />,
      ];
    },
  },
  motodukeName: {
    builder: (c: DetailSearchConditionHooks, requestSearch: () => void): JSX.Element[] => {
      if (isNullOrEmptyString(c.kanriGyoshaCondition.queryValue)) {
        return [];
      }
      return [
        <ConditionBadge
          key={badgeKeyLabelMap.motodukeName}
          label={`${badgeKeyLabelMap.motodukeName}：${c.kanriGyoshaCondition.queryValue}`}
          onDelete={[c.kanriGyoshaCondition.clear, requestSearch]}
        />,
      ];
    },
  },
  naiken: {
    builder: (c: DetailSearchConditionHooks, requestSearch: () => void): JSX.Element[] => {
      if (c.naikenCondition.queryValue === undefined || c.naikenCondition.queryValue === NaikenConditions.unspecified) {
        return [];
      }
      return [
        <ConditionBadge
          key={badgeKeyLabelMap.naiken}
          label={`${badgeKeyLabelMap.naiken}：${NaikenConditionsLabelMap[c.naikenCondition.queryValue]}`}
          onDelete={[c.naikenCondition.clear, requestSearch]}
        />,
      ];
    },
  },
  sokunyukyo: {
    builder: (c: DetailSearchConditionHooks, requestSearch: () => void): JSX.Element[] => {
      return c.isSokunyukyoEnableCondition.value === true
        ? [
            <ConditionBadge
              key={badgeKeyLabelMap.sokunyukyo}
              label={badgeKeyLabelMap.sokunyukyo}
              onDelete={[c.isSokunyukyoEnableCondition.clear, requestSearch]}
            />,
          ]
        : [];
    },
  },
  excludeMoshikomiExist: {
    builder: (c: DetailSearchConditionHooks, requestSearch: () => void): JSX.Element[] => {
      return c.isExcludeMoshikomiExistCondition.value === true
        ? [
            <ConditionBadge
              key={badgeKeyLabelMap.excludeMoshikomiExist}
              label={badgeKeyLabelMap.excludeMoshikomiExist}
              onDelete={[c.isExcludeMoshikomiExistCondition.clear, requestSearch]}
            />,
          ]
        : [];
    },
  },
  kodawari: {
    builder: (c: DetailSearchConditionHooks, requestSearch: () => void): JSX.Element[] => {
      const kodawari = c.kodawariCondition.queryValue;
      if (kodawari === undefined) {
        return [];
      }
      return kodawari.map(value => (
        <ConditionBadge
          key={value}
          label={KodawariConditionsTextMap[value]}
          onDelete={[() => c.kodawariCondition.remove(value), requestSearch]}
        />
      ));
    },
  },
  heyaNumber: {
    builder: (c: DetailSearchConditionHooks, requestSearch: () => void): JSX.Element[] => {
      if (isNullOrEmptyString(c.heyaNumberCondition.queryValue)) {
        return [];
      }
      return [
        <ConditionBadge
          key={badgeKeyLabelMap.heyaNumber}
          label={`${badgeKeyLabelMap.heyaNumber}：${c.heyaNumberCondition.queryValue}`}
          onDelete={[c.heyaNumberCondition.clear, requestSearch]}
        />,
      ];
    },
  },
  totalGazo: {
    builder: (c: DetailSearchConditionHooks, requestSearch: () => void): JSX.Element[] => {
      if (c.gazoSearchCondition.queryValue?.totalGazoCountFrom === undefined) {
        return [];
      }
      return [
        <ConditionBadge
          key={badgeKeyLabelMap.totalGazo}
          label={`${badgeKeyLabelMap.totalGazo}：${c.gazoSearchCondition.queryValue.totalGazoCountFrom}枚以上`}
          onDelete={[c.gazoSearchCondition.clearTotalGazoCount, requestSearch]}
        />,
      ];
    },
  },
  gaikanGazo: {
    builder: (c: DetailSearchConditionHooks, requestSearch: () => void): JSX.Element[] => {
      if (c.gazoSearchCondition.queryValue?.gaikanGazoCountFrom === undefined) {
        return [];
      }
      return [
        <ConditionBadge
          key={badgeKeyLabelMap.gaikanGazo}
          label={`${badgeKeyLabelMap.gaikanGazo}`}
          onDelete={[c.gazoSearchCondition.clearGaikanGazoCount, requestSearch]}
        />,
      ];
    },
  },

  madoriGazo: {
    builder: (c: DetailSearchConditionHooks, requestSearch: () => void): JSX.Element[] => {
      if (c.gazoSearchCondition.queryValue?.madoriGazoCountFrom === undefined) {
        return [];
      }
      return [
        <ConditionBadge
          key={badgeKeyLabelMap.madoriGazo}
          label={`${badgeKeyLabelMap.madoriGazo}`}
          onDelete={[c.gazoSearchCondition.clearMadoriGazoCount, requestSearch]}
        />,
      ];
    },
  },
  naikanGazo: {
    builder: (c: DetailSearchConditionHooks, requestSearch: () => void): JSX.Element[] => {
      if (c.gazoSearchCondition.queryValue?.naikanGazoCountFrom === undefined) {
        return [];
      }
      return [
        <ConditionBadge
          key={badgeKeyLabelMap.naikanGazo}
          label={`${badgeKeyLabelMap.naikanGazo}`}
          onDelete={[c.gazoSearchCondition.clearNaikanGazoCount, requestSearch]}
        />,
      ];
    },
  },
  bukkenKokaiScope: {
    builder: (c: DetailSearchConditionHooks, requestSearch: () => void): JSX.Element[] => {
      if (
        !c.bukkenKokaiScopeCondition.queryValue ||
        c.bukkenKokaiScopeCondition.queryValue.bukkenKokaiScopeRadioState === B2bKokaiCodeRadioState.unselected
      ) {
        return [];
      }

      return [
        <ConditionBadge
          key={badgeKeyLabelMap.bukkenKokaiScope}
          label={`${badgeKeyLabelMap.bukkenKokaiScope}：${
            B2bKokaiCodeLabelMap[c.bukkenKokaiScopeCondition.queryValue.bukkenKokaiScopeRadioState]
          }`}
          onDelete={[c.bukkenKokaiScopeCondition.clear, requestSearch]}
        />,
      ];
    },
  },
};

export default BadgeBuilder;
