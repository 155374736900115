import { Box } from '@mui/material';
import { FC } from 'react';

import { ChinshakuBukkenDetailProps } from './ChinshakuBukkenDetailProps';
import { ForChukaiCompanyComponent } from './ForChukaiCompany';

export const BusinessInfoTab: FC<ChinshakuBukkenDetailProps> = props => {
  return (
    <Box p={{ xs: 0, md: 2 }}>
      <ForChukaiCompanyComponent {...props} />
    </Box>
  );
};
