import { ChinshakuBukken, SpecBukkenView } from '@app/models';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { MuiButtonColorType } from '@lib/components';
import { Button, Tooltip, Chip, Box, buttonClasses } from '@mui/material';
import { Feather, Phone } from 'mdi-material-ui';
import { FC, MouseEventHandler, useMemo } from 'react';

import { ActionButtonLayout } from './ActionButtonLayout';

import { useUserActionTracker } from '@/Hooks/Analytics';
import { useDependency } from '@/Hooks/DependencyHook';
import { useMoveinApplication } from '@/Hooks/SumaiEntry/MoveinApplicationHooks';
import { searchResultSxProps } from '@/Pages/bukken/chintai/SearchPartial/SearchResultStyles';
import { viewStatusAction, ViewStatusBukken } from '@/Services/ISendingViewStatusService';

const PHONE_INQUIRY_TOOLTIP_TEXT = 'お電話でお問い合わせください';
const INVALID_TAKKEN_LICENSE_NUMBER_TOOLTIP_TEXT = '宅建免許番号が不正なため入居申込できません';
const BEFORE_MOSHIKOMI_KAISHI_TOOLTIP_TEXT = 'Web申込受付前です';
const NYUKYO_MOSHIKOMI_TOOLTIP_TEXT = '入居申込';

type ContainerProps = {
  bukkenOrBukkenView: SpecBukkenView | ChinshakuBukken;
  analyticsPlace: string;
  buttonVariant: 'text' | 'outlined' | 'contained';
  viewStatusBukken: ViewStatusBukken;
  buttonColor?: MuiButtonColorType | 'accent';
  useIconButton?: boolean;
  isApplied: boolean;
  webMoshikomiFlag: boolean;
};

const MoveinApplicationButtonContainer: FC<ContainerProps> = props => {
  const sendingViewStatusService = useDependency('sendingViewStatusService');

  const tracker = useUserActionTracker(props.analyticsPlace);
  const { canRedirect, redirect, redirectError, isAfterMoshikomiKaishiDate } = useMoveinApplication(
    props.bukkenOrBukkenView,
    tracker
  );
  const sendingViewStatus: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();
    sendingViewStatusService.send(props.viewStatusBukken, viewStatusAction.moshikomi);
    redirect();
  };

  // 申込方法がWeb and 申込開始日時が過去日（当日含む）であるか未入力の場合は申込可能
  const canWebMoshikomi =
    props.webMoshikomiFlag && (isAfterMoshikomiKaishiDate === true || isAfterMoshikomiKaishiDate === undefined);

  const buttonItem = useMemo(() => {
    if (redirectError === 'invalidTakkenLicenseNumber') {
      return {
        buttonText: <Box my={0.6}>申込</Box>,
        tooltipText: INVALID_TAKKEN_LICENSE_NUMBER_TOOLTIP_TEXT,
        icon: <Phone fontSize="small" />,
      };
    }
    if (redirectError === 'invalidBukkenCondition') {
      return {
        buttonText: <Box my={0.6}>申込</Box>,
        tooltipText: PHONE_INQUIRY_TOOLTIP_TEXT,
        icon: <Phone fontSize="small" />,
      };
    }
    if (!canWebMoshikomi) {
      return {
        buttonText: (
          <Box
            display="inline-flex"
            flexDirection="column"
            lineHeight="1.2em"
            fontSize="0.7rem"
            borderRadius="4px"
            height="1.7rem"
            my={0.2}
            pr={props.useIconButton ? 1 : 0.5}
            pl={props.useIconButton ? 1 : 0}
          >
            申込
            <br />
            受付前
          </Box>
        ),
        tooltipText: BEFORE_MOSHIKOMI_KAISHI_TOOLTIP_TEXT,
        icon: <Feather css={{ marginLeft: '4px' }} />,
      };
    }
    return {
      buttonText: <Box my={0.6}>申込</Box>,
      tooltipText: NYUKYO_MOSHIKOMI_TOOLTIP_TEXT,
      icon: <Feather />,
    };
  }, [canWebMoshikomi, props.useIconButton, redirectError]);

  return (
    <MoveinApplicationButtonPresenter
      canRedirect={canRedirect}
      sendingViewStatus={sendingViewStatus}
      canWebMoshikomi={canWebMoshikomi}
      buttonItem={buttonItem}
      {...props}
    />
  );
};

export { MoveinApplicationButtonContainer as MoveinApplicationButton };

export type MoveinApplicationButtonPresenterProps = {
  canRedirect: boolean;
  sendingViewStatus: MouseEventHandler<HTMLButtonElement>;
  buttonVariant: 'text' | 'outlined' | 'contained';
  buttonColor?: MuiButtonColorType | 'accent';
  useIconButton?: boolean;
  isApplied: boolean;
  canWebMoshikomi: boolean;
  buttonItem: {
    buttonText: EmotionJSX.Element;
    tooltipText: string;
    icon: EmotionJSX.Element;
  };
};

export const MoveinApplicationButtonPresenter: FC<MoveinApplicationButtonPresenterProps> = ({
  canRedirect,
  sendingViewStatus,
  buttonVariant,
  buttonColor,
  useIconButton,
  isApplied,
  buttonItem,
  canWebMoshikomi,
}) => {
  const disabled = !canRedirect || !canWebMoshikomi;
  return (
    <ActionButtonLayout>
      <Box position="relative">
        <Tooltip title={buttonItem.tooltipText} disableInteractive={!disabled}>
          <span>
            <Button
              variant={buttonVariant}
              color={buttonColor !== 'accent' ? buttonColor : 'primary'}
              startIcon={!useIconButton ? buttonItem.icon : ''}
              onClick={!disabled ? sendingViewStatus : undefined}
              sx={{
                [`&.${buttonClasses.root}`]: {
                  ...(buttonColor === 'accent' ? { backgroundColor: theme => theme.palette.accent.main } : undefined),
                  ...(useIconButton && searchResultSxProps.actionSquareButton),
                  padding: 0,
                },
                [`&.${buttonClasses.outlined}`]: searchResultSxProps.actionButtonOutlined,
                [`& .${buttonClasses.startIcon}`]: !useIconButton
                  ? searchResultSxProps.actionButtonStartIcon
                  : searchResultSxProps.actionSquareButtonStartIcon,
              }}
              disabled={disabled}
            >
              {buttonItem.buttonText}
            </Button>
          </span>
        </Tooltip>
        {isApplied ? (
          <Chip
            label="申込あり"
            color="primary"
            size="small"
            sx={{
              background: theme => theme.palette.attention.main,
              position: 'absolute',
              top: '-8px',
              right: '-8px',
              padding: '1px 0 0 0',
              '& span': {
                paddingLeft: '4px',
                paddingRight: '4px',
              },
              height: '16px',
              fontSize: '0.625rem',
              fontWeight: 'bold',
              zIndex: 1,
            }}
          />
        ) : null}
      </Box>
    </ActionButtonLayout>
  );
};
