import { Box, styled } from '@mui/material';

export const IconButtonWidth = '48px';
export const DetailContainerZIndex = 1000;
const TopHeaderHeight = '48px';

export const DetailTopHeaderBox = styled(Box)(({ theme }) => ({
  minHeight: TopHeaderHeight,
  height: '100%',
  backgroundColor: theme.palette.background.paper,
  position: 'sticky',
  top: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  zIndex: DetailContainerZIndex + 1,
  [theme.breakpoints.up('md')]: {
    width: `calc(100% + ${IconButtonWidth})`,
    marginLeft: `-${IconButtonWidth}`,
    borderBottomLeftRadius: '4px',
  },
}));

export const bukkenDetailSxProps = {
  headerLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  linkText: {
    color: 'accent.main',
  },
} as const;
