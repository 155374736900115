import { deepCompare } from '@app/utils';
import { RadioButton } from '@e-seikatsu/design-system';
import { Box, FormGroup, Grid, Stack } from '@mui/material';
import { memo, ChangeEvent, FC } from 'react';

import { searchConditionSxProps } from './SearchConditionStyle';

import { LocalizedDateRangePicker } from '@/Components/Forms/LocalizedDatePicker';
import { reportError } from '@/ErrorLogger';
import { SearchConditionHook } from '@/Hooks/Search/SearchConditionHook';
import { borderSxProps } from '@/Hooks/Styles/BorderStyle';
import {
  asBukkenDateRangeRadioState,
  BukkenDateRangeRadioLabelMap,
  BukkenDateRangeRadioState,
} from '@/Models/SearchConditions/ChintaiSearchConditionEnums/ChintaiSearchConditionEnums';
import { ChintaiSearchConditions, KoshinDateConditions } from '@/Models/SearchConditions/ChintaiSearchConditions';

const reportPlace = '@/Pages/bukken/chintai/SearchPartial/BukkenSearchForms/BukkenKoshinDateForm';
const uninitializedErrorMessage = 'KokaiDateConditions is uninitialized';

type BukkenKoshinDateFormContainerProps = {
  hook: SearchConditionHook<KoshinDateConditions, ChintaiSearchConditions>;
  isSmallDevice: boolean;
};

const BukkenKoshinDateFormContainer: FC<BukkenKoshinDateFormContainerProps> = memo(({ hook, isSmallDevice }) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newValue = asBukkenDateRangeRadioState(event.target.value) ?? BukkenDateRangeRadioState.unselected;
    if (hook.value === undefined) {
      reportError(uninitializedErrorMessage, reportPlace, true);
      return;
    }
    hook.setValue({ ...hook.value, koshinRadioState: newValue });
  };
  const setFromValue = (koshinDateFrom: Date | undefined): void => {
    if (hook.value === undefined) {
      reportError(uninitializedErrorMessage, reportPlace, true);
      return;
    }
    hook.setValue({ ...hook.value, koshinDateFrom });
  };
  const setToValue = (koshinDateTo: Date | undefined): void => {
    if (hook.value === undefined) {
      reportError(uninitializedErrorMessage, reportPlace, true);
      return;
    }
    hook.setValue({ ...hook.value, koshinDateTo });
  };

  return (
    <BukkenKoshinDateFormPresenter
      isSmallDevice={isSmallDevice}
      handleChange={handleChange}
      koshinRadioState={hook.value?.koshinRadioState}
      koshinDateFrom={hook.value?.koshinDateFrom}
      koshinDateTo={hook.value?.koshinDateTo}
      setFromValue={setFromValue}
      setToValue={setToValue}
    />
  );
}, deepCompare);

export type BukkenKoshinDateFormPresenterProps = {
  isSmallDevice: boolean;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  koshinRadioState: BukkenDateRangeRadioState | undefined;
  koshinDateFrom: Date | undefined;
  koshinDateTo: Date | undefined;
  setFromValue: (koshinDateFrom: Date | undefined) => void;
  setToValue: (koshinDateTo: Date | undefined) => void;
};

export const BukkenKoshinDateFormPresenter: FC<BukkenKoshinDateFormPresenterProps> = ({
  isSmallDevice,
  handleChange,
  koshinRadioState,
  koshinDateFrom,
  koshinDateTo,
  setFromValue,
  setToValue,
}) => {
  return (
    <Grid
      data-testclass="bukkenKoshinDateForm"
      container
      sx={{ ...searchConditionSxProps.root, ...borderSxProps.borderBottom }}
    >
      <Grid
        item
        xs={12}
        sm={2}
        sx={{ ...searchConditionSxProps.firstCol, ...(isSmallDevice ? borderSxProps.borderBottom : undefined) }}
      >
        最終更新日
      </Grid>
      <Grid item xs={12} sm={10} sx={{ ...searchConditionSxProps.secondCol, ...searchConditionSxProps.inputCol }}>
        <Stack width="100%">
          <Box display="flex" flexWrap="wrap" gap={1}>
            <RadioButton
              variant="outlined"
              size="small"
              label={BukkenDateRangeRadioLabelMap[BukkenDateRangeRadioState.unselected]}
              value={BukkenDateRangeRadioState.unselected}
              onChange={handleChange}
              checked={koshinRadioState === BukkenDateRangeRadioState.unselected}
            />
            <RadioButton
              variant="outlined"
              size="small"
              label={BukkenDateRangeRadioLabelMap[BukkenDateRangeRadioState.today]}
              value={BukkenDateRangeRadioState.today}
              onChange={handleChange}
              checked={koshinRadioState === BukkenDateRangeRadioState.today}
            />
            <RadioButton
              variant="outlined"
              size="small"
              label={BukkenDateRangeRadioLabelMap[BukkenDateRangeRadioState.threeDays]}
              value={BukkenDateRangeRadioState.threeDays}
              onChange={handleChange}
              checked={koshinRadioState === BukkenDateRangeRadioState.threeDays}
            />
            <RadioButton
              variant="outlined"
              size="small"
              label={BukkenDateRangeRadioLabelMap[BukkenDateRangeRadioState.sevenDays]}
              value={BukkenDateRangeRadioState.sevenDays}
              onChange={handleChange}
              checked={koshinRadioState === BukkenDateRangeRadioState.sevenDays}
            />
            <RadioButton
              variant="outlined"
              size="small"
              label={BukkenDateRangeRadioLabelMap[BukkenDateRangeRadioState.select]}
              value={BukkenDateRangeRadioState.select}
              onChange={handleChange}
              checked={koshinRadioState === BukkenDateRangeRadioState.select}
            />
          </Box>
          {koshinRadioState === BukkenDateRangeRadioState.select && (
            <FormGroup row={true} sx={{ py: 1 }}>
              <LocalizedDateRangePicker
                disabled={koshinRadioState !== BukkenDateRangeRadioState.select}
                fromValue={koshinDateFrom}
                setFromValue={setFromValue}
                toValue={koshinDateTo}
                setToValue={setToValue}
                separatorSxProps={searchConditionSxProps.rangeSeparator}
              />
            </FormGroup>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default BukkenKoshinDateFormContainer;
