import { deepCompare } from '@app/utils';
import { RadioButton, Checkbox } from '@e-seikatsu/design-system';
import { Grid, Box, Stack } from '@mui/material';
import { memo, ChangeEvent, FC } from 'react';

import { searchConditionSxProps } from './SearchConditionStyle';

import { LocalizedDateRangePicker } from '@/Components/Forms/LocalizedDatePicker';
import { reportError } from '@/ErrorLogger';
import { SearchConditionHook } from '@/Hooks/Search/SearchConditionHook';
import { borderSxProps } from '@/Hooks/Styles/BorderStyle';
import {
  asBukkenNyukyoKanoDateRangeRadioState,
  BukkenNyukyoKanoDateRangeRadioLabelMap,
  BukkenNyukyoKanoDateRangeRadioState,
} from '@/Models/SearchConditions/ChintaiSearchConditionEnums/ChintaiSearchConditionEnums';
import { ChintaiSearchConditions, NyukyoKanoDateConditions } from '@/Models/SearchConditions/ChintaiSearchConditions';

const reportPlace = '@/Pages/bukken/chintai/SearchPartial/BukkenSearchForms/BukkenNyukyoKanoDateFormContainer';
const uninitializedErrorMessage = 'NyukyoKanoDateConditions is uninitialized';

type BukkenNyukyoKanoDateFormContainerProps = {
  hook: SearchConditionHook<NyukyoKanoDateConditions, ChintaiSearchConditions>;
  isSmallDevice: boolean;
};

const BukkenNyukyoKanoDateFormContainer: FC<BukkenNyukyoKanoDateFormContainerProps> = memo(
  ({ hook, isSmallDevice }) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
      const newValue =
        asBukkenNyukyoKanoDateRangeRadioState(event.target.value) ?? BukkenNyukyoKanoDateRangeRadioState.unselected;
      if (hook.value === undefined) {
        reportError(uninitializedErrorMessage, reportPlace, true);
        return;
      }
      hook.setValue({
        ...hook.value,
        nyukyoKanoRadioState: {
          ...hook.value.nyukyoKanoRadioState,
          state: newValue,
          hasPlan:
            newValue === BukkenNyukyoKanoDateRangeRadioState.select ? hook.value.nyukyoKanoRadioState.hasPlan : false,
        },
      });
    };
    const setFromValue = (nyukyoKanoDateFrom: Date | undefined): void => {
      if (hook.value === undefined) {
        reportError(uninitializedErrorMessage, reportPlace, true);
        return;
      }
      hook.setValue({ ...hook.value, nyukyoKanoDateFrom });
    };
    const setToValue = (nyukyoKanoDateTo: Date | undefined): void => {
      if (hook.value === undefined) {
        reportError(uninitializedErrorMessage, reportPlace, true);
        return;
      }
      hook.setValue({ ...hook.value, nyukyoKanoDateTo });
    };

    const handleCheck = (event: ChangeEvent<HTMLInputElement>): void => {
      if (hook.value === undefined) {
        reportError(uninitializedErrorMessage, reportPlace, true);
        return;
      }
      hook.setValue({
        ...hook.value,
        nyukyoKanoRadioState: { ...hook.value.nyukyoKanoRadioState, hasPlan: event.target.checked },
      });
    };

    return (
      <BukkenNyukyoKanoDateFormPresenter
        isSmallDevice={isSmallDevice}
        nyukyoKanoRadioState={hook.value?.nyukyoKanoRadioState.state}
        hasPlan={hook.value?.nyukyoKanoRadioState.hasPlan}
        nyukyoKanoDateFrom={hook.value?.nyukyoKanoDateFrom}
        nyukyoKanoDateTo={hook.value?.nyukyoKanoDateTo}
        setFromValue={setFromValue}
        setToValue={setToValue}
        onChange={handleChange}
        onCheck={handleCheck}
      />
    );
  },
  deepCompare
);

export type BukkenNyukyoKanoDateFormPresenterProps = {
  isSmallDevice: boolean;
  nyukyoKanoRadioState: BukkenNyukyoKanoDateRangeRadioState | undefined;
  hasPlan: boolean | undefined;
  nyukyoKanoDateFrom: Date | undefined;
  nyukyoKanoDateTo: Date | undefined;
  setFromValue: (nyukyoKanoDateFrom: Date | undefined) => void;
  setToValue: (nyukyoKanoDateTo: Date | undefined) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onCheck: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const BukkenNyukyoKanoDateFormPresenter: FC<BukkenNyukyoKanoDateFormPresenterProps> = ({
  isSmallDevice,
  nyukyoKanoRadioState,
  hasPlan,
  nyukyoKanoDateFrom,
  nyukyoKanoDateTo,
  setFromValue,
  setToValue,
  onChange,
  onCheck,
}) => {
  return (
    <Grid
      data-testclass="BukkenNyukyoKanoDateFormContainer"
      container
      sx={{ ...searchConditionSxProps.root, ...borderSxProps.borderBottom }}
    >
      <Grid
        item
        xs={12}
        sm={2}
        sx={{ ...searchConditionSxProps.firstCol, ...(isSmallDevice ? borderSxProps.borderBottom : null) }}
      >
        入居可能日
      </Grid>
      <Grid item xs={12} sm={10} sx={{ ...searchConditionSxProps.secondCol, ...searchConditionSxProps.inputCol }}>
        <Stack width="100%" gap={1}>
          <Box display="flex" flexWrap="wrap" gap={1}>
            <RadioButton
              variant="outlined"
              size="small"
              label={BukkenNyukyoKanoDateRangeRadioLabelMap[BukkenNyukyoKanoDateRangeRadioState.unselected]}
              value={BukkenNyukyoKanoDateRangeRadioState.unselected}
              onChange={onChange}
              checked={nyukyoKanoRadioState === BukkenNyukyoKanoDateRangeRadioState.unselected}
            />
            <RadioButton
              variant="outlined"
              size="small"
              label={BukkenNyukyoKanoDateRangeRadioLabelMap[BukkenNyukyoKanoDateRangeRadioState.immediateOccupancy]}
              value={BukkenNyukyoKanoDateRangeRadioState.immediateOccupancy}
              onChange={onChange}
              checked={nyukyoKanoRadioState === BukkenNyukyoKanoDateRangeRadioState.immediateOccupancy}
            />
            <RadioButton
              variant="outlined"
              size="small"
              label={BukkenNyukyoKanoDateRangeRadioLabelMap[BukkenNyukyoKanoDateRangeRadioState.discussed]}
              value={BukkenNyukyoKanoDateRangeRadioState.discussed}
              onChange={onChange}
              checked={nyukyoKanoRadioState === BukkenNyukyoKanoDateRangeRadioState.discussed}
            />
            <RadioButton
              variant="outlined"
              size="small"
              label={BukkenNyukyoKanoDateRangeRadioLabelMap[BukkenNyukyoKanoDateRangeRadioState.select]}
              value={BukkenNyukyoKanoDateRangeRadioState.select}
              onChange={onChange}
              checked={nyukyoKanoRadioState === BukkenNyukyoKanoDateRangeRadioState.select}
            />
          </Box>
          {nyukyoKanoRadioState === BukkenNyukyoKanoDateRangeRadioState.select && (
            <Box display="flex" flexWrap="wrap" gap={1}>
              <LocalizedDateRangePicker
                disabled={nyukyoKanoRadioState !== BukkenNyukyoKanoDateRangeRadioState.select}
                fromValue={nyukyoKanoDateFrom}
                setFromValue={setFromValue}
                toValue={nyukyoKanoDateTo}
                setToValue={setToValue}
                separatorSxProps={searchConditionSxProps.rangeSeparator}
              />
              <Checkbox
                variant="outlined"
                size="medium"
                checked={hasPlan}
                onChange={onCheck}
                label={BukkenNyukyoKanoDateRangeRadioLabelMap[BukkenNyukyoKanoDateRangeRadioState.plan]}
              />
            </Box>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default BukkenNyukyoKanoDateFormContainer;
