import { useState, useEffect, useCallback, useReducer } from 'react';

import { PartnerSearchConditionHook, SearchConditionHook } from './SearchConditionHook';

import {
  BukkenDateRangeRadioState,
  B2bKokaiCodeRadioState,
  BukkenNyukyoKanoDateRangeRadioState,
} from '@/Models/SearchConditions/ChintaiSearchConditionEnums/ChintaiSearchConditionEnums';
import {
  B2bKokaiCodeCondition,
  ChinryoConditions,
  GazoSearchConditions,
  KokaiDateConditions,
  KoshinDateConditions,
  NyukyoKanoDateConditions,
  ShikikinReikinConditions,
} from '@/Models/SearchConditions/ChintaiSearchConditions';
import {
  ApprovalStatusCollection,
  ApprovalStatusCollectionType,
} from '@/Models/SearchConditions/KanriPartnerSearchConditions';
import {
  ApplicationStatusCollectionType,
  ApplicationStatusCollection,
} from '@/Models/SearchConditions/PartnerSearchConditions';

type TogglableListAction<V> = {
  type: 'set' | 'remove' | 'toggle';
  value: V[];
};
type TogglableListReducerFunc<V> = (state: V[], action: TogglableListAction<V>) => V[];
const togglableListReducer = <V>(state: V[], action: TogglableListAction<V>): V[] => {
  const mutableState = Array.from(state);

  switch (action.type) {
    case 'set':
      return Array.from(action.value);
    case 'remove':
      return state.filter(v => !action.value.includes(v));
    case 'toggle':
      action.value.forEach(actv => {
        const index = mutableState.indexOf(actv);
        if (index !== -1) {
          mutableState.splice(index, 1);
          return;
        }
        mutableState.push(actv);
      });
      return mutableState;
  }
};

export const useListSearchCondition = <V extends string, C>(
  conditionValue: V[],
  conditionSetter: (value: V[], conditions: C) => void
): ListSearchConditionHook<V, C> => {
  const [value, dispatch] = useReducer<TogglableListReducerFunc<V>>(togglableListReducer, []);

  const remove = useCallback((...items: V[]): void => {
    dispatch({
      type: 'remove',
      value: items,
    });
  }, []);
  const toggle = useCallback((...items: V[]): void => {
    dispatch({
      type: 'toggle',
      value: items,
    });
  }, []);
  const setValue = useCallback((items: V[]): void => {
    dispatch({
      type: 'set',
      value: items,
    });
  }, []);
  const clear = useCallback((): void => {
    dispatch({
      type: 'set',
      value: [],
    });
  }, []);

  useEffect(() => {
    dispatch({
      type: 'set',
      value: conditionValue,
    });
  }, [conditionValue]);

  const mapToConditions = useCallback(
    (conditions: C): void => {
      conditionSetter(value, conditions);
    },
    [conditionSetter, value]
  );

  return {
    value,
    setValue,
    queryValue: conditionValue,
    clear,
    mapToConditions,
    remove,
    toggle,
  };
};

export type ListSearchConditionHook<V extends string, C> = SearchConditionHook<V[], C> & {
  remove: (...items: V[]) => void;
  toggle: (...items: V[]) => void;
};

export const useSelectSearchCondition = <V extends string, C>(
  conditionValue: V,
  conditionSetter: (value: V, conditions: C) => void,
  initialValue: V,
  choices: V[],
  displayTextMap: { [_ in V]: string }
): SelectSearchConditionHook<V, C> => {
  const [value, setValue] = useState<V>(initialValue);
  const clear = useCallback((): void => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    setValue(conditionValue);
  }, [conditionValue]);

  const mapToConditions = useCallback(
    (conditions: C): void => {
      conditionSetter(value, conditions);
    },
    [conditionSetter, value]
  );

  return {
    value,
    setValue,
    queryValue: conditionValue,
    clear,
    mapToConditions,
    choices,
    displayTextMap,
  };
};

export type SelectSearchConditionHook<V extends string, C> = SearchConditionHook<V, C> & {
  choices: V[];
  displayTextMap: { [_ in V]: string };
};

const strToNumberOrUndefined = (input: string): number | undefined => {
  if (input.length <= 0) {
    return undefined;
  }
  const parsed = Number(input);
  return isNaN(parsed) ? undefined : parsed;
};

const numberOrUndefinedToStr = (input: number | undefined): string => {
  return input === undefined ? '' : input.toString();
};

export const useNumberOrUndefinedSearchCondition = <C>(
  conditionValue: number | undefined,
  conditionSetter: (value: number | undefined, conditions: C) => void
): SearchConditionHook<string, C> => {
  const [value, setValue] = useState<string>('');
  const [queryValue, setQueryValue] = useState<string>('');
  const clear = useCallback((): void => {
    setValue('');
  }, []);

  useEffect(() => {
    const newVal = numberOrUndefinedToStr(conditionValue);
    setValue(newVal);
    setQueryValue(newVal);
  }, [conditionValue]);

  const mapToConditions = useCallback(
    (conditions: C): void => {
      conditionSetter(strToNumberOrUndefined(value), conditions);
    },
    [conditionSetter, value]
  );

  return {
    value,
    setValue,
    queryValue,
    clear,
    mapToConditions,
  };
};

export type ChinryoSearchConditionForm = {
  chinryoTo: number | undefined;
  chinryoFrom: number | undefined;
  komiChinryoTo: number | undefined;
  komiChinryoFrom: number | undefined;
  isIncludeManagementFee: boolean;
  isFreeRentAvailable: boolean;
};

export type ChinryoSearchConditionState = {
  chinryoTo: string;
  chinryoFrom: string;
  isIncludeManagementFee: boolean;
  isFreeRentAvailable: boolean;
};

export type ChinryoSearchConditionHook<C> = SearchConditionHook<ChinryoSearchConditionState, C> & {
  clearChinryo: () => void;
  clearManagementFee: () => void;
  clearIsFreeRentAvailable: () => void;
};

const chinryoSearchConditionInitialState: ChinryoSearchConditionState = {
  chinryoTo: '',
  chinryoFrom: '',
  isIncludeManagementFee: false,
  isFreeRentAvailable: false,
};
export const useChinryoSearchCondition = <C extends ChinryoConditions>(
  conditions: Readonly<ChinryoSearchConditionForm>
): ChinryoSearchConditionHook<C> => {
  const [value, setValue] = useState<ChinryoSearchConditionState>(chinryoSearchConditionInitialState);
  const [queryValue, setQueryValue] = useState<ChinryoSearchConditionState>(chinryoSearchConditionInitialState);
  const clear = useCallback((): void => {
    setValue(chinryoSearchConditionInitialState);
  }, []);
  const clearChinryo = useCallback((): void => {
    setValue(prev => ({ ...prev, chinryoFrom: '', chinryoTo: '' }));
  }, []);
  const clearManagementFee = useCallback((): void => {
    setValue(prev => ({
      ...prev,
      isIncludeManagementFee: false,
    }));
  }, []);
  const clearIsFreeRentAvailable = useCallback((): void => {
    setValue(prev => ({
      ...prev,
      isFreeRentAvailable: false,
    }));
  }, []);

  useEffect(() => {
    let newVal: ChinryoSearchConditionState = {
      chinryoTo: numberOrUndefinedToStr(conditions.chinryoTo),
      chinryoFrom: numberOrUndefinedToStr(conditions.chinryoFrom),
      isIncludeManagementFee: false,
      isFreeRentAvailable: conditions.isFreeRentAvailable,
    };
    if (conditions.komiChinryoTo !== undefined || conditions.komiChinryoFrom !== undefined) {
      newVal = {
        chinryoTo: numberOrUndefinedToStr(conditions.komiChinryoTo),
        chinryoFrom: numberOrUndefinedToStr(conditions.komiChinryoFrom),
        isIncludeManagementFee: true,
        isFreeRentAvailable: conditions.isFreeRentAvailable,
      };
    }
    setValue(newVal);
    setQueryValue(newVal);
  }, [
    conditions.chinryoFrom,
    conditions.chinryoTo,
    conditions.isFreeRentAvailable,
    conditions.komiChinryoFrom,
    conditions.komiChinryoTo,
  ]);

  const mapToConditions = useCallback(
    (conditions: C): void => {
      if (value.isIncludeManagementFee) {
        conditions.komiChinryoFrom = strToNumberOrUndefined(value.chinryoFrom);
        conditions.komiChinryoTo = strToNumberOrUndefined(value.chinryoTo);
      } else {
        conditions.chinryoFrom = strToNumberOrUndefined(value.chinryoFrom);
        conditions.chinryoTo = strToNumberOrUndefined(value.chinryoTo);
      }
      conditions.freeRentAvailable = value.isFreeRentAvailable;
    },
    [value.chinryoFrom, value.chinryoTo, value.isFreeRentAvailable, value.isIncludeManagementFee]
  );

  return {
    value,
    setValue,
    queryValue,
    clear,
    mapToConditions,
    clearChinryo,
    clearManagementFee,
    clearIsFreeRentAvailable,
  };
};

const kokaiDateSearchConditionInitialState = {
  kokaiDateFrom: undefined,
  kokaiDateTo: undefined,
  kokaiRadioState: BukkenDateRangeRadioState.unselected,
};
export const useKokaiDateSearchCondition = <C extends KokaiDateConditions>(
  conditions: Readonly<KokaiDateConditions>
): SearchConditionHook<KokaiDateConditions, C> => {
  const [value, setValue] = useState<KokaiDateConditions>(kokaiDateSearchConditionInitialState);
  const clear = useCallback((): void => {
    setValue(kokaiDateSearchConditionInitialState);
  }, []);

  useEffect(() => {
    setValue(conditions);
  }, [conditions]);

  const mapToConditions = useCallback(
    (conditions: C): void => {
      conditions.kokaiDateFrom = value.kokaiDateFrom;
      conditions.kokaiDateTo = value.kokaiDateTo;
      conditions.kokaiRadioState = value.kokaiRadioState;
    },
    [value.kokaiDateFrom, value.kokaiDateTo, value.kokaiRadioState]
  );

  return {
    value,
    setValue,
    queryValue: conditions,
    clear,
    mapToConditions,
  };
};

const koshinDateSearchConditionInitialState = {
  koshinDateFrom: undefined,
  koshinDateTo: undefined,
  koshinRadioState: BukkenDateRangeRadioState.unselected,
};
export const useKoshinDateSearchCondition = <C extends KoshinDateConditions>(
  conditions: Readonly<KoshinDateConditions>
): SearchConditionHook<KoshinDateConditions, C> => {
  const [value, setValue] = useState<KoshinDateConditions>(koshinDateSearchConditionInitialState);
  const clear = useCallback((): void => {
    setValue(koshinDateSearchConditionInitialState);
  }, []);

  useEffect(() => {
    setValue(conditions);
  }, [conditions]);

  const mapToConditions = useCallback(
    (conditions: C): void => {
      conditions.koshinDateFrom = value.koshinDateFrom;
      conditions.koshinDateTo = value.koshinDateTo;
      conditions.koshinRadioState = value.koshinRadioState;
    },
    [value.koshinDateFrom, value.koshinDateTo, value.koshinRadioState]
  );

  return {
    value,
    setValue,
    queryValue: conditions,
    clear,
    mapToConditions,
  };
};

const NyukyoKanoDateSearchConditionInitialState = {
  nyukyoKanoDateFrom: undefined,
  nyukyoKanoDateTo: undefined,
  nyukyoKanoRadioState: {
    state: BukkenNyukyoKanoDateRangeRadioState.unselected,
    hasPlan: false,
  },
};
export const useNyukyoKanoDateSearchCondition = <C extends NyukyoKanoDateConditions>(
  conditions: Readonly<NyukyoKanoDateConditions>
): SearchConditionHook<NyukyoKanoDateConditions, C> => {
  const [value, setValue] = useState<NyukyoKanoDateConditions>(NyukyoKanoDateSearchConditionInitialState);
  const clear = useCallback((): void => {
    setValue(NyukyoKanoDateSearchConditionInitialState);
  }, []);

  useEffect(() => {
    setValue(conditions);
  }, [conditions]);

  const mapToConditions = useCallback(
    (conditions: C): void => {
      conditions.nyukyoKanoDateFrom = value.nyukyoKanoDateFrom;
      conditions.nyukyoKanoDateTo = value.nyukyoKanoDateTo;
      conditions.nyukyoKanoRadioState = value.nyukyoKanoRadioState;
    },
    [value.nyukyoKanoDateFrom, value.nyukyoKanoDateTo, value.nyukyoKanoRadioState]
  );

  return {
    value,
    setValue,
    queryValue: conditions,
    clear,
    mapToConditions,
  };
};

const GazoSearchConditionInitialState = {
  totalGazoCountFrom: undefined,
  gaikanGazoCountFrom: undefined,
  madoriGazoCountFrom: undefined,
  naikanGazoCountFrom: undefined,
};

export type GazoSearchConditionHook<C> = SearchConditionHook<GazoSearchConditions, C> & {
  clearTotalGazoCount: () => void;
  clearGaikanGazoCount: () => void;
  clearMadoriGazoCount: () => void;
  clearNaikanGazoCount: () => void;
};
export const useGazoSearchCondition = <C extends GazoSearchConditions>(
  conditions: Readonly<GazoSearchConditions>
): GazoSearchConditionHook<C> => {
  const [value, setValue] = useState<GazoSearchConditions>(GazoSearchConditionInitialState);
  const clear = useCallback((): void => {
    setValue(GazoSearchConditionInitialState);
  }, []);
  const clearTotalGazoCount = useCallback((): void => {
    setValue(prev => ({ ...prev, totalGazoCountFrom: GazoSearchConditionInitialState.totalGazoCountFrom }));
  }, []);
  const clearGaikanGazoCount = useCallback((): void => {
    setValue(prev => ({
      ...prev,
      gaikanGazoCountFrom: GazoSearchConditionInitialState.gaikanGazoCountFrom,
    }));
  }, []);
  const clearMadoriGazoCount = useCallback((): void => {
    setValue(prev => ({
      ...prev,
      madoriGazoCountFrom: GazoSearchConditionInitialState.madoriGazoCountFrom,
    }));
  }, []);
  const clearNaikanGazoCount = useCallback((): void => {
    setValue(prev => ({
      ...prev,
      naikanGazoCountFrom: GazoSearchConditionInitialState.naikanGazoCountFrom,
    }));
  }, []);

  useEffect(() => {
    setValue(conditions);
  }, [conditions]);

  const mapToConditions = useCallback(
    (conditions: C): void => {
      conditions.totalGazoCountFrom = value.totalGazoCountFrom;
      conditions.gaikanGazoCountFrom = value.gaikanGazoCountFrom;
      conditions.madoriGazoCountFrom = value.madoriGazoCountFrom;
      conditions.naikanGazoCountFrom = value.naikanGazoCountFrom;
    },
    [value.totalGazoCountFrom, value.gaikanGazoCountFrom, value.madoriGazoCountFrom, value.naikanGazoCountFrom]
  );

  return {
    value,
    setValue,
    queryValue: conditions,
    clear,
    clearTotalGazoCount,
    clearGaikanGazoCount,
    clearMadoriGazoCount,
    clearNaikanGazoCount,
    mapToConditions,
  };
};

export type ShikikinReikinConditionHook<C> = SearchConditionHook<ShikikinReikinConditions, C> & {
  clearShikikin: () => void;
  clearReikin: () => void;
};

const shikikinReikinSearchConditionInitialState = {
  isExcludeShikikin: false,
  isExcludeReikin: false,
  shikikinTo: undefined,
  reikinTo: undefined,
};
export const useShikikinReikinSearchCondition = <C extends ShikikinReikinConditions>(
  conditions: Readonly<ShikikinReikinConditions>
): ShikikinReikinConditionHook<C> => {
  const [value, setValue] = useState<ShikikinReikinConditions>(shikikinReikinSearchConditionInitialState);
  const clear = useCallback((): void => {
    setValue(shikikinReikinSearchConditionInitialState);
  }, []);
  const clearShikikin = useCallback((): void => {
    setValue(prev => ({ ...prev, shikikinTo: undefined, isExcludeShikikin: false }));
  }, []);
  const clearReikin = useCallback((): void => {
    setValue(prev => ({ ...prev, reikinTo: undefined, isExcludeReikin: false }));
  }, []);

  useEffect(() => {
    setValue(conditions);
  }, [conditions]);

  const mapToConditions = useCallback(
    (conditions: C): void => {
      conditions.isExcludeShikikin = value.isExcludeShikikin;
      conditions.isExcludeReikin = value.isExcludeReikin;
      conditions.shikikinTo = value.shikikinTo;
      conditions.reikinTo = value.reikinTo;
    },
    [value.isExcludeReikin, value.isExcludeShikikin, value.reikinTo, value.shikikinTo]
  );

  return {
    value,
    setValue,
    queryValue: conditions,
    clear,
    mapToConditions,
    clearShikikin,
    clearReikin,
  };
};

const ApplicationStatusConditionInitialState = {
  state: [ApplicationStatusCollection.applyable],
};
export const useApplicationStatusCondition = (
  conditions: ApplicationStatusCollectionType[]
): PartnerSearchConditionHook<ApplicationStatusCollectionType[]> => {
  const [value, setValue] = useState<ApplicationStatusCollectionType[]>(ApplicationStatusConditionInitialState.state);
  const clear = useCallback((): void => {
    setValue([]);
  }, []);

  useEffect(() => {
    setValue(conditions);
  }, [conditions]);

  return {
    value,
    setValue,
    queryValue: conditions,
    clear,
  };
};

const ApprovalStatusConditionInitialState = {
  state: [ApprovalStatusCollection.pending],
};
export const useApprovalStatusCondition = (
  conditions: ApprovalStatusCollectionType[]
): PartnerSearchConditionHook<ApprovalStatusCollectionType[]> => {
  const [value, setValue] = useState<ApprovalStatusCollectionType[]>(ApprovalStatusConditionInitialState.state);
  const clear = useCallback((): void => {
    setValue([]);
  }, []);

  useEffect(() => {
    setValue(conditions);
  }, [conditions]);

  return {
    value,
    setValue,
    queryValue: conditions,
    clear,
  };
};

// TODO 他検索条件のラジオボタンでも使えるように共通化しておきたい(https://gitlab.com/eseikatsu/es-square/squareBacklog/-/issues/961)
const bukkenKokaiScopeSearchConditionInitialState = {
  bukkenKokaiScopeRadioState: B2bKokaiCodeRadioState.unselected,
};
export const useB2bKokaiCodeSearchCondition = <C extends B2bKokaiCodeCondition>(
  conditions: Readonly<B2bKokaiCodeCondition>
): SearchConditionHook<B2bKokaiCodeCondition, C> => {
  const [value, setValue] = useState<B2bKokaiCodeCondition>(bukkenKokaiScopeSearchConditionInitialState);
  const clear = useCallback((): void => {
    setValue(bukkenKokaiScopeSearchConditionInitialState);
  }, []);
  useEffect(() => {
    setValue(conditions);
  }, [conditions]);

  const mapToConditions = useCallback(
    (conditions: C): void => {
      conditions.bukkenKokaiScopeRadioState = value.bukkenKokaiScopeRadioState;
    },
    [value.bukkenKokaiScopeRadioState]
  );
  return {
    value,
    setValue,
    queryValue: conditions,
    clear,
    mapToConditions,
  };
};
