import { isNullOrEmpty } from '@app/utils';
import LinkifyIt from 'linkify-it';

const URL_PATTERN = /https?:\/\/[-\w:@&?=+,.!/~*'%$_;#]+/gi;
const HTTPS_SCHEME_PATTERN = /^https:\/\//i;
const PROHIBITED_EXTENSION = /\.(exe|vbs|cmd)(\?[^#]*)?(#.*)?$/i;

/**
 * 半角英数記号で構成されたhttp(s):スキームのURLを検索する（拡張子.exe/.vbs/.cmdを持つURLを除く）
 *
 * @param {string} text - URLを含む文字列
 * @return {LinkifyIt.Match[] | null} - 見つかった位置とURLを格納したリスト（見つからなければnull）
 */
export const LinkifyUrlSafeMatchDecorator = (text: string): LinkifyIt.Match[] | null => {
  const urls = text.matchAll(URL_PATTERN);
  if (urls === null) {
    return null;
  }
  const matches = Array.from(urls)
    .filter(match => !PROHIBITED_EXTENSION.test(match[0]) && match.index !== undefined)
    .map(match => {
      const url = match[0];
      // filterで検査済みなので `|| 0` には到達しない
      const index: number = match.index || 0;
      return {
        schema: url.match(HTTPS_SCHEME_PATTERN) ? 'https:' : 'http:',
        index: index,
        lastIndex: index + url.length,
        text: url,
        url: url,
        raw: url,
      };
    });
  if (isNullOrEmpty(matches)) {
    return null;
  }
  return matches;
};
