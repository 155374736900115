import { SpecBukkenView } from '@app/models';
import { useIsSmallDevice } from '@lib/components';
import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { FC, Fragment, useCallback } from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';

import { BukkenTagList } from '@/Components/DataDisplay/BukkenTagList';
import { useTransitToMotodukeList } from '@/Hooks/SearchResult/TransitToMotodukeHook';
import { useCustomerViewStateHooks } from '@/Hooks/customerViewHooks';
import { RouteDefinitions } from '@/Pages/RouteDefinitions';
import {
  BukkenImage,
  CalculatedSpecBukkenInfo,
  calculateInfo,
  WithChip,
} from '@/Pages/bukken/chintai/SearchPartial/BukkenListItem/BukkenListItemCommons';

type OwnBukkenItemContainerProps = {
  specBukkenView: SpecBukkenView;
};

const OwnBukkenItemContainer: FC<OwnBukkenItemContainerProps> = ({ specBukkenView }) => {
  const isSmallDevice = useIsSmallDevice();
  const customerViewStateHooks = useCustomerViewStateHooks();
  const transitToMotodukeList = useTransitToMotodukeList();
  const calculatedInfo = calculateInfo(specBukkenView, customerViewStateHooks.isCustomerView);

  const navigate = useNavigate();
  const location = useLocation();
  const transitToDetail = useCallback(() => {
    navigate(
      {
        pathname: generatePath(RouteDefinitions.ownBukkenDetail.path, {
          guid: specBukkenView.chinshaku_bukken_view.chinshaku_bukken_guid,
        }),
        search: location.search,
      },
      { state: location.state }
    );
  }, [navigate, specBukkenView.chinshaku_bukken_view.chinshaku_bukken_guid, location.search, location.state]);

  return (
    <Fragment>
      {isSmallDevice ? (
        <OwnBukkenItemMobilePresenter
          specBukkenView={specBukkenView}
          calculatedInfo={calculatedInfo}
          isCustomerView={customerViewStateHooks.isCustomerView}
          transitToMotodukeList={transitToMotodukeList}
          transitToDetail={transitToDetail}
        />
      ) : (
        <OwnBukkenItemPresenter
          specBukkenView={specBukkenView}
          calculatedInfo={calculatedInfo}
          isCustomerView={customerViewStateHooks.isCustomerView}
          transitToMotodukeList={transitToMotodukeList}
          transitToDetail={transitToDetail}
        />
      )}
    </Fragment>
  );
};

export { OwnBukkenItemContainer as OwnBukkenItem };

type OwnBukkenItemPresenterProps = {
  specBukkenView: SpecBukkenView;
  calculatedInfo: CalculatedSpecBukkenInfo;
  isCustomerView: boolean;
  transitToMotodukeList: (motodukeName: string | undefined, e: React.MouseEvent) => void;
  transitToDetail: () => void;
};

export const OwnBukkenItemPresenter: FC<OwnBukkenItemPresenterProps> = ({
  specBukkenView,
  calculatedInfo,
  isCustomerView,
  transitToMotodukeList,
  transitToDetail,
}) => {
  return (
    <Box
      display="grid"
      gridTemplateColumns="0.6fr 1.5fr 1.5fr 1.2fr 0.8fr"
      alignItems="center"
      bgcolor="white"
      minHeight="88px"
      borderRight={`1px solid ${grey[300]}`}
      borderLeft={`1px solid ${grey[300]}`}
      borderBottom={`1px solid ${grey[300]}`}
      py={1}
      gap={1}
      css={{
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: `${grey[100]}`,
        },
      }}
      onClick={transitToDetail}
    >
      <Box>
        <Box p={1} height="88px" css={{ objectFit: 'contain' }}>
          <BukkenImage
            imageGuid={calculatedInfo.imageGuid}
            imageAspectRatio="fitToParent"
            tatemonoName={calculatedInfo.kukakuName}
          />
        </Box>
      </Box>
      <Box>
        <BukkenTagList
          isCustomerView={isCustomerView}
          isNew={calculatedInfo.isNew}
          isChushajo={calculatedInfo.isChushajo}
          isTochi={calculatedInfo.isTochi}
          isApplied={calculatedInfo.isApplied}
          isADExists={calculatedInfo.isADExists}
          isShinchiku={calculatedInfo.isShinchiku}
          isGenteiKokai={calculatedInfo.isGenteiKokai}
          tensaiArray={calculatedInfo.tensaiArray}
        />
        <Box mt={0.5} fontSize="14px" fontWeight="bold">
          {calculatedInfo.kukakuName}
        </Box>
        <Box fontSize="12px">
          {specBukkenView.chinshaku_boshu_joken_view.b2b_eda_bango_hyoji_flag
            ? specBukkenView.jusho_full_text
            : specBukkenView.jusho_text}
        </Box>
        <Box fontSize="12px">{calculatedInfo.kotsuText}</Box>
      </Box>
      <Box fontSize="12px">
        <Box css={{ wordBreak: 'break-word' }}>
          {calculatedInfo.chinryoAmountText}円/{calculatedInfo.kanrihiEtcText}
          {calculatedInfo.isKanrihiExist && <Typography component="span">円</Typography>}
        </Box>
        <Box display="flex" alignItems="center" gap={0.5}>
          <WithChip chipLabel="敷">{calculatedInfo.shikikinText}</WithChip>
          <WithChip chipLabel="礼">{calculatedInfo.reikinText}</WithChip>
        </Box>
        {!isCustomerView && (
          <Fragment>
            <Box>
              <Box>AD {calculatedInfo.adAmountText}</Box>
            </Box>
            <Box display="flex" alignItems="center">
              <Box mr={0.5} minWidth="24px">
                元付
              </Box>
              <Box
                onClick={e => {
                  transitToMotodukeList(specBukkenView.chinshaku_boshu_joken_view.motozuke_gyosha_name, e);
                }}
                color={theme => theme.palette.primary.main}
                css={{
                  wordBreak: 'break-word',
                  '&:hover': {
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  },
                }}
              >
                {specBukkenView.chinshaku_boshu_joken_view.motozuke_gyosha_name ?? ''}
              </Box>
            </Box>
          </Fragment>
        )}
      </Box>
      <Box fontSize="12px">
        <Box>
          {calculatedInfo.madoriText}/{calculatedInfo.mensekiText}
        </Box>
        <Box>
          {calculatedInfo.kozoText}/{calculatedInfo.chikuNensuText}
        </Box>
      </Box>
      <Box fontSize="12px">
        <Box>{calculatedInfo.taikyoDateText}</Box>
        <Box>{calculatedInfo.nyukyoKanoText}</Box>
      </Box>
    </Box>
  );
};

export const OwnBukkenItemMobilePresenter: FC<OwnBukkenItemPresenterProps> = ({
  specBukkenView,
  calculatedInfo,
  isCustomerView,
  transitToMotodukeList,
  transitToDetail,
}) => {
  return (
    <Box
      bgcolor={theme => theme.palette.background.paper}
      borderRadius="4px"
      my={0.5}
      p={1}
      css={{
        '&:hover': {
          cursor: 'pointer',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        },
      }}
      onClick={transitToDetail}
    >
      <Box display="flex" flexDirection="row">
        <Box
          display="flex"
          flexDirection="column"
          flexShrink={0}
          flexBasis="8rem"
          css={{
            padding: 0.5,
            overflowX: 'hidden',
            '&> *': {
              overflowWrap: 'anywhere',
            },
          }}
        >
          <BukkenImage
            imageGuid={calculatedInfo.imageGuid}
            imageAspectRatio="1by1"
            tatemonoName={calculatedInfo.kukakuName}
          />
        </Box>
        <Box
          flexShrink={1}
          ml={1}
          css={{
            padding: 0.5,
            overflowX: 'hidden',
            '&> *': {
              overflowWrap: 'anywhere',
            },
          }}
        >
          <BukkenTagList
            isCustomerView={isCustomerView}
            isNew={calculatedInfo.isNew}
            isChushajo={calculatedInfo.isChushajo}
            isTochi={calculatedInfo.isTochi}
            isApplied={calculatedInfo.isApplied}
            isADExists={calculatedInfo.isADExists}
            isShinchiku={calculatedInfo.isShinchiku}
            isGenteiKokai={calculatedInfo.isGenteiKokai}
            tensaiArray={calculatedInfo.tensaiArray}
          />
          <Box fontSize="14px" fontWeight="bold" py={0.5}>
            {calculatedInfo.kukakuName}
          </Box>
          <Box fontSize="12px">
            {specBukkenView.chinshaku_boshu_joken_view.b2b_eda_bango_hyoji_flag
              ? specBukkenView.jusho_full_text
              : specBukkenView.jusho_text}
          </Box>
          <Box fontSize="12px">{calculatedInfo.kotsuText}</Box>
        </Box>
      </Box>
      <Box
        pl={1}
        display="grid"
        gridTemplateColumns="1.5fr 1fr 1fr"
        gridAutoRows="auto"
        rowGap={1}
        columnGap={1}
        mt={1}
      >
        <Box display="flex" justifyContent="center" flexDirection="column" fontSize="12px">
          <Box css={{ wordBreak: 'break-word' }}>
            {calculatedInfo.chinryoAmountText}円/{calculatedInfo.kanrihiEtcText}
            {calculatedInfo.isKanrihiExist && <Typography component="span">円</Typography>}
          </Box>
          <Box display="flex" alignItems="center" gap={0.5} css={{ wordBreak: 'break-word' }}>
            <WithChip chipLabel="敷">{calculatedInfo.shikikinText}</WithChip>
            <WithChip chipLabel="礼">{calculatedInfo.reikinText}</WithChip>
          </Box>
          {!isCustomerView && (
            <Fragment>
              <Box>AD {calculatedInfo.adAmountText}</Box>
              <Box display="flex" alignItems="center">
                <Box mr={0.5} minWidth="24px">
                  元付
                </Box>
                <Box
                  onClick={e => {
                    transitToMotodukeList(specBukkenView.chinshaku_boshu_joken_view.motozuke_gyosha_name, e);
                  }}
                  color={theme => theme.palette.primary.main}
                  css={{
                    wordBreak: 'break-word',
                    '&:hover': {
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    },
                  }}
                >
                  {specBukkenView.chinshaku_boshu_joken_view.motozuke_gyosha_name ?? ''}
                </Box>
              </Box>
            </Fragment>
          )}
        </Box>
        <Box fontSize="12px" css={{ wordBreak: 'break-word' }}>
          <Box>
            {calculatedInfo.madoriText}/{calculatedInfo.mensekiText}
          </Box>
          <Box>
            {calculatedInfo.kozoText}/{calculatedInfo.chikuNensuText}
          </Box>
        </Box>
        <Box fontSize="12px" css={{ wordBreak: 'break-word' }}>
          <Box>{calculatedInfo.taikyoDateText}</Box>
          <Box>{calculatedInfo.nyukyoKanoText}</Box>
        </Box>
      </Box>
    </Box>
  );
};
