import { SpecBukkenView } from '@app/models';

import { Feed } from './Feed';
import { ChintaiSearchConditions } from './SearchConditions/ChintaiSearchConditions';
import { AsyncListBase } from './SearcherBase';

import { IBukkenApiService } from '@/Services/IBukkenApiService';

export class ChintaiBukkenAsyncList extends AsyncListBase<SpecBukkenView> {
  public constructor(
    private readonly bukkenApiService: IBukkenApiService,
    private readonly conditions: Readonly<ChintaiSearchConditions>,
    itemsPerPage: number
  ) {
    super(itemsPerPage);
  }

  public load(startIndex: number): Promise<Feed<SpecBukkenView>> {
    return this.bukkenApiService.searchChintaiBukken(this.conditions, {
      itemsPerPage: this.itemsPerPage,
      suppressNull: true,
      startIndex: startIndex,
    });
  }
}
