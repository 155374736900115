import { SonotaHiyoCategoryCode } from '@app/enums';
import { isNotNullOrUndefined } from '@app/utils';
import { SerializedStyles, css } from '@emotion/react';
import { useIsSmallDevice } from '@lib/components';
import { Dialog, DialogTitle, IconButton, Divider, useTheme, DialogContent, Grid, Typography } from '@mui/material';
import { Close } from 'mdi-material-ui';
import { FC, useMemo, useState } from 'react';

import {
  ExtraInitialCostData,
  InitialCostCalcType,
  InitialCostGetsujiReadOnlyType,
  InitialCostIchijikinReadOnlyType,
  calcTotalInitialCostPriceAmount,
  createSonotaHiyoDisplayData,
} from './CreateInitialCostDisplayData';
import { InitialCostAddExtraFormButton } from './InitialCostAddExtraFormButton';
import { InitialCostDisplayAlert } from './InitialCostDisplayAlert';
import { InitialCostDisplayGetsujiHiyo } from './InitialCostDisplayGetsujiHiyo';
import { InitialCostDisplayIchijikin } from './InitialCostDisplayIchijikin';
import { InitialCostDisplaySanko } from './InitialCostDisplaySanko';

import { SectionHeader } from '@/Pages/bukken/chintai/DetailPartial/SectionHeaders';
import { kanrihiPriceToYen, makePriceToYenBasedOnChinryo } from '@/Utils/DisplayText/Money';

type InitialCostDisplayDialogContainerProps = {
  isDialogOpen: boolean;
  onDialogClose: () => void;
  tatemonoName: string;
  initialCostCalcData: InitialCostCalcType;
  isCustomerView: boolean;
};

const extraInitialCostExceedThreshold = 10;

const InitialCostDisplayDialogContainer: FC<InitialCostDisplayDialogContainerProps> = ({
  isDialogOpen,
  onDialogClose,
  tatemonoName,
  initialCostCalcData,
  isCustomerView,
}) => {
  const muiTheme = useTheme();
  const GridBorder = `1px solid ${muiTheme.palette.divider}`;
  const isSmallDevice = useIsSmallDevice();

  const initialCostDialogStyles = () =>
    css({
      padding: 8,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      borderBottom: `solid 1px ${muiTheme.palette.divider}`,
    });

  //Number:合計金額計算用（数値）
  const chinryoNumber = isNotNullOrUndefined(initialCostCalcData.chinryo) ? initialCostCalcData.chinryo : 0;
  const kanrihiNumber = kanrihiPriceToYen(initialCostCalcData.kanrihi, initialCostCalcData.kanrihi_kubun_code);
  const kyoekihiNumber = kanrihiPriceToYen(initialCostCalcData.kyoekihi, initialCostCalcData.kyoekihi_kubun_code);
  const zappiNumber = kanrihiPriceToYen(initialCostCalcData.zappi_amount, initialCostCalcData.zappi_kubun_code);
  const getsujiReadOnlyData: InitialCostGetsujiReadOnlyType = {
    chinryo: chinryoNumber,
    kanrihi: kanrihiNumber,
    kyoekihi: kyoekihiNumber,
    zappi: zappiNumber,
  };

  const shikikinNumber = makePriceToYenBasedOnChinryo(
    initialCostCalcData.shikikin_amount,
    initialCostCalcData.shikikin_kubun_code,
    chinryoNumber
  );
  const reikinNumber = makePriceToYenBasedOnChinryo(
    initialCostCalcData.reikin_amount,
    initialCostCalcData.reikin_kubun_code,
    chinryoNumber
  );
  const hoshokinNumber = makePriceToYenBasedOnChinryo(
    initialCostCalcData.hoshokin_amount,
    initialCostCalcData.hoshokin_kubun_code,
    chinryoNumber
  );
  const sompoNumber = isNotNullOrUndefined(initialCostCalcData.sompo_ryokin_amount)
    ? initialCostCalcData.sompo_ryokin_amount
    : 0;
  const ichijikinReadOnlyData: InitialCostIchijikinReadOnlyType = {
    shikikin: shikikinNumber,
    reikin: reikinNumber,
    hoshokin: hoshokinNumber,
    sompo: sompoNumber,
  };

  const extraInitialCostSonotaGetsujiFirstDisplayData = useMemo(() => {
    const sonotaGetsujiData = initialCostCalcData.sonotaHiyo
      ? initialCostCalcData.sonotaHiyo.filter(data => data.sonota_hiyo_code === SonotaHiyoCategoryCode.monthly)
      : [];
    const initialCostSonotaGetsujiData = sonotaGetsujiData?.map(data => createSonotaHiyoDisplayData(data));
    const sonotaGetsujiHiyoDisplayData = initialCostSonotaGetsujiData.map(data => ({ ...data, id: Math.random() })); //追加項目のkeyに指定するため、idを追加

    return sonotaGetsujiHiyoDisplayData;
  }, [initialCostCalcData.sonotaHiyo]);

  const [extraInitialCostSonotaGetsujiDisplayData, setExtraInitialCostSonotaGetsujiDisplayData] = useState<
    ExtraInitialCostData[]
  >(extraInitialCostSonotaGetsujiFirstDisplayData);

  const extraInitialCostSonotaIchijiFirstDisplayData = useMemo(() => {
    const sonotaIchijiData = initialCostCalcData.sonotaHiyo
      ? initialCostCalcData.sonotaHiyo.filter(data => data.sonota_hiyo_code === SonotaHiyoCategoryCode.temporarily)
      : [];
    const initialCostSonotaIchijiData = sonotaIchijiData?.map(data => createSonotaHiyoDisplayData(data));
    const sonotaIchijiDisplayData = initialCostSonotaIchijiData.map(data => ({ ...data, id: Math.random() })); //追加項目のkeyに指定するため、idを追加

    return sonotaIchijiDisplayData;
  }, [initialCostCalcData.sonotaHiyo]);

  const [extraInitialCostSonotaIchijiDisplayData, setExtraInitialCostSonotaIchijiDisplayData] = useState<
    ExtraInitialCostData[]
  >(extraInitialCostSonotaIchijiFirstDisplayData);

  //項目名のみ固定表示の項目
  const [initialCostSonotaGetsujiWithLabelDisplayData, setInitialCostSonotaGetsujiWithLabelDisplayData] = useState<
    ExtraInitialCostData[]
  >([{ label: '日割り費用', price: '' }]);
  const [initialCostSonotaIchijiWithLabelDisplayData, setInitialCostSonotaIchijiWithLabelDisplayData] = useState<
    ExtraInitialCostData[]
  >([{ label: '仲介手数料', price: '' }]);

  const initialCostFirstDisplayAmount = calcTotalInitialCostPriceAmount(
    chinryoNumber,
    kanrihiNumber,
    kyoekihiNumber,
    zappiNumber,
    shikikinNumber,
    reikinNumber,
    hoshokinNumber,
    sompoNumber,
    initialCostSonotaGetsujiWithLabelDisplayData,
    initialCostSonotaIchijiWithLabelDisplayData,
    extraInitialCostSonotaGetsujiDisplayData,
    extraInitialCostSonotaIchijiDisplayData
  );

  const [initialCostPriceTotalAmount, setInitialCostPriceTotalAmount] = useState<number>(initialCostFirstDisplayAmount);

  const handleChangeTotalInitialCostPriceAmount = () => {
    setInitialCostPriceTotalAmount(
      calcTotalInitialCostPriceAmount(
        chinryoNumber,
        kanrihiNumber,
        kyoekihiNumber,
        zappiNumber,
        shikikinNumber,
        reikinNumber,
        hoshokinNumber,
        sompoNumber,
        initialCostSonotaGetsujiWithLabelDisplayData,
        initialCostSonotaIchijiWithLabelDisplayData,
        extraInitialCostSonotaGetsujiDisplayData,
        extraInitialCostSonotaIchijiDisplayData
      )
    );
  };

  return (
    <InitialCostDisplayDialogPresenter
      isDialogOpen={isDialogOpen}
      onDialogClose={onDialogClose}
      initialCostDialogStyles={initialCostDialogStyles}
      gridBorder={GridBorder}
      tatemonoName={tatemonoName}
      isCustomerView={isCustomerView}
      initialCostSonotaGetsujiWithLabelDisplayData={initialCostSonotaGetsujiWithLabelDisplayData}
      setInitialCostSonotaGetsujiWithLabelDisplayData={setInitialCostSonotaGetsujiWithLabelDisplayData}
      extraInitialCostSonotaGetsujiDisplayData={extraInitialCostSonotaGetsujiDisplayData}
      setExtraInitialCostSonotaGetsujiDisplayData={setExtraInitialCostSonotaGetsujiDisplayData}
      initialCostSonotaIchijiWithLabelDisplayData={initialCostSonotaIchijiWithLabelDisplayData}
      setInitialCostSonotaIchijiWithLabelDisplayData={setInitialCostSonotaIchijiWithLabelDisplayData}
      extraInitialCostSonotaIchijiDisplayData={extraInitialCostSonotaIchijiDisplayData}
      setExtraInitialCostSonotaIchijiDisplayData={setExtraInitialCostSonotaIchijiDisplayData}
      initialCostPriceTotalAmount={initialCostPriceTotalAmount}
      handleChangeTotalInitialCostPriceAmount={handleChangeTotalInitialCostPriceAmount}
      isSmallDevice={isSmallDevice}
      initialCostCalcData={initialCostCalcData}
      getsujiReadOnlyData={getsujiReadOnlyData}
      ichijikinReadOnlyData={ichijikinReadOnlyData}
    />
  );
};

export { InitialCostDisplayDialogContainer as InitialCostDisplayDialog };

type InitialCostDisplayDialogPresenterProps = {
  isDialogOpen: boolean;
  onDialogClose: () => void;
  initialCostDialogStyles: () => SerializedStyles;
  gridBorder: string;
  tatemonoName: string;
  isCustomerView: boolean;
  initialCostSonotaGetsujiWithLabelDisplayData: ExtraInitialCostData[];
  setInitialCostSonotaGetsujiWithLabelDisplayData: (value: React.SetStateAction<ExtraInitialCostData[]>) => void;
  extraInitialCostSonotaGetsujiDisplayData: ExtraInitialCostData[];
  setExtraInitialCostSonotaGetsujiDisplayData: (value: React.SetStateAction<ExtraInitialCostData[]>) => void;
  initialCostSonotaIchijiWithLabelDisplayData: ExtraInitialCostData[];
  setInitialCostSonotaIchijiWithLabelDisplayData: (value: React.SetStateAction<ExtraInitialCostData[]>) => void;
  extraInitialCostSonotaIchijiDisplayData: ExtraInitialCostData[];
  setExtraInitialCostSonotaIchijiDisplayData: (value: React.SetStateAction<ExtraInitialCostData[]>) => void;
  initialCostPriceTotalAmount: number;
  handleChangeTotalInitialCostPriceAmount: () => void;
  isSmallDevice: boolean;
  initialCostCalcData: InitialCostCalcType;
  getsujiReadOnlyData: InitialCostGetsujiReadOnlyType;
  ichijikinReadOnlyData: InitialCostIchijikinReadOnlyType;
};

export const InitialCostDisplayDialogPresenter: FC<InitialCostDisplayDialogPresenterProps> = ({
  isDialogOpen,
  onDialogClose,
  initialCostDialogStyles,
  gridBorder,
  tatemonoName,
  isCustomerView,
  initialCostSonotaGetsujiWithLabelDisplayData,
  setInitialCostSonotaGetsujiWithLabelDisplayData,
  extraInitialCostSonotaGetsujiDisplayData,
  setExtraInitialCostSonotaGetsujiDisplayData,
  initialCostSonotaIchijiWithLabelDisplayData,
  setInitialCostSonotaIchijiWithLabelDisplayData,
  extraInitialCostSonotaIchijiDisplayData,
  setExtraInitialCostSonotaIchijiDisplayData,
  initialCostPriceTotalAmount,
  handleChangeTotalInitialCostPriceAmount,
  isSmallDevice,
  initialCostCalcData,
  getsujiReadOnlyData,
  ichijikinReadOnlyData,
}) => (
  <Dialog onClose={onDialogClose} open={isDialogOpen} fullScreen={isSmallDevice}>
    <DialogTitle
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '48px',
        paddingRight: '8px',
      }}
    >
      初期費用見積概算
      <IconButton onClick={onDialogClose} size="large">
        <Close />
      </IconButton>
    </DialogTitle>
    <Divider />
    <DialogContent>
      <InitialCostDisplayAlert initialCostPriceTotalAmount={initialCostPriceTotalAmount} />
      <Typography fontSize="1rem" paddingTop={2}>
        {tatemonoName}
      </Typography>

      <SectionHeader>月次費用</SectionHeader>
      <Grid container css={{ borderTop: gridBorder }}>
        <InitialCostDisplayGetsujiHiyo
          initialCostCalcData={initialCostCalcData}
          getsujiReadOnlyData={getsujiReadOnlyData}
          initialCostDialogStyles={initialCostDialogStyles}
          initialCostSonotaGetsujiWithLabelDisplayData={initialCostSonotaGetsujiWithLabelDisplayData}
          setInitialCostSonotaGetsujiWithLabelDisplayData={setInitialCostSonotaGetsujiWithLabelDisplayData}
          extraInitialCostSonotaGetsujiDisplayData={extraInitialCostSonotaGetsujiDisplayData}
          setExtraInitialCostSonotaGetsujiDisplayData={setExtraInitialCostSonotaGetsujiDisplayData}
          handleChangeTotalInitialCostPriceAmount={handleChangeTotalInitialCostPriceAmount}
          isSmallDevice={isSmallDevice}
        />
        <InitialCostAddExtraFormButton
          extraInitialCostDisplayData={extraInitialCostSonotaGetsujiDisplayData}
          setExtraInitialCostDisplayData={setExtraInitialCostSonotaGetsujiDisplayData}
          disabled={
            extraInitialCostSonotaGetsujiDisplayData.length + extraInitialCostSonotaIchijiDisplayData.length ===
            extraInitialCostExceedThreshold
          }
          description="その他月次費用を追加する"
        />
      </Grid>

      <SectionHeader>一時金</SectionHeader>
      <Grid container css={{ borderTop: gridBorder }}>
        <InitialCostDisplayIchijikin
          initialCostCalcData={initialCostCalcData}
          ichijikinReadOnlyData={ichijikinReadOnlyData}
          initialCostDialogStyles={initialCostDialogStyles}
          initialCostSonotaIchijiWithLabelDisplayData={initialCostSonotaIchijiWithLabelDisplayData}
          setInitialCostSonotaIchijiWithLabelDisplayData={setInitialCostSonotaIchijiWithLabelDisplayData}
          extraInitialCostSonotaIchijiDisplayData={extraInitialCostSonotaIchijiDisplayData}
          setExtraInitialCostSonotaIchijiDisplayData={setExtraInitialCostSonotaIchijiDisplayData}
          handleChangeTotalInitialCostPriceAmount={handleChangeTotalInitialCostPriceAmount}
          isSmallDevice={isSmallDevice}
        />
        <InitialCostAddExtraFormButton
          extraInitialCostDisplayData={extraInitialCostSonotaIchijiDisplayData}
          setExtraInitialCostDisplayData={setExtraInitialCostSonotaIchijiDisplayData}
          disabled={
            extraInitialCostSonotaGetsujiDisplayData.length + extraInitialCostSonotaIchijiDisplayData.length ===
            extraInitialCostExceedThreshold
          }
          description="その他一時金を追加する"
        />
      </Grid>
      <SectionHeader>参考</SectionHeader>
      <InitialCostDisplaySanko
        isCustomerView={isCustomerView}
        initialCostCalcData={initialCostCalcData}
        initialCostDialogStyles={initialCostDialogStyles}
        gridBorder={gridBorder}
      />
    </DialogContent>
  </Dialog>
);
