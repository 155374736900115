import { TochiSetsudo } from '@app/models';
import { isNotNullOrUndefined, isNullOrUndefined } from '@app/utils';
import { dequal } from 'dequal';

const noInputTochiSetsudoJokyoFirst: TochiSetsudo = {
  setsudo_ichi_shitei_flag: false,
  setsudo_hoso_flag: false,
  setsudo_jokyo_shutaru_setsudo_flag: false,
};
const noInputTochiSetsudoJokyoSecond: TochiSetsudo = {
  setsudo_ichi_shitei_flag: false,
  setsudo_hoso_flag: false,
};

const textSeparator = ', ';

export const makeSetsudoText = (setsudo: TochiSetsudo[] | undefined): string => {
  if (isNullOrUndefined(setsudo)) {
    return '-';
  } else {
    const setsudoJokyo: (string | null)[] = [];
    setsudo.forEach((item, index) => {
      if (dequal(item, noInputTochiSetsudoJokyoFirst) || dequal(item, noInputTochiSetsudoJokyoSecond)) {
        return;
      }
      const contentArray = [];
      contentArray.push(
        isNotNullOrUndefined(item.doromen_karano_takasa)
          ? `（${index + 1}）道路面からの高さ：${item.doromen_karano_takasa}m`
          : null
      );
      contentArray.push(isNotNullOrUndefined(item.setsudo_fukuin) ? `幅員：${item.setsudo_fukuin}m` : null);
      contentArray.push(
        isNotNullOrUndefined(item.setsudo_setsumen_cho) ? `接面長：${item.setsudo_setsumen_cho}m` : null
      );
      contentArray.push(isNotNullOrUndefined(item.setsudo_hoso_flag) ? `接道舗装：有` : `接道舗装：無`);

      contentArray.push(isNotNullOrUndefined(item.setsudo_ichi_shitei_flag) ? `接道位置指定：有` : `接道位置指定：無`);
      contentArray.push(
        isNotNullOrUndefined(item.setsudo_jokyo_shutaru_setsudo_flag) ? `主たる接道：有` : `主たる接道：無`
      );
      contentArray.push(
        isNotNullOrUndefined(item.kenchiku_kijunho_42jo_doro_shubetsu)
          ? `建築基準法第42条 道路道路の種類：${item.kenchiku_kijunho_42jo_doro_shubetsu}`
          : null
      );
      contentArray.push(isNotNullOrUndefined(item.setsudo_hoko) ? `方向：${item.setsudo_hoko}` : null);
      contentArray.push(isNotNullOrUndefined(item.setsudo_shubetsu) ? `種別：${item.setsudo_shubetsu}` : null);

      setsudoJokyo.push(contentArray.filter(it => isNotNullOrUndefined(it)).join(textSeparator));
    });
    return setsudoJokyo.length === 0 ? '-' : setsudoJokyo.join(' ');
  }
};
