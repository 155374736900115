import { deepCompare } from '@app/utils';
import { TextField } from '@e-seikatsu/design-system';
import { memo, useEffect, useState, FC } from 'react';

import { ConditionFormGrid } from './ConditionFormGrid';

import { useHandlerOnChangeText } from '@/Hooks/Handlers/ChangeHandlers';
import { useHandlerOnEnter } from '@/Hooks/Handlers/KeyDownHandlers';
import { SearchConditionHook } from '@/Hooks/Search/SearchConditionHook';
import { ChintaiSearchConditions } from '@/Models/SearchConditions/ChintaiSearchConditions';

type BukkenNameFormProps = {
  hook: SearchConditionHook<string, ChintaiSearchConditions>;
  onEnter: () => void;
  isSmallDevice: boolean;
  focusOnOpen: boolean;
};

const BukkenNameForm: FC<BukkenNameFormProps> = memo(({ hook, onEnter, isSmallDevice, focusOnOpen }) => {
  const { value, setValue } = hook;
  const onChange = useHandlerOnChangeText(setValue);
  const onKeyDown = useHandlerOnEnter(onEnter);

  const [formInputRef, setFormInputRef] = useState<HTMLInputElement | null>(null);
  useEffect(() => {
    if (focusOnOpen && formInputRef) {
      formInputRef.focus();
    }
  }, [focusOnOpen, formInputRef]);

  return (
    <ConditionFormGrid label="物件名" isSmallDevice={isSmallDevice}>
      <TextField
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        ref={setFormInputRef}
        size="small"
        length="long"
      />
    </ConditionFormGrid>
  );
}, deepCompare);

export default BukkenNameForm;
