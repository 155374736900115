import { Box, Typography } from '@mui/material';
import { useMemo, FC } from 'react';

import { calcNormalOverviewData } from './OverviewData/CalcNormalOverviewData';
import { calcTochiOverviewData } from './OverviewData/CalcTochiOverviewData';

import { serviceRoomAnnotation } from '@/Consts/Messages';
import { ChinshakuBukkenDetailProps } from '@/Pages/bukken/chintai/DetailPartial/ChinshakuBukkenDetailProps';
import { DetailGrid, DisplayData } from '@/Pages/bukken/chintai/DetailPartial/DetailGrid';
import { SectionHeader } from '@/Pages/bukken/chintai/DetailPartial/SectionHeaders';

type OverviewProps = ChinshakuBukkenDetailProps & {
  isLand: boolean;
  showMap: () => void;
};

export const Overview: FC<OverviewProps> = props => {
  const overviewData = useMemo<DisplayData[]>(
    () =>
      !props.isLand
        ? calcNormalOverviewData(
            props.chinshakuBukken,
            props.tatemono,
            props.kukaku,
            props.showMap,
            props.isCustomerView
          )
        : calcTochiOverviewData(props.chinshakuBukken, props.tatemono, props.showMap),
    [props.chinshakuBukken, props.tatemono, props.kukaku, props.showMap, props.isCustomerView, props.isLand]
  );
  return (
    <Box pt={2}>
      <SectionHeader>物件概要</SectionHeader>
      <DetailGrid data={overviewData} />
      {!props.isLand && (
        <Box pt={0.5}>
          <Typography variant="subtitle1">{serviceRoomAnnotation}</Typography>
        </Box>
      )}
    </Box>
  );
};
