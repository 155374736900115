import { useIsSmallDevice } from '@lib/components';
import { Stack, FormGroup, Theme, Typography } from '@mui/material';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import jaLocale from 'date-fns/locale/ja';
import { FC, useContext } from 'react';

import {
  ValidationContext,
  createInvalidErrorMessage,
} from '@/Pages/bukken/chintai/SearchPartial/BukkenSearchForms/SearchConditionValidHooks';

const isInvalidDatePicker = (fromDate: Date | undefined, toDate: Date | undefined, target: Date | undefined) => {
  if (fromDate === undefined && toDate === undefined) return true;
  if (target === undefined) return false;
  return Number.isNaN(target.getTime());
};

type LocalizedDatePickerProps = {
  value: Date | undefined;
  setValue: (value: Date | undefined) => void;
};

export const LocalizedDatePicker: FC<LocalizedDatePickerProps> = props => {
  const isSmallDevice = useIsSmallDevice();
  const handleDateChange = (date: Date | null): void => {
    props.setValue(date ?? undefined);
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={jaLocale}
      localeText={{ cancelButtonLabel: 'キャンセル', clearButtonLabel: 'リセット', okButtonLabel: '決定' }}
    >
      <DatePicker
        slotProps={{
          textField: {
            size: 'small',
          },
          toolbar: {
            toolbarFormat: 'yyyy/MM/dd',
          },
          actionBar: { actions: !isSmallDevice ? [] : ['cancel', 'clear', 'accept'] },
        }}
        value={props.value}
        onChange={handleDateChange}
        format="yyyy/MM/dd"
      />
    </LocalizationProvider>
  );
};

type LocalizedDateRangePickerProps = {
  fromValue: Date | undefined;
  setFromValue: (value: Date | undefined) => void;
  toValue: Date | undefined;
  setToValue: (value: Date | undefined) => void;
  separatorSxProps?: SystemStyleObject<Theme>;
  disabled?: boolean;
};

export const LocalizedDateRangePicker: FC<LocalizedDateRangePickerProps> = props => {
  const isSmallDevice = useIsSmallDevice();
  const handleFromDateChange = (date: Date | null): void => {
    props.setFromValue(date ?? undefined);
  };
  const handleToDateChange = (date: Date | null): void => {
    props.setToValue(date ?? undefined);
  };

  const isSearchConditionInvalid = useContext(ValidationContext);

  return (
    <FormGroup>
      <Stack direction="row" alignItems="center" gap={1}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={jaLocale}
          localeText={{ cancelButtonLabel: 'キャンセル', clearButtonLabel: 'リセット', okButtonLabel: '決定' }}
        >
          <DatePicker
            data-testclass="calendarShousaiSearch"
            slotProps={{
              textField: {
                size: 'small',
                error: isInvalidDatePicker(props.fromValue, props.toValue, props.fromValue),
              },
              toolbar: {
                toolbarFormat: 'yyyy/MM/dd',
              },
              actionBar: { actions: !isSmallDevice ? [] : ['cancel', 'clear', 'accept'] },
            }}
            localeText={{
              toolbarTitle: '開始日',
            }}
            disabled={props.disabled ?? false}
            value={props.fromValue ?? null}
            onChange={handleFromDateChange}
            format="yyyy/MM/dd"
            label="開始日"
            sx={{ width: isSmallDevice ? '10rem' : '11rem' }}
          />
          ～
          <DatePicker
            data-testclass="calendarShousaiSearch"
            slotProps={{
              textField: {
                size: 'small',
                error: isInvalidDatePicker(props.fromValue, props.toValue, props.toValue),
              },
              toolbar: {
                toolbarFormat: 'yyyy/MM/dd',
              },
              actionBar: { actions: !isSmallDevice ? [] : ['cancel', 'clear', 'accept'] },
            }}
            localeText={{
              toolbarTitle: '終了日',
            }}
            disabled={props.disabled ?? false}
            value={props.toValue ?? null}
            onChange={handleToDateChange}
            format="yyyy/MM/dd"
            label="終了日"
            sx={{ width: isSmallDevice ? '10rem' : '11rem' }}
          />
        </LocalizationProvider>
      </Stack>
      {isSearchConditionInvalid && (
        <Typography color="red" fontSize="12px" pt="4px">
          {createInvalidErrorMessage(props.fromValue, props.toValue)}
        </Typography>
      )}
    </FormGroup>
  );
};
