import { Box } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { FC } from 'react';

import { getTotalPageCount } from '@/Utils/SearchResultUtils';

type OwnBukkenSearchResultFooterContainerProps = {
  itemsPerPage: number | undefined;
  totalCount: number | undefined;
  currentPage: number;
  onChangePage: (page: number) => void;
};

const OwnBukkenSearchResultFooterContainer: FC<OwnBukkenSearchResultFooterContainerProps> = ({
  itemsPerPage,
  totalCount,
  currentPage,
  onChangePage,
}) => {
  const totalPageCount = getTotalPageCount(totalCount, itemsPerPage);

  const handlePageChange = (event: unknown, value: number): void => {
    onChangePage(value);
  };

  return (
    <OwnBukkenSearchResultFooterPresenter
      totalPageCount={totalPageCount}
      handlePageChange={handlePageChange}
      currentPage={currentPage}
    />
  );
};

export { OwnBukkenSearchResultFooterContainer as OwnBukkenSearchResultFooter };

type OwnBukkenSearchResultFooterPresenterProps = {
  totalPageCount: number | undefined;
  handlePageChange: (event: unknown, page: number) => void;
  currentPage: number;
};
export const OwnBukkenSearchResultFooterPresenter: FC<OwnBukkenSearchResultFooterPresenterProps> = ({
  totalPageCount,
  handlePageChange,
  currentPage,
}) => {
  return (
    <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" p={1}>
      <Pagination count={totalPageCount} onChange={handlePageChange} page={currentPage} shape="rounded" size="medium" />
    </Box>
  );
};
