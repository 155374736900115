import { asBaitaiCode, baitaiCodeToText, BaitaiCodeType } from '@app/enums';
import { ChinshakuBoshuJokenViewTensai } from '@app/models';
import { isNotNullOrUndefined } from '@app/utils';
import { Tag } from '@e-seikatsu/design-system';
import { Box, Tooltip, Typography } from '@mui/material';
import { FC, Fragment } from 'react';

import { textSxProps } from '@/Hooks/Styles/TextStyle';

const getAllMediaAllowed = (tensaiArray: ChinshakuBoshuJokenViewTensai[]): boolean => {
  if (tensaiArray.length === 0) {
    return false;
  }
  return tensaiArray[0].baitai_code === 100;
};

type TensaiTooltipContentProps = {
  tensaiArray: ChinshakuBoshuJokenViewTensai[];
};

export const BukkenTagList: FC<{
  isCustomerView: boolean;
  isNew: boolean;
  isChushajo: boolean;
  isTochi: boolean;
  isApplied: boolean;
  isADExists: boolean;
  isShinchiku: boolean;
  isGenteiKokai: boolean;
  tensaiArray: ChinshakuBoshuJokenViewTensai[];
}> = ({
  isCustomerView,
  isNew,
  isChushajo,
  isTochi,
  isApplied,
  isADExists,
  isShinchiku,
  isGenteiKokai,
  tensaiArray,
}) => {
  return (
    <Box display="flex" gap="4px" flexWrap="wrap" sx={{ ...textSxProps.sm, ...textSxProps.bold }}>
      {isNew ? (
        <TagWrapper>
          <Tag label="New" variant="strong" color="error" key="isNew" />
        </TagWrapper>
      ) : null}
      {isChushajo ? (
        <TagWrapper>
          <Tag label="駐車場等" variant="soft" color="success" key="isChushajo" />
        </TagWrapper>
      ) : null}
      {isTochi ? (
        <TagWrapper>
          <Tag label="土地" variant="soft" color="success" key="isTochi" />
        </TagWrapper>
      ) : null}
      {isApplied ? (
        <TagWrapper>
          <Tag label="申込あり" variant="soft" color="error" key="isApplied" />
        </TagWrapper>
      ) : null}
      {!isCustomerView && isADExists ? (
        <TagWrapper>
          <Tag label="ADあり" variant="soft" color="success" key="adExists" />
        </TagWrapper>
      ) : null}
      {isShinchiku ? (
        <TagWrapper>
          <Tag label="新築" variant="soft" color="success" key="isShinchiku" />
        </TagWrapper>
      ) : null}
      {!isCustomerView && tensaiArray.length > 0 ? (
        <Tooltip title={<TensaiTooltipContent tensaiArray={tensaiArray} />} arrow>
          <div>
            <TagWrapper>
              <Tag
                label={getAllMediaAllowed(tensaiArray) ? '広告可' : '広告可※'}
                variant="soft"
                color="success"
                key="canAdvertise"
              />
            </TagWrapper>
          </div>
        </Tooltip>
      ) : null}
      {isGenteiKokai ? (
        <TagWrapper>
          <Tag label="限定公開" variant="soft" color="success" key="isGenteiKokai" />
        </TagWrapper>
      ) : null}
    </Box>
  );
};

const TagWrapper = ({ children }: { children: React.ReactNode }) => <Box>{children}</Box>;

// Oneクライアントのコード上において、baitai_config.xmlの媒体定義のうちtensai_enable=falseの媒体は広告可能媒体一覧の表示からは排除
const hiddenBaitaiCodeInTensaiTooltip: readonly BaitaiCodeType[] = [
  103, 104, 131, 133, 134, 135, 136, 138, 140, 142, 143, 144, 145,
];
const hiddenBaitaiPartialNameInTensaiTooltip = '賃貸標準形式';

const TensaiTooltipContent: FC<TensaiTooltipContentProps> = props => {
  if (props.tensaiArray.length === 0) {
    return null;
  }
  if (props.tensaiArray[0].baitai_code === 100) {
    return (
      <Typography variant="subtitle1" sx={textSxProps.bold}>
        全ての媒体に掲載可能です
      </Typography>
    );
  }
  return (
    <Fragment>
      <Typography variant="subtitle1" sx={textSxProps.bold}>
        以下の媒体に掲載可能です
      </Typography>
      {props.tensaiArray
        .filter(it => {
          const baitaiCode = asBaitaiCode(it.baitai_code);
          return isNotNullOrUndefined(baitaiCode) && !hiddenBaitaiCodeInTensaiTooltip.includes(baitaiCode);
        })
        .map(it => {
          const baitaiName = baitaiCodeToText(asBaitaiCode(it.baitai_code));
          if (baitaiName.length === 0 || baitaiName.includes(hiddenBaitaiPartialNameInTensaiTooltip)) {
            return null;
          }
          return (
            <Typography key={it.baitai_code} variant="body2">
              ・{baitaiName}
            </Typography>
          );
        })}
    </Fragment>
  );
};
