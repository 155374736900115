import { Button, Badge } from '@e-seikatsu/design-system';
import { Box } from '@mui/material';
import { FC, useContext } from 'react';

import { useIsSmallDevice } from '@/Hooks/Styles/IsSmallDevice';
import { ValidationContext } from '@/Pages/bukken/chintai/SearchPartial/BukkenSearchForms/SearchConditionValidHooks';

type PrefectureSelectorFooterProps = {
  selectedPrefs: readonly number[];
  onNext: () => void;
  transitToRegion: () => void;
};

export const PrefectureSelectorFooter: FC<PrefectureSelectorFooterProps> = props => {
  const isSearchConditionInvalid = useContext(ValidationContext);
  const isSmallDevice = useIsSmallDevice();

  return (
    <Box textAlign="right" display="flex" columnGap={1}>
      <Badge invisible={!isSearchConditionInvalid} color="red" value="!" position="outsider">
        <Button
          onClick={props.transitToRegion}
          variant="outlined"
          color="primary"
          size={!isSmallDevice ? 'medium' : 'small'}
        >
          詳細条件を入力
        </Button>
      </Badge>
      <Button
        onClick={props.onNext}
        disabled={props.selectedPrefs.length === 0}
        variant="contained"
        color="primary"
        size={!isSmallDevice ? 'medium' : 'small'}
      >
        路線を選択
      </Button>
    </Box>
  );
};
