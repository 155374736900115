import { ChinshakuBukken, Tatemono } from '@app/models';
import { isNotNullOrEmptyString } from '@app/utils';

import { isBukkenOneHouse } from './Bukken';

export const makeKukakuName = (chinshakuBukken: ChinshakuBukken, tatemono: Tatemono, kukakuNumber: string): string => {
  const b2bBoshuJoken = chinshakuBukken.chinshaku_boshu_joken?.chinshaku_b2b_boshu_joken;
  const oneHouseText = isBukkenOneHouse(chinshakuBukken, tatemono) ? ' (一棟)' : '';
  if (b2bBoshuJoken?.b2b_tatemono_name_hyoji_flag) {
    const tatemonoName = `${tatemono.tatemono_name ?? ''}${
      isNotNullOrEmptyString(kukakuNumber) && b2bBoshuJoken?.b2b_heya_bango_hyoji_flag ? ` ${kukakuNumber}` : ''
    }`;
    return `${tatemonoName}${oneHouseText}`;
  }
  const nokoriJusho = b2bBoshuJoken?.b2b_eda_bango_hyoji_flag
    ? `${tatemono.nokori_jusho ?? ''}${tatemono.gaiku_goto ?? ''}`
    : '';
  const jushoFullText = `${tatemono.jusho_text ?? ''}${nokoriJusho}`;
  return isNotNullOrEmptyString(jushoFullText)
    ? `${jushoFullText}${oneHouseText} の物件情報`
    : `${oneHouseText} 物件情報`;
};
