import { SpecBukkenView, ChinshakuBukken } from '@app/models';

export const getMotozukeGyosha = (
  bukkenOrBukkenView: SpecBukkenView | ChinshakuBukken
): {
  motozukeGyoshaGuid: string | null | undefined;
  motozukeGyoshaResourceType: string | null | undefined;
    } => {
  if ('chinshaku_boshu_joken_view' in bukkenOrBukkenView) {
    return {
      motozukeGyoshaGuid: bukkenOrBukkenView.chinshaku_boshu_joken_view.motozuke_gyosha_guid,
      motozukeGyoshaResourceType: bukkenOrBukkenView.chinshaku_boshu_joken_view.motozuke_gyosha_resource_type,
    };
  }
  if ('chinshaku_boshu_joken' in bukkenOrBukkenView) {
    return {
      motozukeGyoshaGuid:
        bukkenOrBukkenView.chinshaku_boshu_joken?.chinshaku_boshu_joken_motozuke?.motozuke_gyosha_guid,
      motozukeGyoshaResourceType:
        bukkenOrBukkenView.chinshaku_boshu_joken?.chinshaku_boshu_joken_motozuke?.motozuke_gyosha_resource_type,
    };
  }
  return { motozukeGyoshaGuid: '', motozukeGyoshaResourceType: '' };
};
