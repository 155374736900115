import { deepCompare } from '@app/utils';
import { RadioButton } from '@e-seikatsu/design-system';
import { FormGroup, Grid, Box, Stack } from '@mui/material';
import { memo, ChangeEvent, FC } from 'react';

import { searchConditionSxProps } from './SearchConditionStyle';

import { LocalizedDateRangePicker } from '@/Components/Forms/LocalizedDatePicker';
import { reportError } from '@/ErrorLogger';
import { SearchConditionHook } from '@/Hooks/Search/SearchConditionHook';
import { borderSxProps } from '@/Hooks/Styles/BorderStyle';
import {
  asBukkenDateRangeRadioState,
  BukkenDateRangeRadioLabelMap,
  BukkenDateRangeRadioState,
} from '@/Models/SearchConditions/ChintaiSearchConditionEnums/ChintaiSearchConditionEnums';
import { ChintaiSearchConditions, KokaiDateConditions } from '@/Models/SearchConditions/ChintaiSearchConditions';

const reportPlace = '@/Pages/bukken/chintai/SearchPartial/BukkenSearchForms/BukkenKokaiDateForm';
const uninitializedErrorMessage = 'KokaiDateConditions is uninitialized';

type BukkenKokaiDateFormContainerProps = {
  hook: SearchConditionHook<KokaiDateConditions, ChintaiSearchConditions>;
  isSmallDevice: boolean;
};

const BukkenKokaiDateFormContainer: FC<BukkenKokaiDateFormContainerProps> = memo(({ hook, isSmallDevice }) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newValue = asBukkenDateRangeRadioState(event.target.value) ?? BukkenDateRangeRadioState.unselected;
    if (hook.value === undefined) {
      reportError(uninitializedErrorMessage, reportPlace, true);
      return;
    }
    hook.setValue({ ...hook.value, kokaiRadioState: newValue });
  };
  const setFromValue = (kokaiDateFrom: Date | undefined): void => {
    if (hook.value === undefined) {
      reportError(uninitializedErrorMessage, reportPlace, true);
      return;
    }
    hook.setValue({ ...hook.value, kokaiDateFrom });
  };
  const setToValue = (kokaiDateTo: Date | undefined): void => {
    if (hook.value === undefined) {
      reportError(uninitializedErrorMessage, reportPlace, true);
      return;
    }
    hook.setValue({ ...hook.value, kokaiDateTo });
  };

  return (
    <BukkenKokaiDateFormPresenter
      isSmallDevice={isSmallDevice}
      handleChange={handleChange}
      kokaiRadioState={hook.value?.kokaiRadioState}
      kokaiDateFrom={hook.value?.kokaiDateFrom}
      kokaiDateTo={hook.value?.kokaiDateTo}
      setFromValue={setFromValue}
      setToValue={setToValue}
    />
  );
}, deepCompare);

export type BukkenKokaiDateFormPresenterProps = {
  isSmallDevice: boolean;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  kokaiRadioState: BukkenDateRangeRadioState | undefined;
  kokaiDateFrom: Date | undefined;
  kokaiDateTo: Date | undefined;
  setFromValue: (kokaiDateFrom: Date | undefined) => void;
  setToValue: (kokaiDateTo: Date | undefined) => void;
};

export const BukkenKokaiDateFormPresenter: FC<BukkenKokaiDateFormPresenterProps> = ({
  isSmallDevice,
  handleChange,
  kokaiRadioState,
  kokaiDateFrom,
  kokaiDateTo,
  setFromValue,
  setToValue,
}) => {
  return (
    <Grid
      data-testclass="bukkenKokaiDateForm"
      container
      sx={{ ...searchConditionSxProps.root, ...borderSxProps.borderBottom }}
    >
      <Grid
        item
        xs={12}
        sm={2}
        sx={{ ...searchConditionSxProps.firstCol, ...(isSmallDevice ? borderSxProps.borderBottom : null) }}
      >
        情報公開日
      </Grid>
      <Grid item xs={12} sm={10} sx={{ ...searchConditionSxProps.secondCol, ...searchConditionSxProps.inputCol }}>
        <Stack width="100%">
          <Box display="flex" flexWrap="wrap" gap={1}>
            <RadioButton
              variant="outlined"
              size="small"
              label={BukkenDateRangeRadioLabelMap[BukkenDateRangeRadioState.unselected]}
              value={BukkenDateRangeRadioState.unselected}
              onChange={handleChange}
              checked={kokaiRadioState === BukkenDateRangeRadioState.unselected}
            />
            <RadioButton
              variant="outlined"
              size="small"
              label={BukkenDateRangeRadioLabelMap[BukkenDateRangeRadioState.today]}
              value={BukkenDateRangeRadioState.today}
              onChange={handleChange}
              checked={kokaiRadioState === BukkenDateRangeRadioState.today}
            />
            <RadioButton
              variant="outlined"
              size="small"
              label={BukkenDateRangeRadioLabelMap[BukkenDateRangeRadioState.threeDays]}
              value={BukkenDateRangeRadioState.threeDays}
              onChange={handleChange}
              checked={kokaiRadioState === BukkenDateRangeRadioState.threeDays}
            />
            <RadioButton
              variant="outlined"
              size="small"
              label={BukkenDateRangeRadioLabelMap[BukkenDateRangeRadioState.sevenDays]}
              value={BukkenDateRangeRadioState.sevenDays}
              onChange={handleChange}
              checked={kokaiRadioState === BukkenDateRangeRadioState.sevenDays}
            />
            <RadioButton
              variant="outlined"
              size="small"
              label={BukkenDateRangeRadioLabelMap[BukkenDateRangeRadioState.select]}
              value={BukkenDateRangeRadioState.select}
              onChange={handleChange}
              checked={kokaiRadioState === BukkenDateRangeRadioState.select}
            />
          </Box>
          {kokaiRadioState === BukkenDateRangeRadioState.select && (
            <FormGroup row={true} sx={{ py: 1 }}>
              <LocalizedDateRangePicker
                disabled={kokaiRadioState !== BukkenDateRangeRadioState.select}
                fromValue={kokaiDateFrom}
                setFromValue={setFromValue}
                toValue={kokaiDateTo}
                setToValue={setToValue}
                separatorSxProps={searchConditionSxProps.rangeSeparator}
              />
            </FormGroup>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};
export default BukkenKokaiDateFormContainer;
