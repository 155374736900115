import { isNullOrUndefined } from '@app/utils';
import { useState, useCallback, useEffect } from 'react';
import type SwiperCore from 'swiper';

type SwiperStateHook = {
  index: number;
  onSwiper: (swiperInstance: SwiperCore) => void;
  goNext: () => void;
  goBack: () => void;
};
export const useSwiperState = (): SwiperStateHook => {
  const [index, setIndex] = useState(0);
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const goNext = useCallback(() => swiper?.slideNext(), [swiper]);
  const goBack = useCallback(() => swiper?.slidePrev(), [swiper]);

  useEffect(() => {
    if (isNullOrUndefined(swiper)) {
      return undefined;
    }

    setIndex(swiper.activeIndex);
    swiper.on('slideChange', () => setIndex(swiper.activeIndex));
    return () => swiper.off('slideChange');
  }, [swiper]);

  return {
    index,
    onSwiper: setSwiper,
    goNext,
    goBack,
  };
};
