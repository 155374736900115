import { ChinshakuBukken, Tatemono } from '@app/models';
import { isNotNullOrEmpty, isNotNullOrUndefined } from '@app/utils';
import { Box } from '@mui/material';

import { stringToTableVal } from './CalcNormalOverviewData';

import TransitMapButton from '@/Pages/bukken/chintai/DetailPartial/TransitMapTabButton';
import { makeKotsuText, makeSompoString } from '@/Utils/DisplayText/Bukken';

export type CommonDisplayContent = {
  jusho: JSX.Element;
  kotsu: string | JSX.Element[] | undefined;
  sompo: string | undefined;
  biko: string;
};

export const calcCommonDisplayContent = (
  chinshakuBukken: ChinshakuBukken,
  tatemono: Tatemono,
  showMap: () => void
): CommonDisplayContent => {
  const boshuJoken = chinshakuBukken.chinshaku_boshu_joken;
  const jushoText = isNotNullOrUndefined(tatemono.jusho_text)
    ? `${tatemono.jusho_text}${
        chinshakuBukken.chinshaku_boshu_joken?.chinshaku_b2b_boshu_joken?.b2b_eda_bango_hyoji_flag
          ? `${tatemono.nokori_jusho ?? ''}${tatemono.gaiku_goto ?? ''}`
          : ''
      }`
    : '';
  const jusho = (
    <Box display="flex" alignItems="center" flexWrap="wrap">
      <Box pr={2}>{jushoText}</Box>
      <TransitMapButton onClick={showMap} />
    </Box>
  );
  const kotsu = isNotNullOrEmpty(chinshakuBukken.chinshaku_kotsu)
    ? chinshakuBukken.chinshaku_kotsu?.map((k, i) => <div key={i}>{makeKotsuText(k)}</div>)
    : '-';
  const sompo = stringToTableVal(
    makeSompoString(
      boshuJoken?.sompo_flag ?? false,
      boshuJoken?.sompo_name,
      boshuJoken?.sompo_getsusu,
      boshuJoken?.sompo_ryokin_amount,
      boshuJoken?.sompo_comment
    )
  );
  const pdfComment = chinshakuBukken.chinshaku_es_b2b?.es_b2b_chintai_pdf_comment;
  const biko = isNotNullOrUndefined(pdfComment)
    ? stringToTableVal(pdfComment)
    : stringToTableVal(chinshakuBukken.comment);
  return {
    jusho,
    kotsu,
    sompo,
    biko,
  };
};
