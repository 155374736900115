/*  フォームのコンポーネントをまとめたコンポーネント */
import { isNotNullOrUndefined } from '@app/utils';
import { Button, Icon, IconButton } from '@e-seikatsu/design-system';
import {
  Box,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Tooltip,
  Stack,
} from '@mui/material';
import { FC, useContext, useMemo } from 'react';

import B2bKokaiCodeForm from './BukkenSearchForms/B2bKokaiCodeForm';
import BukkenADForm from './BukkenSearchForms/BukkenADForm';
import BukkenChikuNensuForm from './BukkenSearchForms/BukkenChikuNensuForm';
import BukkenChinryoForm from './BukkenSearchForms/BukkenChinryoForm';
import BukkenEkitohoForm from './BukkenSearchForms/BukkenEkitohoForm';
import BukkenGazoForm from './BukkenSearchForms/BukkenGazoForm';
import BukkenGenkyoForm from './BukkenSearchForms/BukkenGenkyoForm';
import BukkenKodawariJokenForm from './BukkenSearchForms/BukkenKodawariJokenForm';
import BukkenKokaiDateForm from './BukkenSearchForms/BukkenKokaiDateForm';
import BukkenKoshinDateForm from './BukkenSearchForms/BukkenKoshinDateForm';
import BukkenKozoForm from './BukkenSearchForms/BukkenKozoForm';
import BukkenMadoriForm from './BukkenSearchForms/BukkenMadoriForm';
import BukkenMototsukeGyoshaForm from './BukkenSearchForms/BukkenMototsukeGyoshaForm';
import BukkenNameForm from './BukkenSearchForms/BukkenNameForm';
import BukkenNameKanaForm from './BukkenSearchForms/BukkenNameKanaForm';
import BukkenNyukyoKanoDateForm from './BukkenSearchForms/BukkenNyukyoKanoDateForm';
import BukkenSennyuMensekiForm from './BukkenSearchForms/BukkenSennyuMenskiForm';
import BukkenShubetsuForm from './BukkenSearchForms/BukkenShubetsuForm';
import BukkenStateForm from './BukkenSearchForms/BukkenStateForm';
import BukkenSyokiHiyoForm from './BukkenSearchForms/BukkenSyokiHiyoForm';
import { HeyaNumberForm } from './BukkenSearchForms/HeyaNumberForm';
import { HeaderText } from './BukkenSearchForms/RegionConditionPartial/HeaderText';
import { dialogSxProps } from './BukkenSearchForms/SearchConditionStyle';
import { ValidationContext } from './BukkenSearchForms/SearchConditionValidHooks';
import { MapSearchProps, RegionSearchProps, SearchType } from './SearchProps';

import {
  ChinryoSearchConditionHook,
  GazoSearchConditionHook,
  ListSearchConditionHook,
  SelectSearchConditionHook,
  ShikikinReikinConditionHook,
} from '@/Hooks/Search/CustomSearchCondition';
import { SearchConditionHook } from '@/Hooks/Search/SearchConditionHook';
import { borderSxProps } from '@/Hooks/Styles/BorderStyle';
import { useIsSmallDevice } from '@/Hooks/Styles/IsSmallDevice';
import { textSxProps } from '@/Hooks/Styles/TextStyle';
import { useCustomerViewStateHooks } from '@/Hooks/customerViewHooks';
import {
  KozoConditions,
  MadoriConditions,
  NaikenConditions,
  SearchBoshuShubetsuConditions,
  GenkyoConditions,
} from '@/Models/SearchConditions/ChintaiSearchConditionEnums/ChintaiSearchConditionEnums';
import { KodawariConditions } from '@/Models/SearchConditions/ChintaiSearchConditionEnums/ChintaiSearchKodawariConditionEnums';
import {
  B2bKokaiCodeCondition,
  ChintaiSearchConditions,
  KokaiDateConditions,
  KoshinDateConditions,
  LineSearchCondition,
  NyukyoKanoDateConditions,
  StationSearchCondition,
} from '@/Models/SearchConditions/ChintaiSearchConditions';

export type DetailSearchConditionHooks = {
  bukkenNameCondition: SearchConditionHook<string, ChintaiSearchConditions>;
  bukkenNameKanaCondition: SearchConditionHook<string, ChintaiSearchConditions>;
  mensekiFromCondition: SearchConditionHook<string, ChintaiSearchConditions>;
  mensekiToCondition: SearchConditionHook<string, ChintaiSearchConditions>;
  boshuShubetsuCondition: ListSearchConditionHook<SearchBoshuShubetsuConditions, ChintaiSearchConditions>;
  chinryoCondition: ChinryoSearchConditionHook<ChintaiSearchConditions>;
  shikikinReikinCondition: ShikikinReikinConditionHook<ChintaiSearchConditions>;
  madoriCondition: ListSearchConditionHook<MadoriConditions, ChintaiSearchConditions>;
  genkyoCondition: ListSearchConditionHook<GenkyoConditions, ChintaiSearchConditions>;
  chikuNensuCondition: SearchConditionHook<string, ChintaiSearchConditions>;
  isShinchikuCondition: SearchConditionHook<boolean, ChintaiSearchConditions>;
  kozoCondition: ListSearchConditionHook<KozoConditions, ChintaiSearchConditions>;
  kokokuyroKagetsuFromCondition: SearchConditionHook<string, ChintaiSearchConditions>;
  kotsuEkitohoCondition: SearchConditionHook<string, ChintaiSearchConditions>;
  kokaiCondition: SearchConditionHook<KokaiDateConditions, ChintaiSearchConditions>;
  bukkenKokaiScopeCondition: SearchConditionHook<B2bKokaiCodeCondition, ChintaiSearchConditions>;
  koshinDateCondition: SearchConditionHook<KoshinDateConditions, ChintaiSearchConditions>;
  nyukyoKanoDateCondition: SearchConditionHook<NyukyoKanoDateConditions, ChintaiSearchConditions>;
  naikenCondition: SelectSearchConditionHook<NaikenConditions, ChintaiSearchConditions>;
  kanriGyoshaCondition: SearchConditionHook<string, ChintaiSearchConditions>;
  kodawariCondition: ListSearchConditionHook<KodawariConditions, ChintaiSearchConditions>;
  heyaNumberCondition: SearchConditionHook<string, ChintaiSearchConditions>;
  isExcludeMoshikomiExistCondition: SearchConditionHook<boolean, ChintaiSearchConditions>;
  isSokunyukyoEnableCondition: SearchConditionHook<boolean, ChintaiSearchConditions>;
  gazoSearchCondition: GazoSearchConditionHook<ChintaiSearchConditions>;
};

type DetailFormsContentProps = {
  onSearch: () => void;
  hooks: DetailSearchConditionHooks;
  isEkitohoEnable: boolean;
  isSmallDevice: boolean;
  searchType: SearchType;
};

const DetailFormsMainContent: FC<DetailFormsContentProps> = ({
  hooks,
  onSearch,
  isEkitohoEnable,
  isSmallDevice,
  searchType,
}) => {
  const customerViewStateHooks = useCustomerViewStateHooks();
  return (
    <Stack gap={2}>
      <Stack gap={1}>
        <HeaderText fixedText="基本条件" isSmallDevice={isSmallDevice} />
        <Box sx={borderSxProps.borderTop}>
          <BukkenNameForm
            hook={hooks.bukkenNameCondition}
            onEnter={onSearch}
            isSmallDevice={isSmallDevice}
            focusOnOpen={true}
          />
          <BukkenNameKanaForm
            hook={hooks.bukkenNameKanaCondition}
            onEnter={onSearch}
            isSmallDevice={isSmallDevice}
            focusOnOpen={false}
          />
          <HeyaNumberForm hook={hooks.heyaNumberCondition} onEnter={onSearch} isSmallDevice={isSmallDevice} />
          {!customerViewStateHooks.isCustomerView && (
            <BukkenMototsukeGyoshaForm
              hook={hooks.kanriGyoshaCondition}
              onEnter={onSearch}
              isSmallDevice={isSmallDevice}
            />
          )}
          <BukkenKokaiDateForm hook={hooks.kokaiCondition} isSmallDevice={isSmallDevice} />
          <BukkenKoshinDateForm hook={hooks.koshinDateCondition} isSmallDevice={isSmallDevice} />
          <BukkenNyukyoKanoDateForm hook={hooks.nyukyoKanoDateCondition} isSmallDevice={isSmallDevice} />
          <BukkenShubetsuForm hook={hooks.boshuShubetsuCondition} isSmallDevice={isSmallDevice} />
          <BukkenKozoForm hook={hooks.kozoCondition} isSmallDevice={isSmallDevice} />
          <BukkenChinryoForm chinryoHook={hooks.chinryoCondition} isSmallDevice={isSmallDevice} />
          <BukkenSyokiHiyoForm hook={hooks.shikikinReikinCondition} isSmallDevice={isSmallDevice} />
          <BukkenMadoriForm hook={hooks.madoriCondition} isSmallDevice={isSmallDevice} />
          <BukkenSennyuMensekiForm
            fromHook={hooks.mensekiFromCondition}
            toHook={hooks.mensekiToCondition}
            isSmallDevice={isSmallDevice}
          />
          {searchType === SearchType.region && (
            <BukkenEkitohoForm
              ekitohoHook={hooks.kotsuEkitohoCondition}
              isEnable={isEkitohoEnable}
              isSmallDevice={isSmallDevice}
            />
          )}
          <BukkenGenkyoForm hook={hooks.genkyoCondition} isSmallDevice={isSmallDevice} />
          <BukkenChikuNensuForm
            chikuNensuHook={hooks.chikuNensuCondition}
            isShinchikuHook={hooks.isShinchikuCondition}
            isSmallDevice={isSmallDevice}
          />
          {!customerViewStateHooks.isCustomerView && (
            <BukkenADForm fromHook={hooks.kokokuyroKagetsuFromCondition} isSmallDevice={isSmallDevice} />
          )}
          <BukkenStateForm
            naikenConditionHook={hooks.naikenCondition}
            isExcludeMoshikomiExistCondition={hooks.isExcludeMoshikomiExistCondition}
            isSokunyukyoEnableCondition={hooks.isSokunyukyoEnableCondition}
            isSmallDevice={isSmallDevice}
          />
          <B2bKokaiCodeForm hook={hooks.bukkenKokaiScopeCondition} isSmallDevice={isSmallDevice} />
          <BukkenGazoForm hook={hooks.gazoSearchCondition} isSmallDevice={isSmallDevice} />
        </Box>
      </Stack>
      <Stack gap={1}>
        <HeaderText fixedText="その他条件" isSmallDevice={isSmallDevice} />
        <BukkenKodawariJokenForm hook={hooks.kodawariCondition} isSmallDevice={isSmallDevice} />
      </Stack>
    </Stack>
  );
};

type DetailFormsFooterContentProps = {
  onClickSearchButton: () => void;
  transitToRegion?: () => void;
};

export const DetailFormsFooterContent: FC<DetailFormsFooterContentProps> = props => {
  const isSearchConditionInvalid = useContext(ValidationContext);
  const isSmallDevice = useIsSmallDevice();

  return (
    <Box textAlign="right" display="flex" gap={1}>
      {isNotNullOrUndefined(props.transitToRegion) && (
        <Button
          onClick={props.transitToRegion}
          variant="outlined"
          color="primary"
          size={!isSmallDevice ? 'medium' : 'small'}
        >
          エリア・沿線を選択
        </Button>
      )}
      <Tooltip title={isSearchConditionInvalid ? '入力項目にエラーがあります' : ''} placement="top">
        <Box minWidth="60px">
          <Button
            disabled={isSearchConditionInvalid}
            onClick={props.onClickSearchButton}
            variant="contained"
            color="primary"
            size={!isSmallDevice ? 'medium' : 'small'}
            fullWidth
          >
            検索
          </Button>
        </Box>
      </Tooltip>
    </Box>
  );
};

type DetailConditionFormsProps = {
  isOpen: boolean;
  closeDialog: () => void;
  onClickSearchButton: () => void;
  hooks: DetailSearchConditionHooks;
  searchTypeProps: RegionSearchProps | MapSearchProps;
  normalSearchProps?: NormalSearchProps;
};

type NormalSearchProps = {
  linesCondition: readonly Readonly<LineSearchCondition>[];
  stationsCondition: readonly Readonly<StationSearchCondition>[];
};

const DetailConditionForms: FC<DetailConditionFormsProps> = props => {
  const isSmallDevice = useIsSmallDevice();

  // 駅もしくは路線が指定されていなければ駅徒歩時間は指定不可
  const isEkitohoEnable = useMemo(() => {
    if (props.searchTypeProps.type === SearchType.region && isNotNullOrUndefined(props.normalSearchProps)) {
      return (
        (props.normalSearchProps.stationsCondition !== undefined &&
          props.normalSearchProps.stationsCondition.length > 0) ||
        (props.normalSearchProps.linesCondition !== undefined && props.normalSearchProps.linesCondition.length > 0)
      );
    } else {
      return false;
    }
  }, [props.normalSearchProps, props.searchTypeProps.type]);

  const onClear = (): void => {
    const conditionHooks = Object.values(props.hooks);
    conditionHooks.forEach(it => {
      it.clear();
    });
  };
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.closeDialog}
      scroll="paper"
      fullScreen={isSmallDevice}
      sx={{
        [`& .${dialogClasses.paper}`]: {
          maxWidth: {
            xs: undefined,
            md: '984px',
          },
          width: {
            xs: undefined,
            md: '984px',
          },
        },
      }}
    >
      <DialogTitle sx={dialogSxProps.dialogTitle({ isMobile: isSmallDevice })}>
        <Grid container alignContent="center" alignItems="center" height="48px">
          <Grid item xs={8}>
            <Box display="flex" justifyContent="flex-start" alignItems="center" gap={1} pl={isSmallDevice ? 2 : 0}>
              <Icon iconName="search" size="large" color="primary" />
              <Typography variant="h6" fontWeight="bold" sx={{ ...textSxProps.lg }}>
                詳細条件
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" justifyContent="flex-end" alignItems="center" gap={1}>
              <Button onClick={onClear} variant="text" color="black">
                クリア
              </Button>
              <IconButton
                onClick={props.closeDialog}
                data-testid="closeDetailConditionForms"
                icon="close"
                variant="iconOnly"
                size="medium"
                color="black"
              />
            </Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={dialogSxProps.dialogContent({ isMobile: isSmallDevice })} dividers>
        <DetailFormsMainContent
          hooks={props.hooks}
          isEkitohoEnable={isEkitohoEnable}
          onSearch={props.onClickSearchButton}
          isSmallDevice={isSmallDevice}
          searchType={props.searchTypeProps.type}
        />
      </DialogContent>
      <DialogActions sx={dialogSxProps.dialogFooter({ isMobile: isSmallDevice })}>
        <DetailFormsFooterContent
          onClickSearchButton={props.onClickSearchButton}
          transitToRegion={
            props.searchTypeProps.type === SearchType.region ? props.searchTypeProps.transitToRegion : undefined
          }
        />
      </DialogActions>
    </Dialog>
  );
};

export default DetailConditionForms;
