import { dequal } from 'dequal';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { createGlobalState } from 'react-use';

import { useConditions } from './ConditionHook';
import { usePage } from './PageHook';

import { useDependency } from '@/Hooks/DependencyHook';
import { ChintaiBukkenAsyncList } from '@/Models/ChintaiBukkenSearcher';
import { getChintaiSearchPageQuery } from '@/Models/SearchConditions/ChintaiBukkenSearchConditions';
import { ChintaiResultOrder } from '@/Models/SearchConditions/ChintaiSearchConditionEnums/ChintaiSearchConditionEnums';
import {
  ChintaiAsyncListHook,
  ChintaiSearchConditions,
  SearchListCache,
} from '@/Models/SearchConditions/ChintaiSearchConditions';
import { SearchPageLocationState } from '@/Pages/bukken/chintai/SearchPageLocationState';

const useChintaiSearchListCache = createGlobalState<SearchListCache>();

export const useOwnBukkenAsyncList = (domainGuid?: string): ChintaiAsyncListHook => {
  const bukkenApiService = useDependency('bukkenApiService');
  const [cache, setCache] = useChintaiSearchListCache();
  const conditions = useConditions();
  const location = useLocation();
  const { currentPage, itemsPerPage, setPage, setItemsPerPage, setSearch, pushPageHistory } =
    usePage<SearchPageLocationState>();

  useEffect(() => {
    pushPageHistory(location.search, currentPage, itemsPerPage);
  }, [currentPage, itemsPerPage, location.search, pushPageHistory]);

  const setAllCondition = useCallback(
    (newConditions: Readonly<ChintaiSearchConditions>) =>
      setSearch(getChintaiSearchPageQuery(newConditions), {
        cacheTimestamp: Date.now(),
      }),
    [setSearch]
  );

  const setCondition = useCallback(
    (newConditions: Readonly<ChintaiSearchConditions>) =>
      setAllCondition({
        ...newConditions,
        order: conditions.order,
      }),
    [conditions.order, setAllCondition]
  );

  const setOrder = useCallback(
    (order: ChintaiResultOrder) =>
      setAllCondition({
        ...conditions,
        order,
      }),
    [conditions, setAllCondition]
  );

  let asyncList: ChintaiBukkenAsyncList | null = null;
  const currentCacheKey: SearchListCache['cacheKey'] = [location.state?.cacheTimestamp, itemsPerPage, conditions];

  if (domainGuid) {
    if (cache && dequal(cache.cacheKey, currentCacheKey)) {
      asyncList = cache.searchList;
    } else {
      asyncList = new ChintaiBukkenAsyncList(
        bukkenApiService,
        { ...conditions, domainGuid: domainGuid ?? '' },
        itemsPerPage
      );
      setCache({
        cacheKey: currentCacheKey,
        searchList: asyncList,
      });
    }
  }

  return {
    asyncList,
    conditions,
    currentPage,
    itemsPerPage,
    setPage,
    setItemsPerPage,
    setOrder,
    setCondition,
  };
};
