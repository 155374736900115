import { deepCompare } from '@app/utils';
import { Box, Grid } from '@mui/material';
import { memo, FC } from 'react';

import BukkenCheckbox from './BukkenCheckbox';
import { searchConditionSxProps } from './SearchConditionStyle';

import { ListSearchConditionHook } from '@/Hooks/Search/CustomSearchCondition';
import { borderSxProps } from '@/Hooks/Styles/BorderStyle';
import {
  KozoConditions,
  KozoConditionsLabelMap,
} from '@/Models/SearchConditions/ChintaiSearchConditionEnums/ChintaiSearchConditionEnums';
import { ChintaiSearchConditions } from '@/Models/SearchConditions/ChintaiSearchConditions';

type BukkenKozoFormProps = {
  hook: ListSearchConditionHook<KozoConditions, ChintaiSearchConditions>;
  isSmallDevice: boolean;
};

const Choices = Object.values(KozoConditions);

const BukkenKozoForm: FC<BukkenKozoFormProps> = memo(({ hook, isSmallDevice }) => {
  return (
    <Grid container sx={{ ...searchConditionSxProps.root, ...borderSxProps.borderBottom }}>
      <Grid
        item
        xs={12}
        sm={2}
        sx={{ ...searchConditionSxProps.firstCol, ...(isSmallDevice ? borderSxProps.borderBottom : undefined) }}
      >
        <div>建物構造</div>
      </Grid>
      <Grid
        item
        xs={12}
        sm={10}
        sx={{
          ...searchConditionSxProps.secondCol,
        }}
      >
        <Box display="flex" flexWrap="wrap" gap={1}>
          {Choices.map(it => {
            return (
              <BukkenCheckbox
                value={hook.value}
                setValue={hook.setValue}
                label={KozoConditionsLabelMap[it]}
                choiceCode={it}
                key={it}
              />
            );
          })}
        </Box>
      </Grid>
    </Grid>
  );
}, deepCompare);

export default BukkenKozoForm;
