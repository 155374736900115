import { dequal } from 'dequal';
import { useCallback, useEffect, useState } from 'react';

import { SearchConditionMapper } from './SearchConditionHook';

import { MapAreaConditions } from '@/Models/SearchConditions/ChintaiSearchConditions';

export type MapAreaSearchConditionHook<C> = {
  value: Required<MapAreaConditions> | null;
  setValue: (value: Required<MapAreaConditions> | null) => void;
  clear: () => void;
} & SearchConditionMapper<C>;

export const useMapAreaCondition = <C>(
  conditionValue: Required<MapAreaConditions> | null,
  conditionSetter: (value: Required<MapAreaConditions> | null, conditions: C) => void
): MapAreaSearchConditionHook<C> => {
  const [value, setValue] = useState<Required<MapAreaConditions> | null>(null);
  const clear = useCallback(() => {
    setValue(null);
  }, []);

  useEffect(() => {
    if (dequal(conditionValue, value)) {
      return;
    }
    setValue(conditionValue);
  }, [conditionValue, value]);
  return {
    value,
    setValue,
    clear,
    mapToConditions: conditionSetter.bind(null, value),
  };
};
