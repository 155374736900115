import { isNullOrUndefined } from '@app/utils';
import { Box, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { FC, ReactNode, MouseEvent as ReactMouseEvent } from 'react';

type LocalSxProps = 'image' | 'cursorPointer' | 'fullwidth' | 'is8rem' | 'is1by1' | 'is4by3' | 'fullHeight' | 'auto';
const localSxProps = {
  image: {
    display: 'block',
    position: 'relative',
    margin: 0,
    '& div, img': {
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    '& img': {
      objectFit: 'contain',
    },
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  fullwidth: {
    width: '100%',
  },
  is8rem: {
    width: '8rem',
  },
  is1by1: {
    paddingTop: '100%',
  },
  is4by3: {
    paddingTop: '75%',
  },
  fullHeight: {
    height: '100%',
  },
  auto: {
    width: 'auto',
    '& img': {
      position: 'relative',
      width: 'auto',
    },
  },
} as const;

type Sizing = 'fullwidth' | 'auto' | '8rem';
type AspectRatio = '1by1' | '4by3' | 'fitToParent';
export type ImageContainerProps = {
  src?: string | null;
  alt?: string;
  sizing?: Sizing;
  aspectRatio?: AspectRatio;
  onClick?: (event: ReactMouseEvent<HTMLElement>) => void;
  sx?: SystemStyleObject<Theme>;
  children: ReactNode;
};

const SizingClassNameMap: { [_ in Sizing]: LocalSxProps } = {
  fullwidth: 'fullwidth',
  auto: 'auto',
  '8rem': 'is8rem',
};

const AspectRatioClassNameMap: { [_ in AspectRatio]: LocalSxProps } = {
  '1by1': 'is1by1',
  '4by3': 'is4by3',
  fitToParent: 'fullHeight',
};

export const ImageContainer: FC<ImageContainerProps> = props => {
  const sizing = props.sizing ?? 'fullwidth';
  const sizingClassSxProps = localSxProps[SizingClassNameMap[sizing]];
  const aspectRatioSxProps =
    sizing === 'auto'
      ? localSxProps.fullHeight
      : localSxProps[AspectRatioClassNameMap[props.aspectRatio ?? 'fitToParent']];
  const cursorClassName = !isNullOrUndefined(props.onClick) ? localSxProps.cursorPointer : null;
  return (
    <Box
      component="figure"
      onClick={props.onClick}
      sx={{
        ...localSxProps.image,
        ...aspectRatioSxProps,
        ...sizingClassSxProps,
        ...cursorClassName,
        ...props.sx,
      }}
    >
      {props.children}
    </Box>
  );
};
