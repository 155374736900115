import { PriceUnitCode } from '@app/enums';
import { isNullOrUndefined } from '@app/utils';
import { SerializedStyles } from '@emotion/react';
import { Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import { DeleteOutline } from 'mdi-material-ui';
import { FC } from 'react';

import {
  InitialCostDisplayData,
  ExtraInitialCostData,
  InitialCostIchijikinReadOnlyType,
  InitialCostCalcType,
  createInitialCostSonotaIchijiDisplayData,
} from './CreateInitialCostDisplayData';
import { InitialCostPriceForm } from './InitialCostPriceForm';

import { theme } from '@/Theme';
import { makePriceText } from '@/Utils/DisplayText/Money';

type InitialCostDisplayIchijikinContainerProps = {
  initialCostCalcData: InitialCostCalcType;
  ichijikinReadOnlyData: InitialCostIchijikinReadOnlyType;
  initialCostDialogStyles: () => SerializedStyles;
  initialCostSonotaIchijiWithLabelDisplayData: ExtraInitialCostData[];
  setInitialCostSonotaIchijiWithLabelDisplayData: (value: React.SetStateAction<ExtraInitialCostData[]>) => void;
  extraInitialCostSonotaIchijiDisplayData: ExtraInitialCostData[];
  setExtraInitialCostSonotaIchijiDisplayData: (value: React.SetStateAction<ExtraInitialCostData[]>) => void;
  handleChangeTotalInitialCostPriceAmount: () => void;
  isSmallDevice: boolean;
};

const InitialCostDisplayIchijikinContainer: FC<InitialCostDisplayIchijikinContainerProps> = ({
  initialCostCalcData,
  ichijikinReadOnlyData,
  initialCostDialogStyles,
  initialCostSonotaIchijiWithLabelDisplayData,
  setInitialCostSonotaIchijiWithLabelDisplayData,
  extraInitialCostSonotaIchijiDisplayData,
  setExtraInitialCostSonotaIchijiDisplayData,
  handleChangeTotalInitialCostPriceAmount,
  isSmallDevice,
}) => {
  const shikikinCode =
    initialCostCalcData.shikikin_kubun_code === PriceUnitCode.actualCost ? PriceUnitCode.actualCost : PriceUnitCode.yen;
  const shikikinText = makePriceText(ichijikinReadOnlyData.shikikin, shikikinCode, {
    emptyIfZero: true,
    emptySubstituteText: '0円',
  });

  const reikinCode =
    initialCostCalcData.reikin_kubun_code === PriceUnitCode.actualCost ? PriceUnitCode.actualCost : PriceUnitCode.yen;
  const reikinText = makePriceText(ichijikinReadOnlyData.reikin, reikinCode, {
    emptyIfZero: true,
    emptySubstituteText: '0円',
  });

  const hoshokinCode =
    initialCostCalcData.hoshokin_kubun_code === PriceUnitCode.actualCost ? PriceUnitCode.actualCost : PriceUnitCode.yen;
  const hoshokinText = makePriceText(ichijikinReadOnlyData.hoshokin, hoshokinCode);

  const sompoText = makePriceText(ichijikinReadOnlyData.sompo, PriceUnitCode.yen, {
    emptyIfZero: true,
    emptySubstituteText: '0円',
  });

  const initialCostIchijiData = createInitialCostSonotaIchijiDisplayData(
    shikikinText,
    reikinText,
    hoshokinText,
    sompoText
  );

  const handleChangeSonotaIchijiWithLabelPrice = (index: number, price: number): void => {
    const newInitialCostSonotaIchijiWithLabelDisplayData = [...initialCostSonotaIchijiWithLabelDisplayData];
    newInitialCostSonotaIchijiWithLabelDisplayData[index].price = price;
    setInitialCostSonotaIchijiWithLabelDisplayData(newInitialCostSonotaIchijiWithLabelDisplayData);
  };

  const handleChangeExtraInitialCostSonotaIchijiLabel = (index: number, label: string): void => {
    const newExtraInitialCostDisplayData = [...extraInitialCostSonotaIchijiDisplayData];
    newExtraInitialCostDisplayData[index].label = label;
    setExtraInitialCostSonotaIchijiDisplayData(newExtraInitialCostDisplayData);
  };
  const handleChangeExtraInitialCostSonotaIchijiPrice = (index: number, price: number): void => {
    const newExtraInitialCostDisplayData = [...extraInitialCostSonotaIchijiDisplayData];
    newExtraInitialCostDisplayData[index].price = price;
    setExtraInitialCostSonotaIchijiDisplayData(newExtraInitialCostDisplayData);
  };
  const removeExtraInitialCostSonotaIchijiInputForm = (index: number): void => {
    const newExtraInitialCostData = [...extraInitialCostSonotaIchijiDisplayData];
    newExtraInitialCostData.splice(index, 1);
    setExtraInitialCostSonotaIchijiDisplayData(newExtraInitialCostData);
  };
  return (
    <InitialCostDisplayIchijikinPresenter
      initialCostIchijiData={initialCostIchijiData}
      initialCostDialogStyles={initialCostDialogStyles}
      initialCostSonotaIchijiWithLabelDisplayData={initialCostSonotaIchijiWithLabelDisplayData}
      handleChangeSonotaIchijiWithLabelPrice={handleChangeSonotaIchijiWithLabelPrice}
      extraInitialCostSonotaIchijiDisplayData={extraInitialCostSonotaIchijiDisplayData}
      handleChangeExtraInitialCostSonotaIchijiLabel={handleChangeExtraInitialCostSonotaIchijiLabel}
      handleChangeExtraInitialCostSonotaIchijiPrice={handleChangeExtraInitialCostSonotaIchijiPrice}
      removeExtraInitialCostSonotaIchijiInputForm={removeExtraInitialCostSonotaIchijiInputForm}
      handleChangeTotalInitialCostPriceAmount={handleChangeTotalInitialCostPriceAmount}
      isSmallDevice={isSmallDevice}
    />
  );
};

export { InitialCostDisplayIchijikinContainer as InitialCostDisplayIchijikin };

type InitialCostDisplayIchijikinPresenterProps = {
  initialCostIchijiData: InitialCostDisplayData[];
  initialCostDialogStyles: () => SerializedStyles;
  initialCostSonotaIchijiWithLabelDisplayData: ExtraInitialCostData[];
  handleChangeSonotaIchijiWithLabelPrice: (index: number, price: number) => void;
  extraInitialCostSonotaIchijiDisplayData: ExtraInitialCostData[];
  handleChangeExtraInitialCostSonotaIchijiLabel: (index: number, label: string) => void;
  handleChangeExtraInitialCostSonotaIchijiPrice: (index: number, price: number) => void;
  removeExtraInitialCostSonotaIchijiInputForm: (index: number) => void;
  handleChangeTotalInitialCostPriceAmount: () => void;
  isSmallDevice: boolean;
};

export const InitialCostDisplayIchijikinPresenter: FC<InitialCostDisplayIchijikinPresenterProps> = ({
  initialCostIchijiData,
  initialCostDialogStyles,
  initialCostSonotaIchijiWithLabelDisplayData,
  handleChangeSonotaIchijiWithLabelPrice,
  extraInitialCostSonotaIchijiDisplayData,
  handleChangeExtraInitialCostSonotaIchijiLabel,
  handleChangeExtraInitialCostSonotaIchijiPrice,
  removeExtraInitialCostSonotaIchijiInputForm,
  handleChangeTotalInitialCostPriceAmount,
  isSmallDevice,
}) => (
  <Grid item xs={12}>
    {initialCostIchijiData.map(data => {
      if (isNullOrUndefined(data.content)) {
        return undefined;
      }
      return (
        <Grid container key={data.label}>
          <Grid item css={[initialCostDialogStyles, { backgroundColor: theme.palette.secondary.light }]} xs={12} md={5}>
            <Typography variant="body1">
              <b>{data.label}</b>
            </Typography>
          </Grid>
          <Grid item css={[initialCostDialogStyles, { textAlign: 'end' }]} xs={12} md={7}>
            {data.content}
          </Grid>
        </Grid>
      );
    })}
    {initialCostSonotaIchijiWithLabelDisplayData.map((data, index) => {
      return (
        <Grid container key={data.label}>
          <Grid item css={[initialCostDialogStyles, { backgroundColor: theme.palette.secondary.light }]} xs={12} md={5}>
            <Typography variant="body1">
              <b>{data.label}</b>
            </Typography>
          </Grid>
          <Grid item css={initialCostDialogStyles} xs={12} md={7}>
            <InitialCostPriceForm
              handleChangeInitialCostPrice={handleChangeSonotaIchijiWithLabelPrice}
              handleChangeTotalInitialCostAmount={handleChangeTotalInitialCostPriceAmount}
              index={index}
              value={data.price === 0 ? '' : data.price.toString()}
              isSmallDevice={isSmallDevice}
            />
          </Grid>
        </Grid>
      );
    })}
    {extraInitialCostSonotaIchijiDisplayData.map((data, index) => {
      if (isNullOrUndefined(data.price)) {
        return undefined;
      }
      return (
        <Grid container key={data.id}>
          <Grid item css={[initialCostDialogStyles, { backgroundColor: theme.palette.secondary.light }]} xs={12} md={5}>
            <Stack direction="row" css={{ justifyContent: 'space-between' }}>
              <TextField
                placeholder="名称"
                variant="standard"
                value={data.label}
                onChange={event => {
                  handleChangeExtraInitialCostSonotaIchijiLabel(index, event.target.value);
                }}
                inputProps={{
                  sx: { fontWeight: 'bold' },
                }}
                css={{
                  [theme.breakpoints.down('md')]: {
                    width: '100%',
                  },
                }}
              />
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() => {
                  handleChangeExtraInitialCostSonotaIchijiPrice(index, 0);
                  removeExtraInitialCostSonotaIchijiInputForm(index);
                  handleChangeTotalInitialCostPriceAmount();
                }}
                css={{ padding: 0 }}
              >
                <DeleteOutline fontSize="small" />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item css={initialCostDialogStyles} xs={12} md={7}>
            <InitialCostPriceForm
              handleChangeInitialCostPrice={handleChangeExtraInitialCostSonotaIchijiPrice}
              handleChangeTotalInitialCostAmount={handleChangeTotalInitialCostPriceAmount}
              index={index}
              value={data.price === 0 ? '' : data.price.toString()}
              isSmallDevice={isSmallDevice}
            />
          </Grid>
        </Grid>
      );
    })}
  </Grid>
);
