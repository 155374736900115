import { isNullOrUndefined } from '@app/utils';
import { Button, IconButton } from '@e-seikatsu/design-system';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Dialog, DialogActions, dialogClasses, DialogContent, DialogTitle, Tab } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useState, FC, Fragment } from 'react';

import { PlaceSelector, PlaceSelectorFooter } from './BukkenSearchForms/PlaceSelector';
import { conditionFormsSxProps, dialogSxProps } from './BukkenSearchForms/SearchConditionStyle';
import { StationAndLineSelector, StationAndLineSelectorFooter } from './BukkenSearchForms/StationAndLineSelector';
import { RegionSearchProps } from './SearchProps';

import { PlaceSearchConditionHook } from '@/Hooks/Search/PlaceSearchCondition';
import {
  PlaceSelectorModes,
  RegionConditionStateHook,
  useRegionConditionStateHook,
} from '@/Hooks/Search/RegionConditionStateHook';
import { LineAndStationSearchConditionHook } from '@/Hooks/Search/StationOrLineSearchCondition';
import { useIsSmallDevice } from '@/Hooks/Styles/IsSmallDevice';
import { ChintaiSearchConditions } from '@/Models/SearchConditions/ChintaiSearchConditions';

export type RegionConditionHooks = {
  placesCondition: PlaceSearchConditionHook<ChintaiSearchConditions>;
  lineAndStationCondition: LineAndStationSearchConditionHook<ChintaiSearchConditions>;
};

type RegionFormsContentProps = {
  hooks: RegionConditionHooks;
  onSearch: () => void;
  regionConditionState: RegionConditionStateHook;
  isDesktop: boolean;
  tabState: string;
  transitToRegion: () => void;
  isOwnBukken: boolean;
};

const RegionFormsContent: FC<RegionFormsContentProps> = props => {
  const clearPlace = useCallback((): void => {
    props.hooks.placesCondition.clear();
    props.regionConditionState.onClearPlace();
  }, [props.hooks.placesCondition, props.regionConditionState]);
  const clearStationAndLine = useCallback((): void => {
    props.hooks.lineAndStationCondition.clear();
    props.regionConditionState.onClearStationAndLine();
  }, [props.hooks.lineAndStationCondition, props.regionConditionState]);
  const isSmallDevice = useIsSmallDevice();

  return (
    <Fragment>
      <DialogContent sx={dialogSxProps.dialogContent({ isMobile: isSmallDevice })} dividers>
        <TabPanel sx={conditionFormsSxProps.tabPanel} value="1">
          <PlaceSelector
            hooks={props.hooks.placesCondition}
            onPlaceSelected={clearStationAndLine}
            regionCondition={props.regionConditionState}
            isOwnBukken={props.isOwnBukken}
          />
        </TabPanel>
        <TabPanel sx={conditionFormsSxProps.tabPanel} value="2">
          <StationAndLineSelector
            lineAndStationHook={props.hooks.lineAndStationCondition}
            onStationAndLineSelected={clearPlace}
            regionCondition={props.regionConditionState}
            isOwnBukken={props.isOwnBukken}
          />
        </TabPanel>
      </DialogContent>
      <DialogActions sx={dialogSxProps.dialogFooter({ isMobile: isSmallDevice })}>
        {props.tabState === '1' ? (
          <PlaceSelectorFooter
            hooks={props.hooks.placesCondition}
            onDetermined={props.onSearch}
            onPlaceSelected={clearStationAndLine}
            regionCondition={props.regionConditionState}
            transitToRegion={props.transitToRegion}
          />
        ) : props.tabState === '2' ? (
          <StationAndLineSelectorFooter
            lineAndStationHook={props.hooks.lineAndStationCondition}
            onDetermined={props.onSearch}
            onStationAndLineSelected={clearPlace}
            regionCondition={props.regionConditionState}
            transitToRegion={props.transitToRegion}
          />
        ) : null}
      </DialogActions>
    </Fragment>
  );
};

type RegionTabState = '1' | '2';

type RegionConditionFormsProps = {
  isOpen: boolean;
  hooks: RegionConditionHooks;
  onSearch: () => void;
  closeDialog: () => void;
  searchTypeProps: RegionSearchProps;
  isOwnBukken: boolean;
};

const RegionConditionForms: FC<RegionConditionFormsProps> = props => {
  const isSmallDevice = useIsSmallDevice();

  const regionConditionState = useRegionConditionStateHook();

  const [tabState, setTabState] = useState<RegionTabState>('1');
  const handleChange = (_: ChangeEvent<unknown>, newValue: string): void => {
    if (newValue === '1' || newValue === '2') {
      setTabState(newValue);
    }
  };

  const onClear = (): void => {
    Object.values(props.hooks).forEach(it => it.clear());
    regionConditionState.onClear();
    setTabState('1');
  };

  useEffect(() => {
    const queryPlacesCondition = props.hooks.placesCondition.queryValue;
    const queryLinesCondition = props.hooks.lineAndStationCondition.queryLines;
    const queryStationsCondition = props.hooks.lineAndStationCondition.queryStations;
    const setPlaceCurrentMode = regionConditionState.setPlaceCurrentMode;
    const setLineCurrentMode = regionConditionState.setLineCurrentMode;

    if (!isNullOrUndefined(queryPlacesCondition) && queryPlacesCondition.length > 0) {
      const newPlaceMode: PlaceSelectorModes = queryPlacesCondition.some(p => p.ooaza.length > 0)
        ? 'ooaza'
        : queryPlacesCondition.some(p => p.city > 0)
        ? 'city'
        : 'pref';
      setTabState('1');
      setLineCurrentMode('pref');
      setPlaceCurrentMode(newPlaceMode);
      return;
    }

    if (!isNullOrUndefined(queryStationsCondition) && queryStationsCondition.length > 0) {
      setTabState('2');
      setPlaceCurrentMode('pref');
      setLineCurrentMode('station');
      return;
    }

    if (!isNullOrUndefined(queryLinesCondition) && queryLinesCondition.length > 0) {
      setTabState('2');
      setPlaceCurrentMode('pref');
      setLineCurrentMode('line');
      return;
    }

    setPlaceCurrentMode('pref');
    setLineCurrentMode('pref');
  }, [
    props.hooks.lineAndStationCondition.queryLines,
    props.hooks.lineAndStationCondition.queryStations,
    props.hooks.placesCondition.queryValue,
    regionConditionState.setLineCurrentMode,
    regionConditionState.setPlaceCurrentMode,
  ]);

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.closeDialog}
      scroll="paper"
      fullScreen={isSmallDevice}
      sx={{
        [`& .${dialogClasses.paper}`]: dialogSxProps.dialogRoot({ isMobile: isSmallDevice }),
      }}
    >
      <TabContext value={tabState}>
        <DialogTitle sx={dialogSxProps.dialogTitle({ isMobile: isSmallDevice })}>
          <Box display="flex">
            <Box flexGrow={1}>
              <TabList onChange={handleChange}>
                <Tab label="エリア" value="1" />
                <Tab label="沿線" value="2" />
              </TabList>
            </Box>
            <Box display="flex" alignItems="center">
              <Button onClick={onClear} variant="text" color="black">
                クリア
              </Button>
              <IconButton
                onClick={props.closeDialog}
                data-testid="closeDetailConditionForms"
                icon="close"
                variant="iconOnly"
                size="medium"
                color="black"
              />
            </Box>
          </Box>
        </DialogTitle>
        <RegionFormsContent
          hooks={props.hooks}
          onSearch={props.onSearch}
          regionConditionState={regionConditionState}
          isDesktop={!isSmallDevice}
          tabState={tabState}
          transitToRegion={props.searchTypeProps.transitToRegion}
          isOwnBukken={props.isOwnBukken}
        />
      </TabContext>
    </Dialog>
  );
};

export default RegionConditionForms;
