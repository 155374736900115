import { range, deepCompare } from '@app/utils';
import { Select, Checkbox } from '@e-seikatsu/design-system';
import { Box, FormGroup, Grid } from '@mui/material';
import { memo, FC, Fragment } from 'react';

import { searchConditionSxProps } from './SearchConditionStyle';

import { ChinryoSearchConditionState } from '@/Hooks/Search/CustomSearchCondition';
import { SearchConditionHook } from '@/Hooks/Search/SearchConditionHook';
import { borderSxProps } from '@/Hooks/Styles/BorderStyle';
import { ChintaiSearchConditions } from '@/Models/SearchConditions/ChintaiSearchConditions';
type BukkenChinryoFormProps = {
  chinryoHook: SearchConditionHook<ChinryoSearchConditionState, ChintaiSearchConditions>;
  isSmallDevice: boolean;
};

const rentValueRange = range(30000, 200000, 5000).concat(
  range(210000, 300000, 100000),
  [400000, 450000, 500000, 1000000]
);

const rentValueToManYen = (rentValue: number): string => {
  return `${rentValue / 10000}万円`;
};

const BukkenChinryoForm: FC<BukkenChinryoFormProps> = memo(({ chinryoHook, isSmallDevice }) => {
  return (
    <Grid container sx={{ ...searchConditionSxProps.root, ...borderSxProps.borderBottom }}>
      <Grid
        item
        xs={12}
        sm={2}
        sx={{ ...searchConditionSxProps.firstCol, ...(isSmallDevice ? borderSxProps.borderBottom : undefined) }}
      >
        賃料
      </Grid>
      <Grid
        item
        xs={12}
        sm={10}
        gap={1}
        sx={{ ...searchConditionSxProps.secondCol, ...searchConditionSxProps.inputCol }}
      >
        <Box width="100%">
          <FormGroup row={true} sx={searchConditionSxProps.formGroupRoot}>
            {isSmallDevice ? (
              <Box display="flex" alignItems="center" width="100%" gap={1}>
                <ChinryoFrom chinryoHook={chinryoHook} isSmallDevice={isSmallDevice} />
                ～
                <ChinryoTo chinryoHook={chinryoHook} isSmallDevice={isSmallDevice} />
              </Box>
            ) : (
              <Fragment>
                <Box display="flex" alignItems="center" gap={1}>
                  <ChinryoFrom chinryoHook={chinryoHook} isSmallDevice={isSmallDevice} />
                  ～
                  <ChinryoTo chinryoHook={chinryoHook} isSmallDevice={isSmallDevice} />
                </Box>
              </Fragment>
            )}
          </FormGroup>
        </Box>
        <FormGroup row={true} sx={searchConditionSxProps.formGroupRoot}>
          <Box display="flex" flexWrap="wrap" gap={1}>
            <Checkbox
              size="small"
              variant="outlined"
              checked={chinryoHook.value?.isIncludeManagementFee}
              label="管理費/共益費込み"
              onChange={(e): void => {
                if (chinryoHook.value === undefined) {
                  return;
                }
                chinryoHook.setValue({ ...chinryoHook.value, isIncludeManagementFee: e.target.checked });
              }}
            />
            <Checkbox
              size="small"
              variant="outlined"
              checked={chinryoHook.value?.isFreeRentAvailable}
              label="フリーレント有り"
              onChange={(e): void => {
                if (chinryoHook.value === undefined) {
                  return;
                }
                chinryoHook.setValue({ ...chinryoHook.value, isFreeRentAvailable: e.target.checked });
              }}
            />
          </Box>
        </FormGroup>
      </Grid>
    </Grid>
  );
}, deepCompare);

type ChinryoFromToProps = {
  chinryoHook: SearchConditionHook<ChinryoSearchConditionState, ChintaiSearchConditions>;
  isSmallDevice: boolean;
};

const ChinryoFrom: FC<ChinryoFromToProps> = props => {
  return (
    <Select
      size="small"
      length={props.isSmallDevice ? 'full' : 'normal'}
      isMaxHeightEnabled={false}
      data-testid="bukkenChinryoForm"
      value={props.chinryoHook.value?.chinryoFrom}
      onChange={(value): void => {
        if (props.chinryoHook.value === undefined) {
          return;
        }
        props.chinryoHook.setValue({ ...props.chinryoHook.value, chinryoFrom: value ?? '' });
      }}
    >
      <Select.Option value="">下限なし</Select.Option>
      {rentValueRange.map(it => (
        <Select.Option value={it.toString()} key={it}>
          {rentValueToManYen(it)}
        </Select.Option>
      ))}
    </Select>
  );
};

const ChinryoTo: FC<ChinryoFromToProps> = props => {
  return (
    <Select
      size="small"
      length={props.isSmallDevice ? 'full' : 'normal'}
      isMaxHeightEnabled={false}
      value={props.chinryoHook.value?.chinryoTo}
      onChange={(value): void => {
        if (props.chinryoHook.value === undefined) {
          return;
        }
        props.chinryoHook.setValue({ ...props.chinryoHook.value, chinryoTo: value ?? '' });
      }}
    >
      <Select.Option value="">上限なし</Select.Option>
      {rentValueRange.map(it => {
        return (
          <Select.Option value={it.toString()} key={it}>
            {rentValueToManYen(it)}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default BukkenChinryoForm;
