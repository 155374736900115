import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Button, ButtonGroup, Tooltip, buttonClasses } from '@mui/material';
import { MenuDown } from 'mdi-material-ui';
import { useRef, useCallback, Fragment } from 'react';
import { useOnOffState } from '../Hooks';
import { MenuWithoutBlock } from './MenuWithoutBlock';
export const SplitButton = props => {
    var _a, _b, _c;
    const [isMenuOpen, { toggle: toggleMenuOpen, setFalse: setMenuClose }] = useOnOffState(false);
    const anchorRef = useRef(null);
    const onMenuButtonClick = useCallback(e => {
        e.stopPropagation();
        toggleMenuOpen();
    }, [toggleMenuOpen]);
    const onMainActionSelected = props.mainAction.onSelected;
    const handleMainButtonClick = useCallback(e => {
        e.stopPropagation();
        onMainActionSelected();
    }, [onMainActionSelected]);
    return (_jsxs(Fragment, { children: [_jsxs(ButtonGroup, Object.assign({ color: (_a = props.color) !== null && _a !== void 0 ? _a : 'primary', variant: props.variant, ref: anchorRef, disabled: props.disabled, sx: { height: '2rem' } }, { children: [_jsx(Tooltip, Object.assign({ title: (_b = props.tooltipTitle) !== null && _b !== void 0 ? _b : '' }, { children: _jsx(Button, Object.assign({ onClick: handleMainButtonClick, startIcon: props.startIcon, sx: {
                                whiteSpace: 'nowrap',
                                [`&.${buttonClasses.root}`]: {
                                    height: '2rem',
                                    paddingLeft: 1,
                                    paddingRight: 1,
                                },
                                [`& .${buttonClasses.startIcon}`]: {
                                    marginRight: 0.5,
                                },
                            } }, { children: props.mainAction.text })) })), _jsx(Button, Object.assign({ size: "small", color: (_c = props.color) !== null && _c !== void 0 ? _c : 'primary', onClick: onMenuButtonClick, sx: {
                            [`&.${buttonClasses.root}`]: { minWidth: '30px', paddingLeft: 0.5, paddingRight: 0.5 },
                        }, "data-testclass": "splitSmallButton" }, { children: _jsx(MenuDown, {}) }))] })), _jsx(MenuWithoutBlock, { isMenuOpen: isMenuOpen, setMenuClose: setMenuClose, menuActions: props.menuActions, anchorEl: anchorRef.current })] }));
};
