import { isNotNullOrUndefined, deepCompare } from '@app/utils';
import { Icon } from '@e-seikatsu/design-system';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Switch,
  Tooltip,
  Typography,
  styled,
  Collapse,
  ListItemButton,
  Badge,
} from '@mui/material';
import { memo, useCallback, useState, FC, Fragment, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { BannerList } from './SideMenuPartial/BannerList';
import { MenuListItem } from './SideMenuPartial/MenuListItem';
import { SavedConditions } from './SideMenuPartial/SavedConditions';

import { NewChip } from '@/Components/NewChip';
import { UserActionTrackFunction, useUserActionTracker } from '@/Hooks/Analytics';
import { useDependency } from '@/Hooks/DependencyHook';
import { usePartnerApplicants } from '@/Hooks/Esa/usePartnerApplicants';
import { useChannels } from '@/Hooks/Messenger/useChannels';
import { textSxProps } from '@/Hooks/Styles/TextStyle';
import { useCustomerViewStateHooks } from '@/Hooks/customerViewHooks';
import { PartnerApplicantsStatusCollection, PartnerApplicantsTypeCollection } from '@/Models/Esa/PartnerApplicants';
import { RelationTypeCollection } from '@/Models/Messenger/Graphql';
import {
  getChintaiSearchPageQuery,
  parseChintaiSearchPageQuery,
} from '@/Models/SearchConditions/ChintaiBukkenSearchConditions';
import { RouteDefinitions } from '@/Pages/RouteDefinitions';
import { ISendingViewStatusService, viewStatusAction } from '@/Services/ISendingViewStatusService';

const CustomerListItem = styled(ListItem)(({ theme }) => ({
  minHeight: '44px',
  paddingLeft: 1,
  [theme.breakpoints.down('md')]: {
    minHeight: '48px',
  },
}));

type SideMenuProps = {
  onItemClick?: () => void;
  domainUid?: string;
};

const analyticsPlace = 'サイドメニュー';

export const SideMenuContainer: FC<SideMenuProps> = memo(props => {
  const switchSearchMethodService = useDependency('switchSearchMethodService');
  const location = useLocation();
  const navigate = useNavigate();

  const tracker = useUserActionTracker(analyticsPlace);
  const sendingViewStatusService = useDependency('sendingViewStatusService');

  const customerViewStateHooks = useCustomerViewStateHooks(location.pathname);
  const onItemClick = useCallback((): void => {
    if (isNotNullOrUndefined(props.onItemClick)) {
      props.onItemClick();
    }
  }, [props]);

  const [isSavedConditionOpen, setIsSavedConditionOpen] = useState(true);
  const [isPartnerOpen, setIsPartnerOpen] = useState(true);

  const onRegionSearchClick = useCallback(() => {
    const query = location.search;
    const pageConditions = parseChintaiSearchPageQuery(query);
    const pageQuery = getChintaiSearchPageQuery(pageConditions);
    switchSearchMethodService.enableMapToAreaSwitch();
    navigate({
      pathname: RouteDefinitions.chintaiBukkenSearch.path,
      search: pageQuery,
    });
    onItemClick();
  }, [navigate, location.search, onItemClick, switchSearchMethodService]);
  const onMapSearchClick = useCallback(() => {
    const query = location.search;
    const pageConditions = parseChintaiSearchPageQuery(query);
    const pageQuery = getChintaiSearchPageQuery(pageConditions);

    switchSearchMethodService.storeSwitchToMapSearchData();
    navigate({
      pathname: RouteDefinitions.chintaiBukkenMapSearch.path,
      search: pageQuery,
    });
    onItemClick();
  }, [navigate, location.search, onItemClick, switchSearchMethodService]);

  const onOwnBukkenClick = useCallback(() => {
    const query = location.search;
    const pageConditions = parseChintaiSearchPageQuery(query);
    const pageQuery = getChintaiSearchPageQuery(pageConditions);

    switchSearchMethodService.storeSwitchToMapSearchData();
    navigate({
      pathname: RouteDefinitions.ownBukken.path,
      search: pageQuery,
    });
    onItemClick();
  }, [navigate, location.search, onItemClick, switchSearchMethodService]);

  const onClick = useCallback(
    (path: string) => {
      navigate(path);
      onItemClick();
    },
    [navigate, onItemClick]
  );

  const { model: partnerApplicantsModel } = usePartnerApplicants(
    {
      startIndex: 1,
      itemsPerPage: 10,
      type: PartnerApplicantsTypeCollection.received,
      states: PartnerApplicantsStatusCollection.standby,
    },
    30 * 1000
  );
  const approvalStandbyTotalCount = partnerApplicantsModel?.totalCounts ?? 0;

  const onSavedConditionHeaderClick = useCallback(() => setIsSavedConditionOpen(prev => !prev), []);
  const onPartnerHeaderClick = useCallback(() => setIsPartnerOpen(prev => !prev), []);

  const { model: channelModel } = useChannels({
    itemsPerPage: undefined,
    startIndex: undefined,
    organizationUid: undefined,
    isRead: false,
    countOnly: true,
    relationType: RelationTypeCollection.partner,
  });
  const isExistsUnreadChannel = useMemo(() => {
    if (!channelModel || !channelModel.data.listChannels) {
      return false;
    }
    return channelModel.data.listChannels.totalCounts > 0;
  }, [channelModel]);

  const BadgeComponent = (
    <Badge
      variant="dot"
      css={{
        marginRight: '12px',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '& .MuiBadge-badge': {
          backgroundColor: '#F44336',
          width: '10px',
          height: '10px',
          borderRadius: '50%',
        },
      }}
    />
  );

  const partnerMenuList: {
    partnerMenu: {
      title: string;
      onClick: () => void;
    };
    badge?: ReactJSXElement;
  }[] = [
    {
      partnerMenu: {
        title: '取引先',
        onClick: () => onClick(RouteDefinitions.partner.path),
      },
      badge: isExistsUnreadChannel ? BadgeComponent : undefined,
    },
    {
      partnerMenu: {
        title: '送信した申請',
        onClick: () => onClick(RouteDefinitions.partnerApplicantsSent.path),
      },
    },
    {
      partnerMenu: {
        title: '受信した申請',
        onClick: () => onClick(RouteDefinitions.partnerApplicantsReceived.path),
      },
      badge: approvalStandbyTotalCount > 0 ? BadgeComponent : undefined,
    },
  ];

  return (
    <SideMenuPresenter
      onRegionSearchClick={onRegionSearchClick}
      onMapSearchClick={onMapSearchClick}
      onOwnBukkenClick={onOwnBukkenClick}
      onSavedConditionHeaderClick={onSavedConditionHeaderClick}
      onPartnerHeaderClick={onPartnerHeaderClick}
      onSavedConditionClick={onItemClick}
      partnerMenuList={partnerMenuList}
      isSavedConditionOpen={isSavedConditionOpen}
      isPartnerOpen={isPartnerOpen}
      isCustomerView={customerViewStateHooks.isCustomerView}
      toggleIsCustomerView={customerViewStateHooks.toggleIsCustomerView}
      tracker={tracker}
      sendingViewStatusService={sendingViewStatusService}
      approvalStandbyTotalCount={approvalStandbyTotalCount}
      onClick={onClick}
      isExistsUnreadChannel={isExistsUnreadChannel}
    />
  );
}, deepCompare);

type SideMenuPresenterProps = {
  onRegionSearchClick: () => void;
  onMapSearchClick: () => void;
  onOwnBukkenClick: () => void;
  onSavedConditionHeaderClick: () => void;
  isPartnerOpen: boolean;
  onPartnerHeaderClick: () => void;
  partnerMenuList: {
    partnerMenu: {
      title: string;
      onClick: () => void;
    };
    badge?: ReactJSXElement;
  }[];
  isSavedConditionOpen: boolean;
  onSavedConditionClick: () => void;
  isCustomerView: boolean;
  toggleIsCustomerView: () => void;
  tracker: UserActionTrackFunction;
  sendingViewStatusService: ISendingViewStatusService;
  approvalStandbyTotalCount: number;
  onClick: (path: string) => void;
  isExistsUnreadChannel: boolean;
};
export const SideMenuPresenter: FC<SideMenuPresenterProps> = memo(
  ({
    onRegionSearchClick,
    onMapSearchClick,
    onOwnBukkenClick,
    onSavedConditionHeaderClick,
    isPartnerOpen,
    onPartnerHeaderClick,
    partnerMenuList,
    isSavedConditionOpen,
    onSavedConditionClick,
    isCustomerView,
    toggleIsCustomerView,
    tracker,
    sendingViewStatusService,
    onClick,
  }) => {
    return (
      <Fragment>
        <Box display="flex" flexDirection="column" sx={{ overflowY: 'auto' }} data-testid="sideMenuSavedCondition">
          <List>
            <CustomerViewSwitch
              isCustomerView={isCustomerView}
              toggleIsCustomerView={toggleIsCustomerView}
              tracker={tracker}
              sendingViewStatusService={sendingViewStatusService}
            />
            <Divider variant="middle" sx={{ marginBottom: 1 }} />
            <MenuListItem onClick={onRegionSearchClick} text="エリア/沿線検索">
              <Icon iconName="train" size="large" color="black" />
            </MenuListItem>
            <MenuListItem onClick={onMapSearchClick} text="地図から検索">
              <Icon iconName="map" size="large" color="black" />
            </MenuListItem>
            {!isCustomerView && (
              <Fragment>
                <MenuListItem
                  onClick={onSavedConditionHeaderClick}
                  text="保存した検索条件"
                  endIcon={
                    isSavedConditionOpen ? (
                      <Icon iconName="expandLess" size="large" color="black" />
                    ) : (
                      <Icon iconName="expandMore" size="large" color="black" />
                    )
                  }
                >
                  <Icon iconName="findInPage" size="large" color="black" />
                </MenuListItem>
                <SavedConditions open={isSavedConditionOpen} onItemClick={onSavedConditionClick} />
              </Fragment>
            )}
            <MenuListItem
              onClick={onOwnBukkenClick}
              text={RouteDefinitions.ownBukken.title}
              // TODO: 2025年4月になったら外す
              newLabel={<NewChip />}
            >
              <Icon iconName="viewList" size="large" color="black" />
            </MenuListItem>
            {!isCustomerView && (
              <Fragment>
                <MenuListItem
                  onClick={onPartnerHeaderClick}
                  text="取引先管理"
                  endIcon={
                    isPartnerOpen ? (
                      <Icon iconName="expandLess" size="large" color="black" />
                    ) : (
                      <Icon iconName="expandMore" size="large" color="black" />
                    )
                  }
                >
                  <Icon iconName="people" size="large" color="black" />
                </MenuListItem>
                <Collapse in={isPartnerOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {partnerMenuList.map(partnerMenuItem => {
                      return (
                        <ListItemButton
                          key={partnerMenuItem.partnerMenu.title}
                          onClick={partnerMenuItem.partnerMenu.onClick}
                        >
                          <ListItemText inset sx={{ paddingLeft: '32px' }}>
                            {partnerMenuItem.partnerMenu.title}
                          </ListItemText>
                          {partnerMenuItem.badge}
                        </ListItemButton>
                      );
                    })}
                  </List>
                </Collapse>
              </Fragment>
            )}
            {!isCustomerView && (
              <Fragment>
                <MenuListItem
                  onClick={() => onClick(RouteDefinitions.setting.path)}
                  text={RouteDefinitions.setting.title}
                >
                  <Icon iconName="settings" size="large" color="black" />
                </MenuListItem>
              </Fragment>
            )}
          </List>
        </Box>
        {!isCustomerView && (
          <Box mt="auto" onTouchStart={e => e.stopPropagation()} onTouchMove={e => e.stopPropagation()}>
            <BannerList />
          </Box>
        )}
      </Fragment>
    );
  },
  deepCompare
);

type CustomerViewSwitchProps = {
  isCustomerView: boolean;
  toggleIsCustomerView: () => void;
  tracker: UserActionTrackFunction;
  sendingViewStatusService: ISendingViewStatusService;
};

export const CustomerViewSwitch: FC<CustomerViewSwitchProps> = memo(
  ({ isCustomerView, toggleIsCustomerView, tracker, sendingViewStatusService }) => {
    return (
      <CustomerListItem>
        <ListItemText>
          <Box display="flex" alignItems="center">
            <Switch
              edge="end"
              color="primary"
              onChange={useCallback(() => {
                // まだisCustomerViewの状態は変わって無いので反転して判定
                tracker('接客用表示', !isCustomerView ? 'on' : 'off');
                if (!isCustomerView) {
                  sendingViewStatusService.send(
                    {
                      bukkenName: null,
                      bukkenGuid: '',
                      kukakuName: null,
                      kukakuGuid: null,
                      kokaimotoGuid: '',
                    },
                    viewStatusAction.customerView
                  );
                }
                toggleIsCustomerView();
              }, [isCustomerView, toggleIsCustomerView, tracker, sendingViewStatusService])}
              checked={isCustomerView}
            />
            {!isCustomerView ? (
              <Fragment>
                <Box pl={2} pr={1}>
                  接客用表示
                </Box>
                <Tooltip title={<CustomerViewTooltipContent />} arrow>
                  <Box display="flex">
                    <Icon iconName="helpFill" size="large" color="black" />
                  </Box>
                </Tooltip>
              </Fragment>
            ) : (
              ''
            )}
          </Box>
        </ListItemText>
      </CustomerListItem>
    );
  },
  deepCompare
);

const CustomerViewTooltipContent: FC = () => {
  const hiddenContentList = [
    '保存した検索条件',
    'AD',
    '広告',
    '元付会社名',
    '元付会社情報',
    '元付帯図面DL',
    '30日以上経過した更新日時',
  ];
  return (
    <Fragment>
      <Typography variant="subtitle1" sx={textSxProps.bold}>
        以下の項目が非表示になります。
      </Typography>
      {hiddenContentList.map(it => {
        return (
          <Typography key={it} variant="body2" sx={textSxProps.sm}>
            ・{it}
          </Typography>
        );
      })}
    </Fragment>
  );
};
