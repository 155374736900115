import { isNotNullOrUndefined, uniqStable } from '@app/utils';
import { ConditionBadge, DetailConditionBarPresenter } from '@lib/components';
import { Badge, Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import { ChevronDown, ChevronUp, MapMarker, Magnify } from 'mdi-material-ui';
import { useMemo, FC } from 'react';
import { useAsync } from 'react-use';

import BadgeBuilder, { BadgeBuilderOptions, badgeKeyLabelMap } from './BadgeBuilder';
import { DetailSearchConditionHooks } from './DetailConditionForms';
import { RegionConditionHooks } from './RegionConditionForms';

import { useDependency } from '@/Hooks/DependencyHook';
import { MapAreaSearchConditionHook } from '@/Hooks/Search/MapAreaSearchConditionHook';
import {
  ChintaiSearchConditions,
  LineSearchCondition,
  StationSearchCondition,
} from '@/Models/SearchConditions/ChintaiSearchConditions';

type RegionConditionChipData = {
  label: string | undefined;
  onDelete: (() => void) | undefined;
};

type RegionConditionHeaderProps = {
  addressHooks: RegionConditionHooks;
  mapAreaConditionHook: MapAreaSearchConditionHook<ChintaiSearchConditions>;
  tabOpen: boolean;
  onTabClick: () => void;
  isSmallDevice: boolean;
  requestSearch: () => void;
};

export const RegionConditionHeader: FC<RegionConditionHeaderProps> = props => {
  const masterApiService = useDependency('masterApiService');

  const {
    actualQueryValue: placeActualQueryValue,
    clear: placeClear,
    onSelectOoaza,
    onSelectCities,
    onSelectPref,
  } = props.addressHooks.placesCondition;

  const { value: placeNameList } = useAsync(async (): Promise<readonly RegionConditionChipData[]> => {
    const getBadgeValue = (label: string | undefined, onSelect: (() => void) | undefined) => {
      return {
        label: label,
        onDelete: () => {
          if (placeActualQueryValue.length === 1) {
            placeClear();
          } else {
            onSelect && onSelect();
          }
        },
      };
    };

    if (placeActualQueryValue.length === 0) {
      return [];
    }

    if (placeActualQueryValue[0].ooaza !== '') {
      return placeActualQueryValue.map(p =>
        getBadgeValue(p.ooaza, () => onSelectOoaza(p.prefecture, p.city, [p.ooaza], false))
      );
    }

    if (placeActualQueryValue[0].city > 0) {
      const prefs = uniqStable(placeActualQueryValue.map(p => p.prefecture));
      // キャッシュウォーム
      await Promise.all(prefs.map(p => masterApiService.getCities(p)));
      return await Promise.all(
        placeActualQueryValue.map(async p => {
          return await masterApiService
            .getCity(p.prefecture, p.city)
            .then(response => getBadgeValue(response.city_name, () => onSelectCities(p.prefecture, [p.city], false)))
            .catch(() => getBadgeValue(undefined, undefined));
        })
      );
    }

    return await Promise.all(
      placeActualQueryValue.map(async p => {
        try {
          const pref = await masterApiService.getPrefecture(p.prefecture);
          return getBadgeValue(pref.name, () => onSelectPref(p.prefecture, false));
        } catch {
          return getBadgeValue(undefined, undefined);
        }
      })
    );
  }, [placeActualQueryValue, placeClear, onSelectOoaza, masterApiService, onSelectCities, onSelectPref]);

  const {
    queryStations: stationsCondition,
    queryLines: linesCondition,
    clear: lineAndStationClear,
    onSelectStations,
    onSelectLines,
  } = props.addressHooks.lineAndStationCondition;
  const { value: lineOrStationNameList } = useAsync(async (): Promise<readonly RegionConditionChipData[]> => {
    const getBadgeValue = (
      label: string | undefined,
      condition: readonly Readonly<LineSearchCondition | StationSearchCondition>[],
      onSelect: (() => void) | undefined
    ) => {
      return {
        label: label,
        onDelete: () => {
          if (condition.length === 1) {
            lineAndStationClear();
          } else {
            onSelect && onSelect();
          }
        },
      };
    };

    if (stationsCondition.length > 0) {
      const lines = uniqStable(stationsCondition.map(s => s.lineCode));
      // キャッシュウォーム
      await Promise.all(lines.map(l => masterApiService.getStations(l)));
      return await Promise.all(
        stationsCondition.map(async s => {
          return await masterApiService
            .getStation(s.lineCode, s.stationCode)
            .then(response =>
              getBadgeValue(response.station_name_data.station_name, stationsCondition, () =>
                onSelectStations(s.prefCodeList, s.lineCode, [s.stationCode], false)
              )
            )
            .catch(() => getBadgeValue(undefined, [], undefined));
        })
      );
    }

    if (linesCondition.length > 0) {
      return await Promise.all(
        linesCondition.map(async l => {
          return await masterApiService
            .getLine(l.lineCode)
            .then(response =>
              getBadgeValue(response.line_name, linesCondition, () =>
                onSelectLines(l.prefCodeList, [l.lineCode], false)
              )
            )
            .catch(() => getBadgeValue(undefined, [], undefined));
        })
      );
    }

    return [];
  }, [lineAndStationClear, linesCondition, masterApiService, onSelectLines, onSelectStations, stationsCondition]);

  const regionConditionChips = useMemo(() => {
    const joinedList = (placeNameList ?? []).concat(lineOrStationNameList ?? []).filter(chipData => !!chipData.label);
    if (isNotNullOrUndefined(props.mapAreaConditionHook.value)) {
      joinedList.unshift({
        label: '地図表示領域',
        onDelete: props.mapAreaConditionHook.clear,
      });
    }
    if (joinedList.length === 0) {
      return ['エリア・沿線を選択'];
    } else if (joinedList.length <= 2) {
      return joinedList.map((it, index) => (
        <ConditionBadge
          key={index}
          label={it.label ?? ''}
          onDelete={
            Array.isArray(it.onDelete) ? [...it.onDelete, props.requestSearch] : [it.onDelete, props.requestSearch]
          }
        />
      ));
    } else {
      return [
        <ConditionBadge
          key={joinedList[0].label}
          label={joinedList[0].label ?? ''}
          onDelete={
            Array.isArray(joinedList[0].onDelete)
              ? [...joinedList[0].onDelete, props.requestSearch]
              : [joinedList[0].onDelete, props.requestSearch]
          }
        />,
        `ほか${joinedList.length - 1}件`,
      ];
    }
  }, [
    lineOrStationNameList,
    placeNameList,
    props.mapAreaConditionHook.clear,
    props.mapAreaConditionHook.value,
    props.requestSearch,
  ]);

  return (
    <Box
      display="flex"
      alignItems="center"
      onClick={props.onTabClick}
      sx={{
        padding: 1,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: grey[200],
        },
        height: '100%',
      }}
    >
      <Box display="flex" flexShrink={0} alignItems="center" pr={1}>
        <MapMarker
          sx={{
            color: 'primary.main',
            fontSize: '1.75rem',
          }}
        />
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" flexGrow={1}>
        {regionConditionChips}
      </Box>
      {!props.isSmallDevice && (props.tabOpen ? <ChevronUp /> : <ChevronDown />)}
    </Box>
  );
};

type DetailConditionHeaderProps = {
  detailHooks: DetailSearchConditionHooks;
  onTabClick: () => void;
  requestSearch: () => void;
  isSmallDevice: boolean;
  badgeLimit?: number;
  badgeBuilderOptions?: BadgeBuilderOptions;
  isCustomerView: boolean;
};

// 接客用表示時に非表示にするバッジのリスト
const hiddenBadgeKeyList = [badgeKeyLabelMap.ad, badgeKeyLabelMap.motodukeName];

const filterByHiddenBadge = (badgeList: JSX.Element[]): JSX.Element[] =>
  badgeList.filter(it => !hiddenBadgeKeyList.includes(String(it.key ?? '')));

export const DetailConditionHeader: FC<DetailConditionHeaderProps> = ({
  detailHooks,
  onTabClick,
  requestSearch,
  isSmallDevice,
  badgeLimit,
  badgeBuilderOptions,
  isCustomerView,
}) => {
  const detailConditionBadges = useMemo(() => {
    return Object.values(BadgeBuilder).flatMap(it => it.builder(detailHooks, requestSearch, badgeBuilderOptions));
  }, [detailHooks, requestSearch, badgeBuilderOptions]);

  return (
    <DetailConditionHeaderPresenter
      onBarClick={onTabClick}
      isSmallDevice={isSmallDevice}
      badgeLimit={badgeLimit}
      onOtherBadgeDeleted={requestSearch}
      isCustomerView={isCustomerView}
    >
      {detailConditionBadges}
    </DetailConditionHeaderPresenter>
  );
};

type DetailConditionHeaderPresenterProps = {
  children: JSX.Element[];
  onBarClick: () => void;
  isSmallDevice: boolean;
  badgeLimit?: number;
  onOtherBadgeDeleted?: () => void;
  isCustomerView: boolean;
};

export const DetailConditionHeaderPresenter: FC<DetailConditionHeaderPresenterProps> = ({
  children,
  onBarClick,
  isSmallDevice,
  onOtherBadgeDeleted,
  badgeLimit,
  isCustomerView,
}) => {
  const filteredChildren = isCustomerView ? filterByHiddenBadge(children) : children;
  return (
    <DetailConditionBarPresenter
      onBarClick={onBarClick}
      isSmallDevice={isSmallDevice}
      badgeLimit={badgeLimit}
      onOtherBadgeDeleted={onOtherBadgeDeleted}
    >
      {filteredChildren}
    </DetailConditionBarPresenter>
  );
};

type DetailConditionIconButtonProps = {
  detailHooks: DetailSearchConditionHooks;
  requestSearch: () => void;
  badgeBuilderOptions?: BadgeBuilderOptions;
};

export const DetailConditionIconButton: FC<DetailConditionIconButtonProps> = props => {
  const detailConditionBadges = useMemo(() => {
    return Object.values(BadgeBuilder).flatMap(it =>
      it.builder(props.detailHooks, props.requestSearch, props.badgeBuilderOptions)
    );
  }, [props.badgeBuilderOptions, props.detailHooks, props.requestSearch]);

  return (
    <Badge color="error" variant="dot" invisible={detailConditionBadges.length === 0}>
      <Magnify sx={{ color: 'primary.main' }} />
    </Badge>
  );
};
