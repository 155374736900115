import { deepCompare } from '@app/utils';
import { Icon, RadioButton } from '@e-seikatsu/design-system';
import { Grid, Box, Tooltip } from '@mui/material';
import { memo, ChangeEvent, FC } from 'react';

import { searchConditionSxProps } from './SearchConditionStyle';

import { reportError } from '@/ErrorLogger';
import { SearchConditionHook } from '@/Hooks/Search/SearchConditionHook';
import { borderSxProps } from '@/Hooks/Styles/BorderStyle';
import {
  B2bKokaiCodeRadioStateType,
  B2bKokaiCodeRadioState,
  B2bKokaiCodeLabelMap,
} from '@/Models/SearchConditions/ChintaiSearchConditionEnums/ChintaiSearchConditionEnums';
import { ChintaiSearchConditions, B2bKokaiCodeCondition } from '@/Models/SearchConditions/ChintaiSearchConditions';

const reportPlace = '@/Pages/bukken/chintai/SearchPartial/BukkenSearchForms/B2bKokaiCodeForm';
const uninitializedErrorMessage = 'B2bKokaiCodeConditions are uninitialized';
type B2bKokaiCodeFormContainerProps = {
  hook: SearchConditionHook<B2bKokaiCodeCondition, ChintaiSearchConditions>;
  isSmallDevice: boolean;
};

const b2bKokaiCodeFormObj = [
  {
    value: B2bKokaiCodeRadioState.unselected,
    label: B2bKokaiCodeLabelMap[B2bKokaiCodeRadioState.unselected],
  },
  {
    value: B2bKokaiCodeRadioState.partner,
    label: B2bKokaiCodeLabelMap[B2bKokaiCodeRadioState.partner],
  },
  {
    value: B2bKokaiCodeRadioState.public,
    label: B2bKokaiCodeLabelMap[B2bKokaiCodeRadioState.public],
  },
];

const B2bKokaiCodeFormContainer: FC<B2bKokaiCodeFormContainerProps> = memo(({ hook, isSmallDevice }) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (hook.value === undefined) {
      reportError(uninitializedErrorMessage, reportPlace, true);
      return;
    }
    hook.setValue({ ...hook.value, bukkenKokaiScopeRadioState: event.target.value as B2bKokaiCodeRadioStateType });
  };

  return (
    <B2bKokaiCodeFormPresenter
      isSmallDevice={isSmallDevice}
      handleChange={handleChange}
      bukkenKokaiScopeRadioState={hook.value?.bukkenKokaiScopeRadioState}
    />
  );
}, deepCompare);

export type B2bKokaiCodeFormPresenterProps = {
  isSmallDevice: boolean;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  bukkenKokaiScopeRadioState: B2bKokaiCodeRadioStateType | undefined;
};

export const B2bKokaiCodeFormPresenter: FC<B2bKokaiCodeFormPresenterProps> = ({
  isSmallDevice,
  handleChange,
  bukkenKokaiScopeRadioState,
}) => {
  return (
    <Grid
      data-testclass="bukkenKokaiScopeForm"
      container
      sx={{ ...searchConditionSxProps.root, ...borderSxProps.borderBottom }}
    >
      <Grid
        item
        xs={12}
        sm={2}
        sx={{ ...searchConditionSxProps.firstCol, ...(isSmallDevice ? borderSxProps.borderBottom : null) }}
      >
        <Box display="flex" alignItems="center" lineHeight="24px" gap={1}>
          公開範囲
          <Tooltip title="元付が取引先のみ限定公開/全体公開した物件で検索することができます">
            <Box display="flex" alignItems="center">
              <Icon iconName="helpFill" size="large" color="black" />
            </Box>
          </Tooltip>
        </Box>
      </Grid>
      <Grid item xs={12} sm={10} sx={{ ...searchConditionSxProps.secondCol, ...searchConditionSxProps.inputCol }}>
        <Box display="flex" width="100%" flexWrap="wrap" gap={1}>
          {b2bKokaiCodeFormObj.map(obj => {
            return (
              <RadioButton
                variant="outlined"
                size="small"
                onChange={handleChange}
                checked={bukkenKokaiScopeRadioState === obj.value}
                key={obj.value}
                label={obj.label}
                value={obj.value}
              />
            );
          })}
        </Box>
      </Grid>
    </Grid>
  );
};

export default B2bKokaiCodeFormContainer;
