import { isNotNullOrUndefined } from '@app/utils';
import { textSxProps, useIsSmallDevice } from '@lib/components';
import { Box, Drawer, drawerClasses, Typography } from '@mui/material';
import { FC, memo } from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';

import { OwnBukkenListPage } from '@/Components/OwnBukken/OwnBukkenListPage';
import { textColorSxProps } from '@/Hooks/Styles/ColorStyle';
import { RouteDefinitions } from '@/Pages/RouteDefinitions';
import DetailPage from '@/Pages/bukken/chintai/Detail';

const OwnBukkenPageContainer: FC = memo(() => {
  const isSmallDevice = useIsSmallDevice();
  const match = useMatch(RouteDefinitions.ownBukkenDetail.path);
  const isOpen = isNotNullOrUndefined(match);

  const location = useLocation();
  const navigate = useNavigate();
  const closeDetail = (): void => {
    navigate({ pathname: RouteDefinitions.ownBukken.path, search: location.search }, { state: location.state });
  };

  return <OwnBukkenPagePresenter isSmallDevice={isSmallDevice} isOpen={isOpen} closeDetail={closeDetail} />;
});

export { OwnBukkenPageContainer as OwnBukkenPage };

type OwnBukkenPagePresenterProps = {
  isSmallDevice: boolean;
  isOpen: boolean;
  closeDetail: () => void;
};

export const OwnBukkenPagePresenter: FC<OwnBukkenPagePresenterProps> = memo(
  ({ isSmallDevice, isOpen, closeDetail }) => (
    <Box display="flex" flexDirection="column" minHeight="100%">
      <Box width="100%" pt={3}>
        <Typography mb={1} mx={2} sx={{ ...textColorSxProps.title, ...textSxProps.bold, ...textSxProps.xl }}>
          自社物件一覧
        </Typography>
        <OwnBukkenListPage />
        <Drawer
          anchor={isSmallDevice ? 'left' : 'right'}
          open={isOpen}
          onClose={closeDetail}
          elevation={0}
          sx={{
            [`& .${drawerClasses.paper}`]: {
              overflowY: 'scroll',
              backgroundColor: 'transparent',
              filter: 'drop-shadow(0 0 8px rgba(0, 0, 0, 0.5))',
            },
          }}
        >
          <DetailPage onClose={closeDetail} isOwnBukkenPage={true} />
        </Drawer>
      </Box>
    </Box>
  )
);
