import { getViewportHeight } from '@app/utils';

import { theme } from '@/Theme';

type UseSearchConditionStyleProps = { gridItemCountPC?: number; gridItemCountMobile?: number };

export const searchConditionSxProps = {
  root: {},
  firstCol: {
    paddingTop: 1,
    paddingBottom: 1,
    paddingLeft: 2,
    paddingRight: 2,
    backgroundColor: theme.palette.secondary.light,
    [theme.breakpoints.up('sm')]: {
      maxWidth: '10rem',
    },
    display: 'flex',
    alignItems: 'center',
  },
  secondCol: {
    paddingTop: 1,
    paddingBottom: 1,
    paddingLeft: 2,
    paddingRight: 2,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  firstColNoWrap: {
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 1,
    paddingBottom: 1,
    backgroundColor: theme.palette.secondary.light,
  },
  secondColNoWrap: {
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 1,
    paddingBottom: 1,
  },
  checkboxGrid: (props: UseSearchConditionStyleProps | Record<string, never>) => ({
    display: 'grid',
    justifyItems: 'start',
    gap: 1,
    gridTemplateColumns: `repeat(${props.gridItemCountPC ?? 5}, 1fr)`,
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: `repeat(${props.gridItemCountMobile ?? 2}, 1fr)`,
    },
  }),
  inputCol: {
    width: '100%',
  },
  rangeSeparator: {
    paddingLeft: 0.5,
    paddingRight: 0.5,
  },
  formGroupRoot: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  inputForm: {
    width: '15rem',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  inputRangeForm: {
    width: '15rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  textInputForm: {
    width: '23rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingTop: 1,
      paddingBottom: 1,
    },
  },
  dateInputForm: {
    width: '8rem',
  },
} as const;

export const addressConditionSxProps = {
  footerPrevButton: {
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      padding: '4px 8px',
    },
  },
  footerTransitButton: {
    width: '8rem',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      padding: '4px 8px',
    },
  },
  footerSearchButton: {
    width: '6rem',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      padding: '4px 8px',
    },
  },
  footerNextButton: {
    width: '7rem',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      padding: '4px 8px',
    },
  },
  buttonStartIcon: {
    marginRight: 0,
  },
} as const;

export const conditionFormsSxProps = {
  clearText: {
    textDecorationLine: 'underline',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      color: theme.palette.primary.main,
    },
  },
  tabPanel: {
    padding: '0 !important',
  },
  popoverPaper: {
    maxHeight: 'calc(100% - 16px)',
    maxWidth: 'calc(100% - 16px)',
    // タブ切り替え時のポップオーバーのサイズ崩れを防ぐために固定
    height: 'calc(100% - 16px)',
    width: 'calc(100% - 16px)',
  },
} as const;

type UseDialogStylesProps = {
  isMobile: boolean;
};

export const dialogSxProps = {
  dialogRoot: (props: UseDialogStylesProps) => {
    return props.isMobile
      ? {}
      : {
          maxWidth: {
            xs: undefined,
            md: '984px',
          },
          width: {
            xs: undefined,
            md: '984px',
          },
          minHeight: {
            xs: undefined,
            md: getViewportHeight(50),
          },
        };
  },
  dialogTitle: (props: UseDialogStylesProps) => ({
    paddingRight: props.isMobile ? 1 : 2,
    paddingLeft: props.isMobile ? 0 : 2,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  dialogContent: (props: UseDialogStylesProps) => ({
    paddingRight: props.isMobile ? 0 : 3,
    paddingLeft: props.isMobile ? 0 : 3,
    paddingTop: 2,
    paddingBottom: 2,
  }),
  dialogFooter: (props: UseDialogStylesProps) => ({
    paddingRight: props.isMobile ? 1 : 2,
    paddingLeft: props.isMobile ? 1 : 2,
  }),
} as const;
