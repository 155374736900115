import { asHoshoninDaikoCode } from '@app/enums';
import { isNotNullOrUndefined, isNullOrUndefined } from '@app/utils';
import { SerializedStyles } from '@emotion/react';
import { Grid, Stack, Tooltip, Typography } from '@mui/material';
import { HelpCircle } from 'mdi-material-ui';
import { FC, Fragment } from 'react';

import { InitialCostCalcType, InitialCostDisplayData } from './CreateInitialCostDisplayData';

import { stringToTableVal } from '@/Pages/bukken/chintai/DetailPartial/GeneralDescriptionTabPartial/OverviewData/CalcNormalOverviewData';
import { theme } from '@/Theme';
import { makeHoshoKaishaText } from '@/Utils/DisplayText/Bukken';
import { makePriceText } from '@/Utils/DisplayText/Money';

type InitialCostDisplaySankoContainerProps = {
  isCustomerView: boolean;
  initialCostCalcData: InitialCostCalcType;
  initialCostDialogStyles: () => SerializedStyles;
  gridBorder: string;
};

const InitialCostDisplaySankoContainer: FC<InitialCostDisplaySankoContainerProps> = ({
  isCustomerView,
  initialCostCalcData,
  initialCostDialogStyles,
  gridBorder,
}) => {
  const initialCostAd = makePriceText(initialCostCalcData.kokokuryo_amount, initialCostCalcData.kokokuryo_tani, {
    emptyIfZero: true,
    emptySubstituteText: '-',
  });

  const hoshoKaishaComment = stringToTableVal(
    makeHoshoKaishaText(
      asHoshoninDaikoCode(initialCostCalcData.hoshonin_daiko_code),
      initialCostCalcData.hoshonin_daiko_kaisha_kubun,
      initialCostCalcData.hoshonin_daiko_shosai
    )
  );

  const displayInititalCostComment = initialCostCalcData.es_b2b_chintai_pdf_comment;
  const initialCostBiko = isNotNullOrUndefined(displayInititalCostComment)
    ? stringToTableVal(displayInititalCostComment)
    : stringToTableVal(initialCostCalcData.comment);

  //参考情報を統合
  const initialCostComments: InitialCostDisplayData[] = [
    { label: '保証会社', content: hoshoKaishaComment },
    { label: '備考', content: initialCostBiko },
  ];

  return (
    <InitialCostDisplaySankoPresenter
      isCustomerView={isCustomerView}
      initialCostAd={initialCostAd}
      initialCostComments={initialCostComments}
      initialCostDialogStyles={initialCostDialogStyles}
      gridBorder={gridBorder}
    />
  );
};

export { InitialCostDisplaySankoContainer as InitialCostDisplaySanko };

type InitialCostDisplaySankoPresenterProps = {
  isCustomerView: boolean;
  initialCostAd: string;
  initialCostComments: InitialCostDisplayData[];
  initialCostDialogStyles: () => SerializedStyles;
  gridBorder: string;
};

export const InitialCostDisplaySankoPresenter: FC<InitialCostDisplaySankoPresenterProps> = ({
  isCustomerView,
  initialCostAd,
  initialCostComments,
  initialCostDialogStyles,
  gridBorder,
}) => (
  <Fragment>
    <Typography color="rgba(0,0,0,0.6)">合計金額に算出されません。追加項目の参考までにご利用ください。</Typography>
    <Grid container css={{ borderTop: gridBorder }} mt={1}>
      {!isCustomerView && (
        <Grid container>
          <Grid item css={[initialCostDialogStyles, { backgroundColor: theme.palette.secondary.light }]} xs={12} md={5}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="body1">
                <b>AD</b>
              </Typography>
              <Tooltip title="ADは接客用表示で非表示になります">
                <HelpCircle css={{ color: 'gray' }} />
              </Tooltip>
            </Stack>
          </Grid>
          <Grid
            item
            css={[initialCostDialogStyles, { textAlign: initialCostAd !== '-' ? 'end' : undefined }]}
            xs={12}
            md={7}
          >
            {initialCostAd}
          </Grid>
        </Grid>
      )}
      <Fragment>
        {initialCostComments.map(comment => {
          if (isNullOrUndefined(comment.content)) {
            return undefined;
          }
          return (
            <Grid container key={comment.label}>
              <Grid
                item
                css={[initialCostDialogStyles, { backgroundColor: theme.palette.secondary.light }]}
                xs={12}
                md={5}
              >
                <Typography variant="body1">
                  <b>{comment.label}</b>
                </Typography>
              </Grid>
              <Grid item css={initialCostDialogStyles} xs={12} md={7}>
                {comment.content}
              </Grid>
            </Grid>
          );
        })}
      </Fragment>
    </Grid>
  </Fragment>
);
