import { Feed } from './Feed';

export abstract class AsyncListBase<ItemType> {
  public feedCache: Record<number, Feed<ItemType>> = {};

  public get totalCount(): number | undefined {
    return Object.keys(this.feedCache).length > 0 ? Object.values(this.feedCache)[0].total_counts : undefined;
  }

  public constructor(public readonly itemsPerPage: number) {}

  public abstract load(startIndex: number): Promise<Feed<ItemType>>;

  public async safeLoad(startIndex: number): Promise<Feed<ItemType>> {
    const result = await this.load(startIndex);
    if (this.totalCount !== result.total_counts) {
      // 検索結果が変動しているのでキャッシュを無効化
      this.feedCache = {};
    }

    return result;
  }

  public async getPage(index: number): Promise<ItemType[]> {
    const startIndex = this.getStartIndex(index);
    const cache = this.feedCache[startIndex];
    if (cache) {
      return cache.items;
    }

    const searchResult = await this.safeLoad(startIndex);
    this.feedCache[startIndex] = searchResult;

    return searchResult.items;
  }

  public getStartIndex(pageIndex: number): number {
    return pageIndex * this.itemsPerPage;
  }
}
