import { deepCompare } from '@app/utils';
import { Select, Checkbox } from '@e-seikatsu/design-system';
import { Grid, Box } from '@mui/material';
import { memo, ChangeEvent, FC, useCallback, useEffect, useState } from 'react';

import { searchConditionSxProps } from './SearchConditionStyle';

import { reportError } from '@/ErrorLogger';
import { SearchConditionHook } from '@/Hooks/Search/SearchConditionHook';
import { borderSxProps } from '@/Hooks/Styles/BorderStyle';
import { ChintaiSearchConditions, GazoSearchConditions } from '@/Models/SearchConditions/ChintaiSearchConditions';

const reportPlace = '@/Pages/bukken/chintai/SearchPartial/BukkenSearchForms/BukkenGazoForm';
const uninitializedErrorMessage = 'GazoSearchConditions is uninitialized';

type BukkenGazoFormContainerProps = {
  hook: SearchConditionHook<GazoSearchConditions, ChintaiSearchConditions>;
  isSmallDevice: boolean;
};

type SelectItem = {
  value: string;
  label: string;
};
const selectItems: SelectItem[] = [
  { value: '1', label: '1枚以上' },
  { value: '3', label: '3枚以上' },
  { value: '5', label: '5枚以上' },
  { value: '10', label: '10枚以上' },
];

const BukkenGazoFormContainer: FC<BukkenGazoFormContainerProps> = memo(({ hook, isSmallDevice }) => {
  const [selectState, setSelectState] = useState<string>('');

  useEffect(() => {
    if (hook.value?.totalGazoCountFrom !== undefined) {
      setSelectState(hook.value.totalGazoCountFrom.toString());
    } else {
      setSelectState('');
    }
  }, [hook]);

  const handleChange = useCallback(
    (value: string): void => {
      const newValue = value;
      if (hook.value === undefined) {
        reportError(uninitializedErrorMessage, reportPlace, true);
        return;
      }
      setSelectState(newValue);
      if (newValue === '') {
        hook.setValue({ ...hook.value, totalGazoCountFrom: undefined });
      } else {
        hook.setValue({ ...hook.value, totalGazoCountFrom: Number(newValue) });
      }
    },
    [hook]
  );

  const handleGaikanCheck = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      if (hook.value === undefined) {
        reportError(uninitializedErrorMessage, reportPlace, true);
        return;
      }
      hook.setValue({ ...hook.value, gaikanGazoCountFrom: event.target.checked ? 1 : undefined });
    },
    [hook]
  );
  const handleMadoriCheck = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      if (hook.value === undefined) {
        reportError(uninitializedErrorMessage, reportPlace, true);
        return;
      }
      hook.setValue({ ...hook.value, madoriGazoCountFrom: event.target.checked ? 1 : undefined });
    },
    [hook]
  );
  const handleNaikanCheck = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      if (hook.value === undefined) {
        reportError(uninitializedErrorMessage, reportPlace, true);
        return;
      }
      hook.setValue({ ...hook.value, naikanGazoCountFrom: event.target.checked ? 1 : undefined });
    },
    [hook]
  );

  return (
    <BukkenGazoFormPresenter
      isSmallDevice={isSmallDevice}
      selectState={selectState}
      gaikanGazoCountFrom={hook.value?.gaikanGazoCountFrom}
      madoriGazoCountFrom={hook.value?.madoriGazoCountFrom}
      naikanGazoCountFrom={hook.value?.naikanGazoCountFrom}
      onChange={handleChange}
      onGaikanCheck={handleGaikanCheck}
      onMadoriCheck={handleMadoriCheck}
      onNaikanCheck={handleNaikanCheck}
    />
  );
}, deepCompare);

export type BukkenGazoFormPresenterProps = {
  isSmallDevice: boolean;
  selectState: string;
  gaikanGazoCountFrom: number | undefined;
  madoriGazoCountFrom: number | undefined;
  naikanGazoCountFrom: number | undefined;
  onChange: (value: string) => void;
  onGaikanCheck: (event: ChangeEvent<HTMLInputElement>) => void;
  onMadoriCheck: (event: ChangeEvent<HTMLInputElement>) => void;
  onNaikanCheck: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const BukkenGazoFormPresenter: FC<BukkenGazoFormPresenterProps> = ({
  isSmallDevice,
  selectState,
  gaikanGazoCountFrom,
  madoriGazoCountFrom,
  naikanGazoCountFrom,
  onChange,
  onGaikanCheck,
  onMadoriCheck,
  onNaikanCheck,
}) => {
  return (
    <Grid container sx={{ ...searchConditionSxProps.root, ...borderSxProps.borderBottom }}>
      <Grid
        item
        xs={12}
        sm={2}
        sx={{ ...searchConditionSxProps.firstCol, ...(isSmallDevice ? borderSxProps.borderBottom : undefined) }}
      >
        画像
      </Grid>
      <Grid
        item
        xs={12}
        sm={10}
        gap={1}
        sx={{ ...searchConditionSxProps.secondCol, ...searchConditionSxProps.inputCol }}
      >
        <Box width="100%">
          <Select size="small" length={isSmallDevice ? 'full' : 'normal'} value={selectState} onChange={onChange}>
            <Select.Option value="">指定しない</Select.Option>
            {selectItems.map(it => (
              <Select.Option value={it.value} key={it.value}>
                {it.label}
              </Select.Option>
            ))}
          </Select>
        </Box>
        <Box display="flex" flexWrap="wrap" gap={1}>
          <Checkbox
            variant="outlined"
            size="small"
            label="外観あり"
            checked={gaikanGazoCountFrom !== undefined}
            onChange={onGaikanCheck}
          />
          <Checkbox
            variant="outlined"
            size="small"
            label="間取り・地図あり"
            checked={madoriGazoCountFrom !== undefined}
            onChange={onMadoriCheck}
          />
          <Checkbox
            variant="outlined"
            size="small"
            label="内観あり"
            checked={naikanGazoCountFrom !== undefined}
            onChange={onNaikanCheck}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default BukkenGazoFormContainer;
