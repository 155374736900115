import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';

const makeSpaceX = (size: string | number): SystemStyleObject<Theme> => ({
  '&> * + *': {
    marginLeft: size,
  },
});

const makeSpaceY = (size: string | number): SystemStyleObject<Theme> => ({
  '&> * + *': {
    marginTop: size,
  },
});

const makeSpaceAllXY = (size: string | number): SystemStyleObject<Theme> => ({
  '&> *': {
    margin: size,
  },
});

type SpacingSxPropsKeys = 'spaceX1' | 'spaceX2' | 'spaceY1' | 'spaceY2' | 'spaceAllXY05';
export const spacingSxProps: Record<SpacingSxPropsKeys, SystemStyleObject<Theme>> = {
  spaceX1: makeSpaceX(1),
  spaceX2: makeSpaceX(2),
  spaceY1: makeSpaceY(1),
  spaceY2: makeSpaceY(2),
  spaceAllXY05: makeSpaceAllXY(0.5),
};
