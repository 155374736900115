import { css } from '@emotion/react';
import { Box, Grid } from '@mui/material';
import { grey } from '@mui/material/colors';
import { FC } from 'react';

import { searchResultHeaderZIndex } from '@/Consts/Values';

const headerItemStyle = css({
  fontWeight: 'bold',
  padding: 1,
});

export const OwnBukkenListColumnHeader: FC = () => {
  return (
    <Grid
      display="grid"
      gridTemplateColumns="0.6fr 1.5fr 1.5fr 1.2fr 0.8fr"
      alignItems="center"
      zIndex={searchResultHeaderZIndex}
      border={`1px solid ${grey[300]}`}
      bgcolor={`${grey[200]}`}
      top={48}
      position="sticky"
      py={0.5}
      pl={2}
    >
      <Box css={headerItemStyle}>画像</Box>
      <Box css={headerItemStyle}>物件名</Box>
      <Box css={headerItemStyle}>
        <Box>賃料/管理費</Box>
        <Box>敷金/礼金</Box>
      </Box>
      <Box css={headerItemStyle}>
        <Box>間取/専有面積</Box>
        <Box>構造/築年数</Box>
      </Box>
      <Box css={headerItemStyle}>
        <Box>退去日</Box>
        <Box>入居時期</Box>
      </Box>
    </Grid>
  );
};
