import { NaikenKubunCode, NaikenKubunCodeType, asNaikenKubunCode } from '@app/enums';
import { ChinshakuBukken, Datejun, SpecBukkenView } from '@app/models';
import { startOfToday } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useAsync } from 'react-use';

import { UserActionTrackFunction } from '@/Hooks/Analytics';
import { useDependency } from '@/Hooks/DependencyHook';
import { useIsValidTakkenLicenseNumber } from '@/Hooks/ValidationHooks';

type PreviewState = 'possible' | 'impossible' | 'notStarted' | 'unknown';
type PreviewInfo = {
  previewState: PreviewState;
  previewStartDatejun: Datejun | undefined;
};
type PreviewReservationHooks = PreviewInfo & {
  canRedirect: boolean;
  redirect: () => void;
  redirectError?: 'invalidBukkenCondition' | 'invalidTakkenLicenseNumber';
};

export const usePreviewReservation = (
  bukkenOrBukkenView: ChinshakuBukken | SpecBukkenView,
  tracker?: UserActionTrackFunction
): PreviewReservationHooks => {
  // Beta 向け対応: 内見/申込管理 への誘導ボタンを一旦非表示にしている
  const sumaiEntryService = useDependency('sumaiEntryService');
  const isValidTakkenLicenseNumber = useIsValidTakkenLicenseNumber();
  const canRedirectAsyncState = useAsync(
    () => sumaiEntryService.checkIsPreviewReservationAvailable(bukkenOrBukkenView, isValidTakkenLicenseNumber),
    [bukkenOrBukkenView, isValidTakkenLicenseNumber, sumaiEntryService]
  );

  const chinshakuBukkenGuid =
    'chinshaku_bukken_view' in bukkenOrBukkenView
      ? bukkenOrBukkenView.chinshaku_bukken_view.chinshaku_bukken_guid
      : bukkenOrBukkenView.chinshaku_bukken_guid;
  const redirect = useCallback(() => {
    tracker?.('内見予約', chinshakuBukkenGuid);
    window.open(
      `${window.location.origin}/bukken/chintai/preview-reservation?guid=${chinshakuBukkenGuid}&domainGuid=${bukkenOrBukkenView.domain_guid}`,
      '_blank',
      'noopener,noreferrer'
    );
  }, [bukkenOrBukkenView.domain_guid, chinshakuBukkenGuid, tracker]);

  const naikenInfo = useMemo(() => calcPreviewInfo(bukkenOrBukkenView), [bukkenOrBukkenView]);

  const b2bKokaiCode =
    'chinshaku_bukken_view' in bukkenOrBukkenView
      ? bukkenOrBukkenView.chinshaku_boshu_joken_view.b2b_kokai_code
      : bukkenOrBukkenView.b2b_kokai_code;
  const isLimitedAccess = b2bKokaiCode === 4;
  let redirectError: 'invalidBukkenCondition' | 'invalidTakkenLicenseNumber' | undefined = undefined;
  if (!canRedirectAsyncState.value) {
    redirectError = 'invalidBukkenCondition';
  } else if (!isValidTakkenLicenseNumber && b2bKokaiCode === 3) {
    redirectError = 'invalidTakkenLicenseNumber';
  }

  return {
    ...naikenInfo,
    canRedirect: (canRedirectAsyncState.value && isValidTakkenLicenseNumber) || isLimitedAccess,
    redirect,
    redirectError,
  };
};

const calcPreviewInfo = (bukkenOrBukkenView: ChinshakuBukken | SpecBukkenView): PreviewInfo => {
  if ('chinshaku_bukken_view' in bukkenOrBukkenView) {
    return calcPreviewStateInner(
      asNaikenKubunCode(bukkenOrBukkenView.chinshaku_boshu_joken_view.naiken_kubun_code),
      Datejun.numberToDatejun(bukkenOrBukkenView.chinshaku_boshu_joken_view.naiken_kaishi_datejun)
    );
  }

  return calcPreviewStateInner(
    asNaikenKubunCode(bukkenOrBukkenView?.chinshaku_boshu_joken?.naiken_kubun_code),
    Datejun.numberToDatejun(bukkenOrBukkenView?.chinshaku_boshu_joken?.naiken_kaishi_datejun)
  );
};

const calcPreviewStateInner = (
  naikenKubunCode: NaikenKubunCodeType | null,
  naikenKaishiDatejun: Datejun | undefined
): PreviewInfo => {
  const todayDatejun = Datejun.dateToDatejun(startOfToday());
  let previewState: PreviewState = 'unknown';

  switch (naikenKubunCode) {
    case null:
      previewState = 'unknown';
      break;
    case NaikenKubunCode.impossible:
      previewState = 'impossible';
      break;
    case NaikenKubunCode.possible:
      previewState = 'possible';
      break;
    case NaikenKubunCode.planned:
      if (!naikenKaishiDatejun) {
        previewState = 'notStarted';
        break;
      }
      if (todayDatejun < naikenKaishiDatejun) {
        previewState = 'notStarted';
        break;
      }
      previewState = 'possible';
      break;
  }

  return {
    previewStartDatejun: naikenKaishiDatejun,
    previewState,
  };
};
