import { isNotNullOrUndefined } from '@app/utils';
import { Box, styled, Toolbar, toolbarClasses } from '@mui/material';
import { useCallback, useEffect, useState, FC } from 'react';

import { hasSearchConditionInvalid, ValidationContext } from './BukkenSearchForms/SearchConditionValidHooks';
import { DetailConditionHeader, RegionConditionHeader } from './ConditionHeader';
import DetailConditionForms from './DetailConditionForms';
import RegionConditionForms from './RegionConditionForms';

import { useAddressConditions, useDetailConditions } from '@/Hooks/Search/Conditions';
import { useMapAreaCondition } from '@/Hooks/Search/MapAreaSearchConditionHook';
import { useIsSmallDevice } from '@/Hooks/Styles/IsSmallDevice';
import { useCustomerViewStateHooks } from '@/Hooks/customerViewHooks';
import { getInitialChintaiSearchConditions } from '@/Models/SearchConditions/ChintaiBukkenSearchConditions';
import { ChintaiSearchConditions } from '@/Models/SearchConditions/ChintaiSearchConditions';

type BukkenSearchConditionsProps = {
  conditions: Readonly<ChintaiSearchConditions>;
  onSearch: (newConditions: ChintaiSearchConditions) => void;
  isOwnBukken: boolean;
};

const TopToolbar = styled(Toolbar)({
  [`&.${toolbarClasses.root}`]: { paddingRight: 0, paddingLeft: 0, minHeight: 0 },
});

const BukkenSearchConditionCard: FC<BukkenSearchConditionsProps> = ({ conditions, onSearch, isOwnBukken }) => {
  const customerViewStateHooks = useCustomerViewStateHooks();

  const detailHooks = useDetailConditions(conditions);
  const addressHooks = useAddressConditions(conditions);

  const detailConditions = Object.values(detailHooks);
  const addressConditions = Object.values(addressHooks);

  const mapAreaCondVal =
    isNotNullOrUndefined(conditions.jgsLatitudeFrom) &&
    isNotNullOrUndefined(conditions.jgsLatitudeTo) &&
    isNotNullOrUndefined(conditions.jgsLongitudeFrom) &&
    isNotNullOrUndefined(conditions.jgsLongitudeTo)
      ? {
          jgsLatitudeFrom: conditions.jgsLatitudeFrom,
          jgsLatitudeTo: conditions.jgsLatitudeTo,
          jgsLongitudeFrom: conditions.jgsLongitudeFrom,
          jgsLongitudeTo: conditions.jgsLongitudeTo,
        }
      : null;
  const mapAreaCondition = useMapAreaCondition(mapAreaCondVal, (value, conditions: ChintaiSearchConditions) => {
    conditions.jgsLatitudeFrom = value?.jgsLatitudeFrom ?? undefined;
    conditions.jgsLatitudeTo = value?.jgsLatitudeTo ?? undefined;
    conditions.jgsLongitudeFrom = value?.jgsLongitudeFrom ?? undefined;
    conditions.jgsLongitudeTo = value?.jgsLongitudeTo ?? undefined;
  });

  const [tabState, setTabState] = useState<number | null>(null);

  const [requestedToSearch, setRequestedToSearch] = useState(false);
  const requestSearch = useCallback(() => setRequestedToSearch(true), []);

  const emitSearchConditions = useCallback(() => {
    const conditions = getInitialChintaiSearchConditions();
    detailConditions.forEach(c => c.mapToConditions(conditions));
    addressConditions.forEach(c => c.mapToConditions(conditions));
    setTabState(null);
    onSearch(conditions);
  }, [detailConditions, addressConditions, onSearch]);

  useEffect(() => {
    if (requestedToSearch) {
      setRequestedToSearch(false);
      emitSearchConditions();
    }
  }, [emitSearchConditions, requestedToSearch]);

  const getOnTabClickFunc = (index: number): (() => void) => {
    return () => setTabState(tabState === index ? null : index);
  };

  const isSmallDevice = useIsSmallDevice();
  const closeDialog = () => {
    setTabState(null);
  };

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        filter:
          'drop-shadow(-5px -5px 10px rgba(174, 174, 192, 0.05)) drop-shadow(5px 5px 8px rgba(174, 174, 192, 0.3))',
      }}
    >
      {!isSmallDevice ? (
        <TopToolbar>
          <Box
            display="flex"
            sx={{
              minHeight: '4rem',
              width: '100%',
            }}
          >
            <Box flexBasis="25%" data-testid="tiikiSentakuSearch">
              <RegionConditionHeader
                addressHooks={addressHooks}
                tabOpen={tabState === 0}
                onTabClick={getOnTabClickFunc(0)}
                isSmallDevice={isSmallDevice}
                requestSearch={requestSearch}
                mapAreaConditionHook={mapAreaCondition}
              />
            </Box>
            <Box flexGrow={1} data-testid="shousaiJoukenSearch">
              <DetailConditionHeader
                detailHooks={detailHooks}
                requestSearch={requestSearch}
                onTabClick={getOnTabClickFunc(1)}
                isSmallDevice={isSmallDevice}
                isCustomerView={customerViewStateHooks.isCustomerView}
              />
            </Box>
          </Box>
        </TopToolbar>
      ) : (
        <Box
          p={2}
          sx={{
            background: theme => theme.palette.secondary.main,
            maxWidth: '100vw',
          }}
        >
          <Box mb={1} sx={{ background: 'white' }}>
            <RegionConditionHeader
              addressHooks={addressHooks}
              tabOpen={tabState === 0}
              onTabClick={getOnTabClickFunc(0)}
              isSmallDevice={isSmallDevice}
              requestSearch={requestSearch}
              mapAreaConditionHook={mapAreaCondition}
            />
          </Box>
          <Box sx={{ background: 'white' }}>
            <DetailConditionHeader
              detailHooks={detailHooks}
              requestSearch={requestSearch}
              onTabClick={getOnTabClickFunc(1)}
              isSmallDevice={isSmallDevice}
              isCustomerView={customerViewStateHooks.isCustomerView}
            />
          </Box>
        </Box>
      )}
      <ValidationContext.Provider value={hasSearchConditionInvalid(detailHooks)}>
        <RegionConditionForms
          isOpen={tabState === 0}
          hooks={addressHooks}
          onSearch={emitSearchConditions}
          closeDialog={closeDialog}
          searchTypeProps={{
            type: 'region',
            transitToRegion: () => {
              setTabState(1);
            },
          }}
          isOwnBukken={isOwnBukken}
        />
        <DetailConditionForms
          isOpen={tabState === 1}
          closeDialog={closeDialog}
          onClickSearchButton={emitSearchConditions}
          hooks={detailHooks}
          searchTypeProps={{
            type: 'region',
            transitToRegion: () => {
              setTabState(0);
            },
          }}
          normalSearchProps={{
            linesCondition: addressHooks.lineAndStationCondition.actualLinesCondition,
            stationsCondition: addressHooks.lineAndStationCondition.actualStationCondition,
          }}
        />
      </ValidationContext.Provider>
    </Box>
  );
};

export default BukkenSearchConditionCard;
