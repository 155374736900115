import { dequal } from 'dequal';
import { useState, useMemo } from 'react';

/**
 * 値が同値(deep-equal)の場合、同じオブジェクトを返却する useMemo です。
 */
export const useStableMemo = <T>(factory: () => T, deps: React.DependencyList): T => {
  const [current, setCurrent] = useState(factory);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memo = useMemo(factory, deps);
  if (!Object.is(current, memo) && !dequal(current, memo)) {
    setCurrent(memo);
  }

  return current;
};
