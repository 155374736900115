import { priceUnitCodeToText, asPriceUnitCode, PriceUnitCode } from '@app/enums';
import { isNullOrUndefined } from '@app/utils';

import { applyNumberFormat } from './Number';

export type MakePriceTextOptions = {
  emptyIfZero?: boolean;
  emptySubstituteText?: string;
  amountOnly?: boolean;
};
export const makePriceText = (
  amount: number | null | undefined,
  priceUnitCode: number | null | undefined,
  options?: MakePriceTextOptions
): string => {
  const emptySubstituteText = options?.emptySubstituteText ?? '';
  const unitCode = asPriceUnitCode(priceUnitCode);
  if (unitCode === PriceUnitCode.actualCost) {
    return '実費';
  }
  if (amount === null || amount === undefined) {
    return emptySubstituteText;
  }
  if (options?.emptyIfZero && amount === 0) {
    return emptySubstituteText;
  }

  const amountText = applyNumberFormat(amount);
  if (options?.amountOnly) {
    return `${amountText}`;
  }

  if (!unitCode) {
    return emptySubstituteText;
  }

  const unitText = priceUnitCodeToText(unitCode);
  return `${amountText}${unitText}`;
};

/**
 * 管理費・共益費・雑費などから金額を取り出す関数
 * @param amount 総額
 * @param priceUnitCode 円・実費以外が渡されるとエラーを発生させる
 */
export const kanrihiPriceToYen = (
  amount: number | null | undefined,
  priceUnitCode: number | null | undefined
): number => {
  if (isNullOrUndefined(amount)) {
    return 0;
  }
  const unitCode = asPriceUnitCode(priceUnitCode);
  if (isNullOrUndefined(unitCode)) {
    return 0;
  }
  switch (unitCode) {
    case PriceUnitCode.yen:
      return amount;
    case PriceUnitCode.actualCost:
      return 0; // 実費が入るパターンはほとんど確認されていないため無視する
    case PriceUnitCode.month:
    case PriceUnitCode.year:
    case PriceUnitCode.percent:
      throw RangeError('管理費・共益費・雑費の単位にヶ月・年・%の単位が渡されています。');
  }
};

/**
 * 初期費用計算シミュレーションにおいて賃料を基準とした金額を計算する関数
 * @param amount 総額
 * @param priceUnitCode 金額の単位
 * @param chinryo 1ヶ月分の賃料
 */

export const makePriceToYenBasedOnChinryo = (
  amount: number | null | undefined,
  priceUnitCode: number | null | undefined,
  chinryo: number | null | undefined
): number => {
  if (isNullOrUndefined(amount)) {
    return 0;
  }
  const unitCode = asPriceUnitCode(priceUnitCode);
  if (isNullOrUndefined(unitCode)) {
    return 0;
  }
  if (isNullOrUndefined(chinryo)) {
    return 0;
  }
  switch (unitCode) {
    case PriceUnitCode.month:
      return chinryo * amount;
    case PriceUnitCode.year:
      return chinryo * amount * 12;
    case PriceUnitCode.yen:
      return amount;
    case PriceUnitCode.percent:
      return Math.ceil(chinryo * (amount / 100));
    case PriceUnitCode.actualCost: //実費の場合は金額に含めない
      return 0;
  }
};
