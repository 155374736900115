import { GazoShubetsuCodeType, GazoShubetsuCode, asGazoShubetsuCode } from '@app/enums';
import { ChinshakuGazo } from '@app/models';
import { isNullOrEmptyString, deepCompare, isNullOrUndefined } from '@app/utils';
import { useOnOffState } from '@lib/components';
import { Box, Grid, Link, Typography, Button } from '@mui/material';
import { Calculator } from 'mdi-material-ui';
import { memo, ReactNode, useCallback, useMemo, useState, FC, Fragment } from 'react';

import { bukkenDetailSxProps } from './BukkenDetailStyle';
import { CalculatedDisplayData } from './CalcDisplayData';
import { ChinshakuBukkenDetailProps } from './ChinshakuBukkenDetailProps';
import { InitialCostCalcType } from './InitialCostDisplayDialogPartial/CreateInitialCostDisplayData';
import { InitialCostDisplayDialog } from './InitialCostDisplayDialogPartial/InitialCostDisplayDialog';
import TransitMapButton from './TransitMapTabButton';

import { BukkenTagList } from '@/Components/DataDisplay/BukkenTagList';
import { ImageCarouselModal } from '@/Components/DataDisplay/ImageCarouselModal';
import ImageFromFileApi from '@/Components/DataDisplay/ImageFromFileApi';
import { ImagedComponent } from '@/Components/DataDisplay/ImagedComponent';
import { SquareChip } from '@/Components/SquareChip';
import { useUserActionTracker } from '@/Hooks/Analytics';
import { useDependency } from '@/Hooks/DependencyHook';
import { spacingSxProps } from '@/Hooks/Styles/SpacingStyle';
import { textSxProps } from '@/Hooks/Styles/TextStyle';
import { viewStatusAction } from '@/Services/ISendingViewStatusService';
import { makeKukakuName } from '@/Utils/DisplayText/KukakuName';

const AnalyticsPlaceName = '賃借物件詳細';

const imageTypeOrderList: GazoShubetsuCodeType[] = [
  GazoShubetsuCode.gaikan,
  GazoShubetsuCode.madori,
  GazoShubetsuCode.genkan,
  GazoShubetsuCode.living,
  GazoShubetsuCode.kitchen,
  GazoShubetsuCode.bedroom,
  GazoShubetsuCode.huro,
  GazoShubetsuCode.veranda,
  GazoShubetsuCode.chirashi,
  GazoShubetsuCode.landmark,
  GazoShubetsuCode.reins,
  GazoShubetsuCode.chizu,
  GazoShubetsuCode.sonotaNaikan1,
  GazoShubetsuCode.shoeneSeinoLabel,
];
const hiddenImageTypeList: GazoShubetsuCodeType[] = [GazoShubetsuCode.torikago, GazoShubetsuCode.shikichiHaichiZu];
const compareFnByImageShowOrder = (a: ChinshakuGazo, b: ChinshakuGazo): number => {
  const codeA = asGazoShubetsuCode(a.gazo_shubetsu_code);
  const codeB = asGazoShubetsuCode(b.gazo_shubetsu_code);
  if (isNullOrUndefined(codeA) || isNullOrUndefined(codeB)) {
    return 0;
  }
  const orderIndexA = imageTypeOrderList.indexOf(codeA);
  const orderIndexB = imageTypeOrderList.indexOf(codeB);
  return orderIndexA > 0 && orderIndexB > 0
    ? orderIndexA - orderIndexB
    : orderIndexA > 0
    ? -1
    : orderIndexB > 0
    ? 1
    : codeA - codeB;
};

const reorderChinshakuGazoList = (original: ChinshakuGazo[]): readonly ChinshakuGazo[] => {
  // 鳥かご図・敷地配置図は管理物件からしか登録できず、媒体に出ることを意図されていない可能性が高いため、
  // One クライアントの物件詳細から登録可能な画像タイプのみに絞る
  const filteredGazoList = original.filter(it => !hiddenImageTypeList.map(Number).includes(it.gazo_shubetsu_code));
  return filteredGazoList.sort(compareFnByImageShowOrder);
};

export type BukkenHeaderProps = ChinshakuBukkenDetailProps & {
  isCustomerView: boolean;
  showMap: () => void;
  headerDisplayData: CalculatedDisplayData;
};

export const BukkenHeaderContainer: FC<BukkenHeaderProps> = memo(
  ({ chinshakuBukken, kukaku, tatemono, isMobile, isCustomerView, showMap, headerDisplayData }) => {
    const sendingViewStatusService = useDependency('sendingViewStatusService');
    const tracker = useUserActionTracker(AnalyticsPlaceName);
    const trackTel = useCallback(
      () => tracker('元付電話番号', chinshakuBukken.chinshaku_bukken_guid),
      [chinshakuBukken.chinshaku_bukken_guid, tracker]
    );
    const [isOpenCarouselModal, { setTrue: openCarouselModalInner, setFalse: closeCarouselModal }] =
      useOnOffState(false);
    const [isOpenInitialCostDialog, { setTrue: onOpenInitialCostDialog, setFalse: onCloseInitialCostDialog }] =
      useOnOffState(false);
    const [imageIndexToShow, setImageIndexToShow] = useState(0);

    const { orderedGazoGuids, headingImageGuid, restImageGuids } = useMemo(() => {
      const orderedGazoList = reorderChinshakuGazoList(chinshakuBukken?.chinshaku_gazo ?? []);
      const headingImageGuid = orderedGazoList[0]?.file_guid ?? null;
      const restImageGuids = orderedGazoList.length <= 1 ? [] : orderedGazoList.slice(1).map(g => g.file_guid);
      return {
        orderedGazoGuids: orderedGazoList.map(g => g.file_guid),
        headingImageGuid,
        restImageGuids,
      };
    }, [chinshakuBukken?.chinshaku_gazo]);

    const openCarouselModal = useCallback(
      (guid: string) => {
        setImageIndexToShow(orderedGazoGuids.indexOf(guid));
        openCarouselModalInner();
      },
      [openCarouselModalInner, orderedGazoGuids]
    );
    const openHeadingImageModal = useCallback(() => {
      if (orderedGazoGuids.length === 0) {
        return;
      }
      openCarouselModal(headingImageGuid);
    }, [headingImageGuid, openCarouselModal, orderedGazoGuids.length]);

    const tatemonoName = makeKukakuName(chinshakuBukken, tatemono, kukaku?.heya_kukaku_number ?? '');
    const madoriMensekiText = [headerDisplayData.madori, headerDisplayData.menseki].filter(t => t.length > 0).join('/');
    const kozoChikunensuText = [headerDisplayData.kozo, headerDisplayData.chikunensu]
      .filter(t => t.length > 0)
      .join('/');

    const onInitialCostDialogButtonClicked = useCallback(() => {
      onOpenInitialCostDialog();
      tracker('初期費用見積概算ボタン押下');
      sendingViewStatusService.send(
        {
          bukkenName: tatemono.tatemono_name ?? null,
          bukkenGuid: tatemono.tatemono_guid,
          kukakuName: kukaku?.heya_kukaku_number ?? null,
          kukakuGuid: kukaku?.kukaku_guid ?? null,
          kokaimotoGuid: chinshakuBukken.domain_guid,
        },
        viewStatusAction.initialCostEstimate
      );
    }, [
      chinshakuBukken.domain_guid,
      kukaku?.heya_kukaku_number,
      kukaku?.kukaku_guid,
      onOpenInitialCostDialog,
      sendingViewStatusService,
      tatemono.tatemono_guid,
      tatemono.tatemono_name,
      tracker,
    ]);

    const boshuJoken = chinshakuBukken.chinshaku_boshu_joken;
    const initialCostCalcData: InitialCostCalcType = {
      chinryo: boshuJoken?.chinryo,
      kanrihi: boshuJoken?.kanrihi,
      kanrihi_kubun_code: boshuJoken?.kanrihi_kubun_code,
      kyoekihi: boshuJoken?.kyoekihi,
      kyoekihi_kubun_code: boshuJoken?.kyoekihi_kubun_code,
      zappi_amount: boshuJoken?.zappi_amount,
      zappi_kubun_code: boshuJoken?.zappi_kubun_code,
      shikikin_amount: boshuJoken?.shikikin_amount,
      shikikin_kubun_code: boshuJoken?.shikikin_kubun_code,
      reikin_amount: boshuJoken?.reikin_amount,
      reikin_kubun_code: boshuJoken?.reikin_kubun_code,
      hoshokin_amount: boshuJoken?.hoshokin_amount,
      hoshokin_kubun_code: boshuJoken?.hoshokin_kubun_code,
      sompo_ryokin_amount: boshuJoken?.sompo_ryokin_amount,
      kokokuryo_amount: boshuJoken?.chinshaku_b2b_boshu_joken?.kokokuryo_amount,
      kokokuryo_tani: boshuJoken?.chinshaku_b2b_boshu_joken?.kokokuryo_tani,
      hoshonin_daiko_code: boshuJoken?.chinshaku_boshu_joken_shosai?.hoshonin_daiko_code,
      hoshonin_daiko_kaisha_kubun: boshuJoken?.chinshaku_boshu_joken_shosai?.hoshonin_daiko_kaisha_kubun,
      hoshonin_daiko_shosai: boshuJoken?.chinshaku_boshu_joken_shosai?.hoshonin_daiko_shosai,
      es_b2b_chintai_pdf_comment: chinshakuBukken.chinshaku_es_b2b?.es_b2b_chintai_pdf_comment,
      comment: chinshakuBukken.comment,
      sonotaHiyo: boshuJoken?.chinshaku_sonota_hiyo,
    };

    return (
      <BukkenHeaderPresenter
        isMobile={isMobile}
        isCustomerView={isCustomerView}
        showMap={showMap}
        headingImageGuid={headingImageGuid}
        restImageGuids={restImageGuids}
        orderedGazoGuids={orderedGazoGuids}
        openCarouselModal={openCarouselModal}
        openHeadingImageModal={openHeadingImageModal}
        headerDisplayData={{
          ...headerDisplayData,
          tatemonoName: tatemonoName,
          madoriMensekiText: madoriMensekiText,
          kozoChikunensuText: kozoChikunensuText,
        }}
        isOpenCarouselModal={isOpenCarouselModal}
        closeCarouselModal={closeCarouselModal}
        imageIndexToShow={imageIndexToShow}
        trackTel={trackTel}
        isOpenInitialCostDialog={isOpenInitialCostDialog}
        onInitialCostDialogButtonClicked={onInitialCostDialogButtonClicked}
        onCloseInitialCostDialog={onCloseInitialCostDialog}
        initialCostCalcData={initialCostCalcData}
      />
    );
  },
  deepCompare
);

export type DetailHeaderDisplayData = CalculatedDisplayData & {
  tatemonoName: string;
  madoriMensekiText: string;
  kozoChikunensuText: string;
};

type BukkenHeaderPresenterProps = {
  isMobile: boolean;
  isCustomerView: boolean;
  showMap: () => void;
  headingImageGuid: string;
  orderedGazoGuids: string[];
  restImageGuids: string[];
  openCarouselModal: (guid: string) => void;
  openHeadingImageModal: () => void;
  headerDisplayData: DetailHeaderDisplayData;
  isOpenCarouselModal: boolean;
  closeCarouselModal: () => void;
  imageIndexToShow: number;
  trackTel: () => void;
  isOpenInitialCostDialog: boolean;
  onInitialCostDialogButtonClicked: () => void;
  onCloseInitialCostDialog: () => void;
  initialCostCalcData: InitialCostCalcType;
};

export const BukkenHeaderPresenter: FC<BukkenHeaderPresenterProps> = memo(
  ({
    isMobile,
    isCustomerView,
    showMap,
    headingImageGuid,
    orderedGazoGuids,
    restImageGuids,
    openCarouselModal,
    openHeadingImageModal,
    headerDisplayData,
    isOpenCarouselModal,
    closeCarouselModal,
    imageIndexToShow,
    trackTel,
    isOpenInitialCostDialog,
    onInitialCostDialogButtonClicked,
    onCloseInitialCostDialog,
    initialCostCalcData,
  }) => {
    return (
      <Grid container>
        <Grid item xs={12} md={4} data-testid="bukkenHeaderTopImage">
          <Box px={{ xs: 0, md: 2 }} py={{ xs: 0, md: 1 }}>
            <ImageFromFileApi
              sizing="fullwidth"
              aspectRatio="4by3"
              src={headingImageGuid}
              onClick={openHeadingImageModal}
              sx={{
                '&:hover': {
                  cursor: orderedGazoGuids.length === 0 ? 'default' : 'pointer',
                },
              }}
            />
          </Box>
        </Grid>
        {isMobile ? (
          <Grid item xs={12}>
            <ImageListHorizontal imageGuids={restImageGuids} paddingTop={2} onClickImage={openCarouselModal} />
          </Grid>
        ) : null}
        <Grid item xs={12} md={8}>
          <Box py={1} px={{ xs: 1, md: 0 }}>
            {isMobile ? (
              <Box pb={1} sx={{ wordBreak: 'break-word', ...textSxProps.lg, ...textSxProps.bold }}>
                {headerDisplayData.tatemonoName}
              </Box>
            ) : null}
            <Box pb={1.5}>
              <BukkenTagList
                isCustomerView={isCustomerView}
                isNew={headerDisplayData.isNew}
                isChushajo={headerDisplayData.isChushajo}
                isTochi={headerDisplayData.isTochi}
                isApplied={headerDisplayData.isApplied}
                isADExists={headerDisplayData.isADExists}
                isShinchiku={headerDisplayData.isShinchiku}
                isGenteiKokai={headerDisplayData.isGenteiKokai}
                tensaiArray={headerDisplayData.tensaiArray}
              />
            </Box>
            <Box display="flex" alignItems="center" flexWrap="wrap">
              <Box pr={2}>{headerDisplayData.address}</Box>
              <TransitMapButton onClick={showMap} />
            </Box>
            <Box pb={1}>
              {headerDisplayData.kotsu.map((k, index) => (
                <div key={index}>{k}</div>
              ))}
            </Box>
            <Box sx={spacingSxProps.spaceY1}>
              <Grid container>
                <ImagedComponent>
                  <Box display="flex" flexDirection="row" alignItems="baseline">
                    <Box pr={0.5}>
                      <Typography sx={{ ...textSxProps.lg, ...textSxProps.bold }} component="span">
                        {headerDisplayData.chinryoAmount}
                      </Typography>
                    </Box>
                    <Box pr={1}>
                      <Typography sx={textSxProps.sm} component="span">
                        円
                      </Typography>
                    </Box>
                    <Typography component="span">(管理費等: {headerDisplayData.kanrihiEtcAmount})</Typography>
                  </Box>
                </ImagedComponent>
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<Calculator />}
                  onClick={onInitialCostDialogButtonClicked}
                  sx={{ marginLeft: '8px' }}
                >
                  見積概算
                </Button>
                <InitialCostDisplayDialog
                  onDialogClose={onCloseInitialCostDialog}
                  isDialogOpen={isOpenInitialCostDialog}
                  tatemonoName={headerDisplayData.tatemonoName}
                  initialCostCalcData={initialCostCalcData}
                  isCustomerView={isCustomerView}
                />
              </Grid>
              <Box display="flex" flexDirection="row" flexWrap="wrap" sx={spacingSxProps.spaceAllXY05}>
                <WithChip label="敷" justifyContent="space-between" isMobile={isMobile}>
                  {headerDisplayData.shikikin}
                </WithChip>
                <WithChip label="礼" justifyContent="space-between" isMobile={isMobile}>
                  {headerDisplayData.reikin}
                </WithChip>
                <WithChip label="退" isMobile={isMobile}>
                  {headerDisplayData.taikyoDateText}
                </WithChip>
                <WithChip label="入" isMobile={isMobile}>
                  {headerDisplayData.nyukyoKanoDateText}
                </WithChip>
              </Box>
              <Box pb={2} display="flex" flexDirection="row" columnGap={2}>
                <Typography>{headerDisplayData.madoriMensekiText}</Typography>
                <Typography>{headerDisplayData.kozoChikunensuText}</Typography>
              </Box>
            </Box>
            {!isCustomerView && (
              <Fragment>
                <Box display="flex" flexDirection="row" columnGap={2}>
                  <Typography sx={textSxProps.sm}>AD</Typography>
                  <Typography>{headerDisplayData.adAmount}</Typography>
                </Box>
                <Box display="flex" flexDirection="row" alignItems="baseline" data-testid="header-motoduke">
                  <Typography sx={{ whiteSpace: 'nowrap', ...textSxProps.sm }}>元付</Typography>
                  <Box pl={1} sx={{ wordBreak: 'break-all' }}>
                    {headerDisplayData.motodukeName}
                  </Box>
                  <Box px={1}>
                    {isMobile && !isNullOrEmptyString(headerDisplayData.motodukeTelNumber) ? (
                      <Link
                        href={`tel:${headerDisplayData.motodukeTelNumber}`}
                        sx={{ whiteSpace: 'nowrap', ...bukkenDetailSxProps.linkText }}
                        onClick={trackTel}
                      >
                        {headerDisplayData.motodukeTelNumber}
                      </Link>
                    ) : (
                      <Typography sx={{ whiteSpace: 'nowrap', ...textSxProps.lg, ...textSxProps.bold }}>
                        {headerDisplayData.motodukeTelNumber}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Fragment>
            )}
          </Box>
        </Grid>
        {isMobile ? null : (
          <Grid item xs={12}>
            <Box pb={2}>
              <ImageListHorizontal imageGuids={restImageGuids} paddingTop={0} onClickImage={openCarouselModal} />
            </Box>
          </Grid>
        )}
        <ImageCarouselModal
          imageGuids={orderedGazoGuids}
          isOpen={isOpenCarouselModal}
          onClose={closeCarouselModal}
          initialIndex={imageIndexToShow}
        />
      </Grid>
    );
  },
  deepCompare
);

type WithChipProps = {
  label: string;
  justifyContent?: 'space-between';
  isMobile: boolean;
  children: ReactNode;
};
const WithChip: FC<WithChipProps> = ({ label, justifyContent, isMobile, children }) => {
  if (label.length === 0) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="row" alignItems="center" justifyContent={justifyContent}>
      <SquareChip mr={isMobile ? 0.5 : 1}>{label}</SquareChip>
      <Typography sx={isMobile ? textSxProps.sm : undefined}>{children}</Typography>
    </Box>
  );
};

type ImageListHorizontalProps = {
  imageGuids: string[];
  paddingTop: number;
  onClickImage: (guid: string) => void;
};
const ImageListHorizontal: FC<ImageListHorizontalProps> = ({ imageGuids, paddingTop, onClickImage }) => {
  return (
    <Box px={2} pt={paddingTop} overflow="auto hidden" maxWidth="100%" whiteSpace="nowrap" display="flex" columnGap={1}>
      {imageGuids.map((g, index) => (
        <Box display="inline-block" height="80px" key={index}>
          <ImageFromFileApi sizing="auto" thumbnail src={g} onClick={() => onClickImage(g)} />
        </Box>
      ))}
    </Box>
  );
};
