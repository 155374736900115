export const SearchType = {
  region: 'region',
  map: 'map',
} as const;
export type SearchType = typeof SearchType[keyof typeof SearchType];

interface SearchProps {
  type: SearchType;
}

export interface RegionSearchProps extends SearchProps {
  type: 'region';
  transitToRegion: () => void;
}

export interface MapSearchProps extends SearchProps {
  type: 'map';
}
