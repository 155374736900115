import { Breakpoints, Typography, useTheme } from '@mui/material';
import { FC, ReactNode } from 'react';

import { textSxProps } from '@/Hooks/Styles/TextStyle';

export const SectionHeaderContainer: FC<{ children: ReactNode }> = ({ children }) => {
  const theme = useTheme();
  return (
    <SectionHeaderPresenter
      children={children}
      accentMain={theme.palette.accent.main}
      breakpoints={theme.breakpoints}
    />
  );
};

export { SectionHeaderContainer as SectionHeader };

type SectionHeaderPresenterProps = {
  children: ReactNode;
  accentMain: string;
  breakpoints: Breakpoints;
};

export const SectionHeaderPresenter: FC<SectionHeaderPresenterProps> = ({ children, accentMain, breakpoints }) => {
  return (
    <Typography
      variant="h2"
      sx={{
        marginTop: 2,
        marginBottom: 2,
        paddingLeft: 2,
        borderLeft: `6px solid ${accentMain}`,
        [breakpoints.down('md')]: {
          paddingLeft: 1,
          marginLeft: 1,
        },
        ...textSxProps.lg,
        ...textSxProps.bold,
      }}
    >
      {children}
    </Typography>
  );
};

export const SectionSubHeader: FC<{ children: ReactNode }> = ({ children }) => {
  const theme = useTheme();
  return (
    <Typography
      component="h3"
      sx={{
        marginTop: 2,
        marginBottom: 2,
        [theme.breakpoints.down('md')]: {
          paddingLeft: 1,
        },
        ...textSxProps.md,
        ...textSxProps.bold,
      }}
    >
      {children}
    </Typography>
  );
};
