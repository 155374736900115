import { Checkbox } from '@e-seikatsu/design-system';
import { Loading } from '@lib/components';
import { Box, Grid, Stack } from '@mui/material';
import { FC, useMemo } from 'react';
import { useAsync } from 'react-use';

import { borderSxProps } from '@/Hooks/Styles/BorderStyle';
import { useIsSmallDevice } from '@/Hooks/Styles/IsSmallDevice';
import { HeaderText } from '@/Pages/bukken/chintai/SearchPartial/BukkenSearchForms/RegionConditionPartial/HeaderText';
import { searchConditionSxProps } from '@/Pages/bukken/chintai/SearchPartial/BukkenSearchForms/SearchConditionStyle';
import { IMasterApiService, Prefecture } from '@/Services/IMasterApiService';
import { IStatsApiService } from '@/Services/IStatsApiService';
import { applyNumberFormat } from '@/Utils/DisplayText/Number';

type PrefWithStat = Prefecture & {
  readonly count?: number;
};

type PrefectureSelectorProps = {
  masterApiService: IMasterApiService;
  statsApiService: IStatsApiService;
  selectedPrefs: readonly number[];
  onSelectPref: (pref: number, checked: boolean) => void;
  isOwnBukken: boolean;
  domainGuid: string | undefined;
};

export const PrefectureSelector: FC<PrefectureSelectorProps> = ({
  masterApiService,
  statsApiService,
  selectedPrefs,
  onSelectPref,
  isOwnBukken,
  domainGuid,
}) => {
  const prefsByRegion = useAsync(() => masterApiService.getAllPrefecturesByRegion(), [masterApiService]);

  const { value: bukkenStatsByPref } = useAsync(
    () => statsApiService.getStatsByPrefs(isOwnBukken, domainGuid),
    [domainGuid, isOwnBukken, statsApiService]
  );

  const prefsByRegionWithStats = useMemo<Record<string, readonly PrefWithStat[]> | undefined>(() => {
    if (prefsByRegion.loading || prefsByRegion.value === undefined) {
      return undefined;
    }

    if (bukkenStatsByPref === undefined) {
      return prefsByRegion.value;
    }

    return Object.fromEntries(
      Object.entries(prefsByRegion.value).map(regionPrefPair => [
        regionPrefPair[0],
        regionPrefPair[1].map(pref => ({
          ...pref,
          count: bukkenStatsByPref.find(s => s.prefecture_code === pref.code)?.bukken_count ?? 0,
        })),
      ])
    );
  }, [bukkenStatsByPref, prefsByRegion.loading, prefsByRegion.value]);
  const isSmallDevice = useIsSmallDevice();

  const list =
    prefsByRegionWithStats === undefined ? (
      <Box p={4}>
        <Loading />
      </Box>
    ) : (
      Object.keys(prefsByRegionWithStats).map((r, index) => (
        <Grid container sx={searchConditionSxProps.root} key={r}>
          <Grid
            item
            xs={12}
            sm={2}
            sx={{
              ...searchConditionSxProps.firstCol,
              ...(index === 0 ? borderSxProps.borderY : borderSxProps.borderBottom),
            }}
            key={r}
          >
            {r}
          </Grid>
          <Grid
            item
            xs={12}
            sm={10}
            sx={{
              ...searchConditionSxProps.secondCol,
              ...searchConditionSxProps.checkboxGrid({ gridItemCountPC: 5, gridItemCountMobile: 2 }),
              ...(index === 0 && !isSmallDevice ? borderSxProps.borderY : borderSxProps.borderBottom),
            }}
          >
            {prefsByRegionWithStats[r].map(p => (
              <Checkbox
                data-testclass="checkbox"
                disabled={p.count === 0}
                checked={selectedPrefs.includes(p.code)}
                onChange={(e): void => onSelectPref(p.code, e.target.checked)}
                label={[p.name, p.count !== undefined ? ` (${applyNumberFormat(p.count)})` : ''].join('')}
                key={p.name}
                size="small"
              />
            ))}
          </Grid>
        </Grid>
      ))
    );

  return (
    <Stack gap={1}>
      <HeaderText fixedText="都道府県選択" isSmallDevice={isSmallDevice} />
      <Box>{list}</Box>
    </Stack>
  );
};
