import { ChinshakuBukken, Datejun, SpecBukkenView } from '@app/models';
import { startOfToday } from 'date-fns';
import { useCallback } from 'react';
import { useAsync } from 'react-use';

import { UserActionTrackFunction } from '@/Hooks/Analytics';
import { useDependency } from '@/Hooks/DependencyHook';
import { useIsValidTakkenLicenseNumber } from '@/Hooks/ValidationHooks';
type MoveinApplicationHooks = {
  canRedirect: boolean;
  redirect: () => void;
  redirectError?: 'invalidBukkenCondition' | 'invalidTakkenLicenseNumber';
  isAfterMoshikomiKaishiDate: boolean | undefined;
};

export const useMoveinApplication = (
  bukkenOrBukkenView: ChinshakuBukken | SpecBukkenView,
  tracker?: UserActionTrackFunction
): MoveinApplicationHooks => {
  const sumaiEntryService = useDependency('sumaiEntryService');
  const isValidTakkenLicenseNumber = useIsValidTakkenLicenseNumber();
  const canRedirectAsyncState = useAsync(
    () => sumaiEntryService.checkIsMoveinApplicationAvailable(bukkenOrBukkenView, isValidTakkenLicenseNumber),
    [bukkenOrBukkenView, isValidTakkenLicenseNumber, sumaiEntryService]
  );

  const chinshakuBukkenGuid =
    'chinshaku_bukken_view' in bukkenOrBukkenView
      ? bukkenOrBukkenView.chinshaku_bukken_view.chinshaku_bukken_guid
      : bukkenOrBukkenView.chinshaku_bukken_guid;
  const redirect = useCallback(() => {
    tracker?.('入居申込', chinshakuBukkenGuid);
    window.open(
      `${window.location.origin}/bukken/chintai/movein-application?guid=${chinshakuBukkenGuid}&domainGuid=${bukkenOrBukkenView.domain_guid}`,
      '_blank',
      'noopener,noreferrer'
    );
  }, [bukkenOrBukkenView.domain_guid, chinshakuBukkenGuid, tracker]);

  const b2bKokaiCode =
    'chinshaku_bukken_view' in bukkenOrBukkenView
      ? bukkenOrBukkenView.chinshaku_boshu_joken_view.b2b_kokai_code
      : bukkenOrBukkenView.b2b_kokai_code;
  const isLimitedAccess = b2bKokaiCode === 4;
  let redirectError: 'invalidBukkenCondition' | 'invalidTakkenLicenseNumber' | undefined = undefined;
  if (!canRedirectAsyncState.value) {
    redirectError = 'invalidBukkenCondition';
  } else if (!isValidTakkenLicenseNumber && b2bKokaiCode === 3) {
    redirectError = 'invalidTakkenLicenseNumber';
  }

  return {
    canRedirect: (canRedirectAsyncState.value && isValidTakkenLicenseNumber) || isLimitedAccess,
    redirect,
    redirectError,
    isAfterMoshikomiKaishiDate: getIsAfterMoshikomiKaishiDate(bukkenOrBukkenView),
  };
};

const getIsAfterMoshikomiKaishiDate = (bukkenOrBukkenView: ChinshakuBukken | SpecBukkenView): boolean | undefined => {
  const todayDatejun = Datejun.dateToDatejun(startOfToday());

  const moshikomiKaishiDateNumber =
    'chinshaku_bukken_view' in bukkenOrBukkenView
      ? bukkenOrBukkenView.chinshaku_boshu_joken_view.moshikomi_kaishi_datejun
      : bukkenOrBukkenView?.chinshaku_boshu_joken?.moshikomi_kaishi_datejun;
  const moshikomiKaishiDatejun = Datejun.numberToDatejun(moshikomiKaishiDateNumber);

  if (!moshikomiKaishiDatejun) {
    return undefined;
  }

  return todayDatejun >= moshikomiKaishiDatejun;
};
