import { NotImplementedError, HttpError } from '@app/errors';
import { ChinshakuBukken, Kukaku, Tatemono } from '@app/models';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useDependency } from '@/Hooks/DependencyHook';

type DetailPageParameters = {
  guid?: string;
};

type ChintaiBukkenDetailHook = {
  // ことごとく readonly にして immutable にしたい
  bukken: ChinshakuBukken | null;
  kukaku: Kukaku | null;
  tatemono: Tatemono | null;
  errorCode: number | null;
};

export default function useChintaiBukkenDetail(): ChintaiBukkenDetailHook {
  const { guid: chinshakuBukkenGuid } = useParams<DetailPageParameters>();
  const bukkenApiService = useDependency('bukkenApiService');
  const [bukken, setBukken] = useState<ChinshakuBukken | null>(null);
  const [kukaku, setKukaku] = useState<Kukaku | null>(null);
  const [tatemono, setTatemono] = useState<Tatemono | null>(null);
  const [errorCode, setErrorCode] = useState<number | null>(null);

  useEffect(() => {
    async function load(): Promise<void> {
      // guid が取得できない時(ex. 物件一覧ルートに居る)はロード処理は行わない
      if (!chinshakuBukkenGuid) {
        return;
      }

      let bukken: ChinshakuBukken;
      try {
        bukken = await bukkenApiService.getChinshakuBukken(chinshakuBukkenGuid);
      } catch (e) {
        if (e instanceof HttpError) {
          setErrorCode(e.statusCode);
        } else {
          throw e;
        }
        return;
      }
      setBukken(bukken);
      /**
       * spec_shubetsu_code
       * 土地 = 1
       * 建物 = 2,
       * 区画 = 3,
       * 建物付帯設備 = 4,
       */
      switch (bukken.spec_shubetsu_code) {
        case 2: {
          try {
            const tatemono = await bukkenApiService.getTatemono(chinshakuBukkenGuid);
            setTatemono(tatemono);
          } catch (e) {
            if (e instanceof HttpError) {
              setErrorCode(e.statusCode);
            } else {
              throw e;
            }
          }
          break;
        }
        case 3: {
          try {
            const kukaku = await bukkenApiService.getKukaku(chinshakuBukkenGuid);
            setKukaku(kukaku);
            setTatemono(kukaku?.tatemono ?? null);
          } catch (e) {
            if (e instanceof HttpError) {
              setErrorCode(e.statusCode);
            } else {
              throw e;
            }
          }
          break;
        }
        default:
          // 土地・建物付帯設備の場合の処理が未実装
          throw new NotImplementedError();
      }
    }
    load();
  }, [bukkenApiService, chinshakuBukkenGuid]);

  return {
    bukken,
    kukaku,
    tatemono,
    errorCode,
  };
}
