import { OoazaNotFoundError } from '@app/errors';
import { uniqStable } from '@app/utils';
import { dequal } from 'dequal';
import { klona } from 'klona';

import {
  BukkenDateRangeRadioState,
  B2bKokaiCodeRadioState,
  BukkenNyukyoKanoDateRangeRadioState,
  ChintaiResultOrder,
} from './ChintaiSearchConditionEnums/ChintaiSearchConditionEnums';
import { ChintaiSearchConditions } from './ChintaiSearchConditions';
import {
  ConditionPageQueryMappers,
  PageQueryValueGetterOptions,
  PageQueryValueSetterOptions,
} from './private/ConditionPageQueryMappers';
import { ConditionParamMappers } from './private/ConditionParamMappers';

import { reportError } from '@/ErrorLogger';
import { IMasterApiService } from '@/Services/IMasterApiService';

/* 検索条件の初期値を定義する */
/* 型は`ChintaiSearchConditions.ts`を参照 */
export const ChintaiSearchConditionsInitial: Readonly<ChintaiSearchConditions> = Object.freeze<ChintaiSearchConditions>(
  {
    bukkenName: '',
    bukkenNameKana: '',
    chinryoFrom: undefined,
    chinryoTo: undefined,
    komiChinryoFrom: undefined,
    komiChinryoTo: undefined,
    freeRentAvailable: false,
    isExcludeShikikin: false,
    isExcludeReikin: false,
    shikikinTo: undefined,
    reikinTo: undefined,
    kotsuEkitoho: undefined,
    jgsLatitudeFrom: undefined,
    jgsLatitudeTo: undefined,
    jgsLongitudeFrom: undefined,
    jgsLongitudeTo: undefined,
    mapWGSLatitude: undefined,
    mapWGSLongitude: undefined,
    mapZoom: undefined,
    mensekiFrom: undefined,
    mensekiTo: undefined,
    kokokuyroKagetsuFrom: undefined,
    kokokuryoKagetsuTo: undefined,
    searchBoshuShubetsuCode: [],
    searchMadoriCode: [],
    searchGenkyoCode: [],
    chikuNensu: undefined,
    isShinchiku: false,
    kozo: [],
    kokaiDateFrom: undefined,
    kokaiDateTo: undefined,
    kokaiRadioState: BukkenDateRangeRadioState.unselected,
    koshinDateFrom: undefined,
    koshinDateTo: undefined,
    koshinRadioState: BukkenDateRangeRadioState.unselected,
    nyukyoKanoDateFrom: undefined,
    nyukyoKanoDateTo: undefined,
    nyukyoKanoRadioState: {
      state: BukkenNyukyoKanoDateRangeRadioState.unselected,
      hasPlan: false,
    },
    naiken: '0',
    isExcludeMoshikomiExist: false,
    isSokunyukyoEnable: false,
    propertyFullKey: '',
    domainGuid: '',
    organizationGuid: '',
    places: [],
    stations: [],
    lines: [],
    kanriGyosha: '',
    kodawari: [],
    heyaNumber: '',
    order: ChintaiResultOrder.lastUpdatedTimeDescending,
    totalGazoCountFrom: undefined,
    gaikanGazoCountFrom: undefined,
    madoriGazoCountFrom: undefined,
    naikanGazoCountFrom: undefined,
    bukkenKokaiScopeRadioState: B2bKokaiCodeRadioState.unselected,
  }
);

export const getInitialChintaiSearchConditions = (): ChintaiSearchConditions => {
  return klona(ChintaiSearchConditionsInitial);
};

type IsChintaiSearchConditionsEmptyOptions = {
  ignoreMapLatLngZoom?: boolean;
};

export const isChintaiSearchConditionsEmpty = (
  conditions: Readonly<ChintaiSearchConditions>,
  options?: IsChintaiSearchConditionsEmptyOptions
): boolean => {
  // order は本質的な検索条件とは異なるので比較対象から外れるように初期値を入れておく
  const conditionsForCompare = {
    ...conditions,
    order: ChintaiSearchConditionsInitial.order,
  };
  if (options?.ignoreMapLatLngZoom === true) {
    conditionsForCompare.mapWGSLatitude = ChintaiSearchConditionsInitial.mapWGSLatitude;
    conditionsForCompare.mapWGSLongitude = ChintaiSearchConditionsInitial.mapWGSLongitude;
    conditionsForCompare.mapZoom = ChintaiSearchConditionsInitial.mapZoom;
  }
  return dequal(conditionsForCompare, ChintaiSearchConditionsInitial);
};

const appendIfNotNullOrEmpty = (params: URLSearchParams, paramName: string, paramValues: string[]): void => {
  paramValues.forEach(paramValue => {
    if (paramValue.length > 0) {
      params.append(paramName, paramValue);
    }
  });
};

export const getChintaiSearchParameters = (conditions: ChintaiSearchConditions): URLSearchParams => {
  const params = new URLSearchParams();

  for (const def of ConditionParamMappers) {
    appendIfNotNullOrEmpty(params, def.paramName, def.paramValueGetter(conditions));
  }
  params.append('type', 'chinshaku');

  return params;
};

export const getJushoSearchParameters = async (
  conditions: ChintaiSearchConditions,
  masterApiService: IMasterApiService
): Promise<URLSearchParams> => {
  const params = new URLSearchParams();
  const placeConditions = conditions.places;

  const cacheWarmPromises: Promise<unknown>[] = [];
  cacheWarmPromises.push(
    ...uniqStable(
      placeConditions.filter(p => p.ooaza.length > 0).map<[number, number]>(p => [p.prefecture, p.city])
    ).map(([pref, city]) => masterApiService.getOoaza(pref, city))
  );
  await Promise.all(cacheWarmPromises);

  for (const place of placeConditions) {
    let jushoCode = place.prefecture.toFixed(0).padStart(2, '0');
    if (place.city > 0) {
      jushoCode += place.city.toFixed(0).padStart(3, '0');
    }
    if (place.ooaza.length > 0) {
      const ooaza = await masterApiService.getOoazaSingle(place.prefecture, place.city, place.ooaza);
      if (ooaza !== null) {
        jushoCode = ooaza.address_code.slice(0, 8);
      } else {
        const error = new OoazaNotFoundError(place.prefecture, place.city, place.ooaza);
        reportError(error, 'getJushoSearchParameters');
        throw error;
      }
    }
    params.append('jusho_code.startswith', jushoCode);
  }

  return params;
};

export const getChintaiSearchPageQuery = (
  conditions: ChintaiSearchConditions,
  options?: PageQueryValueGetterOptions
): string => {
  const params = new URLSearchParams();

  for (const def of Object.values(ConditionPageQueryMappers)) {
    const paramValue = def.pageQueryValueGetter(conditions, options);
    appendIfNotNullOrEmpty(params, def.queryName, paramValue);
  }

  return params.toString();
};

export const parseChintaiSearchPageQuery = (
  query: string,
  options?: PageQueryValueSetterOptions
): ChintaiSearchConditions => {
  const params = new URLSearchParams(query);
  const result = getInitialChintaiSearchConditions();
  Object.values(ConditionPageQueryMappers).forEach(def => {
    const param = params.getAll(def.queryName);
    if (param.length === 0) {
      return;
    }
    def.pageQueryValueSetter(param, result, options);
  });

  return result;
};
