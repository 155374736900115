import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';

type BorderSxPropsKeys = 'border' | 'borderX' | 'borderY' | 'borderTop' | 'borderBottom' | 'borderLeft' | 'borderRight';
type BorderLinePropsKeys = 'solid' | 'dashed';

const generateBorderProps = (theme: Theme, lineKey?: BorderLinePropsKeys): string => {
  return `${lineKey === undefined ? 'solid' : lineKey} 1px var(--color-semantic-border-divider)`;
};

export const borderSxProps: Record<BorderSxPropsKeys, SystemStyleObject<Theme>> = {
  border: {
    borderTop: theme => generateBorderProps(theme),
    borderBottom: theme => generateBorderProps(theme),
    borderLeft: theme => generateBorderProps(theme),
    borderRight: theme => generateBorderProps(theme),
  },
  borderX: {
    borderLeft: theme => generateBorderProps(theme),
    borderRight: theme => generateBorderProps(theme),
  },
  borderY: {
    borderTop: theme => generateBorderProps(theme),
    borderBottom: theme => generateBorderProps(theme),
  },
  borderTop: {
    borderTop: theme => generateBorderProps(theme),
  },
  borderBottom: {
    borderBottom: theme => generateBorderProps(theme),
  },
  borderLeft: {
    borderLeft: theme => generateBorderProps(theme),
  },
  borderRight: {
    borderRight: theme => generateBorderProps(theme),
  },
};
