import { Address, StatByCity } from '@app/models';
import { uniqStable } from '@app/utils';
import { Button, Badge, Checkbox } from '@e-seikatsu/design-system';
import { ErrorMessage, Loading } from '@lib/components';
import { Box, Stack, Tooltip } from '@mui/material';
import { useCallback, useMemo, FC, useContext, Fragment, ChangeEvent } from 'react';
import { useAsync } from 'react-use';

import { getCityDataErrorMessageHeader } from '@/Consts/ErrorMessages';
import { useStableMemo } from '@/Hooks/StableMemo';
import { borderSxProps } from '@/Hooks/Styles/BorderStyle';
import { useIsSmallDevice } from '@/Hooks/Styles/IsSmallDevice';
import { PlaceSearchCondition } from '@/Models/SearchConditions/ChintaiSearchConditions';
import { HeaderText } from '@/Pages/bukken/chintai/SearchPartial/BukkenSearchForms/RegionConditionPartial/HeaderText';
import { searchConditionSxProps } from '@/Pages/bukken/chintai/SearchPartial/BukkenSearchForms/SearchConditionStyle';
import { ValidationContext } from '@/Pages/bukken/chintai/SearchPartial/BukkenSearchForms/SearchConditionValidHooks';
import { IMasterApiService } from '@/Services/IMasterApiService';
import { IStatsApiService } from '@/Services/IStatsApiService';
import { applyNumberFormat } from '@/Utils/DisplayText/Number';

type CityWithStat = {
  readonly name: string;
  readonly code: number;
  readonly count?: number;
};

type CitySelectorProps = {
  masterApiService: IMasterApiService;
  statsApiService: IStatsApiService;
  selectedPlaces: readonly PlaceSearchCondition[];
  onSelectCity: (pref: number, cities: readonly number[], checked: boolean) => void;
  isOwnBukken: boolean;
  domainGuid: string | undefined;
};

export const CitySelector: FC<CitySelectorProps> = props => {
  const selectedPrefs = useStableMemo(
    () => uniqStable(props.selectedPlaces.map(p => p.prefecture)),
    [props.selectedPlaces]
  );
  const { value: prefCodeToNameMap, loading: isLoadingNameMap } = useAsync(async () => {
    return new Map(
      (await Promise.all(selectedPrefs.map(p => props.masterApiService.getPrefecture(p)))).map(p => [p.code, p.name])
    );
  }, [props.masterApiService, selectedPrefs]);
  const citiesByPrefecture = useAsync(
    async () =>
      new Map<number, readonly Address[]>(
        await Promise.all(
          selectedPrefs.map(p =>
            props.masterApiService.getCities(p).then<[number, readonly Address[]]>(cities => [p, cities])
          )
        )
      ),
    [props.masterApiService, selectedPrefs]
  );

  const { value: bukkenStatsByCity } = useAsync(async (): Promise<Map<number, StatByCity[]> | undefined> => {
    return new Map(
      await Promise.all(
        selectedPrefs.map(p =>
          props.statsApiService
            .getStatsByCities(p, props.isOwnBukken, props.domainGuid)
            .then((v): [number, StatByCity[]] => [p, v])
        )
      )
    );
  }, [props.domainGuid, props.isOwnBukken, props.statsApiService, selectedPrefs]);

  const citiesByPrefWithStats = useMemo<Map<number, readonly CityWithStat[]> | undefined>(() => {
    if (citiesByPrefecture.loading || citiesByPrefecture.value === undefined) {
      return undefined;
    }

    if (bukkenStatsByCity === undefined) {
      return new Map(
        Array.from(citiesByPrefecture.value.entries()).map<[number, readonly CityWithStat[]]>(cbp => [
          cbp[0],
          cbp[1].map(c => ({
            name: c.city_name,
            code: parseInt(c.address_code.slice(2, 5)),
          })),
        ])
      );
    }

    return new Map(
      Array.from(citiesByPrefecture.value.entries()).map<[number, readonly CityWithStat[]]>(cbp => {
        return [
          cbp[0],
          cbp[1].map(c => {
            const cityCode = parseInt(c.address_code.slice(2, 5));
            return {
              name: c.city_name,
              code: cityCode,
              count: bukkenStatsByCity.get(cbp[0])?.find(s => s.city_code === cityCode)?.bukken_count ?? 0,
            };
          }),
        ];
      })
    );
  }, [bukkenStatsByCity, citiesByPrefecture.loading, citiesByPrefecture.value]);

  const isSmallDevice = useIsSmallDevice();

  const list =
    citiesByPrefecture.loading || isLoadingNameMap ? (
      <Box p={4}>
        <Loading />
      </Box>
    ) : citiesByPrefWithStats === undefined || prefCodeToNameMap === undefined ? (
      <Box p={4}>
        <ErrorMessage header={getCityDataErrorMessageHeader} small />
      </Box>
    ) : (
      <Fragment>
        {Array.from(citiesByPrefWithStats.keys()).map((p, index) => (
          <PrefectureCheckBoxGroup
            key={p}
            index={index}
            prefName={prefCodeToNameMap.get(p) ?? ''}
            prefCode={p}
            cities={citiesByPrefWithStats.get(p) ?? []}
            selectedCities={props.selectedPlaces.filter(sp => sp.prefecture === p && sp.city > 0).map(sp => sp.city)}
            onSelect={props.onSelectCity}
          />
        ))}
      </Fragment>
    );

  return prefCodeToNameMap === undefined ? null : (
    <Stack gap={1}>
      <HeaderText
        selectionStateText={selectedPrefs.map(p => prefCodeToNameMap.get(p) ?? '').join(', ')}
        fixedText="の市区郡"
        isSmallDevice={isSmallDevice}
      />
      <Box>{list}</Box>
    </Stack>
  );
};

type PrefectureCheckBoxGroupProps = {
  index: number;
  prefName: string;
  prefCode: number;
  cities: readonly CityWithStat[];
  selectedCities: readonly number[];
  onSelect: (pref: number, cities: readonly number[], checked: boolean) => void;
};
const PrefectureCheckBoxGroup: FC<PrefectureCheckBoxGroupProps> = props => {
  const allCitiesSelected = props.cities.length === props.selectedCities.length;
  const onSelectAll = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      const onSelect = props.onSelect;
      onSelect(
        props.prefCode,
        props.cities.map(c => c.code),
        e.target.checked
      );
    },
    [props.cities, props.onSelect, props.prefCode]
  );

  return (
    <Fragment>
      <Box
        sx={{
          ...searchConditionSxProps.firstColNoWrap,
          ...(props.index === 0 ? borderSxProps.borderY : borderSxProps.borderBottom),
        }}
      >
        <Checkbox
          data-testclass="largeCheckbox"
          checked={allCitiesSelected}
          onChange={onSelectAll}
          label={props.prefName}
          variant="text"
          size="small"
        />
      </Box>
      <Box
        sx={{
          ...searchConditionSxProps.secondColNoWrap,
          ...borderSxProps.borderBottom,
          ...searchConditionSxProps.checkboxGrid({ gridItemCountPC: 5, gridItemCountMobile: 2 }),
        }}
      >
        {props.cities.map(c => (
          <Checkbox
            data-testclass="checkbox"
            disabled={c.count === 0}
            checked={props.selectedCities.includes(c.code)}
            onChange={(e): void => props.onSelect(props.prefCode, [c.code], e.target.checked)}
            label={[c.name, c.count !== undefined ? ` (${applyNumberFormat(c.count)})` : ''].join('')}
            key={c.name}
            size="small"
          />
        ))}
      </Box>
    </Fragment>
  );
};

type CitySelectorFooterProps = {
  selectedPlaces: readonly PlaceSearchCondition[];
  onNext: () => void;
  onPrev: () => void;
  onDetermined: () => void;
  transitToRegion: () => void;
};

export const CitySelectorFooter: FC<CitySelectorFooterProps> = props => {
  const isCitySelected = props.selectedPlaces.filter(sp => sp.city > 0).length > 0;
  const isSearchConditionInvalid = useContext(ValidationContext);
  const isSmallDevice = useIsSmallDevice();

  return (
    <Box display="flex" width="100%">
      <Box flexGrow={1} textAlign="left">
        <Button
          onClick={props.onPrev}
          prependIcon="chevronLeft"
          variant="text"
          color="primary"
          size={!isSmallDevice ? 'medium' : 'small'}
        >
          都道府県
        </Button>
      </Box>
      <Box textAlign="right" display="flex" columnGap={1}>
        <Badge invisible={!isSearchConditionInvalid} color="red" value="!" position="outsider">
          <Button
            onClick={props.transitToRegion}
            variant="outlined"
            color="primary"
            size={!isSmallDevice ? 'medium' : 'small'}
          >
            詳細条件を入力
          </Button>
        </Badge>
        <Button
          onClick={props.onNext}
          disabled={!isCitySelected}
          variant="outlined"
          color="primary"
          size={!isSmallDevice ? 'medium' : 'small'}
        >
          町名を選択
        </Button>
        <Tooltip title={isSearchConditionInvalid ? '入力項目にエラーがあります' : ''} placement="top">
          <Box minWidth="60px">
            <Button
              onClick={props.onDetermined}
              disabled={!isCitySelected || isSearchConditionInvalid}
              variant="contained"
              color="primary"
              size={!isSmallDevice ? 'medium' : 'small'}
              fullWidth
            >
              検索
            </Button>
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};
