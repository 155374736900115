import { useEffect, useMemo } from 'react';
import { useAsyncFn } from 'react-use';

import { reportError } from '@/ErrorLogger';
import { UserActionTrackFunction } from '@/Hooks/Analytics';
import { useDependency } from '@/Hooks/DependencyHook';
import { viewStatusAction, ViewStatusBukken } from '@/Services/ISendingViewStatusService';

type FlyerDownloadHook = {
  motodukeDownload: () => Promise<void>;
  kyakudukeDownload: () => Promise<void>;
  canDownload: boolean;
  isDownloading: boolean;
};
export const useFlyerDownload = (
  bukkenGuid: string,
  viewStatusBukken: ViewStatusBukken,
  tracker?: UserActionTrackFunction
): FlyerDownloadHook => {
  const flyerService = useDependency('flyerService');
  const sendingViewStatusService = useDependency('sendingViewStatusService');

  const [motodukeDownloadStates, motodukeDownload] = useAsyncFn(() => {
    tracker?.('元付チラシ出力', bukkenGuid);
    sendingViewStatusService.send(viewStatusBukken, viewStatusAction.motodukeFlyer);
    return flyerService.getChinshakuBukkenMotodukeFlyer(bukkenGuid);
  }, [tracker, bukkenGuid, sendingViewStatusService, viewStatusBukken, flyerService]);

  const [kyakudukeDownloadStates, kyakudukeDownload] = useAsyncFn(() => {
    tracker?.('客付チラシ出力', bukkenGuid);
    sendingViewStatusService.send(viewStatusBukken, viewStatusAction.kyakudukeFlyer);
    return flyerService.getChinshakuBukkenKyakudukeFlyer(bukkenGuid);
  }, [tracker, bukkenGuid, sendingViewStatusService, viewStatusBukken, flyerService]);

  useEffect(() => {
    if (motodukeDownloadStates.error) {
      reportError(motodukeDownloadStates.error, 'useFlyerDownload');
    }
    if (kyakudukeDownloadStates.error) {
      reportError(kyakudukeDownloadStates.error, 'useFlyerDownload');
    }
  }, [motodukeDownloadStates.error, kyakudukeDownloadStates.error]);

  const isDownloading = useMemo(
    () => motodukeDownloadStates.loading || kyakudukeDownloadStates.loading,
    [kyakudukeDownloadStates.loading, motodukeDownloadStates.loading]
  );

  return {
    motodukeDownload,
    kyakudukeDownload,
    isDownloading,
    canDownload: !isDownloading,
  };
};
