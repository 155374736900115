import {
  asBaitaiCode,
  asNyukyoMoshikomiMethodCode,
  asTorihikiTaiyoCode,
  baitaiCodeToText,
  nyukyoMoshikomiMethodCodeToText,
  torihikiTaiyoCodeToText,
  naikenKubunCodeToText,
  asNaikenKubunCode,
  naikenYoyakuHohoCodeToText,
  asNaikenYoyakuHohoCode,
  naikenHohoCodeToText,
  asNaikenHohoCode,
} from '@app/enums';
import { ChinshakuBoshuJokenTensai, ChinshakuBukken, Datejun } from '@app/models';
import { isNullOrEmptyString, isNotNullOrEmptyString, isNotNullOrUndefined } from '@app/utils';
import { Box } from '@mui/material';
import { FC, Fragment, useMemo } from 'react';
import Linkify from 'react-linkify';

import { ChinshakuBukkenDetailProps } from './ChinshakuBukkenDetailProps';
import { DetailGrid, DisplayData } from './DetailGrid';
import { SectionHeader } from './SectionHeaders';

import { appendHyphenToZipCode } from '@/Utils/DisplayText/Address';
import { makePriceText } from '@/Utils/DisplayText/Money';
import { LinkifyUrlSafeMatchDecorator } from '@/Utils/DisplayText/UrlMatch';

const calcData = (chinshakuBukken: ChinshakuBukken): DisplayData[] => {
  const boshuJoken = chinshakuBukken.chinshaku_boshu_joken;
  const b2bBoshuJoken = chinshakuBukken?.chinshaku_boshu_joken?.chinshaku_b2b_boshu_joken;
  const motozuke = chinshakuBukken?.chinshaku_boshu_joken?.chinshaku_boshu_joken_motozuke;

  const moshikomiFlag = boshuJoken?.moshikomi_flag;
  const motozukeGyoshaYubinNumber = motozuke?.motozuke_gyosha_yubin_number;
  const motozukeGyoshaFax = motozuke?.motozuke_gyosha_fax;
  const nyukyoMoshikomiMethod = nyukyoMoshikomiMethodCodeToText(
    asNyukyoMoshikomiMethodCode(boshuJoken?.nyukyo_moshikomi_code)
  );
  const naikenKubun = naikenKubunCodeToText(asNaikenKubunCode(boshuJoken?.naiken_kubun_code));
  const naikenKaishiDatejun = Datejun.numberToDatejun(boshuJoken?.naiken_kaishi_datejun)?.toSlashString();
  const naikenYoyakuHoho = naikenYoyakuHohoCodeToText(asNaikenYoyakuHohoCode(boshuJoken?.naiken_yoyaku_hoho_code));
  const naikenHoho = naikenHohoCodeToText(asNaikenHohoCode(boshuJoken?.naiken_hoho_code));

  return [
    {
      label: '元付備考',
      content: isNotNullOrEmptyString(motozuke?.motozuke_gyosha_comment) ? (
        <div>
          <Linkify matchDecorator={LinkifyUrlSafeMatchDecorator}>{motozuke?.motozuke_gyosha_comment}</Linkify>
        </div>
      ) : (
        '-'
      ),
      span: true,
    },
    {
      label: '広告可否',
      content: makeKokokuPermissionText(boshuJoken?.chinshaku_boshu_joken_tensai),
    },
    {
      label: 'AD',
      content: makePriceText(b2bBoshuJoken?.kokokuryo_amount, b2bBoshuJoken?.kokokuryo_tani, {
        emptyIfZero: true,
        emptySubstituteText: '-',
      }),
    },
    {
      label: '申込',
      content: isNotNullOrUndefined(moshikomiFlag) ? (moshikomiFlag ? '申込あり' : '申込なし') : '-',
    },
    {
      label: '入居申込方法',
      content: isNotNullOrEmptyString(nyukyoMoshikomiMethod) ? nyukyoMoshikomiMethod : '-',
    },
    {
      label: '内見区分',
      content: isNotNullOrEmptyString(naikenKubun) ? naikenKubun : '-',
    },
    {
      label: '内見開始日',
      content: isNotNullOrEmptyString(naikenKaishiDatejun) ? naikenKaishiDatejun : '-',
    },
    {
      label: '内見予約方法',
      content: isNotNullOrEmptyString(naikenYoyakuHoho) && naikenKubun !== '不可' ? naikenYoyakuHoho : '-',
    },
    {
      label: '内見方法',
      content: isNotNullOrEmptyString(naikenHoho) && naikenKubun !== '不可' ? naikenHoho : '-',
    },
    {
      label: 'お問合せ先',
      content: (
        <Box display="flex" flexDirection="column">
          <div>{motozuke?.motozuke_gyosha_name ?? '-'}</div>
          <Box pl={2}>
            取引態様: {torihikiTaiyoCodeToText(asTorihikiTaiyoCode(motozuke?.motozuke_gyosha_torihiki_taiyo_code))}
          </Box>
          <Box pl={2}>担当者: {motozuke?.motozuke_gyosha_tantosha_name ?? '-'}</Box>
          <Box pl={2}>
            所在地:{' '}
            {isNotNullOrUndefined(motozukeGyoshaYubinNumber)
              ? `〒${appendHyphenToZipCode(motozukeGyoshaYubinNumber)}`
              : ''}{' '}
            {motozuke?.motozuke_gyosha_jusho_full_text ?? '-'}
          </Box>
          <Box pl={2}>
            TEL: {motozuke?.motozuke_gyosha_denwa_number}{' '}
            {!isNullOrEmptyString(motozukeGyoshaFax) ? `FAX: ${motozukeGyoshaFax}` : ''}
          </Box>
        </Box>
      ),
      span: true,
    },
  ];
};

const makeKokokuPermissionText = (tensaiArray: ChinshakuBoshuJokenTensai[] | null | undefined): string => {
  if (!tensaiArray) {
    return '広告不可';
  }

  if (tensaiArray[0]?.baitai_code === 100) {
    return '広告可';
  }

  // 媒体コードから媒体名を解決できないものと「賃貸標準形式」と出るものは存在しない媒体なので外しておく
  // (媒体がサービス終了するなどで使用されなくなったコードが残っている可能性がある)
  const permittedBaitaiList = tensaiArray
    .map(t => baitaiCodeToText(asBaitaiCode(t.baitai_code)))
    .filter(baitaiName => baitaiName.length > 0 && !baitaiName.includes('賃貸標準形式'))
    .join('、');

  return `広告可 (但し次の媒体に限る: ${permittedBaitaiList})`;
};

export const ForChukaiCompanyComponent: FC<ChinshakuBukkenDetailProps> = ({ chinshakuBukken }) => {
  const data = useMemo<DisplayData[]>(() => calcData(chinshakuBukken), [chinshakuBukken]);

  return <ForChukaiCompanyComponentPresenter data={data} />;
};

type ForChukaiCompanyComponentPresenterProps = {
  data: DisplayData[];
};

export const ForChukaiCompanyComponentPresenter: FC<ForChukaiCompanyComponentPresenterProps> = ({ data }) => {
  return (
    <Fragment>
      <SectionHeader>不動産会社様向け情報</SectionHeader>
      <DetailGrid data={data} />
    </Fragment>
  );
};
