import { useCallback } from 'react';

import {
  useApplicationStatusCondition,
  useApprovalStatusCondition,
  useB2bKokaiCodeSearchCondition,
  useChinryoSearchCondition,
  useGazoSearchCondition,
  useKokaiDateSearchCondition,
  useKoshinDateSearchCondition,
  useListSearchCondition,
  useNumberOrUndefinedSearchCondition,
  useNyukyoKanoDateSearchCondition,
  useSelectSearchCondition,
  useShikikinReikinSearchCondition,
} from './CustomSearchCondition';
import { PlaceSearchConditionHook, usePlaceSearchCondition } from './PlaceSearchCondition';
import {
  useBooleanSearchCondition,
  usePartnerStringSearchCondition,
  useStringSearchCondition,
} from './PrimitiveSearchCondition';
import { PartnerSearchConditionHook } from './SearchConditionHook';
import { useLineAndStationCondition } from './StationOrLineSearchCondition';

import {
  NaikenConditions,
  NaikenConditionsLabelMap,
} from '@/Models/SearchConditions/ChintaiSearchConditionEnums/ChintaiSearchConditionEnums';
import { ChintaiSearchConditions } from '@/Models/SearchConditions/ChintaiSearchConditions';
import {
  ApprovalStatusCollectionType,
  KanriPartnerSearchConditions,
} from '@/Models/SearchConditions/KanriPartnerSearchConditions';
import {
  ApplicationStatusCollectionType,
  PartnerSearchConditions,
} from '@/Models/SearchConditions/PartnerSearchConditions';
import { DetailSearchConditionHooks } from '@/Pages/bukken/chintai/SearchPartial/DetailConditionForms';
import { RegionConditionHooks } from '@/Pages/bukken/chintai/SearchPartial/RegionConditionForms';

export const useDetailConditions = (conditions: Readonly<ChintaiSearchConditions>): DetailSearchConditionHooks => {
  const boshuShubetsuCondition = useListSearchCondition(
    conditions.searchBoshuShubetsuCode,
    useCallback((value, conditions: ChintaiSearchConditions) => (conditions.searchBoshuShubetsuCode = value), [])
  );
  const madoriCondition = useListSearchCondition(
    conditions.searchMadoriCode,
    useCallback((value, conditions: ChintaiSearchConditions) => (conditions.searchMadoriCode = value), [])
  );
  const genkyoCondition = useListSearchCondition(
    conditions.searchGenkyoCode,
    useCallback((value, conditions: ChintaiSearchConditions) => (conditions.searchGenkyoCode = value), [])
  );
  const chikuNensuCondition = useNumberOrUndefinedSearchCondition(
    conditions.chikuNensu,
    useCallback((value, conditions: ChintaiSearchConditions) => (conditions.chikuNensu = value), [])
  );
  const isShinchikuCondition = useBooleanSearchCondition(
    conditions.isShinchiku,
    useCallback((value, conditions: ChintaiSearchConditions) => (conditions.isShinchiku = value), [])
  );
  const kozoCondition = useListSearchCondition(
    conditions.kozo,
    useCallback((value, conditions: ChintaiSearchConditions) => (conditions.kozo = value), [])
  );
  const chinryoCondition = useChinryoSearchCondition({
    chinryoFrom: conditions.chinryoFrom,
    chinryoTo: conditions.chinryoTo,
    komiChinryoFrom: conditions.komiChinryoFrom,
    komiChinryoTo: conditions.komiChinryoTo,
    isIncludeManagementFee: false,
    isFreeRentAvailable: conditions.freeRentAvailable,
  });
  const shikikinReikinCondition = useShikikinReikinSearchCondition(conditions);
  const mensekiFromCondition = useNumberOrUndefinedSearchCondition(
    conditions.mensekiFrom,
    useCallback((value, conditions: ChintaiSearchConditions) => (conditions.mensekiFrom = value), [])
  );
  const mensekiToCondition = useNumberOrUndefinedSearchCondition(
    conditions.mensekiTo,
    useCallback((value, conditions: ChintaiSearchConditions) => (conditions.mensekiTo = value), [])
  );
  const kokokuyroKagetsuFromCondition = useNumberOrUndefinedSearchCondition(
    conditions.kokokuyroKagetsuFrom,
    useCallback((value, conditions: ChintaiSearchConditions) => (conditions.kokokuyroKagetsuFrom = value), [])
  );
  const kotsuEkitohoCondition = useNumberOrUndefinedSearchCondition(
    conditions.kotsuEkitoho,
    useCallback((value, conditions: ChintaiSearchConditions) => (conditions.kotsuEkitoho = value), [])
  );
  const kokaiCondition = useKokaiDateSearchCondition(conditions);
  const koshinDateCondition = useKoshinDateSearchCondition(conditions);
  const nyukyoKanoDateCondition = useNyukyoKanoDateSearchCondition(conditions);
  const naikenCondition = useSelectSearchCondition(
    conditions.naiken,
    useCallback((value, conditions: ChintaiSearchConditions) => (conditions.naiken = value), []),
    NaikenConditions.unspecified,
    Object.values(NaikenConditions),
    NaikenConditionsLabelMap
  );
  const kodawariCondition = useListSearchCondition(
    conditions.kodawari,
    useCallback((value, conditions: ChintaiSearchConditions) => (conditions.kodawari = value), [])
  );
  const isExcludeMoshikomiExistCondition = useBooleanSearchCondition(
    conditions.isExcludeMoshikomiExist,
    useCallback((value, conditions: ChintaiSearchConditions) => (conditions.isExcludeMoshikomiExist = value), [])
  );
  const isSokunyukyoEnableCondition = useBooleanSearchCondition(
    conditions.isSokunyukyoEnable,
    useCallback((value, conditions: ChintaiSearchConditions) => (conditions.isSokunyukyoEnable = value), [])
  );

  const bukkenNameCondition = useStringSearchCondition(
    conditions.bukkenName,
    useCallback((value, conditions: ChintaiSearchConditions) => (conditions.bukkenName = value), [])
  );
  const bukkenNameKanaCondition = useStringSearchCondition(
    conditions.bukkenNameKana,
    useCallback((value, conditions: ChintaiSearchConditions) => (conditions.bukkenNameKana = value), [])
  );
  const kanriGyoshaCondition = useStringSearchCondition(
    conditions.kanriGyosha,
    useCallback((value, conditions: ChintaiSearchConditions) => (conditions.kanriGyosha = value), [])
  );
  const heyaNumberCondition = useStringSearchCondition(
    conditions.heyaNumber,
    useCallback((value, conditions: ChintaiSearchConditions) => (conditions.heyaNumber = value), [])
  );
  const gazoSearchCondition = useGazoSearchCondition(conditions);
  const bukkenKokaiScopeCondition = useB2bKokaiCodeSearchCondition(conditions);

  return {
    bukkenNameCondition,
    bukkenNameKanaCondition,
    mensekiFromCondition,
    mensekiToCondition,
    boshuShubetsuCondition,
    chinryoCondition,
    shikikinReikinCondition,
    madoriCondition,
    genkyoCondition,
    chikuNensuCondition,
    isShinchikuCondition,
    kozoCondition,
    kokokuyroKagetsuFromCondition,
    kotsuEkitohoCondition,
    kokaiCondition,
    koshinDateCondition,
    nyukyoKanoDateCondition,
    naikenCondition,
    kanriGyoshaCondition,
    kodawariCondition,
    heyaNumberCondition,
    isExcludeMoshikomiExistCondition,
    isSokunyukyoEnableCondition,
    gazoSearchCondition,
    bukkenKokaiScopeCondition,
  };
};

export type PartnerDetailSearchConditionHooks = {
  motodukeNameCondition: PartnerSearchConditionHook<string>;
  denwaFaxNumberCondition: PartnerSearchConditionHook<string>;
  applicationStatusCondition: PartnerSearchConditionHook<ApplicationStatusCollectionType[]>;
};

export const usePartnerDetailConditions = (
  conditions: Readonly<PartnerSearchConditions>
): PartnerDetailSearchConditionHooks => {
  const motodukeNameCondition = usePartnerStringSearchCondition(conditions.motodukeName);
  const denwaFaxNumberCondition = usePartnerStringSearchCondition(conditions.denwaFaxNumber);
  const applicationStatusCondition = useApplicationStatusCondition(conditions.applicationStatus);

  return {
    motodukeNameCondition,
    denwaFaxNumberCondition,
    applicationStatusCondition,
  };
};

export type KanriPartnerDetailSearchConditionHooks = {
  kyakudukeNameCondition: PartnerSearchConditionHook<string>;
  denwaFaxNumberCondition: PartnerSearchConditionHook<string>;
  approvalStatusCondition: PartnerSearchConditionHook<ApprovalStatusCollectionType[]>;
};

export const useKanriPartnerDetailConditions = (
  conditions: Readonly<KanriPartnerSearchConditions>
): KanriPartnerDetailSearchConditionHooks => {
  const kyakudukeNameCondition = usePartnerStringSearchCondition(conditions.kyakudukeName);
  const denwaFaxNumberCondition = usePartnerStringSearchCondition(conditions.denwaFaxNumber);
  const approvalStatusCondition = useApprovalStatusCondition(conditions.approvalStatus);

  return {
    kyakudukeNameCondition,
    denwaFaxNumberCondition,
    approvalStatusCondition,
  };
};

export const useAddressConditions = (conditions: Readonly<ChintaiSearchConditions>): RegionConditionHooks => {
  const placesCondition = usePlaceSearchCondition(
    conditions.places,
    useCallback((value, conditions: ChintaiSearchConditions) => (conditions.places = value), [])
  );
  const lineAndStationCondition = useLineAndStationCondition(
    conditions.lines,
    conditions.stations,
    useCallback((lines, stations, conditions: ChintaiSearchConditions) => {
      conditions.lines = lines;
      conditions.stations = stations;
    }, [])
  );

  return {
    placesCondition,
    lineAndStationCondition,
  };
};

export const usePartnerAreaConditions = (
  conditions: Readonly<PartnerSearchConditions | KanriPartnerSearchConditions>
): PlaceSearchConditionHook<PartnerSearchConditions | KanriPartnerSearchConditions> => {
  const placesCondition = usePlaceSearchCondition(
    conditions.places,
    useCallback(
      (value, conditions: PartnerSearchConditions | KanriPartnerSearchConditions) => (conditions.places = value),
      []
    )
  );

  return placesCondition;
};
