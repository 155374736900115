import { Stack, Button, Tooltip, Grid } from '@mui/material';
import { Plus, HelpCircle } from 'mdi-material-ui';
import { FC } from 'react';

import { ExtraInitialCostData } from './CreateInitialCostDisplayData';

type InitialCostAddExtraFormButtonContainerProps = {
  extraInitialCostDisplayData: ExtraInitialCostData[];
  setExtraInitialCostDisplayData: (value: React.SetStateAction<ExtraInitialCostData[]>) => void;
  disabled: boolean;
  description: string;
};

const InitialCostAddExtraFormButtonContainer: FC<InitialCostAddExtraFormButtonContainerProps> = ({
  extraInitialCostDisplayData,
  setExtraInitialCostDisplayData,
  disabled,
  description,
}) => {
  const onClick = (): void => {
    setExtraInitialCostDisplayData([...extraInitialCostDisplayData, { label: '', price: '', id: Math.random() }]);
  };
  return <InitialCostAddExtraFormButtonPresenter onClick={onClick} disabled={disabled} description={description} />;
};

export { InitialCostAddExtraFormButtonContainer as InitialCostAddExtraFormButton };

type InitialCostAddExtraFormButtonPresenterProps = {
  onClick: () => void;
  disabled: boolean;
  description: string;
};

export const InitialCostAddExtraFormButtonPresenter: FC<InitialCostAddExtraFormButtonPresenterProps> = ({
  onClick,
  disabled,
  description,
}) => (
  <Grid item xs={12}>
    <Stack direction="row" alignItems="center" justifyContent={{ xs: 'flex-end', md: 'flex-start' }}>
      <Button startIcon={<Plus />} css={{ fontWeight: 'bold' }} onClick={onClick} disabled={disabled}>
        {description}
      </Button>
      <Tooltip title="月次費用と一時金で最大10個まで項目を追加できます">
        <HelpCircle css={{ color: 'gray' }} />
      </Tooltip>
    </Stack>
  </Grid>
);
