import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { isNullOrUndefined } from '@app/utils';
import { Box } from '@mui/material';
import { Magnify } from 'mdi-material-ui';
import { useMemo } from 'react';
import { ConditionBadge } from './ConditionBadge';
export const DetailConditionBarPresenter = ({ children, onBarClick, isSmallDevice, onOtherBadgeDeleted, badgeLimit, }) => {
    const detailConditionBadges = useMemo(() => {
        if (isNullOrUndefined(badgeLimit) || isNullOrUndefined(onOtherBadgeDeleted)) {
            return children;
        }
        let badgeListClone = children;
        const badgeLength = badgeListClone.length;
        if (badgeLength > badgeLimit) {
            badgeListClone = badgeListClone.slice(0, badgeLimit - 1);
            badgeListClone.push(_jsx(ConditionBadge, { label: `ほか${badgeLength - badgeLimit + 1}件`, onDelete: onOtherBadgeDeleted }));
        }
        return badgeListClone;
    }, [badgeLimit, children, onOtherBadgeDeleted]);
    return (_jsxs(Box, Object.assign({ display: "flex", onClick: onBarClick, sx: {
            padding: 1,
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: theme => theme.palette.grey[200],
            },
            height: '100%',
        } }, { children: [_jsx(Box, Object.assign({ display: "flex", flexShrink: 0, alignItems: "center", sx: {
                    pr: 1,
                    minWidth: 0,
                } }, { children: _jsx(Magnify, { sx: {
                        color: 'primary.main',
                        fontSize: '1.75rem',
                    } }) })), _jsx(Box, Object.assign({ flexGrow: 1, display: "flex", alignItems: "center", flexWrap: isSmallDevice ? 'nowrap' : 'wrap', sx: {
                    minWidth: 0,
                    overflowX: isSmallDevice ? 'auto' : undefined,
                } }, { children: detailConditionBadges.length > 0 ? detailConditionBadges : '検索条件を入力' }))] })));
};
