import { Alert } from '@e-seikatsu/design-system';
import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';

type InitialCostDisplayAlertContainerProps = {
  initialCostPriceTotalAmount: number;
};

const InitialCostDisplayAlertContainer: FC<InitialCostDisplayAlertContainerProps> = ({
  initialCostPriceTotalAmount,
}) => {
  return <InitialCostDisplayAlertPresenter initialCostPriceTotalAmount={initialCostPriceTotalAmount} />;
};

export { InitialCostDisplayAlertContainer as InitialCostDisplayAlert };

type InitialCostDisplayAlertPresenterProps = {
  initialCostPriceTotalAmount: number;
};

export const InitialCostDisplayAlertPresenter: FC<InitialCostDisplayAlertPresenterProps> = ({
  initialCostPriceTotalAmount,
}) => (
  <Stack spacing={1}>
    <Alert color="warning">
      <Alert.Content>
        契約にかかる費用の目安としてご利用ください。金額はあくまで参考値のため変動することがあります。
      </Alert.Content>
    </Alert>

    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      p={2}
      css={{ backgroundColor: 'rgb(237, 247, 237)' }}
    >
      <Box justifyContent="center">
        合計
        <Typography fontSize="1.25rem" css={{ fontWeight: 'bold' }} component="span" marginLeft={1} marginRight={1}>
          {initialCostPriceTotalAmount.toLocaleString()}
        </Typography>
        円
      </Box>
    </Box>
  </Stack>
);
