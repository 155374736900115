import {
  madoriCodeToText,
  asMadoriCode,
  asNyukyoKanoCode,
  PriceUnitCode,
  ShinchikuChukoCode,
  TatemonoShubetsuCode,
} from '@app/enums';
import { ChinshakuBukken, Datejun, Kukaku, Tatemono, ChinshakuBoshuJokenTensai } from '@app/models';
import { isNotNullOrUndefined, isNullOrUndefined } from '@app/utils';
import { differenceInCalendarDays, parseISO, startOfToday } from 'date-fns';

import { B2bKokaiCodeRadioState } from '@/Models/SearchConditions/ChintaiSearchConditionEnums/ChintaiSearchConditionEnums';
import {
  calcAreaSizeForDisplay,
  makeKotsuText,
  makeNyukyoKanoText,
  makeTaikyoDateText,
} from '@/Utils/DisplayText/Bukken';
import { shunkoDatejunToChikuNensu } from '@/Utils/DisplayText/DateJun';
import { kanrihiPriceToYen, makePriceText } from '@/Utils/DisplayText/Money';

export type CalculatedDisplayData = {
  isNew: boolean;
  isChushajo: boolean;
  isTochi: boolean;
  isApplied: boolean;
  isShinchiku: boolean;
  isADExists: boolean;
  isGenteiKokai: boolean;
  address: string;
  kotsu: string[];
  chinryoAmount: string;
  kanrihiEtcAmount: string;
  shikikin: string;
  reikin: string;
  taikyoDateText: string;
  nyukyoKanoDateText: string;
  madori: string;
  menseki: string;
  kozo: string;
  chikunensu: string;
  adAmount: string;
  motodukeName: string;
  motodukeTelNumber: string;
  tensaiArray: ChinshakuBoshuJokenTensai[];
};
export const calcDisplayData = (
  chinshakuBukken: ChinshakuBukken,
  kukaku: Kukaku | null,
  tatemono: Tatemono
): CalculatedDisplayData => {
  const boshuJoken = chinshakuBukken.chinshaku_boshu_joken;
  const b2bBoshuJoken = boshuJoken?.chinshaku_b2b_boshu_joken;

  const isNew = differenceInCalendarDays(startOfToday(), parseISO(chinshakuBukken.b2b_kokai_kaishi_time ?? '')) <= 7;
  const isChushajo =
    tatemono.tatemono_shubetsu_code === TatemonoShubetsuCode.parking ||
    tatemono.tatemono_shubetsu_code === TatemonoShubetsuCode.motorcycleParking;
  const isTochi = tatemono.tatemono_shubetsu_code === TatemonoShubetsuCode.land;
  const isShinchiku = chinshakuBukken.chinshaku_boshu_joken?.shinchiku_chuko_code === ShinchikuChukoCode.shinchiku;
  const kotsu = chinshakuBukken.chinshaku_kotsu?.map(k => makeKotsuText(k)) ?? [];
  const address = isNotNullOrUndefined(tatemono.jusho_text)
    ? `${tatemono.jusho_text}${
        b2bBoshuJoken?.b2b_eda_bango_hyoji_flag ? `${tatemono.nokori_jusho ?? ''}${tatemono.gaiku_goto ?? ''}` : ''
      }`
    : '';
  const chinryoAmount = makePriceText(boshuJoken?.chinryo, null, { amountOnly: true, emptySubstituteText: '-' });
  const kanrihi = kanrihiPriceToYen(boshuJoken?.kanrihi, boshuJoken?.kanrihi_kubun_code);
  const kyoekihi = kanrihiPriceToYen(boshuJoken?.kyoekihi, boshuJoken?.kyoekihi_kubun_code);
  const kanrihiEtc = kanrihi + kyoekihi;
  const kanrihiEtcUnitCode =
    boshuJoken?.kanrihi_kubun_code === PriceUnitCode.actualCost ||
    boshuJoken?.kyoekihi_kubun_code === PriceUnitCode.actualCost
      ? PriceUnitCode.actualCost
      : PriceUnitCode.yen;
  const kanrihiEtcAmount = makePriceText(kanrihiEtc, kanrihiEtcUnitCode, {
    emptyIfZero: true,
    emptySubstituteText: '-',
  });
  const shikikin = makePriceText(boshuJoken?.shikikin_amount, boshuJoken?.shikikin_kubun_code, {
    emptyIfZero: true,
    emptySubstituteText: '-',
  });
  const reikin = makePriceText(boshuJoken?.reikin_amount, boshuJoken?.reikin_kubun_code, {
    emptyIfZero: true,
    emptySubstituteText: '-',
  });
  const taikyoDateText = makeTaikyoDateText(boshuJoken?.taikyo_date);
  const nyukyoKanoDateText = makeNyukyoKanoText(
    asNyukyoKanoCode(boshuJoken?.nyukyo_kano_code),
    Datejun.numberToDatejun(boshuJoken?.nyukyo_kano_datejun)
  );
  const madori = `${kukaku?.madori_heyasu ?? '-'}${madoriCodeToText(asMadoriCode(kukaku?.madori_code))}`;
  const mensekiVal = calcAreaSizeForDisplay(kukaku, tatemono, tatemono.tochi);
  const menseki = mensekiVal > 0 ? `${mensekiVal.toFixed(2)}㎡` : '-';
  const chikunensuNumber = shunkoDatejunToChikuNensu(Datejun.numberToDatejun(tatemono.shunko_datejun));
  const chikunensu = isShinchiku
    ? '新築'
    : isNullOrUndefined(chikunensuNumber)
    ? '-'
    : chikunensuNumber === 0
    ? '築1年以内'
    : `${chikunensuNumber}年`;
  const adAmount = makePriceText(b2bBoshuJoken?.kokokuryo_amount, b2bBoshuJoken?.kokokuryo_tani, {
    emptyIfZero: true,
    emptySubstituteText: '-',
  });

  const moshikomiFlag = boshuJoken?.moshikomi_flag;
  const kokokuryoAmount = b2bBoshuJoken?.kokokuryo_amount;
  const isADExists =
    isNotNullOrUndefined(kokokuryoAmount) && kokokuryoAmount > 0 && isNotNullOrUndefined(b2bBoshuJoken?.kokokuryo_tani);
  const isGenteiKokai = String(chinshakuBukken.b2b_kokai_code) === B2bKokaiCodeRadioState.partner;
  return {
    isNew,
    isChushajo,
    isTochi,
    isApplied: isNotNullOrUndefined(moshikomiFlag) ? moshikomiFlag : false,
    isShinchiku,
    isADExists: isADExists,
    isGenteiKokai: isGenteiKokai,
    address,
    kotsu,
    chinryoAmount,
    kanrihiEtcAmount,
    shikikin,
    reikin,
    taikyoDateText,
    nyukyoKanoDateText,
    madori,
    menseki,
    kozo: tatemono.kozo ?? '-',
    chikunensu,
    adAmount,
    motodukeName: boshuJoken?.chinshaku_boshu_joken_motozuke?.motozuke_gyosha_name ?? '',
    motodukeTelNumber: boshuJoken?.chinshaku_boshu_joken_motozuke?.motozuke_gyosha_denwa_number ?? '',
    tensaiArray: boshuJoken?.chinshaku_boshu_joken_tensai ?? [],
  };
};
