import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import { AppState } from '@/Consts/AppState';
import { isItemPerPageKey } from '@/Models/SearchConditions/ChintaiSearchConditionEnums/ChintaiSearchConditionEnums';

type PageHook<LocationStateType = unknown> = {
  currentPage: number;
  itemsPerPage: number;
  setPage: (pageIndex: number) => void;
  setItemsPerPage: (itemsPerPage: number) => void;
  setSearch: (search: string, state?: LocationStateType) => void;
  pushPageHistory: (
    search: string,
    pageIndex: number,
    itemsPerPage: number,
    state?: LocationStateType | undefined
  ) => void;
};

const PageParamName = 'p';
const DefaultPage = 1;
const ItemsPerPageParamName = 'items_per_page';
const DefaultItemsPerPage = 10;

export const usePage = <LocationStateType>(): PageHook<LocationStateType> => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [itemsPerPage, setItemsPerPageLocalStorage] = useLocalStorage(
    AppState.bukkenListItemsPerPage,
    isItemPerPageKey(Number(params.get(ItemsPerPageParamName)))
      ? Number(params.get(ItemsPerPageParamName))
      : Number(DefaultItemsPerPage.toFixed(0))
  ) as [number, React.Dispatch<React.SetStateAction<number>>, () => void];
  const currentPage = Number(params.get(PageParamName) ?? DefaultPage.toFixed(0));

  const pushPageHistory = useCallback(
    (search: string, pageIndex: number, itemsPerPage: number, state?: LocationStateType) => {
      const params = new URLSearchParams(search);
      params.set(PageParamName, pageIndex.toFixed(0));
      params.set(ItemsPerPageParamName, itemsPerPage.toFixed(0));
      navigate(
        {
          search: params.toString(),
        },
        { state: state ?? location.state }
      );
    },
    [navigate, location.state]
  );

  const setPage = useCallback(
    (newPageIndex: number) => pushPageHistory(location.search, newPageIndex, itemsPerPage),
    [itemsPerPage, location.search, pushPageHistory]
  );

  const setItemsPerPage = useCallback(
    (newItemsPerPage: number) => {
      // 主に検索結果の範囲外に行ってしまうのを防ぐ意図で、
      // 大体同じカーソル位置に行くようにページ数を書き換える
      const newPage = Math.floor(((currentPage - 1) * itemsPerPage) / newItemsPerPage) + 1;
      setItemsPerPageLocalStorage(newItemsPerPage);
      pushPageHistory(location.search, newPage, itemsPerPage);
    },
    [currentPage, itemsPerPage, location.search, pushPageHistory, setItemsPerPageLocalStorage]
  );

  // 外挿されるクエリが変更される時はページ数はリセットする
  const setSearch = useCallback(
    (search: string, state?: LocationStateType) => pushPageHistory(search, DefaultPage, itemsPerPage, state),
    [itemsPerPage, pushPageHistory]
  );

  return {
    currentPage,
    itemsPerPage,
    setPage,
    setItemsPerPage,
    setSearch,
    pushPageHistory,
  };
};
