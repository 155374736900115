import { ChinshakuBukken, SpecBukkenView } from '@app/models';
import { MuiButtonColorType, useOnOffState } from '@lib/components';
import { Button, buttonClasses, Tooltip } from '@mui/material';
import { AccountPlusOutline, MessageTextOutline } from 'mdi-material-ui';
import { createElement, FC, Fragment, MouseEventHandler, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { ActionButtonLayout } from './ActionButtonLayout';
import { PartnerApplicantsSendExplanationDialog } from './PartnerApplicantsSendExplanationDialog';

import { messengerWindowFeatures } from '@/Components/Partner/PartnerStatusButton';
import { useUserActionTracker } from '@/Hooks/Analytics';
import { useDependency } from '@/Hooks/DependencyHook';
import { useCurrentUser } from '@/Hooks/Esa/RequireCurrentUser';
import { usePartnerApplicants } from '@/Hooks/Esa/usePartnerApplicants';
import { usePartners } from '@/Hooks/Esa/usePartners';
import {
  PUBLIC_DEFAULT_PER_PAGE,
  PUBLIC_DEFAULT_START_INDEX,
  useEsaPublicDomain,
  useEsaPublicOrganization,
} from '@/Hooks/Esa/usePublic';
import { EsaPublicDomain } from '@/Models/Esa/EsaDomain';
import { EsaPublicOrganization } from '@/Models/Esa/EsaOrganization';
import { PartnerApplicantsStatusCollection, PartnerApplicantsTypeCollection } from '@/Models/Esa/PartnerApplicants';
import { searchResultSxProps } from '@/Pages/bukken/chintai/SearchPartial/SearchResultStyles';
import { ServiceConfigure } from '@/Services/Configure';
import { viewStatusAction, ViewStatusBukken } from '@/Services/ISendingViewStatusService';
import { getMotozukeGyosha } from '@/Utils/BukkenUtils';
import { getButtonElement } from '@/Utils/PartnerUtils';

type ContainerProps = {
  bukkenGuid: string;
  bukkenOrBukkenView: SpecBukkenView | ChinshakuBukken;
  motozukeGyoshaGuids: (string | null | undefined)[];
  analyticsPlace: string;
  buttonVariant: 'text' | 'outlined' | 'contained';
  viewStatusBukken: ViewStatusBukken;
  buttonColor?: MuiButtonColorType | 'accent';
  useIconButton?: boolean;
};

export const MessageButtonContainer: FC<ContainerProps> = props => {
  const sendingViewStatusService = useDependency('sendingViewStatusService');
  const tracker = useUserActionTracker(props.analyticsPlace);
  const sourceUserId = uuidv4();
  const viewStatusMessage = { ...props.viewStatusBukken, messageSourceUserId: sourceUserId };
  const { motozukeGyoshaGuid, motozukeGyoshaResourceType } = getMotozukeGyosha(props.bukkenOrBukkenView);
  const user = useCurrentUser();
  const [isOpenExplanationDialog, { setTrue: openExplanationDialog, setFalse: closeExplanationDialog }] =
    useOnOffState(false);

  const searchPartnerQuery = {
    startIndex: PUBLIC_DEFAULT_START_INDEX,
    itemsPerPage: PUBLIC_DEFAULT_PER_PAGE,
    organizationUids: user.defaultOrganizationUid ?? '',
    expandOrganization: true,
    partnerOrganizationGuids: (props.motozukeGyoshaGuids ?? []).join(','),
  };
  const { model: partnersModel } = usePartners(searchPartnerQuery);
  const isPartner =
    partnersModel?.items?.some(partner => {
      if (motozukeGyoshaGuid === undefined) {
        return false;
      }
      return partner.partnerOrganization?.ebone?.organizationGuid === motozukeGyoshaGuid;
    }) ?? false;

  const partnerApplicantsQuery = {
    startIndex: PUBLIC_DEFAULT_START_INDEX,
    itemsPerPage: PUBLIC_DEFAULT_PER_PAGE,
  };
  const currentOrganizationUid = user.defaultOrganizationUid ?? '';
  const { model: partnerApplicantsSentStandbyModel } = usePartnerApplicants({
    ...partnerApplicantsQuery,
    type: PartnerApplicantsTypeCollection.sent,
    states: PartnerApplicantsStatusCollection.standby,
    organizationUids: currentOrganizationUid,
    expandDomain: true,
    expandOrganization: true,
  });
  const isSentApplying =
    partnerApplicantsSentStandbyModel?.items?.some(applicant => {
      if (motozukeGyoshaGuid === undefined) {
        return false;
      }
      return applicant.partnerOrganization?.ebone?.organizationGuid === motozukeGyoshaGuid;
    }) ?? false;

  const { model: partnerApplicantsReceivedStandbyModel } = usePartnerApplicants({
    ...partnerApplicantsQuery,
    type: PartnerApplicantsTypeCollection.received,
    states: PartnerApplicantsStatusCollection.standby,
    organizationUids: currentOrganizationUid,
    expandDomain: true,
    expandOrganization: true,
  });
  const isReceivedApplying =
    partnerApplicantsReceivedStandbyModel?.items?.some(applicant => {
      if (motozukeGyoshaGuid === undefined) {
        return false;
      }
      return applicant.organization?.ebone?.organizationGuid === motozukeGyoshaGuid;
    }) ?? false;

  const isOwnProperty = useMemo(() => {
    if (!user.ebone) {
      return false;
    }
    return user.ebone?.domainGuid === props.bukkenOrBukkenView.domain_guid;
  }, [user.ebone, props.bukkenOrBukkenView.domain_guid]);

  const searchOrganizationParams = useMemo(() => {
    const SearchOrganizationParamsTemp = new URLSearchParams();
    SearchOrganizationParamsTemp.append('startIndex', '1');
    SearchOrganizationParamsTemp.append('organizationGuids', props.motozukeGyoshaGuids?.join(',') ?? '');
    return SearchOrganizationParamsTemp;
  }, [props.motozukeGyoshaGuids]);
  const { model: publicOrganizationModel } = useEsaPublicOrganization(searchOrganizationParams);
  const publicOrganizationList = publicOrganizationModel?.items ?? [];
  const organization = publicOrganizationList?.find(
    organization => organization.ebone?.organizationGuid === motozukeGyoshaGuid
  );

  const isOrganizationPrivate = organization === undefined ? true : false;

  const getDomainParams = new URLSearchParams({
    domainUids: publicOrganizationList
      .map((publicOrganization: EsaPublicOrganization) => {
        return publicOrganization.domainUid;
      })
      .join(','),
  });
  const { model: publicDomainModel } = useEsaPublicDomain(getDomainParams);
  const domain = publicDomainModel?.items?.find(domain => domain.domainUid === organization?.domainUid) ?? [];

  const isInvalidMotozukeGyousha = useMemo(() => {
    return !motozukeGyoshaGuid || motozukeGyoshaResourceType !== 'directory/organization';
  }, [motozukeGyoshaGuid, motozukeGyoshaResourceType]);

  const onOpenMessenger: MouseEventHandler<HTMLButtonElement> = e => {
    tracker('メッセージ', props.bukkenGuid);
    e.stopPropagation();
    sendingViewStatusService.send(viewStatusMessage, viewStatusAction.message);
    window.open(
      `${ServiceConfigure.messengerUrl}/bukken_threads?bukken_guid=${props.bukkenGuid}`,
      '_blank',
      messengerWindowFeatures
    );
  };

  const onOpenSendPartnerApplicant = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    tracker('物件からの取引先申請', props.bukkenGuid);
    e.stopPropagation();
    openExplanationDialog();
  };

  const onCloseDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    closeExplanationDialog();
    e.stopPropagation();
  };

  const buttonElement = getButtonElement(
    isPartner,
    isOrganizationPrivate,
    isSentApplying,
    isReceivedApplying,
    isOwnProperty,
    isInvalidMotozukeGyousha
  );

  return (
    <MessageButtonPresenter
      disabled={buttonElement.disabled}
      onClick={isPartner ? onOpenMessenger : onOpenSendPartnerApplicant}
      tooltipText={buttonElement.tooltipText}
      text={buttonElement.text}
      iconButton={buttonElement.icon}
      organization={organization as EsaPublicOrganization}
      domain={domain as EsaPublicDomain}
      isOpenDialog={isOpenExplanationDialog}
      closeDialog={onCloseDialog}
      {...props}
    />
  );
};

export { MessageButtonContainer as MessageButton };

export type MessageButtonPresenterProps = {
  buttonVariant: 'text' | 'outlined' | 'contained';
  buttonColor?: MuiButtonColorType | 'accent';
  tooltipText: string;
  text: string;
  iconButton: typeof MessageTextOutline | typeof AccountPlusOutline | string;
  domain: EsaPublicDomain;
  organization: EsaPublicOrganization;
  useIconButton?: boolean;
  disabled: boolean;
  isOpenDialog: boolean;
  closeDialog: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClick: MouseEventHandler<HTMLButtonElement>;
  analyticsPlace: string;
};

export const MessageButtonPresenter: FC<MessageButtonPresenterProps> = ({
  buttonVariant,
  buttonColor,
  tooltipText,
  text,
  iconButton,
  domain,
  organization,
  useIconButton,
  disabled,
  isOpenDialog,
  closeDialog,
  onClick,
  analyticsPlace,
}) => {
  return (
    <Fragment>
      <ActionButtonLayout>
        <Tooltip title={tooltipText} disableInteractive>
          <span>
            <Button
              variant={buttonVariant}
              color={buttonColor !== 'accent' ? buttonColor : 'primary'}
              startIcon={!useIconButton && typeof iconButton !== 'string' ? createElement(iconButton) : ''}
              disabled={disabled}
              onClick={!disabled ? onClick : undefined}
              sx={{
                [`&.${buttonClasses.root}`]: useIconButton && searchResultSxProps.actionSquareButton,
                [`&.${buttonClasses.outlined}`]: {
                  ...searchResultSxProps.actionButtonOutlined,
                  paddingLeft: 1,
                  paddingRight: 1,
                },
                [`& .${buttonClasses.startIcon}`]: searchResultSxProps.actionButtonStartIcon,
              }}
            >
              {text}
            </Button>
          </span>
        </Tooltip>
      </ActionButtonLayout>
      {isOpenDialog && (
        <PartnerApplicantsSendExplanationDialog
          isOpenExplanationDialog={isOpenDialog}
          closeExplanationDialog={closeDialog}
          domain={domain}
          organization={organization}
          analyticsPlace={analyticsPlace}
        />
      )}
    </Fragment>
  );
};
