import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDependency } from './DependencyHook';

import { RouteDefinitions } from '@/Pages/RouteDefinitions';

export type CustomerViewStateHooks = {
  isCustomerView: boolean;
  toggleIsCustomerView: () => void;
};

export const useCustomerViewStateHooks = (location?: string): CustomerViewStateHooks => {
  const customerViewService = useDependency('customerViewService');
  const [isCustomerView, setIsCustomerView] = useState(false);
  useEffect(() => {
    return customerViewService.subscribeIsCustomerView((isCustomerView: boolean) => {
      setIsCustomerView(isCustomerView);
    });
  }, [customerViewService]);

  const navigate = useNavigate();
  const toggleIsCustomerView = useCallback((): void => {
    customerViewService.toggleIsCustomerView();
    // 取引先管理等、接客用表示で見せたくないページにいる場合に物件検索画面に遷移する用途で使用
    if (!(location?.startsWith('/bukken') || location?.startsWith('/own_bukken'))) {
      navigate({
        pathname: RouteDefinitions.chintaiBukkenSearch.path,
      });
    }
  }, [customerViewService, location, navigate]);

  return {
    isCustomerView,
    toggleIsCustomerView,
  };
};
