import { Box } from '@mui/material';
import { ReactElement, FC } from 'react';

import { searchResultSxProps } from '@/Pages/bukken/chintai/SearchPartial/SearchResultStyles';

type ActionButtonLayoutProps = {
  children: ReactElement;
};

/**
 * 物件に紐づく各アクションボタンのレイアウトを調整する
 */
export const ActionButtonLayout: FC<ActionButtonLayoutProps> = ({ children }) => {
  return <Box sx={searchResultSxProps.buttonListItem}>{children}</Box>;
};
