import { Box, Link, Typography } from '@mui/material';
import { ViewListOutline } from 'mdi-material-ui';
import { FC, memo } from 'react';

import { ServiceConfigure } from '@/Services/Configure';

const EmptyOwnBukkenPageContainer: FC = memo(() => {
  return <EmptyOwnBukkenPagePresenter />;
});

export { EmptyOwnBukkenPageContainer as EmptyOwnBukkenPage };

export const EmptyOwnBukkenPagePresenter: FC = memo(() => (
  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={6} mx={2}>
    <ViewListOutline color="disabled" sx={{ fontSize: '60px' }} />
    <Typography fontSize="16px" fontWeight="bold" py={2}>
      登録されている自社物件はありません
    </Typography>
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center">
      <span>自社物件の登録は、 </span>
      <span>
        『
        <Link
          href={`${ServiceConfigure.ebChintaiUrl}`}
          target="_blank"
          rel="noopener noreferrer"
          display="inline"
          sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
        >
          いい生活賃貸クラウド 物件広告
        </Link>
        』から可能です
      </span>
    </Box>
  </Box>
));
