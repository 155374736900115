import { isNotNullOrEmptyString, deepCompare } from '@app/utils';
import { Box, Typography } from '@mui/material';
import { memo, FC } from 'react';

import { textSxProps } from '@/Hooks/Styles/TextStyle';

type HeaderTextProps = {
  selectionStateText?: string;
  fixedText: string;
  isSmallDevice: boolean;
};

export const HeaderText: FC<HeaderTextProps> = memo(props => {
  return (
    <Box px={props.isSmallDevice ? 2 : 0}>
      <Typography variant="h6" component="div" sx={{ ...textSxProps.bold, ...textSxProps.lg }}>
        {isNotNullOrEmptyString(props.selectionStateText) ? `${props.selectionStateText} ` : ''}
        {props.fixedText}
      </Typography>
    </Box>
  );
}, deepCompare);
