import { ChinshakuBukken, SpecBukkenView } from '@app/models';
import { IconButtonMenu } from '@lib/components';
import { DotsHorizontal, Loading } from 'mdi-material-ui';
import { FC, Fragment, useMemo } from 'react';

import { useCurrentUser } from '@/Hooks/Esa/RequireCurrentUser';
import { DownloadImageZipHook, useDownloadImageZip } from '@/Hooks/SearchResult/DownloadImageZipHook';
import { getBukkenButtonData, useFetchBukkenData } from '@/Hooks/SearchResult/FetchBukkenDataHook';
import { useIsValidTakkenLicenseNumber } from '@/Hooks/ValidationHooks';
import { ViewStatusBukken } from '@/Services/ISendingViewStatusService';

const NO_AD_TOOLTIP_TEXT = '広告掲載ができない物件です';

type DownloadImageZipButtonContainerProps = {
  bukkenGuid: string;
  bukkenOrBukkenView: SpecBukkenView | ChinshakuBukken;
  kukakuName: string;
  analyticsPlace: string;
  viewStatusBukken: ViewStatusBukken;
  isCustomerView: boolean;
  isSmallToMediumDevice: boolean;
  moshikomiFlag: boolean;
  tensaiFlag: boolean;
  notHyojiFlag: boolean;
};
export const DownloadImageZipButtonContainer: FC<DownloadImageZipButtonContainerProps> = ({
  bukkenGuid,
  bukkenOrBukkenView,
  kukakuName,
  analyticsPlace,
  viewStatusBukken,
  isCustomerView,
  isSmallToMediumDevice,
  moshikomiFlag,
  tensaiFlag,
  notHyojiFlag,
}) => {
  const downloadImageZipHook = useDownloadImageZip(bukkenGuid, kukakuName, analyticsPlace, viewStatusBukken);

  const isValidTakkenLicenseNumber = useIsValidTakkenLicenseNumber();
  const b2bKokaiCode =
    'chinshaku_bukken_view' in bukkenOrBukkenView
      ? bukkenOrBukkenView.chinshaku_boshu_joken_view.b2b_kokai_code
      : bukkenOrBukkenView.b2b_kokai_code;

  const currentUser = useCurrentUser();
  const isOwnBukken = useMemo(() => {
    if (!currentUser.ebone) {
      return false;
    }

    return currentUser.ebone.domainGuid === bukkenOrBukkenView.domain_guid;
  }, [bukkenOrBukkenView.domain_guid, currentUser.ebone]);

  const fetchBukkenButtonData = getBukkenButtonData(
    moshikomiFlag,
    notHyojiFlag,
    isValidTakkenLicenseNumber,
    isOwnBukken,
    b2bKokaiCode
  );

  const fetchBukkenData = useFetchBukkenData(bukkenGuid, analyticsPlace, viewStatusBukken);

  return (
    <DownloadImageZipButtonPresenter
      downloadImageZipHook={downloadImageZipHook}
      tooltipText={fetchBukkenButtonData.tooltipText}
      disabled={fetchBukkenButtonData.disabled}
      fetchBukkenData={fetchBukkenData}
      isCustomerView={isCustomerView}
      isSmallToMediumDevice={isSmallToMediumDevice}
      moshikomiFlag={moshikomiFlag}
      tensaiFlag={tensaiFlag}
    />
  );
};

export { DownloadImageZipButtonContainer as DownloadImageZipButton };

export type DownloadImageZipButtonPresenterProps = {
  downloadImageZipHook: DownloadImageZipHook;
  tooltipText: string;
  disabled: boolean;
  fetchBukkenData: () => void;
  isCustomerView: boolean;
  isSmallToMediumDevice: boolean;
  moshikomiFlag: boolean;
  tensaiFlag: boolean;
};

export const DownloadImageZipButtonPresenter: FC<DownloadImageZipButtonPresenterProps> = ({
  downloadImageZipHook,
  tooltipText,
  disabled,
  fetchBukkenData,
  isCustomerView,
  isSmallToMediumDevice,
  moshikomiFlag,
  tensaiFlag,
}) => (
  <Fragment>
    {!isCustomerView && (
      <IconButtonMenu
        icon={
          !downloadImageZipHook.isDownloading ? (
            <DotsHorizontal />
          ) : (
            <Loading
              sx={{
                animation: 'spin infinite 1s linear',
                '@keyframes spin': {
                  from: {
                    transform: 'rotate(0deg)',
                  },
                  to: {
                    transform: 'rotate(360deg)',
                  },
                },
              }}
            />
          )
        }
        menuActions={[
          {
            text: '画像一括ダウンロード',
            onSelected: downloadImageZipHook.downloadImageZip,
            disabledText: NO_AD_TOOLTIP_TEXT,
            disabled: moshikomiFlag || !tensaiFlag,
          },
          ...(isSmallToMediumDevice
            ? [
                {
                  text: '取込',
                  onSelected: fetchBukkenData,
                  disabledText: tooltipText,
                  disabled: disabled,
                },
              ]
            : []),
        ]}
        padding="6px"
      />
    )}
  </Fragment>
);
