import { ChinshakuBukken } from '@app/models';
import { useCallback, useState } from 'react';

import { useUserActionTracker } from '@/Hooks/Analytics';
import { useDependency } from '@/Hooks/DependencyHook';
import { useEnqueueSnackbar } from '@/Hooks/Snackbar';
import { viewStatusAction, ViewStatusBukken } from '@/Services/ISendingViewStatusService';

export type DownloadImageZipHook = {
  downloadImageZip: () => void;
  isDownloading: boolean;
};

export const useDownloadImageZip = (
  chinshakuBukkenGuid: string,
  bukkenName: string,
  analyticsPlace: string,
  viewStatusBukken: ViewStatusBukken,
  chinshakuBukken?: ChinshakuBukken
): DownloadImageZipHook => {
  const tracker = useUserActionTracker(analyticsPlace);
  const downloadImageZipService = useDependency('downloadImageZipService');
  const sendingViewStatusService = useDependency('sendingViewStatusService');
  const [isDownloading, setIsDownloading] = useState(false);
  const enqueueSnackbar = useEnqueueSnackbar();

  const downloadImageZip = useCallback(async () => {
    tracker('画像一括DL', chinshakuBukkenGuid);
    sendingViewStatusService.send(viewStatusBukken, viewStatusAction.imageZipDL);
    setIsDownloading(true);
    const result = await downloadImageZipService.download(chinshakuBukkenGuid, bukkenName, chinshakuBukken);
    if (!result.isSuccess) {
      enqueueSnackbar(result.message, { variant: 'error' });
    }
    setIsDownloading(false);
  }, [
    bukkenName,
    chinshakuBukken,
    chinshakuBukkenGuid,
    downloadImageZipService,
    enqueueSnackbar,
    sendingViewStatusService,
    tracker,
    viewStatusBukken,
  ]);

  return {
    downloadImageZip,
    isDownloading,
  };
};
