import { deepCompare } from '@app/utils';
import { Checkbox } from '@e-seikatsu/design-system';
import { Box } from '@mui/material';
import { memo, FC } from 'react';

import { ConditionFormGrid } from './ConditionFormGrid';

import { ListSearchConditionHook } from '@/Hooks/Search/CustomSearchCondition';
import { borderSxProps } from '@/Hooks/Styles/BorderStyle';
import {
  IndoorEquipConditions,
  IndoorEquipConditionsLabelMap,
  BuildingEquipConditions,
  BuildingEquipConditionsLabelMap,
  ChushajoConditions,
  ChushajoConditionsTextMap,
  FloorConditions,
  FloorConditionsLabelMap,
  GasConditions,
  GasConditionsLabelMap,
  KitchenConditions,
  KitchenConditionsLabelMap,
  KodawariConditions,
  NyukyoConditions,
  NyukyoConditionsLabelMap,
  RoomStorageConditions,
  RoomStorageConditionsLabelMap,
  SanitaryEquipConditions,
  SanitaryEquipConditionsLabelMap,
  SecurityConditions,
  SecurityConditionsLabelMap,
  TelecomEquipConditions,
  TelecomEquipConditionsLabelMap,
} from '@/Models/SearchConditions/ChintaiSearchConditionEnums/ChintaiSearchKodawariConditionEnums';
import { ChintaiSearchConditions } from '@/Models/SearchConditions/ChintaiSearchConditions';

type BukkenKodawariJokenFormProps = {
  hook: ListSearchConditionHook<KodawariConditions, ChintaiSearchConditions>;
  isSmallDevice: boolean;
};

const BukkenKodawariJokenForm: FC<BukkenKodawariJokenFormProps> = memo(({ hook, isSmallDevice }) => {
  const { value, toggle } = hook;

  if (value === undefined) {
    return null;
  }

  return (
    <Box sx={borderSxProps.borderTop}>
      <FloorJokenForm currentConditions={value} toggle={toggle} isSmallDevice={isSmallDevice} />
      <NyukyoJokenForm currentConditions={value} toggle={toggle} isSmallDevice={isSmallDevice} />
      <KitchenJokenForm currentConditions={value} toggle={toggle} isSmallDevice={isSmallDevice} />
      <SanitaryEquipJokenForm currentConditions={value} toggle={toggle} isSmallDevice={isSmallDevice} />
      <IndoorEquipJokenForm currentConditions={value} toggle={toggle} isSmallDevice={isSmallDevice} />
      <RoomStorageJokenForm currentConditions={value} toggle={toggle} isSmallDevice={isSmallDevice} />
      <TelecomEquipJokenForm currentConditions={value} toggle={toggle} isSmallDevice={isSmallDevice} />
      <SecurityJokenForm currentConditions={value} toggle={toggle} isSmallDevice={isSmallDevice} />
      <GasJokenForm currentConditions={value} toggle={toggle} isSmallDevice={isSmallDevice} />
      <BuildingEquipJokenForm currentConditions={value} toggle={toggle} isSmallDevice={isSmallDevice} />
      <ChushajoJokenForm currentConditions={value} toggle={toggle} isSmallDevice={isSmallDevice} />
    </Box>
  );
}, deepCompare);

type KodawariJokenFormProps = {
  currentConditions: KodawariConditions[];
  toggle: (cond: KodawariConditions) => void;
  isSmallDevice: boolean;
};

const makeKodawariJokenFormForCategory = (
  label: string,
  values: readonly KodawariConditions[],
  textMap: Partial<{ [_ in KodawariConditions]: string }>
): FC<KodawariJokenFormProps> => {
  return ({ currentConditions, toggle, isSmallDevice }) => (
    <ConditionFormGrid label={label} isSmallDevice={isSmallDevice}>
      <Box display="flex" flexWrap="wrap" gap={1}>
        {values.map(v => (
          <LabelledCheckbox
            key={v}
            checked={currentConditions.includes(v)}
            value={v}
            label={textMap[v] ?? ''}
            toggle={toggle}
          />
        ))}
      </Box>
    </ConditionFormGrid>
  );
};

type CheckboxProps = {
  checked: boolean;
  value: KodawariConditions;
  label: string;
  toggle: (cond: KodawariConditions) => void;
};
const LabelledCheckbox: FC<CheckboxProps> = memo(({ checked, label, value, toggle }) => (
  <Box display="flex" alignItems="center">
    <Checkbox variant="outlined" size="small" label={label} checked={checked} onChange={() => toggle(value)} />
  </Box>
));

const ChushajoJokenForm = makeKodawariJokenFormForCategory(
  '駐車場・駐輪場',
  Object.values(ChushajoConditions),
  ChushajoConditionsTextMap
);

const FloorJokenForm = makeKodawariJokenFormForCategory(
  '所在階',
  Object.values(FloorConditions),
  FloorConditionsLabelMap
);

const NyukyoJokenForm = makeKodawariJokenFormForCategory(
  '入居条件',
  Object.values(NyukyoConditions),
  NyukyoConditionsLabelMap
);

const KitchenJokenForm = makeKodawariJokenFormForCategory(
  'キッチン',
  Object.values(KitchenConditions),
  KitchenConditionsLabelMap
);

const SanitaryEquipJokenForm = makeKodawariJokenFormForCategory(
  '水回り',
  Object.values(SanitaryEquipConditions),
  SanitaryEquipConditionsLabelMap
);

const IndoorEquipJokenForm = makeKodawariJokenFormForCategory(
  '室内設備',
  Object.values(IndoorEquipConditions),
  IndoorEquipConditionsLabelMap
);

const RoomStorageJokenForm = makeKodawariJokenFormForCategory(
  '収納',
  Object.values(RoomStorageConditions),
  RoomStorageConditionsLabelMap
);

const TelecomEquipJokenForm = makeKodawariJokenFormForCategory(
  '放送・通信',
  Object.values(TelecomEquipConditions),
  TelecomEquipConditionsLabelMap
);

const SecurityJokenForm = makeKodawariJokenFormForCategory(
  'セキュリティ',
  Object.values(SecurityConditions),
  SecurityConditionsLabelMap
);

const GasJokenForm = makeKodawariJokenFormForCategory('ガス', Object.values(GasConditions), GasConditionsLabelMap);

const BuildingEquipJokenForm = makeKodawariJokenFormForCategory(
  '建物設備',
  Object.values(BuildingEquipConditions),
  BuildingEquipConditionsLabelMap
);

export default BukkenKodawariJokenForm;
