import { deepCompare } from '@app/utils';
import { Select } from '@e-seikatsu/design-system';
import { Grid } from '@mui/material';
import { memo, FC } from 'react';

import { searchConditionSxProps } from './SearchConditionStyle';

import { SearchConditionHook } from '@/Hooks/Search/SearchConditionHook';
import { borderSxProps } from '@/Hooks/Styles/BorderStyle';
import { ChintaiSearchConditions } from '@/Models/SearchConditions/ChintaiSearchConditions';

type BukkenADFormProps = {
  fromHook: SearchConditionHook<string, ChintaiSearchConditions>;
  isSmallDevice: boolean;
};

const BukkenADForm: FC<BukkenADFormProps> = memo(({ fromHook, isSmallDevice }) => {
  const values = [0.5, 1, 1.5, 2, 2.5, 3];
  const valueToLabel = (value: number): string => {
    return `${value * 100}%以上`;
  };
  return (
    <Grid container sx={{ ...searchConditionSxProps.root, ...borderSxProps.borderBottom }}>
      <Grid
        item
        xs={12}
        sm={2}
        sx={{ ...searchConditionSxProps.firstCol, ...(isSmallDevice ? borderSxProps.borderBottom : undefined) }}
      >
        AD
      </Grid>
      <Grid item xs={12} sm={10} sx={{ ...searchConditionSxProps.secondCol, ...searchConditionSxProps.inputCol }}>
        <Select
          size="small"
          length={isSmallDevice ? 'full' : 'normal'}
          value={fromHook.value}
          onChange={(value): void => {
            fromHook.setValue(value ?? '');
          }}
        >
          <Select.Option value="">下限なし</Select.Option>
          {values.map(it => (
            <Select.Option value={it.toString()} key={it}>
              {valueToLabel(it)}
            </Select.Option>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
}, deepCompare);

export default BukkenADForm;
