import { asSetsudoJokyoKadochiCode, PriceUnitCode, setsudoJokyoKadochiCodeToText } from '@app/enums';
import { ChinshakuBukken, Tatemono } from '@app/models';
import { isNotNullOrUndefined, isNullOrUndefined } from '@app/utils';
import Linkify from 'react-linkify';

import { calcCommonDisplayContent } from './CalcCommonDisplayContent';

import { tochiTokuchoCategoryName } from '@/Consts/TochiTokuchoCategoryName';
import { DisplayData } from '@/Pages/bukken/chintai/DetailPartial/DetailGrid';
import { getTextFromDatejun } from '@/Utils/DisplayText/DateJun';
import { makeKukakuName } from '@/Utils/DisplayText/KukakuName';
import { makePriceText } from '@/Utils/DisplayText/Money';
import { makeSetsudoText } from '@/Utils/DisplayText/Setsudo';
import { LinkifyUrlSafeMatchDecorator } from '@/Utils/DisplayText/UrlMatch';

export const calcTochiOverviewData = (
  chinshakuBukken: ChinshakuBukken,
  tatemono: Tatemono,
  showMap: () => void
): DisplayData[] => {
  const textSeparator = ', ';
  const commonDisplayContent = calcCommonDisplayContent(chinshakuBukken, tatemono, showMap);
  const tochiMensekiKobo = isNullOrUndefined(tatemono.tochi?.tochi_menseki_kobo)
    ? '-'
    : `${tatemono.tochi?.tochi_menseki_kobo.toFixed(2)}㎡`;
  const tochiMensekiJissoku = isNullOrUndefined(tatemono.tochi?.tochi_menseki_jissoku)
    ? '-'
    : `${tatemono.tochi?.tochi_menseki_jissoku.toFixed(2)}㎡`;
  const tochiShidoFutanMensekiKobo = isNullOrUndefined(tatemono.tochi?.tochi_shosai?.shido_futan_menseki_kobo)
    ? '-'
    : `${tatemono.tochi?.tochi_shosai?.shido_futan_menseki_kobo.toFixed(2)}㎡`;
  const tochiShidoFutanMensekiJissoku = isNullOrUndefined(tatemono.tochi?.tochi_shosai?.shido_futan_menseki_jissoku)
    ? '-'
    : `${tatemono.tochi?.tochi_shosai?.shido_futan_menseki_jissoku.toFixed(2)}㎡`;
  const tochiYotoChiki =
    isNullOrUndefined(tatemono.tochi?.tochi_yoto_chiki) ||
    isNullOrUndefined(tatemono.tochi?.tochi_yoto_chiki[0].yoto_chiki)
      ? '-'
      : `${tatemono.tochi?.tochi_yoto_chiki[0].yoto_chiki}`;
  const tochiShiteiKenpeiRitsu = isNullOrUndefined(tatemono.tochi?.shitei_kempei_ritsu)
    ? '-'
    : `${tatemono.tochi?.shitei_kempei_ritsu.toFixed(2)}％`;
  const tochiShiteiYosekiRitsu = isNullOrUndefined(tatemono.tochi?.shitei_yoseki_ritsu)
    ? '-'
    : `${tatemono.tochi?.shitei_yoseki_ritsu.toFixed(2)}％`;

  const zoseiKanryoDatejun = isNullOrUndefined(tatemono.tochi?.tochi_shosai?.zosei_kanryo_datejun)
    ? '-'
    : `${getTextFromDatejun(tatemono.tochi?.tochi_shosai?.zosei_kanryo_datejun)}`;

  const setsudoJokyoKadochiCode = asSetsudoJokyoKadochiCode(tatemono.tochi?.tochi_shosai?.setsudo_jokyo_kadochi_code);
  const kadochi = isNotNullOrUndefined(setsudoJokyoKadochiCode)
    ? setsudoJokyoKadochiCodeToText(setsudoJokyoKadochiCode)
    : '-';

  const tochiTokuchoSaitekiYoto = !isNullOrUndefined(tatemono.tochi?.tochi_tokucho)
    ? tatemono.tochi?.tochi_tokucho
        .filter(
          tokucho =>
            tokucho.tokucho_category_name === tochiTokuchoCategoryName.saitekiYoto &&
            !isNullOrUndefined(tokucho.tokucho_name)
        )
        .map(tokucho => tokucho.tokucho_name)
    : [];
  const tochiTokuchoChikiChiku = !isNullOrUndefined(tatemono.tochi?.tochi_tokucho)
    ? tatemono.tochi?.tochi_tokucho
        .filter(
          tokucho =>
            tokucho.tokucho_category_name === tochiTokuchoCategoryName.toshikeikakuho &&
            !isNullOrUndefined(tokucho.tokucho_name)
        )
        .map(tokucho => tokucho.tokucho_name)
    : [];
  const tochiShosaiSeikeichi = isNullOrUndefined(tatemono.tochi?.tochi_shosai?.seikeichi_flag)
    ? '-'
    : tatemono.tochi?.tochi_shosai?.seikeichi_flag
    ? '整形地'
    : '不整形地';

  const futekigoSetsudo = isNullOrUndefined(tatemono.tochi?.tochi_shosai?.setsudo_jokyo_futekigo_setsudo_flag)
    ? '-'
    : tatemono.tochi?.tochi_shosai?.setsudo_jokyo_futekigo_setsudo_flag
    ? '有'
    : '無';

  const sonotaHoreiSeigen = !isNullOrUndefined(tatemono.tochi?.tochi_tokucho)
    ? tatemono.tochi?.tochi_tokucho
        .filter(
          tokucho =>
            tokucho.tokucho_category_name === tochiTokuchoCategoryName.sonotaHoreiSeigen &&
            !isNullOrUndefined(tokucho.tokucho_name)
        )
        .map(tokucho => tokucho.tokucho_name)
    : [];

  return [
    {
      label: '物件名',
      content: makeKukakuName(chinshakuBukken, tatemono, ''),
      span: true,
    },
    {
      label: '物件所在地',
      content: commonDisplayContent.jusho,
      span: true,
    },
    {
      label: '交通機関',
      content: commonDisplayContent.kotsu,
      span: true,
    },
    {
      label: '土地面積（公簿）',
      content: tochiMensekiKobo,
    },
    {
      label: '土地面積（実測）',
      content: tochiMensekiJissoku,
    },
    {
      label: '私道負担面積（公簿）',
      content: tochiShidoFutanMensekiKobo,
    },
    {
      label: '私道負担面積（実測）',
      content: tochiShidoFutanMensekiJissoku,
    },
    {
      label: '用途地域',
      content: tochiYotoChiki,
      span: true,
    },
    {
      label: '地目（現況）',
      content: `${tatemono.tochi?.chimoku_genkyo ?? '-'}`,
    },
    {
      label: '地目（登記簿）',
      content: `${tatemono.tochi?.chimoku_tokibo ?? '-'}`,
    },
    {
      label: '建ぺい率',
      content: tochiShiteiKenpeiRitsu,
    },
    {
      label: '容積率',
      content: tochiShiteiYosekiRitsu,
    },
    {
      label: '坪単価',
      content: makePriceText(chinshakuBukken.chinshaku_boshu_joken?.chinryo_per_tsubo, PriceUnitCode.yen, {
        emptySubstituteText: '-',
      }),
    },
    {
      label: '国土法提出',
      content: `${tatemono.tochi?.tochi_jigyo?.kokudoho_kyoka_jizen_todokede ?? '-'}`,
    },
    {
      label: '地勢（区分）',
      content: `${tatemono.tochi?.tochi_shosai?.chisei ?? '-'}`,
    },
    {
      label: '地勢（整形地）',
      content: tochiShosaiSeikeichi,
    },
    {
      label: '土地権利',
      content: `${chinshakuBukken.chinshaku_boshu_joken?.chinshaku_boshu_joken_shosai?.tochi_kenri ?? '-'}`,
    },
    {
      label: '住宅保険',
      content: (
        <div>
          <Linkify matchDecorator={LinkifyUrlSafeMatchDecorator}>{commonDisplayContent.sompo}</Linkify>
        </div>
      ),
    },
    {
      label: 'セットバック',
      content: `${tatemono.tochi?.tochi_shosai?.setback ?? '-'}`,
    },
    {
      label: '地域地区',
      content:
        isNullOrUndefined(tochiTokuchoChikiChiku) || tochiTokuchoChikiChiku.length === 0
          ? '-'
          : tochiTokuchoChikiChiku.join(textSeparator),
    },
    {
      label: '最適用途',
      content:
        isNullOrUndefined(tochiTokuchoSaitekiYoto) || tochiTokuchoSaitekiYoto.length === 0
          ? '-'
          : tochiTokuchoSaitekiYoto.join(textSeparator),
      span: true,
    },
    {
      label: '接道状況',
      content: makeSetsudoText(tatemono.tochi?.tochi_setsudo),
      span: true,
    },
    {
      label: '造成完了年月日',
      content: zoseiKanryoDatejun,
    },
    {
      label: '角地',
      content: kadochi,
    },
    {
      label: '不適合接道',
      content: futekigoSetsudo,
    },
    {
      label: '都市計画法',
      content: `${tatemono.tochi?.tochi_toshi_keikaku?.toshi_keikaku_kuiki ?? '-'}`,
    },
    {
      label: 'その他法令制限',
      content:
        isNullOrUndefined(sonotaHoreiSeigen) || sonotaHoreiSeigen.length === 0
          ? '-'
          : sonotaHoreiSeigen.join(textSeparator),
      span: true,
    },
    {
      label: '備考',
      content: (
        <div>
          <Linkify matchDecorator={LinkifyUrlSafeMatchDecorator}>{commonDisplayContent.biko}</Linkify>
        </div>
      ),
      span: true,
    },
  ];
};
