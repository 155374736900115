import { ChangeEventHandler, useCallback } from 'react';

export const useHandlerOnChangeText = (
  handler: (value: string) => void
): ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> => {
  return useCallback<ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>>(
    e => handler(e.target.value),
    [handler]
  );
};
