import { convertMilliToAngle, isNotNullOrUndefined, isNullOrUndefined } from '@app/utils';
import { Alert } from '@e-seikatsu/design-system';
import { NavitimeMap } from '@lib/components';
import { Box, Typography } from '@mui/material';
import { OpenInNew } from 'mdi-material-ui';
import { FC } from 'react';

import { ChinshakuBukkenDetailProps } from './ChinshakuBukkenDetailProps';
import { SectionHeader, SectionSubHeader } from './SectionHeaders';

import { useDetailMap } from '@/Hooks/NavitimeMap/DetailMap';
import { textSxProps } from '@/Hooks/Styles/TextStyle';

export const SurroundingsInfoTab: FC<ChinshakuBukkenDetailProps> = ({ tatemono, chinshakuBukken }) => {
  const shuhenShisetsu = isNotNullOrUndefined(chinshakuBukken.chinshaku_shuhen_shisetsu)
    ? chinshakuBukken.chinshaku_shuhen_shisetsu.map(it => {
        const shubetsu = it.shuhen_shisetsu?.shuhen_shisetsu_shubetsu;
        const name = it.shuhen_shisetsu?.shuhen_shisetsu_name;
        if (isNullOrUndefined(shubetsu) && isNullOrUndefined(name)) {
          return null;
        }
        const shisetsuText =
          isNotNullOrUndefined(shubetsu) && isNotNullOrUndefined(name)
            ? `${shubetsu}：${name}`
            : `${shubetsu ?? ''}${name ?? ''}`;

        return (
          <Typography variant="body1" key={it.shuhen_shisetsu_guid}>
            {shisetsuText}
            {isNotNullOrUndefined(it.shuhen_shisetsu_kyori) ? ` ${it.shuhen_shisetsu_kyori}m` : ''}
          </Typography>
        );
      })
    : [];

  const address = `${tatemono.jusho_text}${tatemono.nokori_jusho ? tatemono.nokori_jusho : ''}${
    tatemono.gaiku_goto ? tatemono.gaiku_goto : ''
  }`;
  const detailMapHook = useDetailMap(
    {
      latitude: tatemono?.ido_wgs ?? undefined,
      longitude: tatemono?.keido_wgs ?? undefined,
    },
    address
  );

  const getNavitimeMap = () => {
    if (isNullOrUndefined(tatemono?.keido_wgs) || isNullOrUndefined(tatemono?.ido_wgs)) {
      return (
        <Alert color="error">
          <Alert.Content>緯度経度情報が指定されていないため地図を表示できません</Alert.Content>
        </Alert>
      );
    }
    return <NavitimeMap hook={detailMapHook} />;
  };

  return (
    <Box p={{ xs: 0, md: 2 }}>
      <SectionHeader>地図・近隣情報</SectionHeader>
      {getNavitimeMap()}
      {isNotNullOrUndefined(tatemono?.ido_wgs) && isNotNullOrUndefined(tatemono.keido_wgs) && (
        <Box pt={1} width="100%" display="flex" justifyContent="flex-end">
          <Box
            component="a"
            href={`https://www.google.com/maps?q=${convertMilliToAngle(tatemono.ido_wgs)},${convertMilliToAngle(
              tatemono.keido_wgs
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ ...textSxProps.primaryText, ...textSxProps.nonDecoration, ...textSxProps.underlineOnHover }}
          >
            <Box display="flex" alignItems="center">
              大きな地図で見る
              <OpenInNew />
            </Box>
          </Box>
        </Box>
      )}
      <Box pt={2}>
        <SectionSubHeader>周辺施設</SectionSubHeader>
        <Box pl={{ xs: 1, md: 0 }}>{shuhenShisetsu.length > 0 ? shuhenShisetsu : '-'}</Box>
      </Box>
    </Box>
  );
};
