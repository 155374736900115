import { ShinchikuChukoCode, PriceUnitCode, asNyukyoKanoCode, TatemonoShubetsuCode } from '@app/enums';
import { ChinshakuBoshuJokenViewTensai, SpecBukkenView, Datejun } from '@app/models';
import { isNotNullOrUndefined, isNotNullOrEmptyString, isNullOrUndefined } from '@app/utils';
import { Box } from '@mui/material';
import { parseISO, differenceInCalendarDays, startOfToday, max as maxDate } from 'date-fns';
import { FC, ReactNode, MouseEvent } from 'react';

import ImageFromFileApi from '@/Components/DataDisplay/ImageFromFileApi';
import { SquareChip } from '@/Components/SquareChip';
import { textSxProps } from '@/Hooks/Styles/TextStyle';
import { B2bKokaiCodeRadioState } from '@/Models/SearchConditions/ChintaiSearchConditionEnums/ChintaiSearchConditionEnums';
import { isSpecBukkenViewOneHouse, makeNyukyoKanoText, makeTaikyoDateText } from '@/Utils/DisplayText/Bukken';
import { makeDateDiffTextFromNow } from '@/Utils/DisplayText/Date';
import { shunkoDatejunToChikuNensu } from '@/Utils/DisplayText/DateJun';
import { makePriceText, kanrihiPriceToYen } from '@/Utils/DisplayText/Money';

export type CalculatedSpecBukkenInfo = {
  isNew: boolean;
  isChushajo: boolean;
  isTochi: boolean;
  kukakuName: string;
  updatedDateText: string;
  isApplied: boolean;
  isADExists: boolean;
  isShinchiku: boolean;
  isGenteiKokai: boolean;
  kotsuText: string;
  mensekiText: string;
  madoriText: string;
  kozoText: string;
  chinryoAmountText: string;
  kanrihiEtcText: string;
  isKanrihiExist: boolean;
  shikikinText: string;
  reikinText: string;
  chikuNensuText: string;
  taikyoDateText: string;
  nyukyoKanoText: string;
  adAmountText: string;
  imageGuid: string | null;
  tensaiArray: ChinshakuBoshuJokenViewTensai[];
};

export const calculateInfo = (spec: SpecBukkenView, isCustomerView: boolean): CalculatedSpecBukkenInfo => {
  const bukkenView = spec.chinshaku_bukken_view;
  const boshuJokenView = spec.chinshaku_boshu_joken_view;

  const publishDate = parseISO(bukkenView.one_network_keisai_kaishi_time);
  const isNew = differenceInCalendarDays(startOfToday(), publishDate) <= 3;
  const isChushajo =
    spec.tatemono_shubetsu_code === TatemonoShubetsuCode.parking ||
    spec.tatemono_shubetsu_code === TatemonoShubetsuCode.motorcycleParking;
  const isTochi = spec.tatemono_shubetsu_code === TatemonoShubetsuCode.land;
  const kukakuName = makeKukakuNameView(spec);
  const bukkenLastUpdateDate = bukkenView.saishu_koshin_time ? parseISO(bukkenView.saishu_koshin_time) : null;
  const boshuJokenLastUpdateDate = boshuJokenView.saishu_koshin_time
    ? parseISO(boshuJokenView.saishu_koshin_time)
    : null;
  const updatedDate = maxDate([bukkenLastUpdateDate, boshuJokenLastUpdateDate].filter(isNotNullOrUndefined));
  const updatedDateText = makeDateDiffTextFromNow(updatedDate, isCustomerView);
  const isApplied = boshuJokenView.moshikomi_flag;
  const isADExists =
    isNotNullOrUndefined(boshuJokenView.kokokuryo_amount) &&
    boshuJokenView.kokokuryo_amount > 0 &&
    isNotNullOrUndefined(boshuJokenView.kokokuryo_tani);
  const isShinchiku = boshuJokenView.shinchiku_chuko_code === ShinchikuChukoCode.shinchiku;
  const kotsuText = bukkenView.kotsu_text_1 ? bukkenView.kotsu_text_1.concat(bukkenView.kotsu_text_2 ? ' 他' : '') : '';
  const menseki = spec.senyu_menseki ?? spec.search_menseki;
  const mensekiText = menseki?.toFixed(2).concat(' ㎡') ?? '-';
  const chinryoAmountText = makePriceText(boshuJokenView.chinryo, null, { amountOnly: true, emptySubstituteText: '-' });
  const kanrihi = kanrihiPriceToYen(boshuJokenView.kanrihi, boshuJokenView.kanrihi_kubun_code);
  const kyoekihi = kanrihiPriceToYen(boshuJokenView.kyoekihi, boshuJokenView.kyoekihi_kubun_code);
  const kanrihiEtc = kanrihi + kyoekihi;
  const kanrihiEtcUnitCode =
    boshuJokenView.kanrihi_kubun_code === PriceUnitCode.actualCost ||
    boshuJokenView.kyoekihi_kubun_code === PriceUnitCode.actualCost
      ? PriceUnitCode.actualCost
      : null;
  const kanrihiEtcText = makePriceText(kanrihiEtc, kanrihiEtcUnitCode, {
    amountOnly: true,
    emptyIfZero: true,
    emptySubstituteText: '-',
  });
  const isKanrihiExist = kanrihiEtcUnitCode !== PriceUnitCode.actualCost && kanrihiEtc > 0;
  const shikikinText = makePriceText(boshuJokenView.shikikin_en, PriceUnitCode.yen, { emptySubstituteText: '-' });
  const reikinText = makePriceText(boshuJokenView.reikin_en, PriceUnitCode.yen, { emptySubstituteText: '-' });
  const chikuNensu = shunkoDatejunToChikuNensu(Datejun.numberToDatejun(spec.shunko_datejun));
  const chikuNensuText = isShinchiku
    ? '新築'
    : isNullOrUndefined(chikuNensu)
    ? '-'
    : chikuNensu === 0
    ? '築1年以内'
    : `${chikuNensu}年`;
  const taikyoDateText = makeTaikyoDateText(boshuJokenView.taikyo_date);
  const nyukyoKanoText = makeNyukyoKanoText(
    asNyukyoKanoCode(boshuJokenView.nyukyo_kano_code),
    Datejun.numberToDatejun(boshuJokenView.nyukyo_kano_datejun),
    false
  );
  const adAmountText = makePriceText(boshuJokenView.kokokuryo_amount, boshuJokenView.kokokuryo_tani, {
    emptyIfZero: true,
    emptySubstituteText: '-',
  });
  const imageGuid =
    [
      bukkenView.gaikan_gazo_file_guid,
      bukkenView.madori_gazo_file_guid,
      bukkenView.sonota_gazo_1_file_guid,
      bukkenView.sonota_gazo_2_file_guid,
    ].filter(isNotNullOrEmptyString)[0] ?? null;
  const tensaiArray = boshuJokenView.chinshaku_boshu_joken_tensai ?? [];
  const isGenteiKokai = String(boshuJokenView?.b2b_kokai_code) === B2bKokaiCodeRadioState.partner;

  return {
    isNew,
    isChushajo,
    isTochi,
    kukakuName,
    updatedDateText,
    isApplied,
    isADExists,
    isShinchiku,
    isGenteiKokai,
    kotsuText,
    mensekiText,
    madoriText: spec.madori_name ?? '-',
    kozoText: spec.kozo ?? '-',
    chinryoAmountText,
    kanrihiEtcText,
    isKanrihiExist,
    shikikinText,
    reikinText,
    chikuNensuText,
    taikyoDateText,
    nyukyoKanoText,
    adAmountText,
    imageGuid,
    tensaiArray,
  };
};

export type BukkenListItemProp = {
  specBukkenView: SpecBukkenView;
  motozukeGyoshaGuids: (string | null | undefined)[];
  transitToDetail: () => void;
  transitToMotodukeList?: (motodukeName: string | undefined, e: MouseEvent) => void;
  analyticsPlace: string;
};

export const BukkenImage: FC<{
  imageAspectRatio: '1by1' | '4by3' | 'fitToParent';
  imageGuid: string | null;
  tatemonoName: string;
}> = ({ imageAspectRatio, imageGuid: imageSrc, tatemonoName }) => {
  return (
    <ImageFromFileApi src={imageSrc} alt={`${tatemonoName} 外観`} sizing="fullwidth" aspectRatio={imageAspectRatio} />
  );
};

export const WithChip: FC<{
  chipLabel: string;
  justifyContent?: 'space-between' | 'flex-start';
  smText?: boolean;
  children: ReactNode;
}> = ({ chipLabel, justifyContent, children, smText }) => {
  if (chipLabel.length === 0) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="row" justifyContent={justifyContent} alignItems="center">
      <SquareChip mr="4px">{chipLabel}</SquareChip>
      <Box sx={smText ? textSxProps.sm : undefined}>{children}</Box>
    </Box>
  );
};

const makeKukakuNameView = (spec: SpecBukkenView): string => {
  const oneHouseText = isSpecBukkenViewOneHouse(spec) ? ' (一棟)' : '';
  if (spec.chinshaku_boshu_joken_view.b2b_tatemono_name_hyoji_flag) {
    const kukakuNumber = spec.heya_kukaku_number ?? '';
    const tatemonoName = `${spec.tatemono_name}${
      isNotNullOrEmptyString(kukakuNumber) && spec.chinshaku_boshu_joken_view.b2b_heya_bango_hyoji_flag
        ? ` ${kukakuNumber}`
        : ''
    }`;
    return `${tatemonoName}${oneHouseText}`;
  }
  const jushoText = spec.chinshaku_boshu_joken_view.b2b_eda_bango_hyoji_flag
    ? spec.jusho_full_text
    : spec.jusho_text ?? '';
  return isNotNullOrEmptyString(jushoText) ? `${jushoText}${oneHouseText}の物件情報` : `${oneHouseText}物件情報`;
};
