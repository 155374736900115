import { deepCompare } from '@app/utils';
import { TextField } from '@e-seikatsu/design-system';
import { memo, FC } from 'react';

import { ConditionFormGrid } from './ConditionFormGrid';

import { useHandlerOnChangeText } from '@/Hooks/Handlers/ChangeHandlers';
import { useHandlerOnEnter } from '@/Hooks/Handlers/KeyDownHandlers';
import { SearchConditionHook } from '@/Hooks/Search/SearchConditionHook';
import { ChintaiSearchConditions } from '@/Models/SearchConditions/ChintaiSearchConditions';

type HeyaNumberFormProps = {
  hook: SearchConditionHook<string, ChintaiSearchConditions>;
  onEnter: () => void;
  isSmallDevice: boolean;
};

export const HeyaNumberForm: FC<HeyaNumberFormProps> = memo(({ hook, onEnter, isSmallDevice }) => {
  const { value, setValue } = hook;
  const onChange = useHandlerOnChangeText(setValue);
  const onKeyDown = useHandlerOnEnter(onEnter);

  return (
    <ConditionFormGrid label="部屋番号" isSmallDevice={isSmallDevice}>
      <TextField size="small" length="short" value={value} onChange={onChange} onKeyDown={onKeyDown} />
    </ConditionFormGrid>
  );
}, deepCompare);
