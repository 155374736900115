import {
  FreeRentCategoryCode,
  PriceUnitCode,
  SonotaHiyoCategoryCode,
  asFreeRentCategoryCode,
  asPriceUnitCode,
  freeRentCategoryCodeToText,
  asGenkyoCode,
  asHoshoninDaikoCode,
  asKeiyakuKikanKubunCode,
  asKoshinryoCode,
  asNyukyoKanoCode,
  genkyoCodeToText,
  keiyakuKikanKubunCodeToText,
  koshinryoCodeToText,
  TatemonoShubetsuCode,
} from '@app/enums';
import { ChinshakuBukken, Kukaku, Tatemono, Datejun } from '@app/models';
import { isNotNullOrEmptyString, isNotNullOrUndefined, isNullOrUndefined } from '@app/utils';
import { format, isValid, max, parseISO } from 'date-fns';
import Linkify from 'react-linkify';

import { calcCommonDisplayContent } from './CalcCommonDisplayContent';

import { DisplayData } from '@/Pages/bukken/chintai/DetailPartial/DetailGrid';
import {
  calcAreaSizeForDisplay,
  getKeiyakuKubunCodeMap,
  makeHoshoKaishaText,
  makeNyukyoJokenText,
  makeNyukyoKanoText,
} from '@/Utils/DisplayText/Bukken';
import { makeKukakuName } from '@/Utils/DisplayText/KukakuName';
import { makePriceText } from '@/Utils/DisplayText/Money';
import { LinkifyUrlSafeMatchDecorator } from '@/Utils/DisplayText/UrlMatch';

export const stringToTableVal = (value: string | null | undefined): string => {
  return isNotNullOrUndefined(value) && value.length > 0 ? value : '-';
};

export const overviewDateFormat = 'yyyy/MM/dd';
export const overviewDateTimeFormat = 'yyyy/MM/dd HH:mm';

export const overviewSeparator = '，';

export const TochiGenkyoDisplayTatemonoShubetsu: readonly number[] = [
  TatemonoShubetsuCode.land,
  TatemonoShubetsuCode.parking,
  TatemonoShubetsuCode.motorcycleParking,
];

export const calcNormalOverviewData = (
  chinshakuBukken: ChinshakuBukken,
  tatemono: Tatemono,
  kukaku: Kukaku | null,
  showMap: () => void,
  isCustomerView: boolean
): DisplayData[] => {
  const boshuJoken = chinshakuBukken.chinshaku_boshu_joken;

  const commonDisplayContent = calcCommonDisplayContent(chinshakuBukken, tatemono, showMap);

  const madori =
    kukaku?.kukaku_heya_shosai !== undefined
      ? kukaku.kukaku_heya_shosai
          .map(it => {
            const heyaShosaiList: string[] = [];
            const menseki =
              isNotNullOrUndefined(it.heya_shosai_heya_menseki) &&
              isNotNullOrUndefined(it.heya_shosai_heya_menseki_unit)
                ? `${it.heya_shosai_heya_menseki}${it.heya_shosai_heya_menseki_unit}`
                : null;
            if (isNotNullOrUndefined(menseki)) {
              heyaShosaiList.push(menseki);
            }
            const floors = isNotNullOrUndefined(it.heya_shosai_heya_kaisu) ? `${it.heya_shosai_heya_kaisu}階` : null;
            if (isNotNullOrUndefined(floors)) {
              heyaShosaiList.push(floors);
            }
            if (heyaShosaiList.length === 0) {
              return it.heya_shosai_heya_type;
            }
            return `${it.heya_shosai_heya_type}(${heyaShosaiList.join(' ')})`;
          })
          .join(overviewSeparator)
      : '-';
  const mensekiVal = calcAreaSizeForDisplay(kukaku, tatemono, tatemono.tochi);
  const menseki = mensekiVal === 0 ? '-' : `${mensekiVal.toFixed(2)}㎡`;
  const chikuNengetsu = Datejun.numberToDatejun(tatemono.shunko_datejun)?.toSlashString({ yearAndMonthOnly: true });
  const chinryo = makePriceText(boshuJoken?.chinryo, PriceUnitCode.yen, { emptySubstituteText: '-' });
  const kanrihiUnitCode = asPriceUnitCode(boshuJoken?.kanrihi_kubun_code);
  const kanrihi =
    kanrihiUnitCode === PriceUnitCode.actualCost
      ? '実費'
      : makePriceText(boshuJoken?.kanrihi, kanrihiUnitCode, {
          emptyIfZero: true,
          emptySubstituteText: '-',
        });
  const kyoekihiUnitCode = asPriceUnitCode(boshuJoken?.kyoekihi_kubun_code);
  const kyoekihi =
    kyoekihiUnitCode === PriceUnitCode.actualCost
      ? '実費'
      : makePriceText(boshuJoken?.kyoekihi, kyoekihiUnitCode, {
          emptyIfZero: true,
          emptySubstituteText: '-',
        });
  const zappiUnitCode = asPriceUnitCode(boshuJoken?.zappi_kubun_code);
  const zappi =
    zappiUnitCode === PriceUnitCode.actualCost
      ? '実費'
      : makePriceText(boshuJoken?.zappi_amount, zappiUnitCode, {
          emptyIfZero: true,
          emptySubstituteText: '-',
        });
  const kanrihiEtcArray = [kanrihi, kyoekihi, zappi];
  const kanrihiEtc = kanrihiEtcArray.every(it => it === '-') ? '-' : kanrihiEtcArray.join('/');
  const shikikin = makePriceText(boshuJoken?.shikikin_amount, boshuJoken?.shikikin_kubun_code, {
    emptyIfZero: true,
    emptySubstituteText: '-',
  });
  const reikin = makePriceText(boshuJoken?.reikin_amount, boshuJoken?.reikin_kubun_code, {
    emptyIfZero: true,
    emptySubstituteText: '-',
  });
  const kenrikin = makePriceText(boshuJoken?.kenrikin_amount, boshuJoken?.kenrikin_kubun_code, {
    emptyIfZero: true,
    emptySubstituteText: '-',
  });
  const reikinKenrikinArray = [reikin, kenrikin];
  const reikinKenrikin = reikinKenrikinArray.every(it => it === '-') ? '-' : reikinKenrikinArray.join('/');
  const hoshokin = makePriceText(boshuJoken?.hoshokin_amount, boshuJoken?.hoshokin_kubun_code, {
    emptyIfZero: true,
    emptySubstituteText: '-',
  });
  const shikibiki = makePriceText(boshuJoken?.shikibiki_amount, boshuJoken?.shikibiki_kubun_code, {
    emptyIfZero: true,
    emptySubstituteText: '-',
  });
  const shokyakukin = makePriceText(boshuJoken?.shokyaku_amount, boshuJoken?.shokyaku_kubun_code, {
    emptyIfZero: true,
    emptySubstituteText: '-',
  });
  const shikibikiShokyakuArray = [shikibiki, shokyakukin];
  const shikibikiShokyaku = shikibikiShokyakuArray.every(it => it === '-') ? '-' : shikibikiShokyakuArray.join('/');
  const koshinryoKubunCode = asPriceUnitCode(boshuJoken?.koshinryo_kubun_code);
  const koshinryoCode = [PriceUnitCode.month, PriceUnitCode.year, PriceUnitCode.percent].some(
    it => it === koshinryoKubunCode
  )
    ? koshinryoCodeToText(asKoshinryoCode(boshuJoken?.koshinryo_code))
    : '';
  const koshinryoAmount = makePriceText(boshuJoken?.koshinryo_amount, boshuJoken?.koshinryo_kubun_code, {
    emptySubstituteText: '-',
  });
  const koshinryo = isNotNullOrEmptyString(koshinryoCode) ? `${koshinryoCode} ${koshinryoAmount}` : koshinryoAmount;

  const koshinJimuTesuryo = makePriceText(
    boshuJoken?.koshin_jimu_tesuryo_amount,
    boshuJoken?.koshin_jimu_tesuryo_kubun_code
  );
  let sonotaIchijikin =
    boshuJoken?.chinshaku_sonota_hiyo != null
      ? boshuJoken.chinshaku_sonota_hiyo
          .filter(it => it.sonota_hiyo_code === SonotaHiyoCategoryCode.temporarily)
          .map(
            it =>
              `${it.sonota_hiyo_name}：${makePriceText(it.sonota_hiyo_amount, PriceUnitCode.yen, {
                emptySubstituteText: '-',
              })}`
          )
          .join(' ')
      : '';
  if (sonotaIchijikin.length === 0) {
    sonotaIchijikin = '-';
  }
  let sonotaGetsuji =
    boshuJoken?.chinshaku_sonota_hiyo != null
      ? boshuJoken.chinshaku_sonota_hiyo
          .filter(it => it.sonota_hiyo_code === SonotaHiyoCategoryCode.monthly)
          .map(
            it =>
              `${it.sonota_hiyo_name}：${makePriceText(it.sonota_hiyo_amount, PriceUnitCode.yen, {
                emptySubstituteText: '-',
              })}`
          )
          .join(' ')
      : '';
  if (sonotaGetsuji.length === 0) {
    sonotaGetsuji = '-';
  }
  const freeRentCode = asFreeRentCategoryCode(boshuJoken?.chinshaku_boshu_joken_shosai?.free_rent_tsukisu_kubun_code);
  let freeRent;
  switch (freeRentCode) {
    case null:
      freeRent = '-';
      break;
    case FreeRentCategoryCode.none:
      freeRent = '無';
      break;
    case FreeRentCategoryCode.term:
    case FreeRentCategoryCode.from:
      freeRent = `${
        boshuJoken?.chinshaku_boshu_joken_shosai?.free_rent_tsukisu_amount ?? '-'
      }${freeRentCategoryCodeToText(freeRentCode)}`;
      break;
  }
  const keiyakuKikanKubun = keiyakuKikanKubunCodeToText(
    asKeiyakuKikanKubunCode(boshuJoken?.chinshaku_boshu_joken_shosai?.keiyaku_kikan_kubun_code)
  );
  const genkyoCode = asGenkyoCode(boshuJoken?.chintai_genkyo_code);
  // 建物種別が土地の場合は、土地スペックの現況を採用する
  const genkyo = TochiGenkyoDisplayTatemonoShubetsu.includes(tatemono.tatemono_shubetsu_code)
    ? tatemono.tochi?.tochi_genkyo ?? '-'
    : !isNullOrUndefined(genkyoCode)
    ? genkyoCodeToText(genkyoCode)
    : '-';
  const nyukyoKanoCode = asNyukyoKanoCode(boshuJoken?.nyukyo_kano_code);
  const nyukyoJiki = makeNyukyoKanoText(nyukyoKanoCode, Datejun.numberToDatejun(boshuJoken?.nyukyo_kano_datejun));

  const taikyoDate =
    boshuJoken?.taikyo_date != null ? format(parseISO(boshuJoken.taikyo_date), overviewDateFormat) : '-';
  const publishedDateTime =
    chinshakuBukken.b2b_kokai_kaishi_time != null
      ? format(parseISO(chinshakuBukken.b2b_kokai_kaishi_time), overviewDateTimeFormat)
      : '-';
  const bukkenLastUpdateDate = chinshakuBukken.saishu_koshin_time ? parseISO(chinshakuBukken.saishu_koshin_time) : null;
  const boshuJokenLastUpdateDate = boshuJoken?.saishu_koshin_time ? parseISO(boshuJoken.saishu_koshin_time) : null;
  const updatedDateTime = max([bukkenLastUpdateDate, boshuJokenLastUpdateDate].filter(isNotNullOrUndefined));
  const updatedDateTimeText = isValid(updatedDateTime) ? format(updatedDateTime, overviewDateTimeFormat) : '';

  return [
    {
      label: '物件名',
      content: makeKukakuName(chinshakuBukken, tatemono, kukaku?.heya_kukaku_number ?? ''),
      span: true,
    },
    {
      label: '物件所在地',
      content: commonDisplayContent.jusho,
      span: true,
    },
    {
      label: '交通機関',
      content: commonDisplayContent.kotsu,
      span: true,
    },
    { label: '間取り詳細', content: madori ?? '-' },
    { label: '専有面積', content: menseki },
    {
      label: '所在階',
      content: `${kukaku?.shozaikai && kukaku.shozaikai.length > 0 ? kukaku.shozaikai : '-'}階(地上${
        tatemono.chijo_kaisu ?? '-'
      }階${tatemono.chika_kaisu ? `・地下${tatemono.chika_kaisu}階` : ''})`,
    },
    { label: '部屋向き', content: stringToTableVal(kukaku?.shuyo_saikomen) },
    { label: '建物構造', content: tatemono.kozo ?? '-' },
    { label: '築年月', content: chikuNengetsu ?? '-' },
    { label: '総戸数', content: tatemono.to_sokosu ?? '-', span: true },
    { label: '賃料', content: chinryo, isImage: true },
    { label: '管理費/共益費/雑費', content: kanrihiEtc },
    { label: 'その他月次費用', content: sonotaGetsuji, span: true },
    { label: '敷金', content: shikikin },
    { label: '礼金/権利金', content: reikinKenrikin },
    {
      label: '保証金',
      content: hoshokin,
    },
    {
      label: '敷引き/償却金',
      content: shikibikiShokyaku,
    },
    {
      label: '更新料',
      content: isNotNullOrEmptyString(koshinJimuTesuryo)
        ? `${koshinryo} (更新事務手数料 : ${koshinJimuTesuryo})`
        : koshinryo,
      span: true,
    },
    { label: 'その他一時金', content: sonotaIchijikin, span: true },
    {
      label: 'その他費用関連コメント',
      content: boshuJoken?.sonota_hiyo_kanren_comment ?? '-',
      span: true,
    },
    { label: 'フリーレント有無', content: freeRent },
    {
      label: 'フリーレント詳細',
      content: (
        <div>
          <Linkify matchDecorator={LinkifyUrlSafeMatchDecorator}>
            {stringToTableVal(boshuJoken?.chinshaku_boshu_joken_shosai?.free_rent_joken_shosai)}
          </Linkify>
        </div>
      ),
    },
    {
      label: '損害保険',
      content: (
        <div>
          <Linkify matchDecorator={LinkifyUrlSafeMatchDecorator}>{commonDisplayContent.sompo}</Linkify>
        </div>
      ),
      span: true,
    },
    { label: '契約形態', content: getKeiyakuKubunCodeMap(boshuJoken?.chintai_keiyaku_code) ?? '-' },
    {
      label: '期間',
      content: boshuJoken?.chinshaku_boshu_joken_shosai?.keiyaku_kikan
        ? `${boshuJoken.chinshaku_boshu_joken_shosai.keiyaku_kikan}${keiyakuKikanKubun}`
        : '-',
    },
    {
      label: '保証会社',
      content: (
        <div>
          <Linkify matchDecorator={LinkifyUrlSafeMatchDecorator}>
            {stringToTableVal(
              makeHoshoKaishaText(
                asHoshoninDaikoCode(boshuJoken?.chinshaku_boshu_joken_shosai?.hoshonin_daiko_code),
                boshuJoken?.chinshaku_boshu_joken_shosai?.hoshonin_daiko_kaisha_kubun,
                boshuJoken?.chinshaku_boshu_joken_shosai?.hoshonin_daiko_shosai
              )
            )}
          </Linkify>
        </div>
      ),
    },
    { label: '現況', content: genkyo },
    { label: '入居時期', content: nyukyoJiki },
    { label: '退去日', content: taikyoDate },
    {
      label: '入居条件',
      content: stringToTableVal(
        makeNyukyoJokenText(boshuJoken?.chinshaku_boshu_joken_shosai, boshuJoken?.nyukyo_joken_comment)
      ),
      span: true,
    },
    {
      label: '備考',
      content: (
        <div>
          <Linkify matchDecorator={LinkifyUrlSafeMatchDecorator}>{commonDisplayContent.biko}</Linkify>
        </div>
      ),
      span: true,
    },
    { label: '情報公開日時', content: !isCustomerView ? publishedDateTime : undefined },
    { label: '情報更新日時', content: !isCustomerView ? updatedDateTimeText : undefined },
  ];
};
