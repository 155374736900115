import { getViewportHeight } from '@app/utils';
import { Box, Fade, Modal, styled } from '@mui/material';
import { Close, ChevronRight, ChevronLeft } from 'mdi-material-ui';
import { FC } from 'react';
import type SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import ImageFromFileApi from './ImageFromFileApi';

import { useSwiperState } from '@/Hooks/Swiper';

const buttonCommons = {
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'background-color 200ms',
  fontSize: '2rem',
  backgroundColor: 'rgba(255,255,255,0.25)',
  '&:hover': {
    backgroundColor: 'rgba(255,255,255,0.5)',
  },
  cursor: 'pointer',
  zIndex: 2,
} as const;

const CommonButton = styled(Box)(({ theme }) => ({
  ...buttonCommons,
  top: '30%',
  height: '40%',
  width: '4rem',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const PrevButton = styled(CommonButton)({
  left: 0,
});

const NextButton = styled(CommonButton)({
  right: 0,
});

const CloseButton = styled(Box)(({ theme }) => ({
  ...buttonCommons,
  top: 0,
  right: 0,
  width: '4rem',
  height: '4rem',
  [theme.breakpoints.down('md')]: {
    width: '3rem',
    height: '3rem',
  },
}));

const SwiperSlideBox = styled(Box)(({ theme }) => ({
  padding: 1,
  height: getViewportHeight(100),
  width: '100vw',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    padding: 4,
  },
}));

type ImageCarouselModalProps = {
  isOpen: boolean;
  initialIndex: number;
  imageGuids: readonly string[];
  onClose: () => void;
};

export const ImageCarouselModal: FC<ImageCarouselModalProps> = ({ isOpen, initialIndex, imageGuids, onClose }) => {
  const { index, onSwiper, goNext, goBack } = useSwiperState();

  return (
    <ImageCarouselModalPresenter
      isOpen={isOpen}
      initialIndex={initialIndex}
      imageGuids={imageGuids}
      onClose={onClose}
      index={index}
      onSwiper={onSwiper}
      goNext={goNext}
      goBack={goBack}
    />
  );
};

type ImageCarouselModalPresenterProps = {
  isOpen: boolean;
  initialIndex: number;
  imageGuids: readonly string[];
  onClose: () => void;
  index: number;
  onSwiper: (swiperInstance: SwiperCore) => void;
  goNext: () => void;
  goBack: () => void;
};

export const ImageCarouselModalPresenter: FC<ImageCarouselModalPresenterProps> = ({
  isOpen,
  initialIndex,
  imageGuids,
  onClose,
  index,
  onSwiper,
  goNext,
  goBack,
}) => {
  return (
    <Modal open={isOpen} onClose={onClose} closeAfterTransition>
      <Fade in={isOpen}>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
          }}
        >
          <Swiper virtual initialSlide={initialIndex} onSwiper={onSwiper}>
            {imageGuids.map((ig, idx) => (
              <SwiperSlide key={idx} virtualIndex={idx}>
                <SwiperSlideBox onClick={onClose}>
                  <ImageFromFileApi
                    aspectRatio="fitToParent"
                    sizing="fullwidth"
                    src={ig}
                    sx={{
                      userSelect: 'none',
                      maxWidth: 640,
                    }}
                  />
                </SwiperSlideBox>
              </SwiperSlide>
            ))}
          </Swiper>
          <PrevButton sx={{ display: index === 0 ? 'none' : undefined }} onClick={goBack}>
            <ChevronLeft data-testid="keyboardArrowLeft" fontSize="inherit" />
          </PrevButton>
          <NextButton sx={{ display: index === imageGuids.length - 1 ? 'none' : undefined }} onClick={goNext}>
            <ChevronRight data-testid="keyboardArrowRight" fontSize="inherit" />
          </NextButton>
          <CloseButton onClick={onClose}>
            <Close fontSize="inherit" />
          </CloseButton>
        </Box>
      </Fade>
    </Modal>
  );
};
