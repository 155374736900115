import { deepCompare } from '@app/utils';
import { Select } from '@e-seikatsu/design-system';
import { Grid } from '@mui/material';
import { memo, useCallback, useEffect, useState, FC } from 'react';

import { searchConditionSxProps } from './SearchConditionStyle';

import { SearchConditionHook } from '@/Hooks/Search/SearchConditionHook';
import { borderSxProps } from '@/Hooks/Styles/BorderStyle';
import { ChintaiSearchConditions } from '@/Models/SearchConditions/ChintaiSearchConditions';

type BukkenChikuNensuFormProps = {
  chikuNensuHook: SearchConditionHook<string, ChintaiSearchConditions>;
  isShinchikuHook: SearchConditionHook<boolean, ChintaiSearchConditions>;
  isSmallDevice: boolean;
};
type RadioChoice = {
  value: string;
  label: string;
};
const radioChoices: RadioChoice[] = [
  { value: '3', label: '3年以内' },
  { value: '5', label: '5年以内' },
  { value: '7', label: '7年以内' },
  { value: '10', label: '10年以内' },
  { value: '15', label: '15年以内' },
  { value: '20', label: '20年以内' },
  { value: '25', label: '25年以内' },
  { value: '30', label: '30年以内' },
];

const BukkenChikuNensuForm: FC<BukkenChikuNensuFormProps> = memo(
  ({ chikuNensuHook, isShinchikuHook, isSmallDevice }) => {
    const [choiceState, setChoiceState] = useState<string>('');
    useEffect(() => {
      if (isShinchikuHook.value) {
        setChoiceState('1');
      } else if (chikuNensuHook.value !== undefined) {
        setChoiceState(chikuNensuHook.value);
      }
    }, [chikuNensuHook.value, isShinchikuHook.value]);

    const handleChange = useCallback(
      (value: string): void => {
        const newValue = value;
        setChoiceState(newValue);
        if (newValue === '') {
          chikuNensuHook.setValue('');
          isShinchikuHook.setValue(false);
        } else if (newValue === '1') {
          chikuNensuHook.setValue('');
          isShinchikuHook.setValue(true);
        } else {
          chikuNensuHook.setValue(newValue);
          isShinchikuHook.setValue(false);
        }
      },
      [chikuNensuHook, isShinchikuHook]
    );
    return (
      <Grid container sx={{ ...searchConditionSxProps.root, ...borderSxProps.borderBottom }}>
        <Grid
          item
          xs={12}
          sm={2}
          sx={{ ...searchConditionSxProps.firstCol, ...(isSmallDevice ? borderSxProps.borderBottom : undefined) }}
        >
          築年数
        </Grid>
        <Grid item xs={12} sm={10} sx={{ ...searchConditionSxProps.secondCol, ...searchConditionSxProps.inputCol }}>
          <Select size="small" length={isSmallDevice ? 'full' : 'normal'} value={choiceState} onChange={handleChange}>
            <Select.Option value="">指定しない</Select.Option>
            <Select.Option value="1">新築</Select.Option>
            {radioChoices.map(it => (
              <Select.Option value={it.value} key={it.value}>
                {it.label}
              </Select.Option>
            ))}
          </Select>
        </Grid>
      </Grid>
    );
  },
  deepCompare
);

export default BukkenChikuNensuForm;
