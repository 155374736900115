import { KeyboardEvent, useCallback } from 'react';

export const useHandlerOnEnter = (handler: () => void): ((e: KeyboardEvent) => void) => {
  return useCallback(
    (e: KeyboardEvent) => {
      if (!e.nativeEvent.isComposing && e.key === 'Enter') {
        handler();
      }
    },
    [handler]
  );
};
