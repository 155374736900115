import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import {
  getInitialChintaiSearchConditions,
  parseChintaiSearchPageQuery,
} from '@/Models/SearchConditions/ChintaiBukkenSearchConditions';
import { ChintaiSearchConditions } from '@/Models/SearchConditions/ChintaiSearchConditions';

export const useConditions = (): ChintaiSearchConditions => {
  const location = useLocation();
  return useMemo<ChintaiSearchConditions>(() => {
    if (location.search.length === 0) {
      return getInitialChintaiSearchConditions();
    }

    return parseChintaiSearchPageQuery(location.search);
  }, [location.search]);
};
