import { ChinshakuBukken, Tatemono, Kukaku } from '@app/models';
import { isNullOrUndefined } from '@app/utils';
import { Loading } from '@lib/components';
import { Box, IconButton, styled, Link } from '@mui/material';
import { Close } from 'mdi-material-ui';
import { useEffect, useState, FC } from 'react';

import useChintaiBukkenDetail from './DetailHook';
import { bukkenDetailSxProps, DetailTopHeaderBox, IconButtonWidth } from './DetailPartial/BukkenDetailStyle';
import DetailContainer from './DetailPartial/DetailContainer';

import { useUserActionTracker } from '@/Hooks/Analytics';
import { useIsSmallDevice } from '@/Hooks/Styles/IsSmallDevice';
import { useCustomerViewStateHooks } from '@/Hooks/customerViewHooks';
import { GeneralNotFoundPage } from '@/Pages/error/GeneralNotFoundPage';
import { GeneralServerErrorPage } from '@/Pages/error/GeneralServerErrorPage';

const AnalyticsPlaceName = '賃借物件一覧';

const DetailOuterContainer = styled('div')(({ theme }) => ({
  width: '100vw',
  height: '100%',
  [theme.breakpoints.up('md')]: {
    width: theme.breakpoints.values.md,
  },
}));

const DetailContainerBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  minHeight: '100%',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    width: `calc(100% - ${IconButtonWidth})`,
    marginLeft: 'auto',
  },
}));

const useBukkenDetailViewTracking = (
  bukken: ChinshakuBukken | null,
  tatemono: Tatemono | null,
  kukaku: Kukaku | null
): void => {
  // 物件詳細閲覧を個別のトラッキングイベントで観測
  const tracker = useUserActionTracker(AnalyticsPlaceName);
  const [done, setDone] = useState(false);
  useEffect(() => {
    if (done || bukken === null || tatemono === null || kukaku === null) {
      return;
    }
    tracker('賃借物件詳細閲覧', bukken.chinshaku_bukken_guid);
    setDone(true);
  }, [bukken, done, kukaku, tatemono, tracker]);
};

type DetailPageProps = {
  onClose: () => void;
  isOwnBukkenPage: boolean;
};

const DetailPage: FC<DetailPageProps> = props => {
  const customerViewStateHooks = useCustomerViewStateHooks();
  const detailHooks = useChintaiBukkenDetail();
  const isSmallDevice = useIsSmallDevice();
  useBukkenDetailViewTracking(detailHooks.bukken, detailHooks.tatemono, detailHooks.kukaku);

  // 読込中
  if ((detailHooks.bukken === null || detailHooks.tatemono === null) && isNullOrUndefined(detailHooks.errorCode)) {
    return (
      <DetailOuterContainer>
        <DetailContainerBox>
          <DetailTopHeaderBox>
            <Box sx={bukkenDetailSxProps.headerLabel}>
              <IconButton component={Link} onClick={props.onClose} size="large">
                <Close />
              </IconButton>
            </Box>
          </DetailTopHeaderBox>
          <DetailContainerBox height="100%">
            <Loading />
          </DetailContainerBox>
        </DetailContainerBox>
      </DetailOuterContainer>
    );
  }

  // ネットワークエラー発生時
  if (detailHooks.bukken === null || detailHooks.tatemono === null) {
    return (
      <DetailOuterContainer>
        <DetailContainerBox>
          <DetailTopHeaderBox>
            <Box sx={bukkenDetailSxProps.headerLabel}>
              <IconButton component={Link} onClick={props.onClose} size="large">
                <Close />
              </IconButton>
            </Box>
          </DetailTopHeaderBox>
          {detailHooks.errorCode === 404 ? <GeneralNotFoundPage /> : <GeneralServerErrorPage />}
        </DetailContainerBox>
      </DetailOuterContainer>
    );
  }

  return (
    <DetailOuterContainer>
      <DetailContainerBox>
        <DetailContainer
          chinshakuBukken={detailHooks.bukken}
          kukaku={detailHooks.kukaku}
          tatemono={detailHooks.tatemono}
          isMobile={isSmallDevice}
          onClose={props.onClose}
          isCustomerView={customerViewStateHooks.isCustomerView}
          isOwnBukkenPage={props.isOwnBukkenPage}
        />
      </DetailContainerBox>
    </DetailOuterContainer>
  );
};

export default DetailPage;
