import {
  asChushajoJokyoCode,
  asPriceUnitCode,
  ChushajoJokyoCode,
  ChushajoJokyoCodeToTextMap,
  PriceUnitCode,
} from '@app/enums';
import {
  ChinshakuBoshuJokenChushajo,
  ChinshakuBukken,
  Kukaku,
  KukakuOkugaiSetsubi,
  KukakuTokucho,
  Tatemono,
  TatemonoTokucho,
} from '@app/models';
import { isNotNullOrEmptyString, isNotNullOrUndefined, isNullOrUndefined } from '@app/utils';
import { Box } from '@mui/material';
import { FC, Fragment, useMemo } from 'react';

import { ChinshakuBukkenDetailProps } from '@/Pages/bukken/chintai/DetailPartial/ChinshakuBukkenDetailProps';
import { DetailGrid, DisplayData } from '@/Pages/bukken/chintai/DetailPartial/DetailGrid';
import { SectionHeader, SectionSubHeader } from '@/Pages/bukken/chintai/DetailPartial/SectionHeaders';
import { makePriceText } from '@/Utils/DisplayText/Money';

const uncategorized = 'カテゴリ不明';
const otherCategory = 'その他';
const securityCategory = '管理・防犯';
const setsubiNameSeparator = '，';

const calcFacilityData = (
  chinshakuBukken: ChinshakuBukken,
  kukaku: Kukaku | null,
  tatemono: Tatemono
): DisplayData[] => {
  const facilityData: DisplayData[] = [];
  facilityData.push({
    label: '駐車場',
    content: getChushajoInfo(chinshakuBukken?.chinshaku_boshu_joken?.chinshaku_boshu_joken_chushajo),
    span: true,
  });
  const lifelineData = [];
  const denkiFlag = tatemono?.tatemono_shosai?.denki_flag;
  const denkiAmpare = tatemono?.tatemono_shosai?.denki_ampere ?? kukaku?.denki_ampere;
  lifelineData.push(
    '電気：' +
      (isNotNullOrUndefined(denkiFlag)
        ? denkiFlag
          ? `有${isNotNullOrUndefined(denkiAmpare) ? `（${denkiAmpare}A）` : ''}`
          : '-'
        : '-')
  );
  lifelineData.push(`ガス：${tatemono?.tatemono_shosai?.gas_kubun ?? '-'}`);
  lifelineData.push(`上水道：${tatemono?.tatemono_shosai?.josui_kubun ?? '-'}`);
  lifelineData.push(`下水道：${tatemono?.tatemono_shosai?.gesui_kubun ?? '-'}`);
  facilityData.push({ label: 'ライフライン', content: lifelineData.join(setsubiNameSeparator), span: true });
  facilityData.push(...getKukakuSetsubi(kukaku?.kukaku_tokucho));
  return facilityData;
};

type KukakuTokuchoGroup = {
  category: string;
  tokuchoList: string[];
};

const getKukakuSetsubi = (kukakuTokuchoList: readonly Readonly<KukakuTokucho>[] | undefined): DisplayData[] => {
  if (kukakuTokuchoList === undefined) {
    return [];
  }
  const kukakuTokuchoGroup = kukakuTokuchoList.reduce<KukakuTokuchoGroup[]>((accumulator, currentVal) => {
    // セキュリティに属する項目を除去
    if (currentVal.tokucho_category_name === securityCategory) {
      return accumulator;
    }
    const element = accumulator.find(p => p.category === currentVal.tokucho_category_name);
    if (element) {
      element.tokuchoList.push(currentVal.tokucho_name);
    } else {
      accumulator.push({
        category: currentVal.tokucho_category_name ?? uncategorized,
        tokuchoList: [currentVal.tokucho_name],
      });
    }
    return accumulator;
  }, []);
  const kukakuData = kukakuTokuchoGroup.map<DisplayData>(it => {
    return {
      label: it.category,
      content: it.tokuchoList.join(setsubiNameSeparator),
      span: true,
    };
  });
  return kukakuData.sort(setsubiDataComparer);
};

const getTatemonoSetsubi = (tatemonoTokuchoList: readonly Readonly<TatemonoTokucho>[] | undefined): DisplayData[] => {
  if (tatemonoTokuchoList === undefined) {
    return [];
  }
  const tatemonoSetsubiGroup = tatemonoTokuchoList.reduce<KukakuTokuchoGroup[]>((accumulator, currentVal) => {
    // セキュリティに属する項目を除去
    if (currentVal.tokucho_category_name === securityCategory) {
      return accumulator;
    }
    const element = accumulator.find(p => p.category === currentVal.tokucho_category_name);
    if (element) {
      element.tokuchoList.push(currentVal.tokucho_name);
    } else {
      accumulator.push({
        category: currentVal.tokucho_category_name ?? uncategorized,
        tokuchoList: [currentVal.tokucho_name],
      });
    }
    return accumulator;
  }, []);
  const tatemonoData = tatemonoSetsubiGroup.map<DisplayData>(it => {
    return {
      label: it.category,
      content: it.tokuchoList.join(setsubiNameSeparator),
      span: true,
    };
  });
  return tatemonoData.sort(setsubiDataComparer);
};

const getSecurityData = (
  kukakuTokuchoList: readonly Readonly<KukakuTokucho>[] | undefined,
  tatemonoTokuchoList: readonly Readonly<TatemonoTokucho>[] | undefined
): DisplayData | null => {
  const kukakuSecurity = kukakuTokuchoList?.filter(it => it.tokucho_category_name === securityCategory) ?? [];
  const tatemonoSecurity = tatemonoTokuchoList?.filter(it => it.tokucho_category_name === securityCategory) ?? [];
  const securityList = kukakuSecurity.concat(tatemonoSecurity).map(it => it.tokucho_name);
  if (securityList.length === 0) {
    return null;
  }
  return {
    label: securityCategory,
    content: securityList.join(setsubiNameSeparator),
    span: true,
  };
};

const getKukakuOkugaiSetsubi = (
  okugaiSetsubiList: readonly Readonly<KukakuOkugaiSetsubi>[] | undefined
): DisplayData | null => {
  if (okugaiSetsubiList === undefined) {
    return null;
  }
  const okugaiSetsubi = okugaiSetsubiList
    .filter(it => it.okugai_setsubi_kubun !== null)
    .map(it => {
      let ret = it.okugai_setsubi_kubun;
      const setsubiInfo: string[] = [];
      if (isNotNullOrUndefined(it.okugai_setsubi_hoko)) {
        setsubiInfo.push(`方向：${it.okugai_setsubi_hoko}`);
      }
      if (isNotNullOrUndefined(it.okugai_setsubi_menseki)) {
        setsubiInfo.push(`面積：${it.okugai_setsubi_menseki}㎡`);
      }
      if (setsubiInfo.length > 0) {
        ret += `（${setsubiInfo.join('，')}）`;
      }
      return ret;
    })
    .join(setsubiNameSeparator);
  if (okugaiSetsubi.length === 0) {
    return null;
  }
  return {
    label: '屋外設備',
    content: okugaiSetsubi,
    span: true,
  };
};

const getChushajoInfo = (chushajo: ChinshakuBoshuJokenChushajo | null | undefined): string => {
  if (isNullOrUndefined(chushajo)) {
    return '-';
  }
  const chushajoJokyo = asChushajoJokyoCode(chushajo.chushajo_jokyo);
  const churinjoBikeInfo = getChurinjoBikeInfo(chushajo);
  // 駐車場情報が存在しない場合は必ず格納されている駐輪場・バイク置場情報のみ表示
  if (chushajoJokyo === null) {
    return churinjoBikeInfo;
  }

  const chushajoInfo: string[] = [];
  chushajoInfo.push(`${ChushajoJokyoCodeToTextMap[chushajoJokyo]}`);

  // 駐車場状況が 無 or 要問合せ or 空無 の場合、空き台数などを表示すると矛盾した内容になるため、状況＋駐輪場、バイク置き場のみにする
  if (
    chushajo.chushajo_jokyo === ChushajoJokyoCode.無 ||
    chushajo.chushajo_jokyo === ChushajoJokyoCode.要問い合わせ ||
    chushajo.chushajo_jokyo === ChushajoJokyoCode.空無
  ) {
    return [`駐車場：${chushajoInfo}`, churinjoBikeInfo].join(setsubiNameSeparator);
  }

  // 価格
  if (
    isNotNullOrUndefined(chushajo.chushajo_kakaku_from) &&
    isNotNullOrUndefined(chushajo.chushajo_kakaku_to) &&
    chushajo.chushajo_kakaku_from === chushajo.chushajo_kakaku_to
  ) {
    chushajoInfo.push(
      `価格：${makePriceText(chushajo.chushajo_kakaku_from, PriceUnitCode.yen, {
        emptySubstituteText: '-',
      })}/月`
    );
  } else if (isNotNullOrUndefined(chushajo.chushajo_kakaku_from) && isNotNullOrUndefined(chushajo.chushajo_kakaku_to)) {
    chushajoInfo.push(
      `価格：${makePriceText(chushajo.chushajo_kakaku_from, PriceUnitCode.yen, {
        amountOnly: true,
        emptySubstituteText: '-',
      })}～${makePriceText(chushajo.chushajo_kakaku_to, PriceUnitCode.yen, {
        emptySubstituteText: '-',
      })}/月`
    );
  } else if (isNotNullOrUndefined(chushajo.chushajo_kakaku_from)) {
    chushajoInfo.push(
      `価格：${makePriceText(chushajo.chushajo_kakaku_from, PriceUnitCode.yen, {
        emptySubstituteText: '-',
      })}/月～`
    );
  } else if (isNotNullOrUndefined(chushajo.chushajo_kakaku_to)) {
    chushajoInfo.push(
      `価格：～${makePriceText(chushajo.chushajo_kakaku_to, PriceUnitCode.yen, {
        emptySubstituteText: '-',
      })}/月`
    );
  }

  chushajoInfo.push(isNotNullOrEmptyString(chushajo.chushajo_shubetsu) ? `種別：${chushajo.chushajo_shubetsu}` : '');
  chushajoInfo.push(isNotNullOrUndefined(chushajo.chushajo_kyori) ? `駐車場まで${chushajo.chushajo_kyori}m` : '');
  chushajoInfo.push(
    isNotNullOrEmptyString(chushajo.chushajo_shutter_shubetsu)
      ? `シャッター：${chushajo.chushajo_shutter_shubetsu}`
      : ''
  );
  chushajoInfo.push(
    isNotNullOrEmptyString(chushajo.chushajo_yane_shubetsu) ? `屋根：${chushajo.chushajo_yane_shubetsu}` : ''
  );
  chushajoInfo.push(
    isNotNullOrUndefined(chushajo.chushajo_aki_daisu) ? `空き台数：${chushajo.chushajo_aki_daisu}台 ` : ''
  );
  if (
    isNotNullOrUndefined(chushajo.shikichi_nai_chusha_kano_daisu) ||
    isNotNullOrUndefined(chushajo.shikichi_gai_chusha_kano_daisu)
  ) {
    let chushaDaisu = '駐車可能台数：';
    if (isNotNullOrUndefined(chushajo.shikichi_nai_chusha_kano_daisu)) {
      chushaDaisu += `${chushajo.shikichi_nai_chusha_kano_daisu}台（敷地内）`;
    }
    if (isNotNullOrUndefined(chushajo.shikichi_gai_chusha_kano_daisu)) {
      chushaDaisu += `${chushajo.shikichi_gai_chusha_kano_daisu}台（敷地外）`;
    }
    chushajoInfo.push(chushaDaisu);
  }
  // 敷金
  const ChushajoShikikinKubunCode = asPriceUnitCode(chushajo.chushajo_shikikin_kubun_code);
  chushajoInfo.push(
    isNotNullOrUndefined(chushajo.chushajo_shikikin_amount) && isNotNullOrUndefined(ChushajoShikikinKubunCode)
      ? `駐車場敷金：${makePriceText(chushajo.chushajo_shikikin_amount, ChushajoShikikinKubunCode)}`
      : ''
  );
  // 礼金
  const ChushajoReikinKubunCode = asPriceUnitCode(chushajo.chushajo_reikin_kubun_code);
  chushajoInfo.push(
    isNotNullOrUndefined(chushajo.chushajo_reikin_amount) && isNotNullOrUndefined(ChushajoReikinKubunCode)
      ? `駐車場礼金：${makePriceText(chushajo.chushajo_reikin_amount, ChushajoReikinKubunCode)}`
      : ''
  );

  // 駐輪場・バイク置場
  chushajoInfo.push(churinjoBikeInfo);

  chushajoInfo.push(
    isNotNullOrEmptyString(chushajo.chushajo_comment_boshu_joken_yo)
      ? `コメント：${chushajo.chushajo_comment_boshu_joken_yo} `
      : ''
  );
  chushajoInfo.push(
    isNotNullOrEmptyString(chushajo.chushajo_sonota_seigen) ? `その他制限：${chushajo.chushajo_sonota_seigen} ` : ''
  );
  return chushajoInfo.filter(it => it.length > 0).join(setsubiNameSeparator);
};

const getChurinjoBikeInfo = (chushajo: ChinshakuBoshuJokenChushajo): string => {
  return [
    chushajo.churinjo_flag === true ? '駐輪場：有' : '駐輪場：無',
    chushajo.bike_okiba_flag === true ? 'バイク置き場：有' : 'バイク置き場：無',
  ].join(setsubiNameSeparator);
};

// カテゴリの表示順序を各詳細画面間で一定にする
const setsubiDataComparer = (x: DisplayData, y: DisplayData): number => {
  if (x.label === uncategorized) {
    return 1;
  }
  if (y.label === uncategorized) {
    return -1;
  }
  if (x.label === otherCategory) {
    return 1;
  }
  if (y.label === otherCategory) {
    return -1;
  }
  return x.label.localeCompare(y.label, 'ja');
};

export const FacilityDetail: FC<ChinshakuBukkenDetailProps> = props => {
  const facilityData = useMemo<DisplayData[]>(
    () => calcFacilityData(props.chinshakuBukken, props.kukaku, props.tatemono),
    [props.chinshakuBukken, props.kukaku, props.tatemono]
  );
  const tatemonoData = useMemo<DisplayData[]>(
    () => getTatemonoSetsubi(props.tatemono?.tatemono_tokucho),
    [props.tatemono.tatemono_tokucho]
  );
  const okugaiData = useMemo<DisplayData[]>(() => {
    const kukakuOkugaiSetsubi = getKukakuOkugaiSetsubi(props.kukaku?.kukaku_okugai_setsubi);
    return kukakuOkugaiSetsubi !== null ? [kukakuOkugaiSetsubi] : [];
  }, [props.kukaku?.kukaku_okugai_setsubi]);
  const securityData = useMemo<DisplayData[]>(() => {
    const security = getSecurityData(props.kukaku?.kukaku_tokucho, props.tatemono?.tatemono_tokucho);
    return security !== null ? [security] : [];
  }, [props.kukaku?.kukaku_tokucho, props.tatemono?.tatemono_tokucho]);

  return (
    <Box pt={2}>
      <SectionHeader>設備詳細</SectionHeader>
      <SectionSubHeader>区画設備</SectionSubHeader>
      <DetailGrid data={facilityData} />
      {tatemonoData.length > 0 && (
        <Fragment>
          <SectionSubHeader>建物設備</SectionSubHeader>
          <DetailGrid data={tatemonoData} />
        </Fragment>
      )}
      {securityData.length > 0 && (
        <Fragment>
          <SectionSubHeader>セキュリティ</SectionSubHeader>
          <DetailGrid data={securityData} />
        </Fragment>
      )}
      {okugaiData.length > 0 && (
        <Fragment>
          <SectionSubHeader>屋外設備</SectionSubHeader>
          <DetailGrid data={okugaiData} />
        </Fragment>
      )}
    </Box>
  );
};
