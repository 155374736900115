import { deepCompare } from '@app/utils';
import { Checkbox } from '@e-seikatsu/design-system';
import { Box, Grid } from '@mui/material';
import { memo, FC } from 'react';

import { searchConditionSxProps } from './SearchConditionStyle';

import { SelectSearchConditionHook } from '@/Hooks/Search/CustomSearchCondition';
import { SearchConditionHook } from '@/Hooks/Search/SearchConditionHook';
import { borderSxProps } from '@/Hooks/Styles/BorderStyle';
import { NaikenConditions } from '@/Models/SearchConditions/ChintaiSearchConditionEnums/ChintaiSearchConditionEnums';
import { ChintaiSearchConditions } from '@/Models/SearchConditions/ChintaiSearchConditions';

type BukkenStateFormProps = {
  naikenConditionHook: SelectSearchConditionHook<NaikenConditions, ChintaiSearchConditions>;
  isExcludeMoshikomiExistCondition: SearchConditionHook<boolean, ChintaiSearchConditions>;
  isSokunyukyoEnableCondition: SearchConditionHook<boolean, ChintaiSearchConditions>;
  isSmallDevice: boolean;
};

const BukkenStateForm: FC<BukkenStateFormProps> = memo(props => {
  return (
    <Grid container sx={{ ...searchConditionSxProps.root, ...borderSxProps.borderBottom }}>
      <Grid
        item
        xs={12}
        sm={2}
        sx={{ ...searchConditionSxProps.firstCol, ...(props.isSmallDevice ? borderSxProps.borderBottom : null) }}
      >
        物件の状況
      </Grid>
      <Grid
        item
        xs={12}
        sm={10}
        sx={{
          ...searchConditionSxProps.secondCol,
        }}
      >
        <Box display="flex" flexWrap="wrap" gap={1}>
          <Checkbox
            variant="outlined"
            size="small"
            label="内見可能のみ表示"
            checked={props.naikenConditionHook.value === NaikenConditions.enable}
            onChange={(e): void => {
              if (e.target.checked) {
                props.naikenConditionHook.setValue(NaikenConditions.enable);
              } else {
                props.naikenConditionHook.setValue(NaikenConditions.unspecified);
              }
            }}
          />
          <Checkbox
            variant="outlined"
            size="small"
            label="即入居可能のみ表示"
            checked={props.isSokunyukyoEnableCondition.value}
            onChange={(e): void => {
              props.isSokunyukyoEnableCondition.setValue(e.target.checked);
            }}
          />
          <Checkbox
            variant="outlined"
            size="small"
            label="申込ありを除外"
            checked={props.isExcludeMoshikomiExistCondition.value}
            onChange={(e): void => {
              props.isExcludeMoshikomiExistCondition.setValue(e.target.checked);
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}, deepCompare);

export default BukkenStateForm;
