import { isNotNullOrUndefined, isNullOrUndefined, WGSLatLng } from '@app/utils';
import { defaultMapZoom, NavitimeMapHook } from '@lib/components';
import { useEffect, useState } from 'react';
import { useMount } from 'react-use';
import { v4 as uuidv4 } from 'uuid';

import { useDependency } from '@/Hooks/DependencyHook';

export const useDetailMap = (inputWGSLatLng: Partial<WGSLatLng>, address: string): NavitimeMapHook => {
  const navitimeApiService = useDependency('navitimeApiService');

  const [mapID] = useState(uuidv4());
  const [latLng, setLatLng] = useState<WGSLatLng | null>(null);
  const [map, setMap] = useState<navitime.geo.Map | null>(null);
  const [pin, setPin] = useState<navitime.geo.overlay.Pin | null>(null);
  const [mountTargetRef, setMountTargetRef] = useState<HTMLElement | null>(null);

  useMount(() => {
    const load = async (): Promise<void> => {
      if (isNullOrUndefined(address)) {
        return;
      }
      const latLng = await navitimeApiService.getLatLngFromAddress(address);
      setLatLng(latLng);
    };
    if (isNullOrUndefined(inputWGSLatLng.latitude) || isNullOrUndefined(inputWGSLatLng.longitude)) {
      load();
    } else {
      setLatLng({ latitude: inputWGSLatLng.latitude, longitude: inputWGSLatLng.longitude });
    }
  });

  useEffect(() => {
    if (isNullOrUndefined(window.navitime) || isNullOrUndefined(mountTargetRef)) {
      return;
    }
    if (latLng !== null && map === null) {
      const newMap = new window.navitime.geo.Map(
        mapID,
        new window.navitime.geo.LatLng(latLng.latitude, latLng.longitude),
        defaultMapZoom
      );
      setMap(newMap);
    }
  }, [latLng, map, mapID, mountTargetRef]);

  useEffect(() => {
    if (isNullOrUndefined(window.navitime)) {
      return;
    }
    if (map === null || latLng?.longitude == null || latLng?.latitude == null) {
      return;
    }
    const newlatLng = new navitime.geo.LatLng(latLng.latitude, latLng.longitude);
    if (isNotNullOrUndefined(pin)) {
      return;
    }
    setPin(
      new window.navitime.geo.overlay.Pin({
        map: map,
        icon: `/pin_red.png`,
        position: new window.navitime.geo.LatLng(latLng.latitude, latLng.longitude),
        draggable: false,
        zIndex: 10,
        raiseOnDrag: true,
        cursor: 'auto',
        iconPosition: window.navitime.geo.ControlPosition.BOTTOM,
      })
    );
    map.moveTo(newlatLng, map.getZoom());
  }, [latLng, map, pin]);

  return {
    mapRef: setMountTargetRef,
    mapID,
  };
};
