import { Box, Typography } from '@mui/material';
import { MapMarker } from 'mdi-material-ui';
import { FC } from 'react';

type MapButtonProps = {
  onClick: () => void;
};

const TransitMapButton: FC<MapButtonProps> = ({ onClick }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        textDecoration: 'underline',
      }}
    >
      <MapMarker fontSize="small" />
      <Typography>地図</Typography>
    </Box>
  );
};

export default TransitMapButton;
